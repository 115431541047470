define("product-management/pods/admin/settings/code-snippets/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P4uiaBj/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"stardust/code-snippets/form\",[],[[\"@showRoute\"],[\"admin.settings.code-snippets.show\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/code-snippets/new/template.hbs"
    }
  });

  _exports.default = _default;
});