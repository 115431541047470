define("product-management/pods/components/site-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2lTGlHfH",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"suggestionTemplate\",\"source\",\"highlight\",\"action\",\"autoFocus\",\"class\",\"placeholder\",\"async\",\"selection\",\"transformSelection\"],[[35,2],[30,[36,0],[[32,0,[\"search\"]]],null],true,[30,[36,0],[[32,0,[\"select\"]]],null],true,\"p-2 rounded border border-gray-200 w-full\",\"search\",true,[35,1],[30,[36,0],[[32,0,[\"transformSelection\"]]],null]]]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"searchValue\",\"searchResultTemplate\",\"aupac-typeahead\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/site-search/template.hbs"
    }
  });

  _exports.default = _default;
});