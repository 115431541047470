define("product-management/pods/components/tags/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "65GgYX/O",
    "block": "{\"symbols\":[\"tag\",\"@taggable\"],\"statements\":[[10,\"div\"],[14,0,\"w-64\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"flex flex-wrap items-center cursor-pointer\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleToggleEdit\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2,[\"tags\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"tags/item\",[],[[\"@tag\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"        \"],[10,\"a\"],[12],[2,\"\\n          Edit Tags\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"tags/edit\",[],[[\"@taggable\",\"@onEditingComplete\"],[[32,2],[30,[36,0],[[32,0,[\"handleEditComplete\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"-track-array\",\"each\",\"isEditing\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/tags/list/template.hbs"
    }
  });

  _exports.default = _default;
});