define("product-management/pods/components/wip-table/item-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bXzemTg9",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],[[35,8,[\"icon\"]]],[[\"size\"],[1]]]],[2,\"\\n\"],[10,\"span\"],[12],[1,[34,9]],[13],[2,\"\\n\\n\"],[6,[37,14],[[30,[36,13],[[30,[36,12],[[35,11],\"in_process\"],null],[30,[36,10],[[35,2],\"overall_status\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"release\"],[12],[2,\"\\n\"],[6,[37,6],null,[[\"onClick\",\"class\"],[[30,[36,1],[[32,0],[30,[36,5],[[35,4]],null],true],null],\"btn btn-danger btn-xs\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],[\"undo\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[6,[37,7],null,[[\"triggerEvents\",\"renderInPlace\",\"visible\",\"placement\",\"title\"],[\"manual\",true,[35,4],\"bottom\",\"Restart?\"]],[[\"default\"],[{\"statements\":[[2,\"Are you sure you want to release this back to the queue?\\n      \"],[10,\"hr\"],[12],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-xs btn-danger\"],[4,[38,1],[[32,0],\"release\",[35,0],[35,2]],null],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"undo\"],null]],[2,\" Release\\n      \"],[13],[2,\"\\n      or \"],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],[30,[36,5],[[35,4]],null],false],null],[12],[2,\"cancel\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,14],[[30,[36,13],[[30,[36,12],[[35,11],\"in_process\"],null],[30,[36,12],[[35,2],\"overall_status\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],\"forceComplete\",[35,0]],null],[12],[2,\"Force\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"item\",\"action\",\"status\",\"fa-icon\",\"isPopoverVisible\",\"mut\",\"bs-button\",\"bs-popover\",\"icon\",\"displayText\",\"not-eq\",\"statusValue\",\"eq\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/wip-table/item-status/template.hbs"
    }
  });

  _exports.default = _default;
});