define("product-management/pods/components/purchasing/purchase-order/form/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5mtYHw1y",
    "block": "{\"symbols\":[\"item\",\"@items\",\"@purchaseOrder\",\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[30,[36,2],[\"id\",[32,3,[\"purchaseOrderItems\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,4,[[30,[36,1],null,[[\"item\"],[[30,[36,0],[\"purchasing/purchase-order/form/item-form\"],[[\"purchaseOrder\",\"items\",\"model\"],[[32,3],[32,2],[32,1]]]]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/purchase-order/form/body/template.hbs"
    }
  });

  _exports.default = _default;
});