define("product-management/pods/confirmation/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    firstName: (0, _model.attr)("string"),
    email: (0, _model.attr)("string"),
    password: (0, _model.attr)("string")
  });

  _exports.default = _default;
});