define("product-management/components/ordering/wizards/screen-only/screen-type", ["exports", "marygrove-components/components/ordering/wizards/screen-only/screen-type"], function (_exports, _screenType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _screenType.default;
    }
  });
});