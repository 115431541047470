define("product-management/pods/orphan-count/adapter", ["exports", "@ember-data/adapter/rest", "ember-simple-auth-components/configuration", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _rest, _configuration, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    authorizer: 'authorizer:devise',
    host: _configuration.default.host,
    namespace: "api/v1",
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      return "".concat(this.host, "/api/v1/orphan_count");
    }
  });

  _exports.default = _default;
});