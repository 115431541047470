define("product-management/pods/components/ordering/return-of-goods/warranty/filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rl4H2527",
    "block": "{\"symbols\":[\"@route\",\"@dealerView\"],\"statements\":[[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@query\"],[[32,1],[30,[36,0],null,[[\"status\",\"mode\"],[\"created\",\"warranty-request\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Pending\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@query\"],[[32,1],[30,[36,0],null,[[\"status\",\"mode\"],[\"order_placed\",\"warranty-request\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Approved\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@query\"],[[32,1],[30,[36,0],null,[[\"status\",\"mode\"],[\"complete\",\"warranty-request\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Complete\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hash\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/return-of-goods/warranty/filter/template.hbs"
    }
  });

  _exports.default = _default;
});