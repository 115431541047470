define("product-management/pods/dealer-admin/order/conversion-kit/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x9BaHC+w",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n    \"],[1,[30,[36,13],null,[[\"line-item\",\"details\",\"options\",\"motors\",\"screens\",\"colorOptions\",\"experience\",\"screenType\",\"selectedScreen\",\"step\",\"can-go-next\",\"steps-path\",\"onScreenSelect\",\"onClearScreen\",\"onGotoPrevious\",\"onFieldChange\",\"onSelect\"],[[35,12],[35,11],[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"selectScreen\"],null],[30,[36,0],[[32,0],\"clearScreen\"],null],[30,[36,0],[[32,0],\"moveStep\",-1],null],[30,[36,0],[[32,0],\"fieldChange\"],null],[30,[36,0],[[32,0],\"pushSelection\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n    \"],[1,[30,[36,15],null,[[\"line-item\",\"details\",\"onAddToOrder\",\"is-ready-to-add\"],[[35,12],[35,11],[30,[36,0],[[32,0],\"addToOrder\"],null],[35,14]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,17],null,[[\"on-move-step\",\"step\",\"steps\",\"can-go-next\"],[[30,[36,0],[[32,0],\"moveStep\"],null],[35,3],[35,16],[35,2]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"stepsPath\",\"canGoNext\",\"step\",\"selectedScreen\",\"screenType\",\"experience\",\"colorOptions\",\"screens\",\"motors\",\"options\",\"details\",\"lineItem\",\"ordering/wizard-step\",\"isReadyToAdd\",\"ordering/wizard-summary\",\"steps\",\"ordering/wizard-navigation\"]}",
    "meta": {
      "moduleName": "product-management/pods/dealer-admin/order/conversion-kit/index/template.hbs"
    }
  });

  _exports.default = _default;
});