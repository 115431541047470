define("product-management/pods/components/remote-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TAbGft54",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"img\"],[16,\"src\",[32,0,[\"image\",\"src\"]]],[17,1],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"imageLoaded\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/remote-image/template.hbs"
    }
  });

  _exports.default = _default;
});