define("product-management/pods/components/dealers/tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "14DpAr0K",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[6,[37,8],[[35,7,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"my-2\"],[12],[2,\"\\n    Tags:\\n    \"],[10,\"div\"],[14,0,\"flex flex-wrap items-center\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"tags\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"block p-2 text-white bg-green-dark rounded-lg text-xs mx-1 my-2\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n                \"],[10,\"span\"],[14,0,\"block mr-1\"],[12],[2,\"\\n                    \"],[1,[32,1]],[2,\"\\n                \"],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"mr-1\"],[12],[2,\"\\n                    \"],[11,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleDeleteTag\"]],[32,1]],null]],null],[12],[2,\"\\n                        \"],[10,\"i\"],[14,0,\"fa fa-trash-o block\"],[12],[13],[2,\"\\n                    \"],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[11,\"form\"],[4,[38,1],[\"submit\",[30,[36,0],[[32,0,[\"handleNewTagSubmit\"]]],null]],null],[12],[2,\"\\n            \"],[11,\"input\"],[24,0,\"border border-grey-light rounded p-2 text-xs\"],[16,2,[34,5]],[4,[38,6],[[32,0,[\"handleNewTagInsert\"]],[32,0]],null],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleNewTag\"]]],null]],null],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"dealer\",\"-track-array\",\"each\",\"newTag\",\"did-insert\",\"loadData\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/tags/template.hbs"
    }
  });

  _exports.default = _default;
});