define("product-management/pods/components/icon-radio-button/component", ["exports", "@babel/runtime/helpers/esm/typeof", "lodash/isEqual"], function (_exports, _typeof2, _isEqual2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    icon: "fa-check-circle",
    classNames: ["ember-icon-radio-button"],
    click: function click() {
      this.setValue();
    },
    classNameBindings: ["checked", "wrapper-class"],
    hasBeenChecked: false,
    isOther: false,
    isNotOther: Ember.computed.not("isOther"),
    otherInputClasses: Ember.computed("other-class", function () {
      var result = "ember-icon-radio-button-other";
      var otherClass = this["other-class"];

      if (Ember.isPresent(otherClass)) {
        result += " ".concat(otherClass);
      }

      return result;
    }),
    sameAsModelValue: Ember.computed("modelValue", "value", function () {
      var value = this.value,
          modelValue = this.modelValue;
      if ((0, _typeof2.default)(value) === "object") return (0, _isEqual2.default)(value, modelValue);else return this.modelValue === this.value;
    }),
    isStandardOptionNotSelected: Ember.computed.not("isStandardOptionSelected"),
    isCustomChecked: Ember.computed.and("isStandardOptionNotSelected", "hasBeenChecked"),
    isNormalChecked: Ember.computed.and("isNotOther", "sameAsModelValue"),
    checked: Ember.computed("isNormalChecked", "isCustomChecked", function () {
      var isOther = this.isOther;
      return isOther ? this.isCustomChecked : this.isNormalChecked;
    }),
    actions: {
      selectValue: function selectValue() {
        this.setValue();
      }
    },
    setValue: function setValue() {
      if (this.isOther) {
        Ember.set(this, "hasBeenChecked", true), Ember.set(this, "modelValue", this.customValue); // this.$("input").focus();
      } else {
        this.set("modelValue", this.value);
      }

      this.attrs.onSetValue();
    }
  });

  _exports.default = _default;
});