define("product-management/pods/components/painless-manufacturing/production/release-from-in-process/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bc0LaVZx",
    "block": "{\"symbols\":[\"ShopCode\"],\"statements\":[[11,\"a\"],[24,0,\"cursor-pointer font-semibold\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"toggleRelease\"]]],null]],null],[12],[2,\"\\n  Release from In Process\\n\"],[13],[2,\"\\n\"],[6,[37,3],[[32,0,[\"isShowingRelease\"]]],null,[[\"default\"],[{\"statements\":[[8,\"shop/shop-code\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n    \"],[8,[32,1,[\"entry\"]],[],[[\"@inputType\"],[\"text\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,[36,0],[[32,1,[\"isValid\"]]],null]],[24,0,\"btn btn-block btn-sm btn-danger\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleRelease\"]],[32,1,[\"value\"]]],null]],null],[12],[2,\"\\n      Confirm\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"not\",\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/release-from-in-process/template.hbs"
    }
  });

  _exports.default = _default;
});