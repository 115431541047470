define("product-management/pods/components/admin/inventory-items/item-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7qLc5k8N",
    "block": "{\"symbols\":[\"@inventoryItem\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"Name\"],[13],[2,\"\\n\\t\"],[1,[30,[36,0],null,[[\"class\",\"value\"],[\"form-control\",[32,1,[\"name\"]]]]]],[2,\"\\n\\n\\t\"],[1,[30,[36,2],null,[[\"errors\",\"field\"],[[35,1],\"name\"]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"Part #\"],[13],[2,\"\\n\\t\"],[1,[30,[36,0],null,[[\"class\",\"value\"],[\"form-control\",[32,1,[\"sku\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"Description\"],[13],[2,\"\\n\\t\"],[1,[30,[36,3],null,[[\"class\",\"value\"],[\"form-control\",[32,1,[\"description\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n\\t\"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,5],[\"click\",[30,[36,4],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n\\t\\tSave\\n\\t\"],[13],[2,\" or \\n\\t \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.accounting.purchasing.inventory-items\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t cancel\\n\\t \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"input\",\"errors\",\"ui/error-message\",\"textarea\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/admin/inventory-items/item-form/template.hbs"
    }
  });

  _exports.default = _default;
});