define("product-management/pods/orders/show/index/attachments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NWpt5IJn",
    "block": "{\"symbols\":[\"attachment\"],\"statements\":[[10,\"div\"],[14,0,\"mt-16 \"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1,[\"attachments\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex flex-row\"],[12],[2,\"\\n      \"],[10,\"a\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"remove\"]],[32,1]],null]],[14,0,\"text-sm text-grey-dark inline-block mr-3 mt-2 cursor-pointer hover:text-grey-darkest\"],[12],[2,\"x\"],[13],[2,\"\\n      \\n      \"],[10,\"a\"],[15,6,[31,[[32,1,[\"file\",\"url\"]]]]],[14,\"target\",\"_blank\"],[14,0,\"py-2 text-lg\"],[12],[2,\"\\n         \"],[1,[32,1,[\"file\",\"name\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    No Attachment(s) found\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"relative cursor-pointer my-10\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,0,\"opacity-0 block absolute w-full h-full cursor-pointer\"],[14,\"accept\",\"image/*\"],[15,\"onchange\",[30,[36,0],[[32,0,[\"upload\"]]],null]],[14,4,\"file\"],[12],[13],[2,\"\\n\\n   \"],[8,\"stardust/button\",[[24,0,\"\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n     \"],[1,[30,[36,4],[\"plus\"],null]],[2,\" Add Attachment\\n   \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"model\",\"-track-array\",\"each\",\"fa-icon\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/index/attachments/template.hbs"
    }
  });

  _exports.default = _default;
});