define("product-management/pods/admin/accounting/sales-tax/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dysjEpre",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"p-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex text-xl mb-4 sp-sub-nav\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@query\"],[\"admin.accounting.sales-tax\",[30,[36,0],null,[[\"status\"],[\"pending\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Pending\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@query\"],[\"admin.accounting.sales-tax\",[30,[36,0],null,[[\"status\"],[\"approved\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Approved\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hash\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/sales-tax/template.hbs"
    }
  });

  _exports.default = _default;
});