define("product-management/pods/components/fulfillment/delivery-zones/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PemKZyWK",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"border-2 border-grey-light rounded\"],[4,[38,2],[[30,[36,1],[[32,0,[\"handleSetup\"]]],null],[32,0]],null],[12],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"bg-grey-light w-1/5 p-2\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"img\"],[14,0,\"w-full block mx-auto mr-2 h-16\"],[14,\"src\",\"/assets/images/icons/delivery.svg\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"bg-white text-2xl w-4/5 p-4\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,0,\"block\"],[12],[2,\"Delivery Zone \"],[1,[35,0,[\"name\"]]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\tLoading...\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"deliveryZone\",\"fn\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/delivery-zones/card/template.hbs"
    }
  });

  _exports.default = _default;
});