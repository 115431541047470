define("product-management/pods/components/stardust/picklist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CA9+OBsB",
    "block": "{\"symbols\":[\"option\",\"@selected\",\"@onChange\",\"&default\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"handleSetup\"]],[32,0]],null],[12],[2,\"\\n\"],[8,\"power-select\",[],[[\"@selected\",\"@options\",\"@onChange\"],[[32,2],[32,0,[\"list\"]],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n   \"],[18,4,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/picklist/template.hbs"
    }
  });

  _exports.default = _default;
});