define("product-management/pods/components/pm/order/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XfIYNZLZ",
    "block": "{\"symbols\":[\"@order\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"border border-grey-lighter rounded mt-2 mb-4 pm-order-details\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex flex-row px-4 py-2\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,1],null,[[\"deliveryInfo\",\"dealerInfoCard\",\"status\",\"invoiceLink\",\"orderingInfo\",\"totals\"],[[30,[36,0],[\"pm/order/delivery-info\"],[[\"order\"],[[32,1]]]],[30,[36,0],[\"pm/dealer/info-card\"],[[\"dealer\"],[[32,1,[\"dealer\"]]]]],[30,[36,0],[\"pm/order/status\"],[[\"order\"],[[32,1]]]],[30,[36,0],[\"pm/order/invoice-link\"],[[\"order\"],[[32,1]]]],[30,[36,0],[\"pm/order/ordering-info\"],[[\"order\"],[[32,1]]]],[30,[36,0],[\"pm/order/details/totals\"],[[\"order\"],[[32,1]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/order/details/template.hbs"
    }
  });

  _exports.default = _default;
});