define("product-management/pods/components/flexible-forms/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QCdHOXv0",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"configurator\",\"customization\",\"steps\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"painless-manufacturing/line-item/wizard/configurator/step\",[],[[\"@step\",\"@onchange\",\"@showRequiredErrors\",\"@lastUpdated\",\"@onFocus\",\"@currentScroll\",\"@fieldWrapperClass\",\"@labelClass\"],[[32,1],[30,[36,0],[[32,0,[\"handleFieldChange\"]]],null],[32,0,[\"showRequiredErrors\"]],[32,0,[\"lastUpdated\"]],[30,[36,0],[[32,0,[\"changeFocus\"]]],null],[32,0,[\"currentScroll\"]],\"mb-6\",\"font-semibold\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"mt-4\"],[12],[2,\"\\n  \"],[8,\"ui/async-button\",[[24,0,\"btn btn-primary p-2\"]],[[\"@onClick\",\"@confirm\",\"@isPending\",\"@pendingText\",\"@defaultText\"],[[30,[36,0],[[32,0,[\"handleSave\"]]],null],false,[32,0,[\"save\",\"isRunning\"]],\"Saving...\",\"Save\"]],null],[2,\"\\n\"],[6,[37,4],[[32,0,[\"isEditing\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  or\\n  \"],[11,\"a\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleToggleEdit\"]]],null]],null],[12],[2,\"\\n    cancel\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/flexible-forms/form/template.hbs"
    }
  });

  _exports.default = _default;
});