define("product-management/pods/components/pm/comments/count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0mFFiNn0",
    "block": "{\"symbols\":[\"@onToggleComments\",\"@commentCount\"],\"statements\":[[10,\"div\"],[14,0,\"text-right text-lg\"],[12],[2,\"\\n  \"],[10,\"a\"],[14,6,\"#\"],[15,\"onclick\",[30,[36,0],[[32,0],[32,1]],null]],[12],[1,[32,2]],[2,\" Comments\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/comments/count/template.hbs"
    }
  });

  _exports.default = _default;
});