define("product-management/pods/work-queue/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    active: (0, _model.attr)(),
    started: (0, _model.attr)(),
    orders: (0, _model.hasMany)(),
    changeWorkingStatus: (0, _emberApiActions.memberAction)({
      path: "change_working_status",
      type: "put"
    })
  });

  _exports.default = _default;
});