define("product-management/pods/components/wip-table/need-by/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FoixK0Hj",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"value\",\"onSelection\",\"useUtc\",\"firstDay\",\"format\",\"class\"],[[35,2],[30,[36,1],[[32,0],\"setDate\",[35,0,[\"orderId\"]]],null],true,0,\"MM/DD/YYYY\",\"form-control\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"action\",\"needBy\",\"pikaday-input\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/wip-table/need-by/template.hbs"
    }
  });

  _exports.default = _default;
});