define("product-management/mixins/shared/cable-registration-route", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    cable: Ember.inject.service(),
    wsHost: _emberGetConfig.default.APP.wsHost,
    model: function model() {
      var wsHost = this.wsHost;
      return this.cable.createConsumer(wsHost);
    }
  });

  _exports.default = _default;
});