define("product-management/pods/components/pm/order/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aF1B4HkH",
    "block": "{\"symbols\":[\"canDo\",\"@order\",\"@showSubStatus\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"pm-order-status \",[30,[36,0],[[32,2,[\"status\"]]],null]]]],[12],[2,\"\\n  \"],[1,[30,[36,1],[[32,2,[\"status\"]]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[32,3],[30,[36,4],[[32,2,[\"status\"]],\"ordered\"],null],[30,[36,3],[[30,[36,2],[[32,2,[\"subStatus\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n  \"],[8,\"orders/sub-status-icon\",[],[[\"@order\",\"@subText\",\"@subStatus\",\"@showText\"],[[32,2],\"Prepayment Dealer\",[32,2,[\"subStatus\"]],true]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"can-do\",[],[[\"@updateWith\",\"@model\",\"@action\",\"@policy\"],[[32,2,[\"status\"]],[32,2],\"update\",\"Order::PutOnHold\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[32,1,[\"allowed\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"painless-manufacturing/production/on-hold-toggle\",[[24,0,\"mt-4 border-t border-grey-dark pt-4\"]],[[\"@order\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"dasherize\",\"humanize\",\"is-empty\",\"not\",\"eq\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/order/status/template.hbs"
    }
  });

  _exports.default = _default;
});