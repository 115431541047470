define("product-management/pods/components/orders/dealer-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8c+4Wtv/",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Order For: \\n\"],[10,\"span\"],[14,0,\"heading-line-break\"],[12],[2,\"\\n    \"],[6,[37,1],null,[[\"route\",\"model\"],[\"admin.dealer\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[35,0,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"dealer\",\"link-to\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/orders/dealer-name/template.hbs"
    }
  });

  _exports.default = _default;
});