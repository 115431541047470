define("product-management/pods/components/dealers/shipping-address-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasShippingAddress: Ember.computed.notEmpty("dealer.shippingAddress"),
    hasShippingCity: Ember.computed.notEmpty("dealer.shippingCity"),
    hasShippingState: Ember.computed.notEmpty("dealer.shippingState"),
    hasShippingZip: Ember.computed.notEmpty("dealer.shippingZip"),
    hasADefaultAddress: Ember.computed.and("hasShippingAddress", "hasShippingCity", "hasShippingState", "hasShippingZip"),
    setToDealerAddress: function setToDealerAddress() {
      var dealer = this.dealer,
          order = this.order;
      var variables = ["Address", "City", "State", "Zip"];
      variables.forEach(function (item) {
        var field = "shipping".concat(item);
        order.set(field, dealer.get(field));
      });
    },
    actions: {
      useDefaultAddress: function useDefaultAddress() {
        this.setToDealerAddress();
      }
    }
  });

  _exports.default = _default;
});