define("product-management/pods/admin/critical-inventory/index/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vPsT+2oJ",
    "block": "{\"symbols\":[\"Modal\",\"footer\"],\"statements\":[[8,\"bs-modal\",[],[[\"@onHide\"],[[30,[36,0],[[32,0,[\"handleHide\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[2,\"\\n\\t\\t\\tInventory Item Details: \"],[1,[35,1,[\"name\"]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\\n\\t\"],[8,\"purchasing/critical-inventory/count-information\",[],[[\"@item\"],[[34,1]]],null],[2,\"\\n\\n\\n \\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/critical-inventory/index/show/template.hbs"
    }
  });

  _exports.default = _default;
});