define("product-management/pods/admin/settings/product-types/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wv6l3v7a",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"Edit Product Type\"],[13],[2,\"\\n\"],[8,\"pm/products/product-types/form\",[],[[\"@productType\",\"@onSave\",\"@cancelRoute\"],[[34,0],[30,[36,1],[\"save\"],null],\"admin.settings.product_types\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"route-action\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/product-types/detail/template.hbs"
    }
  });

  _exports.default = _default;
});