define("product-management/pods/components/shop/misc-items/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pzG0NP6P",
    "block": "{\"symbols\":[\"unit\"],\"statements\":[[10,\"h3\"],[12],[2,\"Misc Items\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"class\",\"route\",\"model\"],[\"list-group-item\",\"shop.notes_unit\",[32,1,[\"items\",\"firstObject\",\"unitId\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h4\"],[12],[2,\"PO#: \"],[1,[32,1,[\"value\"]]],[13],[2,\"\\n    \"],[10,\"h5\"],[12],[2,\"Unit #\"],[1,[32,1,[\"items\",\"firstObject\",\"position\"]]],[13],[2,\"\\n    \"],[10,\"h5\"],[12],[2,\"Dealer: \"],[1,[32,1,[\"items\",\"firstObject\",\"dealerName\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"units\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/misc-items/template.hbs"
    }
  });

  _exports.default = _default;
});