define("product-management/pods/components/wip-report/shipping-method/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    icon: Ember.computed("item.shippingMethod", function () {
      return this.get("item.shippingMethod").replace(" ", "").toLowerCase();
    })
  });

  _exports.default = _default;
});