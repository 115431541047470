define("product-management/utils/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusColorMap = void 0;
  var statusColorMap = {
    "pending": "grey",
    "ready": "red",
    "in_process": "yellow",
    "complete": "green"
  };
  _exports.statusColorMap = statusColorMap;
});