define("product-management/pods/users/confirmation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wovthix8",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[6,[37,6],[[30,[36,5],[[35,0],[35,4]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"row\"],[14,1,\"login_container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-6 col-md-offset-3\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"well white_well\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"Welcome \"],[1,[35,0,[\"firstName\"]]],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"To get started, all you need to do is enter a password below. Once you create your password, you will be taken to the login page, where you can sign in with\\n  your email address \"],[10,\"strong\"],[12],[2,\"(\"],[1,[35,0,[\"email\"]]],[2,\")\"],[13],[2,\" and the password you enter.\"],[13],[2,\"\\n\\n  \"],[1,[34,1]],[2,\"\\n\\n  \"],[1,[30,[36,3],null,[[\"buttonText\",\"pendingButtonText\",\"onSubmit\",\"changeset\"],[\"Confirm My Account\",\"Confirming...\",[30,[36,2],[[32,0],\"createPassword\"],null],[32,1]]]]],[2,\"\\n\\n  \\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"password-requirements\",\"action\",\"password-form\",\"PasswordValidations\",\"changeset\",\"with\"]}",
    "meta": {
      "moduleName": "product-management/pods/users/confirmation/template.hbs"
    }
  });

  _exports.default = _default;
});