define("product-management/pods/components/pricing/price-per-square-foot/add-option/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["price-per-square-foot-add-option-container"],
    isAdding: false,
    toggleIsAdding: function toggleIsAdding() {
      this.toggleProperty("isAdding");
    },
    actions: {
      save: function save(product, newOption) {
        var _this = this;

        this.toggleIsAdding();
        return this.attrs.onAdd(product, newOption).then(function () {
          _this.toggleIsAdding();
        });
      }
    }
  });

  _exports.default = _default;
});