define("product-management/pods/components/work-signoffs/signature-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qwOTDn+G",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[1,[30,[36,2],[[35,0,[\"station\"]]],null]],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,0,[\"forced\"]],[30,[36,3],[[35,0,[\"signature\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"p-2 my-2\"],[12],[2,\"\\n    Forced complete, no signature available\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,0,[\"signature\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[1,[30,[36,6],[[35,0,[\"createdAt\"]],\"MM/DD/YYYY hh:mma\"],null]],[2,\"\\n\"],[6,[37,5],[[35,0,[\"employee\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[35,0,[\"employee\"]]],[2,\"\\n  -\\n  \"],[1,[35,0,[\"shopCode\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"signoff\",\"html-safe\",\"humanize\",\"is-empty\",\"or\",\"if\",\"moment-format\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/work-signoffs/signature-display/template.hbs"
    }
  });

  _exports.default = _default;
});