define("product-management/pods/components/painless-manufacturing/production/station-hold/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mF3fL6Nh",
    "block": "{\"symbols\":[\"ShopCode\",\"reason\",\"@noTrigger\"],\"statements\":[[6,[37,5],[[32,3]],null,[[\"default\"],[{\"statements\":[[11,\"a\"],[24,0,\"cursor-pointer font-semibold\"],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"toggle\"]]],null]],null],[12],[2,\"\\n  Put Station On Hold\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[32,0,[\"isShowing\"]]],null,[[\"default\"],[{\"statements\":[[8,\"shop/shop-code\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"font-bold\"],[12],[2,\"\\n      Hold Reason:\\n    \"],[13],[2,\"\\n    \"],[8,\"power-select-from-option-list\",[],[[\"@renderInPlace\",\"@name\",\"@onChange\",\"@selected\"],[true,\"On Hold Reasons - Station\",[34,0],[32,0,[\"onHoldReason\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\"],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n    \"],[8,[32,1,[\"entry\"]],[],[[\"@inputType\"],[\"text\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,[36,2],[[30,[36,1],[[32,1,[\"isValid\"]]],null],[30,[36,1],[[32,0,[\"reasonPresent\"]]],null]],null]],[24,0,\"btn btn-block btn-sm btn-danger\"],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"handleHold\"]],[32,1,[\"value\"]]],null]],null],[12],[2,\"\\n      Confirm\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"handleChangeReason\",\"not\",\"or\",\"fn\",\"on\",\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/station-hold/template.hbs"
    }
  });

  _exports.default = _default;
});