define("product-management/pods/admin/settings/price-multipliers/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eYqpqdW0",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pricing/price-multiplier/form\",[],[[\"@model\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/price-multipliers/show/template.hbs"
    }
  });

  _exports.default = _default;
});