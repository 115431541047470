define("product-management/pods/components/admin/vendors/table-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ZMzoGdp",
    "block": "{\"symbols\":[\"Filter\",\"record\",\"@records\"],\"statements\":[[8,\"sun-pro/ui/table-filter\",[],[[\"@filterMethod\",\"@records\",\"@filterType\",\"@filterFields\",\"@filterMatch\"],[\"local\",[32,3],\"includes\",[30,[36,0],[\"id\",\"name\",\"contact\",\"accountNumber\"],null],\"any\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n    \"],[10,\"table\"],[14,0,\"table table-condensed table-striped\"],[12],[2,\"\\n      \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"\\n            Name\\n          \"],[13],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"\\n            Contact\\n          \"],[13],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"\\n            Account Number\\n          \"],[13],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"\\n          Default Terms\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"name\",[32,1,[\"records\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"admin/vendors/table-item\",[],[[\"@record\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"array\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/admin/vendors/table-list/template.hbs"
    }
  });

  _exports.default = _default;
});