define("product-management/pods/components/pm/ui/nav/hamburger/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o1dtSzbx",
    "block": "{\"symbols\":[\"@menuClass\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[32,1],\" z-10 absolute pin-r pin-t border-2 bg-white mt-12 px-6 py-6 w-64\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n      \"],[18,2,[[30,[36,1],null,[[\"item\"],[[30,[36,0],[\"pm/ui/nav/hamburger/item\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/nav/hamburger/content/template.hbs"
    }
  });

  _exports.default = _default;
});