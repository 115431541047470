define("product-management/pods/dealer/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    dealerDiscounts: (0, _model.hasMany)(),
    nickname: (0, _model.attr)(),
    displayName: (0, _model.attr)(),
    catalogItems: (0, _model.hasMany)(),
    orders: (0, _model.hasMany)(),
    qbCustomer: (0, _model.belongsTo)(),
    users: (0, _model.hasMany)(),
    notificationEmail: (0, _model.attr)(),
    canDeliver: (0, _model.attr)(),
    canShip: (0, _model.attr)(),
    officePhone: (0, _model.attr)(),
    cellPhone: (0, _model.attr)(),
    canPickup: (0, _model.attr)(),
    billingAddress1: (0, _model.attr)(),
    billingAddress2: (0, _model.attr)(),
    billingAddress3: (0, _model.attr)(),
    billingCity: (0, _model.attr)(),
    billingState: (0, _model.attr)(),
    billingZip: (0, _model.attr)(),
    billingEmail: (0, _model.attr)(),
    shippingAddress: (0, _model.attr)(),
    shippingCity: (0, _model.attr)(),
    shippingState: (0, _model.attr)(),
    shippingZip: (0, _model.attr)(),
    apiKey: (0, _model.attr)(),
    apiActivated: (0, _model.attr)(),
    shippingApiUri: (0, _model.attr)(),
    defaultShippingMethod: (0, _model.attr)(),
    labelPrefix: (0, _model.attr)(),
    disabled: (0, _model.attr)(),
    canRequestNeedByDate: (0, _model.attr)(),
    singleInvoicePerOrder: (0, _model.attr)(),
    allowCutdowns: (0, _model.attr)(),
    allowWarrantyClaims: (0, _model.attr)(),
    allowConversations: (0, _model.attr)(),
    createdAt: (0, _model.attr)(),
    updatedAt: (0, _model.attr)(),
    createdBy: (0, _model.attr)(),
    updatedBy: (0, _model.attr)(),
    tags: (0, _model.attr)(),
    salesRep: (0, _model.belongsTo)("user", {
      inverse: null
    }),
    current: (0, _emberApiActions.collectionAction)({
      path: "current",
      type: "get"
    })
  });

  _exports.default = _default;
});