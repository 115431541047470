define("product-management/pods/components/orders/ship-on-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C2vGOtdX",
    "block": "{\"symbols\":[\"@on-set-date\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"label\"],[12],[2,\"Ship on\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,3],null,[[\"value\",\"onSelection\",\"useUtc\",\"firstDay\",\"format\",\"readonly\",\"class\"],[[35,2],[32,1],true,0,\"MM/DD/YYYY\",\"readonly\",\"form-control bg-white-important\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"showLabel\",\"if\",\"shipOn\",\"pikaday-input\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/orders/ship-on-date/template.hbs"
    }
  });

  _exports.default = _default;
});