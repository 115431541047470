define("product-management/pods/components/purchasing/critical-inventory/count-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XhsKxy5e",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n\\t\"],[11,\"form\"],[4,[38,1],[\"submit\",[30,[36,0],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"font-semibold text-lg block mb-2\"],[12],[2,\"Quantity On Hand\"],[13],[2,\"\\n\\n\\t\\t\"],[11,\"input\"],[24,0,\"input-lg form-control\"],[4,[38,2],[[32,0,[\"handleInputInsert\"]],[32,0]],null],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]]],null]],null],[12],[13],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"mt-2\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"button\"],[14,0,\"p-2 text-lg bg-red-dark rounded text-white font-semibold\"],[14,4,\"submit\"],[12],[2,\"\\n\\t\\t\\t\\tUpdate Count\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"did-insert\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/critical-inventory/count-input/template.hbs"
    }
  });

  _exports.default = _default;
});