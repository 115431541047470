define("product-management/pods/components/messenger/notification-bell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZlFK6+4N",
    "block": "{\"symbols\":[],\"statements\":[[8,\"action-cable-subscriber\",[],[[\"@name\",\"@params\",\"@channel\",\"@received\"],[[34,2],[30,[36,4],null,[[\"token\"],[[35,3]]]],\"MessengerNotificationChannel\",[30,[36,0],[[32,0,[\"handleWebSocket\"]]],null]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"relative p-2\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"bg-grey-light h-10 p-2 rounded-full text-blue-darker text-center w-10\"],[4,[38,5],[\"click\",[30,[36,0],[[32,0,[\"handleToggleConversations\"]]],null]],null],[12],[2,\"\\n    \"],[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"fill\",\"none\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"stroke-width\",\"1.5\"],[14,\"stroke\",\"currentColor\"],[14,0,\"w-6 h-6\"],[12],[2,\"\\n      \"],[10,\"path\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"d\",\"M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,1],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,5,\"margin-right: -5px;\"],[14,0,\"absolute bg-red-dark leading-6 font-bold h-6 pin-r pin-t rounded-full text-center text-sm text-white w-6\"],[12],[2,\"\\n      \"],[1,[34,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,7],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"messenger/conversations\",[],[[\"@onClose\"],[[30,[36,0],[[32,0,[\"handleToggleConversations\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"unreadMessages\",\"subscriptionName\",\"token\",\"hash\",\"on\",\"gt\",\"if\",\"showConversations\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/messenger/notification-bell/template.hbs"
    }
  });

  _exports.default = _default;
});