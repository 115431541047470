define("product-management/pods/components/icon-radio-button-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GjE8Ye1h",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[6,[37,10],[[30,[36,9],[[30,[36,9],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,5],null,[[\"value\",\"displayValue\",\"wrapper-class\",\"icon\",\"modelValue\",\"onSetValue\",\"isStandardOptionSelected\"],[[32,1,[\"value\"]],[32,1,[\"displayValue\"]],[35,7],[35,6],[35,0],[30,[36,2],[[32,0],\"setValue\"],null],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,12],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,5],null,[[\"other-label\",\"other-class\",\"onSetValue\",\"isStandardOptionSelected\",\"modelValue\",\"isOther\"],[[35,4],[35,3],[30,[36,2],[[32,0],\"setValue\"],null],[35,1],[35,0],true]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"modelValue\",\"isStandardOptionSelected\",\"action\",\"other-class\",\"other-label\",\"icon-radio-button\",\"icon\",\"wrapper-class\",\"mappedOptions\",\"-track-array\",\"each\",\"allow-other\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/icon-radio-button-list/template.hbs"
    }
  });

  _exports.default = _default;
});