define("product-management/pods/admin/settings/product-weights/categories/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PsQSGkXi",
    "block": "{\"symbols\":[\"pwc\"],\"statements\":[[10,\"div\"],[14,0,\"text-right mb-4\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"admin.settings.product-weights.categories.new\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Add New\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"name\",[35,0]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"list-group-item\"],[12],[2,\"\\n        \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.settings.product-weights.categories.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/product-weights/categories/index/template.hbs"
    }
  });

  _exports.default = _default;
});