define("product-management/pods/components/painless-manufacturing/production/production-line/filter/picklist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HbRXy214",
    "block": "{\"symbols\":[\"filterOption\",\"@filter\"],\"statements\":[[8,\"power-select\",[],[[\"@options\",\"@selected\",\"@placeholder\",\"@onChange\"],[[32,2,[\"listData\"]],[32,0,[\"selected\"]],[32,2,[\"label\"]],[30,[36,0],[[32,0,[\"handleChange\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \\n\\t\"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/production-line/filter/picklist/template.hbs"
    }
  });

  _exports.default = _default;
});