define("product-management/pods/components/painless-manufacturing/production/unit-status/production-line-station-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "slBQPIEh",
    "block": "{\"symbols\":[\"@hideStatusLabel\",\"@lineItem\"],\"statements\":[[2,\"\\n\"],[8,\"painless-manufacturing/production/unit-status/station-status\",[[16,0,[31,[[30,[36,0],[[32,1],\"w-12\",\"w-40\"],null]]]]],[[\"@muteName\",\"@station\",\"@lineItem\",\"@hideStatusLabel\"],[true,[34,1],[32,2],[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"mappedStation\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/unit-status/production-line-station-status/template.hbs"
    }
  });

  _exports.default = _default;
});