define("product-management/pods/components/ui/error-message/component", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    "error-message": Ember.computed("mappedErrors.length", "field", function () {
      var mappedErrors = this.mappedErrors,
          field = this.field;
      var item = mappedErrors.find(function (error) {
        return error.field === field;
      });
      return item === undefined ? "" : item.message;
    }),
    mappedErrors: Ember.computed.map("errors", function (error) {
      var _error$detail$split = error.detail.split(" - "),
          _error$detail$split2 = (0, _slicedToArray2.default)(_error$detail$split, 2),
          field = _error$detail$split2[0],
          message = _error$detail$split2[1];

      return {
        field: field,
        message: message
      };
    })
  });

  _exports.default = _default;
});