define("product-management/pods/components/dealers/delegates/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h6Gtx6sH",
    "block": "{\"symbols\":[\"Row\",\"@dealer\",\"&attrs\"],\"statements\":[[11,\"div\"],[17,3],[12],[2,\"\\n\"],[6,[37,3],[[32,2,[\"dealerDelegates\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"mb-4\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"text-2xl text-grey-dark\"],[12],[2,\"Users that have been given access to this dealer account\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[8,\"tailwind-ui/lists/narrow\",[],[[\"@records\"],[[32,2,[\"dealerDelegates\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t  \"],[8,[32,1,[\"listItem\"]],[],[[\"@title\",\"@subtitle\"],[[32,1,[\"record\",\"user\",\"fullName\"]],[30,[36,0],[[32,1,[\"record\",\"user\",\"email\"]],\" - \",[32,1,[\"record\",\"user\",\"dealer\",\"name\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[11,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleDelete\"]],[32,1,[\"record\",\"id\"]]],null]],null],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-times-circle text-red-dark text-2xl\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t  No users have been granted access yet.\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/delegates/list/template.hbs"
    }
  });

  _exports.default = _default;
});