define("product-management/utils/css-classes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.negativeClass = _exports.positiveClass = void 0;
  var positiveClass = "bg-green-light";
  _exports.positiveClass = positiveClass;
  var negativeClass = "bg-red-light";
  _exports.negativeClass = negativeClass;
});