define("product-management/mixins/shared/web-socket-controller", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    cable: Ember.inject.service(),
    wsHost: _emberGetConfig.default.APP.wsHost,
    subscribeToChannel: function subscribeToChannel(name, channelName, _received, consumer) {
      var wsHost = this.wsHost;
      var subscription = consumer.subscriptions.create(channelName, {
        received: function received(data) {
          _received(data);
        }
      });
      if (this.consumer === undefined) Ember.set(this, "consumer", consumer);
      Ember.set(this, "".concat(name, "Subscription"), subscription);
    },
    unsubscribeFromChannel: function unsubscribeFromChannel(name) {
      var subscription = this.get("".concat(name, "Subscription"));
      var cable = this.cable;

      if (subscription) {
        var consumer = this.consumer;
        consumer.subscriptions.remove(subscription);
        subscription.unsubscribe();
      }
    }
  });

  _exports.default = _default;
});