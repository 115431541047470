define("product-management/pods/orders/show/index/index/work-signoffs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9TE1Psji",
    "block": "{\"symbols\":[\"signoff\"],\"statements\":[[8,\"bs-modal-simple\",[],[[\"@title\",\"@size\",\"@onHidden\"],[\"Work Signoffs\",\"lg\",[30,[36,0],[\"cancel\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1,[\"workSignoffs\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t  \"],[8,\"work-signoffs/signature-display\",[],[[\"@signoff\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"\\t\\t\\t  \"],[10,\"div\"],[14,0,\"blank-slate\"],[12],[2,\"\\n\\t\\t\\t\\t\\tNo work has been completed on this item yet.\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/index/index/work-signoffs/template.hbs"
    }
  });

  _exports.default = _default;
});