define("product-management/pods/components/painless-manufacturing/inputs/textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tjqYnpI/",
    "block": "{\"symbols\":[\"errors\",\"@field\",\"@showRequiredErrors\"],\"statements\":[[8,\"painless-manufacturing/inputs/errors\",[],[[\"@warnings\",\"@errors\",\"@showRequiredErrors\"],[[32,2,[\"warningMessages\"]],[32,2,[\"errors\"]],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"mb-4 pm-field w-1/2\"],[12],[2,\"\\n    \"],[8,\"painless-manufacturing/inputs/label\",[],[[\"@required\"],[[32,2,[\"required\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,2,[\"label\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,2,[\"hint\"]],[30,[36,0],[[32,2,[\"hintPosition\"]],\"before\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"painless-manufacturing/inputs/hint\",[],[[\"@field\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[11,\"div\"],[24,0,\"flex flex-row\"],[4,[38,3],[[32,0,[\"didInsert\"]]],null],[12],[2,\"\\n      \"],[10,\"textarea\"],[15,0,[31,[\"px-3 py-2 text-base border mr-4 bg-grey-lightest text-black w-full\\n          \",[30,[36,2],[[32,1,[\"present\"]],\"border-red-dark\",\"border-grey-lighter\"],null]]]],[14,\"rows\",\"5\"],[15,2,[32,0,[\"value\"]]],[15,\"oninput\",[30,[36,4],[[32,0,[\"handleInput\"]]],null]],[15,\"onkeydown\",[30,[36,4],[[32,0,[\"handleKeyDown\"]]],null]],[15,\"onblur\",[30,[36,4],[[32,0,[\"updateValue\"]]],null]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,[32,1,[\"messages\"]],[],[[],[]],null],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,2,[\"hint\"]],[30,[36,0],[[32,2,[\"hintPosition\"]],\"after\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"painless-manufacturing/inputs/hint\",[],[[\"@field\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"and\",\"if\",\"did-insert\",\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/inputs/textarea/template.hbs"
    }
  });

  _exports.default = _default;
});