define("product-management/pods/pm/users/list/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+gpSpy4u",
    "block": "{\"symbols\":[\"@showRoute\",\"@user\"],\"statements\":[[10,\"tr\"],[15,0,[34,0]],[12],[2,\"\\n  \"],[10,\"td\"],[12],[2,\"\\n\\t\\t\"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[[32,1],[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[34,1]]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[32,2,[\"email\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[8,\"pm/users/list/status-display\",[],[[\"@onAction\",\"@user\",\"@status\"],[[30,[36,3],[[32,0],[35,2]],null],[32,2],[34,4]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,5],[[32,2,[\"role\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,6],[[32,2,[\"lastSignInAt\"]],\"MM/DD/YY\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"statusClass\",\"fullName\",\"handleClick\",\"action\",\"status\",\"humanize\",\"moment-format\"]}",
    "meta": {
      "moduleName": "product-management/pods/pm/users/list/row/template.hbs"
    }
  });

  _exports.default = _default;
});