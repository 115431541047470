define("product-management/pods/components/audit-trail-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Nb2OC8v",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"toggle-audit-trail\"],null],[12],[1,[34,3]],[13],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"bs-modal-simple\",[],[[\"@onHide\",\"@title\"],[[30,[36,0],[[32,0],\"toggle-audit-trail\"],null],\"Audit Trail\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t  \"],[8,\"audit-trail\",[],[[\"@resource\",\"@sections-to-show\"],[[34,1],[34,2]]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"resource\",\"sections-to-show\",\"link-text\",\"is-showing-audit-trail\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/audit-trail-link/template.hbs"
    }
  });

  _exports.default = _default;
});