define("product-management/validations/shop-code", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: (0, _validators.validatePresence)({
      presence: true,
      message: "Please enter a first name"
    }),
    lastName: (0, _validators.validatePresence)({
      presence: true,
      message: "Please enter a last name"
    }),
    code: [(0, _validators.validatePresence)({
      presence: true,
      message: "Please enter a valid code"
    }), (0, _validators.validateLength)({
      is: 4,
      message: "Please enter a valid code"
    })]
  };
  _exports.default = _default;
});