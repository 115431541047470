define("product-management/utils/stardust/date-filter-options", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFilterOptions = dateFilterOptions;
  var monthToDate = "Month to Date";
  var quarterToDate = "Quarter to Date";
  var yearToDate = "Year to Date";
  var lastYearMonthToDate = "Last Year - Month to Date";
  var lastYearQuarterToDate = "Last Year - Quarter to Date";
  var lastYearYearToDate = "Last Year - Year to Date";
  var lastMonth = "Last Month";
  var lastQuarter = "Last Quarter";
  var lastYear = "Last Year";
  var runningTwelve = "Last 12 Months";

  function dateFilterOptions() {
    var includeCustom = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    var output = [{
      label: monthToDate,
      startAt: (0, _moment.default)().startOf("month"),
      endAt: (0, _moment.default)()
    }, {
      label: quarterToDate,
      startAt: (0, _moment.default)().startOf("quarter"),
      endAt: (0, _moment.default)()
    }, {
      label: yearToDate,
      startAt: (0, _moment.default)().startOf("year"),
      endAt: (0, _moment.default)()
    }, {
      label: lastYearMonthToDate,
      startAt: (0, _moment.default)().subtract(1, "year").startOf("month"),
      endAt: (0, _moment.default)().subtract(1, "year")
    }, {
      label: lastYearQuarterToDate,
      startAt: (0, _moment.default)().subtract(1, "year").startOf("quarter"),
      endAt: (0, _moment.default)().subtract(1, "year")
    }, {
      label: lastYearYearToDate,
      startAt: (0, _moment.default)().subtract(1, "year").startOf("year"),
      endAt: (0, _moment.default)().subtract(1, "year")
    }, {
      label: lastMonth,
      startAt: (0, _moment.default)().subtract(1, "month").startOf("month"),
      endAt: (0, _moment.default)().subtract(1, "month").endOf("month")
    }, {
      label: lastQuarter,
      startAt: (0, _moment.default)().subtract(1, "quarter").startOf("quarter"),
      endAt: (0, _moment.default)().subtract(1, "quarter").endOf("quarter")
    }, {
      label: lastYear,
      startAt: (0, _moment.default)().subtract(1, "year").startOf("year"),
      endAt: (0, _moment.default)().subtract(1, "year").endOf("year")
    } // {
    //   label: runningTwelve,
    //   startAt: moment().subtract(1, "year").subtract(1, "month"),
    //   endAt: moment().subtract(1, "month")
    // }
    ];
    if (includeCustom) output.push({
      label: "Custom"
    });
    return output;
  }
});