define("product-management/templates/resource-cards/customer-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WHOdHgy0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"resource-not-found\"],[12],[2,\"\\n  Could not find a customer by that name. \\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/templates/resource-cards/customer-not-found.hbs"
    }
  });

  _exports.default = _default;
});