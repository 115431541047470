define("product-management/pods/components/audit-trail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FIELD_REPLACEMENTS = /(_at|_by_id|_by|_by_email|_meta)$/g;

  var _default = Ember.Component.extend({
    fields: ["at", "by", "meta"],
    auditTrail: Ember.computed.alias("resource.auditTrail"),
    sections: Ember.computed("auditTrail", function () {
      var auditTrail = this.auditTrail;
      var sections = this.getUniqueSectionsFromAuditTrail(auditTrail);
      return this.sortSections(sections, auditTrail);
    }),
    getUniqueSectionsFromAuditTrail: function getUniqueSectionsFromAuditTrail(auditTrail) {
      var keys = this.getAuditTrailKeys(auditTrail);
      return this.getSectionsFromAuditTrail(keys).uniq();
    },
    getSectionsFromAuditTrail: function getSectionsFromAuditTrail(keys) {
      var _this = this;

      return keys.map(function (item) {
        return _this.getEventName(item);
      });
    },
    sortSections: function sortSections(sections, auditTrail) {
      var _this2 = this;

      return sections.sort(function (a, b) {
        return _this2.compareAuditTrailEvent(a, b, auditTrail);
      });
    },
    compareAuditTrailEvent: function compareAuditTrailEvent(a, b, auditTrail) {
      var aParsed = moment(auditTrail["".concat(a, "_at")]).toDate();
      var bParsed = moment(auditTrail["".concat(b, "_at")]).toDate();
      if (aParsed > bParsed) return 1;else if (aParsed < bParsed) return -1;else return 0;
    },
    getAuditTrailKeys: function getAuditTrailKeys(auditTrail) {
      return Object.keys(auditTrail);
    },
    getEventName: function getEventName(item) {
      return item.replace(FIELD_REPLACEMENTS, '');
    }
  });

  _exports.default = _default;
});