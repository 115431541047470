define("product-management/pods/components/shared/filter-bar-wrapper/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["filter-bar"],
    myStickyOptions: {
      topSpacing: 90
    }
  });

  _exports.default = _default;
});