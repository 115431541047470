define("product-management/components/ordering/edit/edit-zipper", ["exports", "marygrove-components/components/ordering/edit/edit-zipper"], function (_exports, _editZipper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _editZipper.default;
    }
  });
});