define("product-management/pods/components/ui/local-table-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      clearFilter: function clearFilter() {
        this.set("data-filter", "");
        Ember.run.later(this, function () {
          this.element.querySelector("input").focus();
        }, 250);
      }
    },
    "filtered-records": Ember.computed("data-filter", "records", "filter-fields", function () {
      var dataFilter = this["data-filter"];
      var records = this.records;
      var filterFields = this["filter-fields"];
      if (Ember.isEmpty(dataFilter)) return records;
      return records.filter(function (item) {
        return filterFields.any(function (field) {
          return item.get(field).includes(dataFilter);
        });
      });
    })
  });

  _exports.default = _default;
});