define("product-management/pods/components/mail-to/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yvzIGIxl",
    "block": "{\"symbols\":[\"@email\"],\"statements\":[[10,\"a\"],[15,6,[31,[\"mailto:\",[32,1]]]],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/mail-to/template.hbs"
    }
  });

  _exports.default = _default;
});