define("product-management/pods/components/stardust/ui/flyout-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n0EvgyhC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"relative inline-block text-left\"],[4,[38,1],[[30,[36,0],[[32,0,[\"setupComponent\"]]],null],[32,0]],null],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"flex items-center text-grey-light hover:text-grey-dark focus:outline-none focus:text-grey-600\"],[4,[38,2],[\"click\",[30,[36,0],[[32,0,[\"toggleMenu\"]]],null]],null],[12],[2,\"\\n      \"],[10,\"svg\"],[14,0,\"h-5 w-5\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 20 20\"],[12],[2,\"\\n        \"],[10,\"path\"],[14,\"d\",\"M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[34,5],\" absolute \",[34,4],\" mt-2 w-auto rounded-md\\n      \",[30,[36,3],[[32,0,[\"menuOpen\"]],\"hidden\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"rounded-md bg-white shadow-xs border border-grey-light\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"p-4\"],[14,\"role\",\"menu\"],[12],[2,\"\\n        \"],[18,1,[[30,[36,7],null,[[\"closeMenu\",\"menuItem\"],[[30,[36,0],[[32,0,[\"toggleMenu\"]]],null],[30,[36,6],[\"stardust/ui/flyout-menu/item\"],[[\"onExitMenu\"],[[30,[36,0],[[32,0,[\"toggleMenu\"]]],null]]]]]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\",\"on\",\"unless\",\"orientationClass\",\"zIndexClass\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/ui/flyout-menu/template.hbs"
    }
  });

  _exports.default = _default;
});