define("product-management/pods/components/sun-pro/qc/work-signoff-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H8QhUaRl",
    "block": "{\"symbols\":[\"@signoff\"],\"statements\":[[10,\"div\"],[14,0,\"border-l border-grey-lighter description-list-container sm:px-6 sm:py-5\"],[12],[2,\"\\n  \"],[10,\"dt\"],[12],[2,\"\\n    Station\\n  \"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"station\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"dt\"],[12],[2,\"\\n    Employee\\n  \"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[2,\"\\n    \"],[1,[32,1,[\"employee\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"dt\"],[12],[2,\"\\n    When\\n  \"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,1,[\"createdAt\"]],\"MM/DD/YYYY hh:mma\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,3],[[32,1,[\"forced\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"dt\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"text-red-dark font-semibold\"],[12],[2,\"\\n        Forced\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"dd\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"dt\"],[12],[2,\"\\n    Signature\\n  \"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,6],[[35,4,[\"forced\"]],[30,[36,5],[[35,4,[\"signature\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      Forced complete, no signature available\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"signature-container\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"signature\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"html-safe\",\"humanize\",\"moment-format\",\"if\",\"signoff\",\"is-empty\",\"or\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/qc/work-signoff-details/template.hbs"
    }
  });

  _exports.default = _default;
});