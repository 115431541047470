define("product-management/components/ordering/wizards/screen-only/enter-dimensions", ["exports", "marygrove-components/components/ordering/wizards/screen-only/enter-dimensions"], function (_exports, _enterDimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _enterDimensions.default;
    }
  });
});