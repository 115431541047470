define("product-management/pods/components/shop/station-signature/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GYDtXFXG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"complete-button-container\"],[12],[2,\"\\n  \"],[8,\"stardust/ui/async-button\",[[24,0,\"btn btn-primary btn-lg btn-block\"]],[[\"@icon\",\"@disabled\",\"@pendingText\",\"@defaultText\",\"@isPending\",\"@onClick\",\"@textColor\"],[\"check\",[30,[36,1],[[32,0,[\"notReadyToComplete\"]],[30,[36,0],[[32,0,[\"canComplete\"]]],null]],null],\"Completing...\",\"Complete\",[34,2,[\"isRunning\"]],[30,[36,3],[[32,0,[\"handleClick\"]]],null],\"white\"]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"not\",\"or\",\"doComplete\",\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/station-signature/template.hbs"
    }
  });

  _exports.default = _default;
});