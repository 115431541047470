define("product-management/pods/admin/accounting/inventory/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll("inventory-item-count");
    },
    getSum: function getSum(total, number) {
      return total + number;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var totalUnitsArray = model.mapBy("count");
      var totalUnits = totalUnitsArray.reduce(this.getSum);
      controller.setProperties({
        "inventory-item-counts": model.sortBy("name"),
        "total-units": totalUnits
      });
    }
  });

  _exports.default = _default;
});