define("product-management/pods/components/stardust/ui/flyout-menu/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fkoH46cz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"a\"],[24,0,\"border-b-2 block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900\"],[24,\"role\",\"menuitem\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleClick\"]]],null]],null],[12],[18,1,null],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/ui/flyout-menu/item/template.hbs"
    }
  });

  _exports.default = _default;
});