define("product-management/pods/components/orders/shipping-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    shipment: Ember.computed.alias("order.shipment"),
    isSaving: false,
    isEditing: false,
    actions: {
      updateShippingInfo: function updateShippingInfo(order) {
        var _this = this;

        this._toggleSaving();

        return this.attrs.onUpdate(order).then(function () {
          _this._toggleSaving();

          _this._toggleEdit();
        });
      },
      toggleEdit: function toggleEdit() {
        this._toggleEdit();
      }
    },
    _toggleSaving: function _toggleSaving() {
      this.toggleProperty("isSaving");
    },
    _toggleEdit: function _toggleEdit() {
      this.toggleProperty("isEditing");
    }
  });

  _exports.default = _default;
});