define("product-management/pods/components/shop/notes-display-for-shipping-out/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gctHfcxn",
    "block": "{\"symbols\":[\"note\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[12],[1,[32,1,[\"note\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"notes\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/notes-display-for-shipping-out/template.hbs"
    }
  });

  _exports.default = _default;
});