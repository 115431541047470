define("product-management/pods/components/navigation/mobile-nav-links/start-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L4JbAaEc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"handleToggleStart\"]]],null]],null],[12],[2,\"\\n\\t\"],[10,\"span\"],[12],[2,\"Start a New Order\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[14,0,\"p-4\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[12],[2,\"Enter a PO#\"],[13],[2,\"\\n\\t\\t\"],[8,\"input\",[[24,0,\"form-control\"],[4,[38,1],[[32,0,[\"handleInputInsert\"]]],null],[4,[38,3],[\"input\",[30,[36,2],[[32,0,[\"handleInput\"]]],null]],null]],[[\"@value\"],[[34,0]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"mt-2\"],[12],[2,\"\\n\\t\\t\"],[8,\"stardust/ui/async-button\",[[24,0,\"btn btn-primary w-32 p-1 text-sm\"]],[[\"@disabled\",\"@defaultText\",\"@textColor\",\"@pendingText\",\"@isPending\",\"@onClick\"],[[30,[36,5],[[35,4]],null],\"Start Order\",\"white\",\"Please wait...\",[32,0,[\"save\",\"isRunning\"]],[30,[36,2],[[32,0,[\"handleSave\"]]],null]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dealerReference\",\"did-insert\",\"fn\",\"on\",\"isValid\",\"not\",\"isStarting\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/navigation/mobile-nav-links/start-order/template.hbs"
    }
  });

  _exports.default = _default;
});