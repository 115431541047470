define("product-management/pods/dealer/ability", ["exports", "product-management/pods/application/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ability.default.extend({});

  _exports.default = _default;
});