define("product-management/pods/components/wip-table/need-by/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "td",
    needBy: Ember.computed("item.needBy", function () {
      var needBy = this.get("item.needBy");
      if (Ember.isEmpty(needBy)) return null;
      return (0, _moment.default)(needBy).toString();
    }),
    actions: {
      setDate: function setDate(orderId, value) {
        this.attrs["on-set-date"](orderId, "needBy", value);
      }
    }
  });

  _exports.default = _default;
});