define("product-management/pods/components/sun-pro/fulfillment/deliveries/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VxOiFzP1",
    "block": "{\"symbols\":[\"&attrs\",\"@delivery\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"p-4 text-white rounded-lg font-semibold text-lg  \",[34,0]]]],[17,1],[12],[2,\"\\n  \"],[1,[30,[36,1],[[32,2,[\"status\"]]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"cssClass\",\"humanize\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/fulfillment/deliveries/status/template.hbs"
    }
  });

  _exports.default = _default;
});