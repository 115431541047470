define("product-management/pods/admin/settings/stations/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KagYsrsG",
    "block": "{\"symbols\":[\"station\",\"PageHeader\"],\"statements\":[[8,\"settings/page-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,[32,2,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Stations\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,[32,2,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"admin.settings.stations.new\"]],[[\"default\"],[{\"statements\":[[2,\"Add a Station\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"w-1/2\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"list-group-item\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"flex justify-between items-center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.settings.stations.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[8,\"stations/key\",[],[[\"@station\"],[[32,1]]],null],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/stations/index/template.hbs"
    }
  });

  _exports.default = _default;
});