define("product-management/pods/components/admin/inventory-items/table-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    savedRecords: Ember.computed.filterBy("records", "isNew", false),
    sortBy: ["name"],
    sortedRecords: Ember.computed.sort("savedRecords", "sortBy")
  });

  _exports.default = _default;
});