define("product-management/pods/components/pm/ui/selectable-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8IB3tnlH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,4],null,[[\"selected\",\"onSelect\",\"button\"],[[35,3],[30,[36,2],[[32,0],[35,1]],null],[30,[36,0],[\"pm/ui/select-button\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"selectItem\",\"action\",\"selected\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/selectable-container/template.hbs"
    }
  });

  _exports.default = _default;
});