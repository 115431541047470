define("product-management/pods/shop-diagnostic/cut-sheets/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    order: Ember.computed.alias("model"),
    lineItemSort: ["sequenceNumber"],
    lineItems: Ember.computed.sort("order.lineItems", "lineItemSort")
  });

  _exports.default = _default;
});