define("product-management/pods/components/async-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3YN9w55+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[32,0],[35,5]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[35,2],\"pending\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"default\",\"pending\",\"textState\",\"eq\",\"if\",\"_stateObject\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/async-button/template.hbs"
    }
  });

  _exports.default = _default;
});