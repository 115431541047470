define("product-management/pods/components/pm/notes/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XQ6J6qvI",
    "block": "{\"symbols\":[\"note\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"pm/notes/item\",[],[[\"@note\",\"@noteRoute\"],[[32,1],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"mt-4\"],[12],[2,\"\\n    \"],[8,\"blank-slate\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    There aren't any notes for this item yet. \\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"noteRoute\",\"notes\",\"-track-array\",\"each\",\"hasNotes\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/notes/list/template.hbs"
    }
  });

  _exports.default = _default;
});