define("product-management/pods/components/dealers/accounting/display/cards-on-file/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "guCXXvbV",
    "block": "{\"symbols\":[\"Cards\",\"row\",\"@mxMerchantAccount\"],\"statements\":[[8,\"dealers/accounting/display/cards-on-file\",[],[[\"@mxMerchantAccount\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"display-form\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"display-group\"],[12],[2,\"\\n            \"],[10,\"span\"],[12],[2,\"Cards On File\"],[13],[2,\"\\n            \"],[10,\"table\"],[14,0,\"table table-striped table-condensed\"],[12],[2,\"\\n                \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[8,\"dealers/accounting/display/cards-on-file/table/header\",[],[[],[]],null],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n                \"],[10,\"tbody\"],[12],[2,\"\\n                    \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                        \"],[8,\"dealers/accounting/display/cards-on-file/table/row\",[],[[\"@record\"],[[32,2]]],null],[2,\"\\n                    \"]],\"parameters\":[2]}]]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/accounting/display/cards-on-file/table/template.hbs"
    }
  });

  _exports.default = _default;
});