define("product-management/pods/components/sun-pro/ui/quill-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yct5Yag3",
    "block": "{\"symbols\":[\"@text\"],\"statements\":[[8,\"quill-editor\",[],[[\"@name\",\"@text\",\"@onChange\",\"@placeholder\",\"@theme\"],[[34,0],[32,1],[32,0,[\"handleChange\"]],[34,1],[34,2]]],null]],\"hasEval\":false,\"upvars\":[\"editorName\",\"placeholder\",\"theme\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/ui/quill-editor/template.hbs"
    }
  });

  _exports.default = _default;
});