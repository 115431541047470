define("product-management/pods/admin/route", ["exports", "ember-ui-helpers/mixins/notifyable", "product-management/mixins/shared/top-level-route"], function (_exports, _notifyable, _topLevelRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_topLevelRoute.default, _notifyable.default, {// userAgent: service(),
    // redirect(){
    // 	const userAgent = this.userAgent
    // 	if(userAgent.get("device.isMobile"))
    // 		this.transitionTo("admin_mobile")
    // }
  });

  _exports.default = _default;
});