define("product-management/pods/components/kpis/statistics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fvvo6IHz",
    "block": "{\"symbols\":[\"@title\",\"@statisticClasses\",\"&default\"],\"statements\":[[10,\"h3\"],[14,0,\"text-xl leading-6 font-medium text-grey-darkest\"],[12],[2,\"\\n\\t\"],[1,[32,1]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"px-2\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"mt-5 flex bg-grey-lighter rounded flex-wrap\"],[12],[2,\"\\n\\t\\t\"],[18,3,[[30,[36,1],null,[[\"stat\"],[[30,[36,0],[\"kpis/statistics/statistic\"],[[\"class\"],[[32,2]]]]]]]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/kpis/statistics/template.hbs"
    }
  });

  _exports.default = _default;
});