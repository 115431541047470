define("product-management/pods/admin/accounting/purchasing/vendors/show/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fvW86kNS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"Vendor Profile \"],[1,[35,0,[\"name\"]]],[13],[2,\"\\n\"],[8,\"admin/vendors/item-form\",[],[[\"@item\",\"@onSave\"],[[32,0,[\"model\"]],[30,[36,1],[[32,0,[\"handleSave\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/purchasing/vendors/show/index/template.hbs"
    }
  });

  _exports.default = _default;
});