define("product-management/pods/components/admin/vendors/table-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HUyBGqvI",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[10,\"td\"],[12],[2,\"\\n\\t\"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.accounting.purchasing.vendors.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n\\t\"],[1,[32,1,[\"contact\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n\\t\"],[1,[32,1,[\"accountNumber\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n\\t\"],[1,[32,1,[\"defaultTerms\"]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/admin/vendors/table-item/template.hbs"
    }
  });

  _exports.default = _default;
});