define("product-management/pods/shop-diagnostic/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+oQTdLXv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n      \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"no-print\"],[4,[38,0],[[32,0],\"logout\"],null],[12],[2,\"Logout\"],[13],[2,\"\\n      \"],[10,\"hr\"],[14,0,\"no-print\"],[12],[13],[2,\"\\n      \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop-diagnostic/template.hbs"
    }
  });

  _exports.default = _default;
});