define("product-management/pods/components/shop/line-items/final-cut-station/component", ["exports", "product-management/mixins/shop/interior-station"], function (_exports, _interiorStation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_interiorStation.default, {
    queryParams: ["step"],
    step: 1,
    componentName: Ember.computed("step", function () {
      var step = this.step;
      return "shop/line-items/final-cut-station/step-".concat(step);
    }),
    actions: {
      nextStep: function nextStep(step) {
        if (step === undefined) {
          step = parseInt(this.step) + 1;
        }

        this.setProperties({
          step: step
        });
      },
      prevStep: function prevStep(step) {
        step = parseInt(this.step) - 1;
        this.setProperties({
          step: step
        });
      }
    }
  });

  _exports.default = _default;
});