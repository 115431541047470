define("product-management/pods/components/ui/timeline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0EXj0JCE",
    "block": "{\"symbols\":[\"row\",\"index\",\"@items\",\"&default\"],\"statements\":[[10,\"nav\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"overflow-hidden\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[18,4,[[30,[36,3],null,[[\"row\",\"listItem\"],[[32,1],[30,[36,2],[\"ui/timeline/item\"],[[\"lastItem\"],[[30,[36,1],[[30,[36,0],[[32,3,[\"length\"]]],null],[32,2]],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"dec\",\"eq\",\"component\",\"hash\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/timeline/template.hbs"
    }
  });

  _exports.default = _default;
});