define("product-management/pods/components/shop/notes-display-for-shipping-out/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "ul"
  });

  _exports.default = _default;
});