define("product-management/pods/dealer-admin/order/notes-item/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var order = this.modelFor("dealer_admin.order").order;
      return Ember.RSVP.hash({
        order: order,
        lineItem: this.store.createRecord("lineItem", {
          quantity: 1,
          order: order
        })
      });
    }
  });

  _exports.default = _default;
});