define("product-management/pods/components/pricing/size-matrix-height-only-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["form-group"],
    pricingMethod: Ember.computed.alias("product.pricingMethod"),
    pricing: Ember.computed.alias("product.pricing")
  });

  _exports.default = _default;
});