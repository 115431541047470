define("product-management/pods/components/ordering/edit-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    order: Ember.computed.alias("line-item.order")
  });

  _exports.default = _default;
});