define("product-management/pods/shop-diagnostic/cut-sheet/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iwoH4Azz",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[35,0]],null]],[2,\"\\n\"],[1,[30,[36,4],null,[[\"lineItem\",\"order\"],[[35,3],[35,2]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"cutSheetTitle\",\"page-title\",\"order\",\"lineItem\",\"custom/shop/cut-sheet/cut-sheet-general\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop-diagnostic/cut-sheet/template.hbs"
    }
  });

  _exports.default = _default;
});