define("product-management/pods/admin/accounting/invoices/route", ["exports", "ember-invoicing/mixins/invoice-list-route"], function (_exports, _invoiceListRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_invoiceListRoute.default, {});

  _exports.default = _default;
});