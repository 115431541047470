define("product-management/validations/product", ["exports", "ember-changeset-validations/validators", "product-management/validators/conditional"], function (_exports, _validators, _conditional) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)(true),
    productType: (0, _validators.validatePresence)(true),
    pricingMethod: (0, _validators.validatePresence)(true),
    pricing: (0, _validators.validatePresence)(true),
    screen: (0, _conditional.default)({
      dependency: {
        field: "productType",
        valueFrom: "name",
        includes: ["Complete Screens"]
      }
    }),
    motor: (0, _conditional.default)({
      dependency: {
        field: "productType",
        valueFrom: "name",
        includes: ["Complete Screens"]
      }
    })
  };
  _exports.default = _default;
});