define("product-management/pods/orders/show/audits/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o9NMvx5h",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-xl font-semibold mb-2\"],[12],[2,\"\\n    Audits\\n  \"],[13],[2,\"\\n  \"],[10,\"nav\"],[14,0,\"flex audit-trail-navigation\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"orders.show.audits.index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Full Audit Trail\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      |\\n    \"],[13],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"orders.show.audits.delivery-history\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Delivery History\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      |\\n    \"],[13],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"orders.show.index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Return to Order\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/audits/template.hbs"
    }
  });

  _exports.default = _default;
});