define("product-management/mixins/shop/hardware/other-units", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    moreThanOneUnit: Ember.computed.gt("unit-count", 1),
    displayOtherUnits: Ember.computed.and("moreThanOneUnit", "should-fetch-other-units"),
    mainAreaClass: Ember.computed('displayOtherUnits', function () {
      return this.displayOtherUnits ? "col-md-9" : "col-md-12";
    })
  });

  _exports.default = _default;
});