define("product-management/validators/conditional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateConditional;

  function dependencyValue(changes, content, field, valueFrom) {
    if (changes[field] != undefined) {
      return changes[field].get(valueFrom);
    } else {
      return content.get("".concat(field, ".").concat(valueFrom));
    }
  }

  function matchesDependencyValue(includes, presentValue) {
    return includes.includes(presentValue);
  }

  function isValid(newValue) {
    if (newValue === null) return false;
    return Ember.isPresent(newValue.get("name"));
  }

  function validateConditional(opts) {
    return function (key, newValue, oldValue, changes, content) {
      var _opts$dependency = opts.dependency,
          field = _opts$dependency.field,
          includes = _opts$dependency.includes,
          valueFrom = _opts$dependency.valueFrom;
      var presentValue = dependencyValue(changes, content, field, valueFrom);

      if (!matchesDependencyValue(includes, presentValue)) {
        return true;
      }

      return isValid(newValue);
    };
  }
});