define("product-management/pods/components/painless-manufacturing/order/place-work-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uEic0Xrj",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"select-item\"],[[32,0,[\"selectedQueue\"]],[32,0,[\"workQueues\"]],\"content.name\",\"content.name\",[30,[36,0],[[32,0],[32,0,[\"handleQueueSelected\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"button\"],[15,\"onclick\",[30,[36,4],[[32,0,[\"place\"]]],null]],[14,0,\"mt-4 rounded bg-green border px-3 py-2 hover:bg-green-dark text-white w-full\"],[12],[2,\"\\n    Place Work Orders\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"ember-selectize\",\"workQueues\",\"if\",\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/order/place-work-order/template.hbs"
    }
  });

  _exports.default = _default;
});