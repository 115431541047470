define("product-management/pods/admin/settings/product-weights/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jJm0J8Mk",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex text-xl mb-4 sp-sub-nav\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.product-weights.weights\"]],[[\"default\"],[{\"statements\":[[2,\"Product Weights\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.product-weights.categories\"]],[[\"default\"],[{\"statements\":[[2,\"Product Weight Categories\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/product-weights/template.hbs"
    }
  });

  _exports.default = _default;
});