define("product-management/pods/components/product-view/complete-screens/nav-tabs/component", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: ""
  });

  _exports.default = _default;
});