define("product-management/pods/product/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // productType: DS.belongsTo(),
    optionSet: (0, _model.belongsTo)(),
    productType: (0, _model.belongsTo)(),
    catalogItems: (0, _model.hasMany)(),
    screen: (0, _model.belongsTo)(),
    invoiceItem: (0, _model.belongsTo)(),
    labelConfig: (0, _model.attr)(),
    name: (0, _model.attr)(),
    pricingMethod: (0, _model.attr)(),
    defaultOptions: (0, _model.attr)("raw"),
    simplePrice: (0, _model.attr)(),
    description: (0, _model.attr)(),
    isForSale: (0, _model.attr)(),
    onlyPicked: (0, _model.attr)(),
    configurationName: (0, _model.attr)(),
    workFlow: (0, _model.belongsTo)(),
    canSwapManualGearBox: (0, _model.attr)(),
    checkInventoryBeforeBuild: (0, _model.attr)(),
    treatMultipleAsSingleUnit: (0, _model.attr)(),
    pricing: (0, _model.attr)("raw"),
    stations: (0, _model.attr)("raw"),
    orderConfig: (0, _model.attr)("raw"),
    optionSetOverrides: (0, _model.attr)("raw"),
    defaultRoute: Ember.computed.alias("productType.defaultRoute"),
    isCompleteScreen: Ember.computed.equal("productType.name", "Complete Screens"),
    isPartProduct: Ember.computed.equal("productType.name", "Parts"),
    isPriceOnly: Ember.computed.equal("pricingMethod", "price-only"),
    isPricePerSquareFoot: Ember.computed.equal('pricingMethod', 'price-per-square-foot'),
    hasNoPricingComponent: Ember.computed.or('isPriceOnly'),
    price: Ember.computed.alias("pricing.price"),
    isMotor: (0, _model.attr)(),
    motor: (0, _model.belongsTo)("product", {
      inverse: "motor",
      async: true
    }),
    products: (0, _model.hasMany)("product", {
      inverse: "motor",
      async: true
    }),
    quote: (0, _emberApiActions.memberAction)({
      type: "get",
      path: "quote"
    })
  });

  _exports.default = _default;
});