define("product-management/pods/components/ordering/start-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ncPaElG8",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[6,[37,6],null,[[\"renderInPlace\",\"onHide\"],[false,[30,[36,2],[[32,0],\"cancel\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[32,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('product-management/pods/components/ordering/start-order/template.hbs' @ L2:C7) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"h4\"],[12],[2,\"Start your order\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[30,[36,4],[[32,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('product-management/pods/components/ordering/start-order/template.hbs' @ L5:C7) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      Enter your PO #:\\n      \"],[1,[30,[36,3],null,[[\"onEnter\",\"auto-focus\",\"propertyName\",\"changeset\"],[[30,[36,2],[[32,0],\"start\",[35,0]],null],true,\"dealerReference\",[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[30,[36,4],[[32,1,[\"footer\"]],\"expected `modal.footer` to be a contextual component but found a string. Did you mean `(component modal.footer)`? ('product-management/pods/components/ordering/start-order/template.hbs' @ L11:C7) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,\"disabled\",[30,[36,1],[[35,0,[\"isValid\"]],\"disabled\"],null]],[24,0,\"btn-add\"],[24,4,\"submit\"],[4,[38,2],[[32,0],\"start\",[35,0]],null],[12],[2,\"Start Order\"],[13],[2,\"\\n      \"],[11,\"a\"],[24,6,\"#\"],[4,[38,2],[[32,0],\"cancel\"],null],[12],[2,\"cancel\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"changeset\",\"unless\",\"action\",\"validated-input\",\"-assert-implicit-component-helper-argument\",\"component\",\"bs-modal\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/start-order/template.hbs"
    }
  });

  _exports.default = _default;
});