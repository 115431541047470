define("product-management/pods/components/google/address/auto-complete/clear/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ULMd11K",
    "block": "{\"symbols\":[\"@onClear\",\"&attrs\"],\"statements\":[[11,\"a\"],[16,\"onclick\",[32,1]],[24,0,\"cursor-pointer text-blue-400\"],[17,2],[12],[2,\"clear address\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/google/address/auto-complete/clear/template.hbs"
    }
  });

  _exports.default = _default;
});