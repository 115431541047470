define("product-management/pods/components/sun-pro/news-messages/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RksR7zJG",
    "block": "{\"symbols\":[\"@newsMessage\"],\"statements\":[[10,\"div\"],[14,0,\"row my-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Enter a subject\"],[13],[2,\"\\n      \"],[11,\"input\"],[16,2,[32,1,[\"subject\"]]],[24,0,\"form-control\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]]],null]],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Enter your message\"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"h-64\"],[12],[2,\"\\n        \"],[8,\"sun-pro/ui/quill-editor\",[],[[\"@text\",\"@onChange\"],[[32,1,[\"content\"]],[30,[36,0],[[32,0,[\"handleChange\"]]],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"my-6 text-right\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"Save\"],[13],[2,\"\\n      or\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.news-messages\"]],[[\"default\"],[{\"statements\":[[2,\"cancel\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/news-messages/form/template.hbs"
    }
  });

  _exports.default = _default;
});