define("product-management/helpers/get-dealer-summary-data-field", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function getDealerSummaryDataField(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 4),
        data = _ref2[0],
        key = _ref2[1],
        field = _ref2[2],
        _ref2$ = _ref2[3],
        defaultValue = _ref2$ === void 0 ? null : _ref2$;

    var item = data.find(function (item) {
      return item.name == key;
    });
    if (item) return item.data[field];else return defaultValue;
  });

  _exports.default = _default;
});