define("product-management/initializers/ember-simple-auth-components", ["exports", "product-management/config/environment", "ember-simple-auth-components/configuration"], function (_exports, _environment, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-simple-auth-components',
    initialize: function initialize(registry) {
      var config = _environment.default['ember-simple-auth-components'] || {};
      config.host = _environment.default.APP.host;

      _configuration.default.load(config);
    }
  };
  _exports.default = _default;
});