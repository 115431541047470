define("product-management/pods/components/stardust/report/sql/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5JlPAx8T",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"relative mb-2\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"z-1\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"flex justify-between mb-2\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,0,\"text-lg font-semibold mb-2\"],[12],[2,\"SQL\"],[13],[2,\"\\n\\t\\t\\t\"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleCopy\"]]],null]],null],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-copy bg-teal-darker fa fa-copy font-semibold mb-2 p-2 rounded rounded-lg text-base text-sm text-white\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"pre\"],[14,0,\"mb-2 whitespace-pre-line\"],[12],[2,\"\\t\"],[1,[34,2]],[2,\"\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[11,\"textarea\"],[24,0,\"border-0 outline-none absolute top-0 left-0 z-0\"],[24,\"rows\",\"0\"],[24,\"cols\",\"0\"],[24,5,\"height:0px;width:0px;resize:none\"],[4,[38,3],[[32,0,[\"handleTextareaInsert\"]],[32,0]],null],[12],[1,[34,2]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"trimmedSql\",\"did-insert\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/report/sql/template.hbs"
    }
  });

  _exports.default = _default;
});