define("product-management/pods/dealer-admin/order/interior-unit/index/route", ["exports", "marygrove-components/mixins/interior-unit-wizard-route"], function (_exports, _interiorUnitWizardRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_interiorUnitWizardRoute.default, {});

  _exports.default = _default;
});