define("product-management/pods/components/shop-codes/table-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    persistedShopCodes: Ember.computed.filterBy("shop-codes", "isNew", false),
    availableShopCodeCollection: Ember.computed("persistedShopCodes", "is-showing-deactivated", function () {
      var persistedShopCodes = this.persistedShopCodes;
      if (this["is-showing-deactivated"]) return persistedShopCodes;else return persistedShopCodes.filterBy("active", true);
    }),
    toggleText: Ember.computed("is-showing-deactivated", function () {
      return this["is-showing-deactivated"] ? "Only show active codes" : "Show deactivated codes";
    }),
    "sort-by": ["firstName", "lastName"],
    sortedShopCodes: Ember.computed.sort("availableShopCodeCollection", "sort-by"),
    hasData: Ember.computed.notEmpty("sortedShopCodes"),
    "is-showing-deactivated": false,
    actions: {
      toggleDeactivated: function toggleDeactivated() {
        this.toggleProperty("is-showing-deactivated");
      }
    }
  });

  _exports.default = _default;
});