define("product-management/pods/components/pm/ui/loading-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t+R1Y960",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\tLoading\\n\"]],\"parameters\":[]}]]],[10,\"span\"],[14,0,\"dot dot1\"],[12],[2,\".\"],[13],[10,\"span\"],[14,0,\"dot dot2\"],[12],[2,\".\"],[13],[10,\"span\"],[14,0,\"dot dot3\"],[12],[2,\".\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/loading-message/template.hbs"
    }
  });

  _exports.default = _default;
});