define("product-management/pods/components/action-cable-subscriber/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c+Gd3U/b",
    "block": "{\"symbols\":[\"@params\",\"&default\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"handleUpdate\"]],[32,0],[32,1]],null],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-update\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/action-cable-subscriber/template.hbs"
    }
  });

  _exports.default = _default;
});