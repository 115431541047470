define("product-management/pods/components/orders/shipping-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7QswyIKT",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[10,\"dt\"],[12],[2,\"\\nShip To:\\n\"],[13],[2,\"\\n\"],[10,\"dd\"],[12],[2,\"\\n\"],[6,[37,9],[[35,10]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[35,3,[\"shippingAddress\"]]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[35,3,[\"shippingCity\"]]],[2,\", \"],[1,[35,3,[\"shippingState\"]]],[2,\" \"],[1,[35,3,[\"shippingZip\"]]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[\"changeShippingAddress order\",[35,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"margin-top-10\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-xs btn-default\"],[4,[38,2],[[32,0],\"toggleEdit\"],null],[12],[2,\"Edit\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"hr\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"   \"],[10,\"h4\"],[12],[2,\"Edit Address\"],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"   \"],[1,[30,[36,1],null,[[\"changeset\"],[[32,1]]]]],[2,\"\\n   \"],[10,\"hr\"],[12],[13],[2,\"\\n   \"],[11,\"button\"],[24,0,\"btn btn-xs btn-primary\"],[4,[38,2],[[32,0],\"updateShippingInfo\",[32,1]],null],[12],[2,\"Save\"],[13],[2,\"\\n   or \"],[11,\"a\"],[24,6,\"#\"],[4,[38,2],[[32,0],\"toggleEdit\"],null],[12],[2,\"cancel\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[10,\"h4\"],[14,0,\"text-center\"],[12],[1,[30,[36,0],[\"refresh\"],null]],[2,\" Saving...\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"orders/admin-shipping-form\",\"action\",\"order\",\"changeset\",\"with\",\"can\",\"if\",\"isEditing\",\"unless\",\"isSaving\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/orders/shipping-info/template.hbs"
    }
  });

  _exports.default = _default;
});