define("product-management/pods/admin/production-lines/show/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8uIoZnAw",
    "block": "{\"symbols\":[],\"statements\":[[8,\"painless-manufacturing/production/production-line\",[],[[\"@stations\",\"@productionLineId\",\"@hideStatusLabel\"],[[34,0],[34,1,[\"id\"]],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"stations\",\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/production-lines/show/index/template.hbs"
    }
  });

  _exports.default = _default;
});