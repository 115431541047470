define("product-management/pods/dealer-admin/order/conversion-kit/index/route", ["exports", "marygrove-components/mixins/conversion-kit-wizard-route"], function (_exports, _conversionKitWizardRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_conversionKitWizardRoute.default, {});

  _exports.default = _default;
});