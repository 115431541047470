define("product-management/pods/components/ordering/return-of-goods/warranty-claim/warranty-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hYZ9oqZr",
    "block": "{\"symbols\":[\"&attrs\",\"@warrantyItem\"],\"statements\":[[11,\"div\"],[24,0,\"h-64 p-4 border border-grey rounded-lg w-64 cursor-pointer\"],[17,1],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleClick\"]]],null]],null],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"block font-semibold mb-2 text-center\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,2,[\"name\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"img\"],[15,\"src\",[32,2,[\"product\",\"image\",\"url\"]]],[14,0,\"rounded-lg w-32 block h-auto mx-auto\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"humanize\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/return-of-goods/warranty-claim/warranty-item/template.hbs"
    }
  });

  _exports.default = _default;
});