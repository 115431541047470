define("product-management/pods/components/stardust/report/compared-headers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gRroURjt",
    "block": "{\"symbols\":[\"@showChangePercentage\",\"@showChange\",\"@endAt\",\"@startAt\",\"@compareEndAt\",\"@compareStartAt\",\"@comparing\",\"&default\"],\"statements\":[[18,8,[[30,[36,1],null,[[\"header\"],[[30,[36,0],[\"stardust/report/compared-headers/item\"],[[\"comparing\",\"compareStartAt\",\"compareEndAt\",\"startAt\",\"endAt\",\"showChange\",\"showChangePercentage\"],[[32,7],[32,6],[32,5],[32,4],[32,3],[32,2],[32,1]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/report/compared-headers/template.hbs"
    }
  });

  _exports.default = _default;
});