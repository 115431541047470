define("product-management/pods/components/icon-radio-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yqu8UnFP",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[11,\"a\"],[24,6,\"#\"],[4,[38,3],[[32,0],\"selectValue\"],null],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"ember-icon-radio-button-icon fa \",[34,7]]]],[12],[13],[2,\"\\n  \"],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"ember-icon-radio-button-label\"],[12],[1,[34,6]],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[35,8]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[14,0,\"ember-icon-radio-button-other-label\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,5],null,[[\"value\",\"key-up\",\"class\"],[[35,4],[30,[36,3],[[32,0],\"selectValue\"],null],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"other-label\",\"if\",\"otherInputClasses\",\"action\",\"customValue\",\"input\",\"displayValue\",\"icon\",\"isOther\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/icon-radio-button/template.hbs"
    }
  });

  _exports.default = _default;
});