define("product-management/pods/components/pm/attachments/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TgMl/KvT",
    "block": "{\"symbols\":[\"attachment\",\"&default\",\"@attachable\"],\"statements\":[[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[30,[36,3],[\"createdAt\",[32,3,[\"attachments\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,2,[[32,1],[30,[36,1],[[32,0],[35,2]],null],[30,[36,1],[[32,0],[35,0]],null]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"updateAttachment\",\"action\",\"deleteAttachment\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/attachments/body/template.hbs"
    }
  });

  _exports.default = _default;
});