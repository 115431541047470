define("product-management/pods/admin/accounting/shipments/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    shipments: Ember.computed.alias("model"),
    isSaved: false,
    actions: {
      saveShippingCost: function saveShippingCost(shipment) {
        var _this = this;

        var promises = [];
        shipment.get("boxes").forEach(function (item) {
          promises.push(item.save());
        });
        return Ember.RSVP.allSettled(promises).then(function () {
          shipment.markAsCosted().then(function (data) {
            _this.store.pushPayload(data);

            shipment.set("isMovingToInvoice", true);
            Ember.run.later(_this, function () {
              if (this.isDestroyed) {
                return;
              }

              this.shipments.removeObject(shipment);
              shipment.set("isMovingToInvoice", false);
            }, 5000);
          });
        });
      }
    }
  });

  _exports.default = _default;
});