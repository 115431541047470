define("product-management/pods/components/password-requirements/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "arTn+DFh",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Your password must:\\n\"],[10,\"ul\"],[12],[2,\"\\n  \"],[10,\"li\"],[12],[2,\"be at least 8 characters\"],[13],[2,\"\\n  \"],[10,\"li\"],[12],[2,\"include 1 capital letter\"],[13],[2,\"\\n  \"],[10,\"li\"],[12],[2,\"include 1 number\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/password-requirements/template.hbs"
    }
  });

  _exports.default = _default;
});