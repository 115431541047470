define("product-management/pods/dealer-admin/order/edit-line-item/route", ["exports", "product-management/mixins/orders/edit-line-item-route"], function (_exports, _editLineItemRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_editLineItemRoute.default, {});

  _exports.default = _default;
});