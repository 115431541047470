define("product-management/pods/pm/users/list/status-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XnrzHmBA",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[1,[30,[36,4],[[32,1,[\"status\"]]],null]],[2,\"\\n\\n\"],[10,\"div\"],[12],[2,\"\\n  \"],[10,\"button\"],[15,\"onclick\",[30,[36,6],[[32,0],[35,5]],null]],[15,0,[31,[\"btn \",[34,7],\" btn-xs\"]]],[12],[1,[34,8]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[32,0,[\"status\"]],\"unconfirmed\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[35,2,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[11,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleResendClick\"]]],null]],null],[12],[2,\"\\n\\t\"],[10,\"small\"],[12],[2,\"Resend Confirmation\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  Sending...\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"resendConfirmation\",\"unless\",\"humanize\",\"handleClick\",\"action\",\"buttonClass\",\"buttonText\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/pm/users/list/status-display/template.hbs"
    }
  });

  _exports.default = _default;
});