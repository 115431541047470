define("product-management/helpers/zero-if-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function zeroIfEmpty(value, _opts) {
    return Ember.isPresent(value) ? value : 0;
  });

  _exports.default = _default;
});