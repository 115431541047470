define("product-management/pods/components/purchasing/critical-inventory/projection-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T6qjeev0",
    "block": "{\"symbols\":[\"@isRunningSave\",\"@isEditing\"],\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[11,\"input\"],[16,\"disabled\",[32,1]],[16,2,[34,2]],[16,0,[31,[\"border border-grey-light p-1 text-sm rounded w-12 \",[30,[36,1],[[32,1],\"disabled\"],null]]]],[4,[38,3],[[32,0,[\"handleInsert\"]],[32,0]],null],[4,[38,5],[\"input\",[30,[36,4],[[32,0,[\"handleInput\"]]],null]],null],[4,[38,5],[\"keydown\",[30,[36,4],[[32,0,[\"handleKeydown\"]]],null]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,0,[\"projection\"]],[35,0,[\"projection\"]],\"--0--\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"projection\",\"if\",\"projectionValue\",\"did-insert\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/critical-inventory/projection-input/template.hbs"
    }
  });

  _exports.default = _default;
});