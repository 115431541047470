define("product-management/pods/components/audit-trail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WfVXYSUM",
    "block": "{\"symbols\":[\"section\",\"field\",\"@resource\"],\"statements\":[[10,\"span\"],[14,0,\"text-xl block font-semibold mb-2\"],[12],[2,\"\\n  Summary (\\n  \"],[1,[30,[36,1],[[32,3,[\"status\"]]],null]],[2,\"\\n  )\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"grid grid-cols-3\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"p-2\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"p-2 border border-grey-light rounded\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n          \"],[10,\"span\"],[14,0,\"text-lg block font-semibold mb-1\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[32,1]],null]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[8,\"audit-trail-item\",[],[[\"@section\",\"@audit-trail\",\"@key-suffix\"],[[32,1],[34,0],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"auditTrail\",\"humanize\",\"fields\",\"-track-array\",\"each\",\"sections\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/audit-trail/template.hbs"
    }
  });

  _exports.default = _default;
});