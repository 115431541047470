define("product-management/pods/shop-diagnostic/line-item/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord("lineItem", params.line_item_id, {
        reload: true,
        include: "order"
      });
    }
  });

  _exports.default = _default;
});