define("product-management/utils/get-cmd-key", ["exports", "ember-keyboard/utils/get-cmd-key"], function (_exports, _getCmdKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _getCmdKey.default;
    }
  });
});