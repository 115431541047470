define("product-management/utils/focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.focusFirst = focusFirst;
  _exports.focus = focus;

  function focusFirst(selector) {
    document.querySelectorAll(selector)[0].focus();
  }

  function focus(selector) {
    focusFirst(selector);
  }
});