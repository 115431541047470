define("product-management/pods/components/pm/ui/tags/tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L06Ez6oY",
    "block": "{\"symbols\":[\"@index\",\"@tag\",\"@canEdit\"],\"statements\":[[10,\"div\"],[14,0,\"text-sm bg-blue-lightest text-grey-darker rounded-lg px-3 py-2 border m-1\"],[12],[2,\"\\n  \"],[1,[32,2]],[2,\" \\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"a\"],[15,\"onclick\",[30,[36,1],[[32,0],[35,0],[32,1]],null]],[14,0,\"pl-2 text-grey cursor-pointer\"],[12],[2,\"x\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"removeTag\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/tags/tag/template.hbs"
    }
  });

  _exports.default = _default;
});