define("product-management/pods/components/edit-line-item-container/component", ["exports", "marygrove-components/mixins/awning-step-validation"], function (_exports, _awningStepValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_awningStepValidation.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.setupValidation();
    },
    isInvalid: Ember.computed.not("isValid"),
    componentName: Ember.computed("mode", function () {
      return "ordering/edit/edit-".concat(this.mode);
    }),
    actions: {
      save: function save(changeset) {
        this.attrs.onSave(changeset);
      },
      closeModal: function closeModal() {
        this.attrs.onClose();
      }
    },
    awningIsValid: true,
    colorsIsValid: true,
    zipperIsValid: true,
    // hoodHousingIsValid: true,
    bottomBarIsValid: true,
    width: Ember.computed.alias("model.width"),
    leftHeight: Ember.computed.alias("model.leftHeight"),
    rightHeight: Ember.computed.alias("model.rightHeight"),
    height: Ember.computed.alias("model.height"),
    lineItem: Ember.computed.alias("model"),
    details: Ember.computed.alias("lineItem.details"),
    frameSizeIsValid: Ember.computed("details.frame_size", "details.width", "details.projection", function () {
      return true;
    }),
    hoodHousingIsValid: Ember.computed("model.rollTubeSize", "model.hoodColor", "model.hoodSize", function () {
      var rollTubeSize = this.get("model.rollTubeSize");
      var hoodColor = this.get("model.hoodColor");
      var hoodSize = this.get("model.hoodSize");
      var result = Ember.isPresent(hoodSize) && Ember.isPresent(hoodColor);
      return hoodSize === "None" ? result && Ember.isPresent(rollTubeSize) : result;
    }),
    interiorDimensionsIsValid: Ember.computed("width", "height", function () {
      return this.width != 0 && this.height != 0;
    }),
    dimensionsIsValid: Ember.computed("width", "leftHeight", "rightHeight", function () {
      return this.width != 0 && this.leftHeight != 0 && this.rightHeight != 0;
    }),
    setupValidation: function setupValidation() {
      var mode = this.mode;
      var cp = "".concat(Ember.String.camelize(mode), "IsValid");
      Ember.defineProperty(this, "isValid", Ember.computed.equal(cp, true));
    },
    needRemote: Ember.computed.alias("model.needRemote"),
    remoteQuantity: Ember.computed.alias("model.remoteQuantity"),
    remoteChannels: Ember.computed.alias("model.controlRemoteChannels"),
    controlType: Ember.computed.alias("model.controlType"),
    motorId: Ember.computed.alias("model.motorId"),
    motorName: Ember.computed.alias("model.motorName"),
    controlLocation: Ember.computed.alias("model.controlLocation"),
    fasciaColor: Ember.computed.alias("model.fasciaColor"),
    interiorScreenIsValid: Ember.computed("model.meshName", "model.meshColor", function () {
      return Ember.isPresent(this.get("model.meshName")) && Ember.isPresent(this.get("model.meshColor"));
    }),
    screenDetailsIsValid: Ember.computed("model.meshName", "model.meshColor", function () {
      return Ember.isPresent(this.get("model.meshName")) && Ember.isPresent(this.get("model.meshColor"));
    }),
    motorIsValid: Ember.computed("controlLocation", "needRemote", "remoteChannels", "motorName", "remoteQuantity", function () {
      var controlLocation = this.controlLocation;
      var needRemote = this.needRemote;
      var remoteChannels = this.remoteChannels;
      var motorName = this.motorName;
      var remoteQuantity = this.remoteQuantity;
      if (Ember.isEmpty(controlLocation) || Ember.isEmpty(motorName) || Ember.isEmpty(needRemote)) return false;
      if (needRemote === "No") return true;
      return Ember.isPresent(remoteChannels) && Ember.isPresent(remoteQuantity);
    }),
    fasciaIsValid: Ember.computed("fasciaColor", function () {
      return Ember.isPresent(this.fasciaColor);
    }),
    controlsIsValid: Ember.computed("needRemote", "remoteChannels", "controlType", "motorId", "remoteQuantity", function () {
      var needRemote = this.needRemote;
      var remoteChannels = this.remoteChannels;
      var controlType = this.controlType;
      var motorId = this.motorId;
      var remoteQuantity = this.remoteQuantity;
      if (controlType.toLowerCase() === "manual gear box") return true;
      if (Ember.isEmpty(needRemote)) return false;
      if (needRemote.toLowerCase() === "no") return Ember.isPresent(motorId);
      return Ember.isPresent(remoteChannels) && Ember.isPresent(remoteQuantity);
    }),
    sidetrackAngle: Ember.computed.alias('model.sideTrackAngle'),
    hasAnglePunchedValue: Ember.computed.notEmpty('model.sideTrackAnglePunched'),
    hasTracksPunchedValue: Ember.computed.notEmpty('model.sideTrackPunched'),
    sidetrackRecessedDetailsComplete: Ember.computed.notEmpty("model.sideTrackUChannel"),
    // hasSidetrackBracketsValue: Ember.computed.notEmpty("model.sideTrackBrackets"),
    // sidetrackRecessedDetailsComplete: Ember.computed.and("hasSidetrackBracketsValue","hasSidetrackUChannelValue"),
    sidetrackStandardDetailsComplete: Ember.computed('sidetrackAngle', 'hasAnglePunchedValue', 'hasTracksPunchedValue', function () {
      var sidetrackAngle = this.sidetrackAngle;
      var hasAnglePunchedValue = this.hasAnglePunchedValue;
      var hasTracksPunchedValue = this.hasTracksPunchedValue;

      if (Ember.isEmpty(sidetrackAngle) || Ember.isEmpty(hasTracksPunchedValue)) {
        return false;
      }

      if (sidetrackAngle == "1x2") {
        return hasAnglePunchedValue;
      }

      return true;
    }),
    sidetracksIsValid: Ember.computed("model.sideTrackType", "sidetrackStandardDetailsComplete", "sidetrackRecessedDetailsComplete", function () {
      return this.get("model.sideTrackType") === "standard" ? this.sidetrackStandardDetailsComplete : this.sidetrackRecessedDetailsComplete;
    })
  });

  _exports.default = _default;
});