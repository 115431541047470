define("product-management/utils/is-present-and-greater-than-zero", ["exports", "shamgar-components/utils/is-present-and-greater-than-zero"], function (_exports, _isPresentAndGreaterThanZero) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isPresentAndGreaterThanZero.default;
    }
  });
});