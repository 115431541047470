define("product-management/pods/components/shop/line-items/unit-modifications-notice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sPD2+L7e",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,8]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"blank-slate text-left mb-6\"],[12],[2,\"\\n  \"],[10,\"strong\"],[12],[2,\"There are modifications to this unit\"],[13],[2,\"\\n  \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"No Roll Tube\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,4],[[35,3],[35,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"No Hood/Housing\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"No Screen\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"No Bottom Bar\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"No Side Tracks\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"noRollTube\",\"if\",\"noHoodHousing\",\"notRecessed\",\"and\",\"noScreen\",\"noBottomBar\",\"noSideTracks\",\"hasExceptions\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/line-items/unit-modifications-notice/template.hbs"
    }
  });

  _exports.default = _default;
});