define("product-management/pods/admin/settings/option-lists/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YQmsxRX1",
    "block": "{\"symbols\":[\"lists\",\"group\",\"item\",\"PageHeader\"],\"statements\":[[8,\"settings/page-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,[32,4,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Option Lists\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,[32,4,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"admin.settings.option-lists.new\"]],[[\"default\"],[{\"statements\":[[2,\"Add a List\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[4]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,5],[[30,[36,4],[\"groupName\",[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"h3\"],[12],[1,[30,[36,0],[[32,2]],null]],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@model\"],[\"admin.settings.option-lists.show\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,3,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[3]}]]]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"humanize\",\"-track-array\",\"each\",\"model\",\"group-by\",\"-each-in\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/option-lists/index/template.hbs"
    }
  });

  _exports.default = _default;
});