define("product-management/pods/orders/show/line-items/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XOKnyz4e",
    "block": "{\"symbols\":[],\"statements\":[[8,\"painless-manufacturing/line-item/wizard/step3\",[],[[\"@lineItemId\",\"@productId\",\"@orderId\",\"@details\",\"@quantity\",\"@groupName\"],[[32,0,[\"model\",\"id\"]],[32,0,[\"model\",\"product\",\"id\"]],[32,0,[\"model\",\"order\",\"id\"]],[32,0,[\"model\",\"details\"]],[32,0,[\"model\",\"quantity\"]],[32,0,[\"groupName\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/line-items/edit/template.hbs"
    }
  });

  _exports.default = _default;
});