define("product-management/pods/admin/settings/product-weights/categories/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7i+66Tux",
    "block": "{\"symbols\":[\"pw\"],\"statements\":[[8,\"products/product-weight-categories/form\",[],[[\"@model\"],[[34,0]]],null],[2,\"\\n\\n\"],[10,\"h3\"],[12],[2,\"Assigned Rules\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,0,[\"productWeights\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"product.name\",[35,0,[\"productWeights\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"list-group-item\"],[12],[2,\"\\n        \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.settings.product-weights.weights.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,1,[\"product\",\"name\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[8,\"blank-slate\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    There are no rules assigned to this category\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"sort-by\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/product-weights/categories/show/template.hbs"
    }
  });

  _exports.default = _default;
});