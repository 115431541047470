define("product-management/pods/components/dealers/delegates/add-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+X50WqO7",
    "block": "{\"symbols\":[\"Row\",\"&attrs\"],\"statements\":[[11,\"div\"],[17,2],[12],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"block font-semibold text-lg mb-2\"],[12],[2,\"Search for a user\"],[13],[2,\"\\n\\t\"],[11,\"input\"],[24,\"placeholder\",\"Enter a name or an email to find a user\"],[24,0,\"form-control\"],[4,[38,7],[\"input\",[30,[36,6],[[32,0,[\"handleSearch\"]]],null]],null],[12],[13],[2,\"\\n\"],[6,[37,3],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"mt-4\"],[12],[2,\"\\n\\t\\t\"],[8,\"tailwind-ui/lists/narrow\",[],[[\"@records\"],[[34,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,[32,1,[\"listItem\"]],[],[[\"@title\",\"@subtitle\"],[[32,1,[\"record\",\"fullName\"]],[30,[36,5],[[32,1,[\"record\",\"email\"]],\" - \",[32,1,[\"record\",\"dealer\",\"name\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[11,\"button\"],[24,0,\"bg-green-light rounded text-white p-2\"],[4,[38,7],[\"click\",[30,[36,6],[[32,0,[\"handleAdd\"]],[32,1,[\"record\",\"id\"]]],null]],null],[12],[2,\"\\n\\t\\t\\tAdd\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1],[30,[36,0],[[32,0,[\"search\",\"isRunning\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"div\"],[14,0,\"mt-4 alert alert-warning\"],[12],[2,\"\\n\\t\\t\\t\\tNo users in the system match your search criteria.\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"searchTerm\",\"and\",\"if\",\"searchResults\",\"concat\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/delegates/add-form/template.hbs"
    }
  });

  _exports.default = _default;
});