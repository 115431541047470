define("product-management/pods/components/painless-manufacturing/order/returns/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eLq1FY7j",
    "block": "{\"symbols\":[\"@order\"],\"statements\":[[10,\"div\"],[14,0,\"row mt-6\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"lineItems\",\"length\"]],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"blank-slate\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[14,0,\"mb-4\"],[12],[2,\"\\n\\t\\t\\tSending us items for warranty, templates, is very simple. For each item you would like to send us, please enter some notes, then write the provided RGA number on a label and affix it to the item you are sending to us.\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"btn btn-primary btn-lg\"]],[[\"@route\"],[\"orders.show.line-items.new\"]],[[\"default\"],[{\"statements\":[[2,\"Add an item\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\\tor \\n\\t\\t\\t\\t\"],[8,\"painless-manufacturing/order/returns/toggle-button\",[],[[\"@order\"],[[32,1]]],null],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/order/returns/form/template.hbs"
    }
  });

  _exports.default = _default;
});