define("product-management/pods/shop/pick-station/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8wB9LPPU",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Pick Station\"],[[\"replace\"],[false]]]],[2,\"\\n\"],[8,\"sun-pro/shop/pick-station\",[],[[\"@productionLine\"],[[34,1]]],null]],\"hasEval\":false,\"upvars\":[\"page-title\",\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop/pick-station/template.hbs"
    }
  });

  _exports.default = _default;
});