define("product-management/pods/components/pm/dealer/info-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CUS1zixI",
    "block": "{\"symbols\":[\"@dealer\"],\"statements\":[[10,\"span\"],[14,0,\"text-xl font-semibold mb-1\"],[12],[2,\"\\n  Dealer\\n\"],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"block\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"isAdmin\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[6,[37,0],null,[[\"route\",\"model\"],[\"admin.dealer\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,2],[[32,1,[\"shippingAddress\"]]],null,[[\"default\"],[{\"statements\":[[10,\"address\"],[14,0,\"mb-2 mt-2\"],[12],[2,\"\\n  \"],[1,[32,1,[\"shippingAddress\"]]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[32,1,[\"shippingCity\"]]],[2,\", \"],[1,[32,1,[\"shippingState\"]]],[2,\" \"],[1,[32,1,[\"shippingZip\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,1,[\"officePhone\"]]],null,[[\"default\"],[{\"statements\":[[2,\"Office: \"],[1,[32,1,[\"officePhone\"]]],[10,\"br\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,1,[\"cellPhone\"]]],null,[[\"default\"],[{\"statements\":[[2,\"Cell: \"],[1,[32,1,[\"cellPhone\"]]],[10,\"br\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"session\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/dealer/info-card/template.hbs"
    }
  });

  _exports.default = _default;
});