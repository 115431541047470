define("product-management/pods/components/pricing/price-per-square-foot/option-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MAscdRat",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"clearfix\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pull-left\"],[12],[2,\"\\n    \"],[10,\"dl\"],[12],[2,\"\\n      \"],[10,\"dt\"],[12],[2,\"\\n      Screen:\\n      \"],[13],[2,\"\\n      \"],[10,\"dd\"],[12],[2,\"\\n      \"],[1,[35,0,[\"name\"]]],[2,\"  \\n      \"],[13],[2,\"\\n      \"],[10,\"dt\"],[12],[2,\"Min Sq Ft.\"],[13],[2,\"\\n      \"],[10,\"dd\"],[12],[1,[35,0,[\"minimum_square_feet\"]]],[13],[2,\"\\n      \"],[10,\"dt\"],[12],[2,\"Price Per Sq Ft.\"],[13],[2,\"\\n      \"],[10,\"dd\"],[12],[1,[35,0,[\"price_per_square_foot\"]]],[13],[2,\"\\n      \"],[10,\"dt\"],[12],[2,\"Oversized Price Per Sq Ft.\"],[13],[2,\"\\n      \"],[10,\"dd\"],[12],[1,[35,0,[\"oversized_price_per_square_foot\"]]],[13],[2,\"\\n      \"],[10,\"dt\"],[12],[2,\"Oversized Height\"],[13],[2,\"\\n      \"],[10,\"dd\"],[12],[1,[35,0,[\"oversized_height\"]]],[13],[2,\"\\n      \"],[10,\"dt\"],[12],[2,\"Modifier Value\"],[13],[2,\"\\n      \"],[10,\"dd\"],[12],[1,[30,[36,1],[[35,0,[\"modifier_value\"]],[35,0,[\"modifier_value\"]],\"Default\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,6,\"#\"],[4,[38,3],[[32,0],\"deleteOption\",[35,2],[35,0,[\"id\"]]],null],[12],[1,[30,[36,4],[\"trash-o\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"option\",\"if\",\"product\",\"action\",\"fa-icon\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pricing/price-per-square-foot/option-item/template.hbs"
    }
  });

  _exports.default = _default;
});