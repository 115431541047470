define("product-management/helpers/replace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replace = replace;
  _exports.default = void 0;

  function replace(params, hash) {
    return params[0].replace(params[1], params[2]);
  }

  var _default = Ember.Helper.helper(replace);

  _exports.default = _default;
});