define("product-management/pods/components/orders/printing/delivery-ticket/component", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    actions: {
      printDeliveryTicket: function printDeliveryTicket(order) {
        var host = _emberGetConfig.default.APP.host;
        location.href = "".concat(host, "/downloads?order_id=").concat(order.id);
      }
    }
  });

  _exports.default = _default;
});