define("product-management/pods/components/sun-pro/shop/stations/other-units/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "htJa2pJ4",
    "block": "{\"symbols\":[\"unit\",\"@currentStation\",\"&attrs\",\"@nextItem\"],\"statements\":[[11,\"div\"],[4,[38,6],[[32,0,[\"handleInsert\"]],[32,0]],null],[4,[38,7],[[32,0,[\"handleUpdate\"]],[32,0],[32,4]],null],[12],[2,\"\\n\"],[6,[37,8],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"text-left\"],[17,3],[12],[2,\"\\n      \"],[10,\"hr\"],[14,0,\"border-grey-dark text-center text-xl\"],[14,\"data-hr-content\",\"Other Units Remaining on This Order\"],[14,5,\"--bg:white; --p:0 10px; --trans-x:-50%; --trans-y:-50%;\"],[12],[13],[2,\"\\n\"],[6,[37,5],[[35,4,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"flex flex-wrap\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"position\",[35,0]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"sun-pro/shop/stations/unit-card\",[[24,0,\"w-1/2 p-1\"]],[[\"@currentStation\",\"@item\"],[[32,2],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        Loading...\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"otherUnits\",\"sort-by\",\"-track-array\",\"each\",\"load\",\"unless\",\"did-insert\",\"did-update\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/shop/stations/other-units/template.hbs"
    }
  });

  _exports.default = _default;
});