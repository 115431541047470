define("product-management/pods/components/shared/filter-bar-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "489csMQX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],null,[[\"options\"],[[35,0]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"myStickyOptions\",\"sticky-container\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shared/filter-bar-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});