define("product-management/pods/components/fulfillment/delivery-zones/map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tec9w6nR",
    "block": "{\"symbols\":[\"G\",\"marker\",\"@deliveryZone\"],\"statements\":[[11,\"div\"],[4,[38,1],[[32,0,[\"handleSetup\"]],[32,0]],null],[4,[38,2],[[32,0,[\"handleSetup\"]],[32,0],[32,3,[\"dealers\",\"length\"]]],null],[12],[2,\"\\n\\t\"],[8,\"g-map\",[],[[\"@lat\",\"@lng\",\"@zoom\"],[[32,3,[\"averageLatitude\"]],[32,3,[\"averageLongitude\"]],8]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"fulfillment/delivery-zones/map/dealer-marker\",[],[[\"@onToggleInfoWindow\",\"@selected\",\"@markerComponent\",\"@marker\"],[[30,[36,0],[[32,0,[\"handleInfoWindowToggle\"]],[32,2,[\"id\"]]],null],[32,0,[\"infoWindowId\"]],[32,1,[\"marker\"]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\",\"did-update\",\"markers\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/delivery-zones/map/template.hbs"
    }
  });

  _exports.default = _default;
});