define("product-management/pods/components/ui/forms/csv-uploader/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LdB9kPd1",
    "block": "{\"symbols\":[\"option\",\"@header\"],\"statements\":[[10,\"th\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-64\"],[12],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"power-select\",[],[[\"@options\",\"@onChange\"],[[34,5],[30,[36,1],[[32,0,[\"handleFieldAssignment\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleToggleEditing\"]]],null]],null],[12],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"text-grey-light text-sm\"],[12],[2,\"\\n          (do not import)\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"text-red-dark text-sm\"],[12],[2,\"\\n          (import as \"],[1,[34,0]],[2,\")\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fieldMapping\",\"fn\",\"on\",\"isMapped\",\"unless\",\"options\",\"isEditing\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/forms/csv-uploader/header/template.hbs"
    }
  });

  _exports.default = _default;
});