define("product-management/helpers/formatted-hour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formattedHour(hour) {
    var amPm = hour > 11 ? "pm" : "am";

    if (hour > 12) {
      hour -= 12;
    }

    if (hour == 0) hour = 12;
    return "".concat(hour).concat(amPm);
  });

  _exports.default = _default;
});