define("product-management/pods/shop/pickup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9NNRvFys",
    "block": "{\"symbols\":[\"PmShippingPickupSummaryList\",\"PmShopNavigationFooter\"],\"statements\":[[6,[37,3],[[30,[36,2],[\"pm/shipping/pickup/summary-list\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[\"pm/shop/navigation/footer\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\n\\n\"],[1,[30,[36,0],[\"Pickups\"],null]],[2,\"\\n\"],[10,\"h3\"],[12],[2,\"Pickup Orders\"],[13],[2,\"\\n\\n\"],[8,[32,1],[],[[\"@items\",\"@detailRoute\"],[[34,1],\"shop.pickup_details\"]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mt-8 text-right\"],[12],[2,\"\\n\\t\"],[8,\"fulfillment/clear-station\",[[24,0,\"btn btn-danger\"]],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"model\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop/pickup/template.hbs"
    }
  });

  _exports.default = _default;
});