define("product-management/pods/components/production-lines/configuration/available-stations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V2F4HKDQ",
    "block": "{\"symbols\":[\"station\"],\"statements\":[[10,\"span\"],[14,0,\"text-xl font-semibold block pb-2 mb-2 border-b border-grey-lighter\"],[12],[2,\"\\n\\tAvailable Stations\\n\"],[13],[2,\"\\n\"],[10,\"table\"],[14,0,\"table table-striped table-condensed\"],[12],[2,\"\\n\\t\"],[10,\"thead\"],[12],[2,\"\\n\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Station\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Daily Goal\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Daily Schedule\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Key\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[11,\"button\"],[24,0,\"btn btn-sm btn-default\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleAdd\"]],[32,1]],null]],null],[12],[10,\"i\"],[14,0,\"fa fa-plus-circle\"],[12],[13],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.settings.stations.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"td\"],[12],[1,[32,1,[\"settings\",\"dailyGoal\"]]],[13],[2,\"\\n\\t\\t\\t\"],[10,\"td\"],[12],[1,[32,1,[\"settings\",\"dailySchedule\"]]],[13],[2,\"\\n\\t\\t\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"stations/key\",[],[[\"@station\"],[[32,1]]],null],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"stations\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/production-lines/configuration/available-stations/template.hbs"
    }
  });

  _exports.default = _default;
});