define("product-management/pods/dealer-admin/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "shamgar-auto-upgrade/mixins/auto-upgrade-route"], function (_exports, _authenticatedRouteMixin, _autoUpgradeRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_autoUpgradeRoute.default, _authenticatedRouteMixin.default, {
    currentDealer: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      var isDealer = this.get("session.isDealer");
      var newOrder = this.store.createRecord("order");
      var dealer;
      if (isDealer) dealer = this.currentDealer.currentDealer();
      return Ember.RSVP.hash({
        newOrder: newOrder,
        dealer: dealer
      });
    }
  });

  _exports.default = _default;
});