define("product-management/pods/admin/dealer/accounting/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fY6alQiY",
    "block": "{\"symbols\":[],\"statements\":[[8,\"dealers/accounting/display\",[],[[\"@dealer\"],[[34,0]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/dealer/accounting/index/template.hbs"
    }
  });

  _exports.default = _default;
});