define("product-management/pods/components/kpis/settings/recalculate-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HgIzvqCk",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[8,\"stardust/ui/async-button\",[[17,1],[24,0,\"btn btn-danger\"]],[[\"@onClick\",\"@isPending\",\"@pendingText\",\"@defaultText\",\"@textColor\"],[[30,[36,0],[[32,0,[\"handleClick\"]]],null],[32,0,[\"calculate\",\"isRunning\"]],\"Calculating\",\"Calculate\",\"white\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/kpis/settings/recalculate-button/template.hbs"
    }
  });

  _exports.default = _default;
});