define("product-management/pods/admin-mobile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V3i2qSKZ",
    "block": "{\"symbols\":[\"navbar\"],\"statements\":[[6,[37,7],null,[[\"class\",\"fluid\",\"collapsed\",\"onCollapse\",\"onExpand\"],[\"navbar-fixed-top\",true,[35,5],[30,[36,0],[[32,0],[30,[36,6],[[35,5]],null],true],null],[30,[36,0],[[32,0],[30,[36,6],[[35,5]],null],false],null]]],[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"container mobile-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"navbar-header\"],[12],[2,\"\\n    \"],[1,[32,1,[\"toggle\"]]],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"content\"]],\"expected `navbar.content` to be a contextual component but found a string. Did you mean `(component navbar.content)`? ('product-management/pods/admin-mobile/template.hbs' @ L7:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"navbar\",\"on-collapse\",\"on-logout\"],[[32,1],[30,[36,0],[[32,0],\"collapseNavbar\"],null],[30,[36,0],[[32,0],\"logout\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[10,\"div\"],[14,0,\"container margin-top-30\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n      \"],[1,[30,[36,4],[[30,[36,8],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"navigation/mobile-nav-links\",\"navigation/nav-logo\",\"-assert-implicit-component-helper-argument\",\"component\",\"collapsed\",\"mut\",\"bs-navbar\",\"-outlet\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin-mobile/template.hbs"
    }
  });

  _exports.default = _default;
});