define("product-management/pods/components/pm/ui/download-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p1afkT6h",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"  \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"btn btn-sm btn-success\"],[15,\"onclick\",[30,[36,1],[[32,0],[35,0]],null]],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"download\"]],null],[2,\"\\n      \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"download\",\"action\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/download-button/template.hbs"
    }
  });

  _exports.default = _default;
});