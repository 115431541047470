define("product-management/pods/components/products/order-config-sections/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1161gu0N",
    "block": "{\"symbols\":[\"sectionItem\"],\"statements\":[[10,\"h3\"],[12],[2,\"Select a section:\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"order-config-sections\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[6,[37,1],null,[[\"query\"],[[30,[36,0],null,[[\"section\"],[[32,1,[\"value\"]]]]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"label\"]]]],\"parameters\":[]}]]],[2,\" |\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[6,[37,1],null,[[\"query\"],[[30,[36,0],null,[[\"section\"],[\"all\"]]]]],[[\"default\"],[{\"statements\":[[2,\"All\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-hash\",\"link-to\",\"sections\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/products/order-config-sections/template.hbs"
    }
  });

  _exports.default = _default;
});