define("product-management/pods/components/pm/order/line-item/quantity-and-price-headers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fPcy/pl5",
    "block": "{\"symbols\":[\"@lineItem\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,1,[\"quotedPrice\"]],[30,[36,0],[[32,1,[\"quotedPrice\"]],0],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"th\"],[12],[2,\"Quantity\"],[13],[2,\"\\n  \"],[10,\"th\"],[12],[2,\"Price\"],[13],[2,\"\\n  \"],[10,\"th\"],[12],[2,\"Total\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"gt\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/order/line-item/quantity-and-price-headers/template.hbs"
    }
  });

  _exports.default = _default;
});