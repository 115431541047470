define("product-management/pods/components/ui/error-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OPZ0pYTa",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"error-message\"],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"error-message\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/error-message/template.hbs"
    }
  });

  _exports.default = _default;
});