define("product-management/pods/components/product-view/complete-screens/nav-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XibrsBFS",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[6,[37,1],null,[[\"route\"],[[30,[36,0],[\"product.settings\"],[[\"namespace\"],[\"admin\"]]]]],[[\"default\"],[{\"statements\":[[2,\"Settings\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[6,[37,1],null,[[\"route\"],[[30,[36,0],[\"product.pricing\"],[[\"namespace\"],[\"admin\"]]]]],[[\"default\"],[{\"statements\":[[2,\"Pricing\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"get-shamgar-route\",\"link-to\",\"active-link\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/product-view/complete-screens/nav-tabs/template.hbs"
    }
  });

  _exports.default = _default;
});