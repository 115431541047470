define("product-management/pods/orders/show/override-confirmation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "679I2MO4",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pm/order/override-confirmation-form\",[],[[\"@order\",\"@onCancel\",\"@onConfirm\",\"@workQueues\"],[[34,0],[30,[36,1],[\"closeModal\"],null],[30,[36,1],[\"confirmOverride\"],null],[34,2]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"order\",\"route-action\",\"workQueues\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/override-confirmation/template.hbs"
    }
  });

  _exports.default = _default;
});