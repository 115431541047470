define("product-management/pods/components/stardust/data-table/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L5nRcy2j",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"tfoot\"],[12],[2,\"\\n\\t\"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/data-table/footer/template.hbs"
    }
  });

  _exports.default = _default;
});