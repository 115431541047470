define("product-management/pods/components/work-queues/move-queue-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isChecked: Ember.computed("ordersToMove", "orderId", "ordersToMove.length", function () {
      var ordersToMove = this.ordersToMove;
      var orderId = this.orderId;
      return ordersToMove.find(function (item) {
        return item === orderId;
      }) != undefined;
    }),
    tagName: "",
    actions: {
      toggleOrder: function toggleOrder(orderId) {
        return this.attrs.onToggleOrder(orderId);
      }
    }
  });

  _exports.default = _default;
});