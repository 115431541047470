define("product-management/pods/components/shop/deliveries/details-workflow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iPMhcq93",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,0,[\"workflowDetails\",\"delivery_fields\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"mb-2\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mb-1\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"type\"]],\"fraction\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[30,[36,1],[[35,0],[32,1,[\"field\"]]],null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[35,0],[32,1,[\"field\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"queueItem\",\"get\",\"number-as-fraction\",\"eq\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/deliveries/details-workflow/template.hbs"
    }
  });

  _exports.default = _default;
});