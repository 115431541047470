define("product-management/pods/orders/show/index/index/split/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VV8G81VR",
    "block": "{\"symbols\":[],\"statements\":[[8,\"painless-manufacturing/order/split\",[],[[\"@order\"],[[34,0]]],null],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/index/index/split/template.hbs"
    }
  });

  _exports.default = _default;
});