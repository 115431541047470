define("product-management/helpers/stringify", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringify = stringify;
  _exports.default = void 0;

  function stringify(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        obj = _ref2[0];

    var output = JSON.stringify(obj, null, 2);
    return output;
  }

  var _default = Ember.Helper.helper(stringify);

  _exports.default = _default;
});