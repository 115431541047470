define("product-management/pods/components/painless-manufacturing/production/production-line/filter/filter-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8QDBEcjz",
    "block": "{\"symbols\":[\"@filter\",\"@onChange\"],\"statements\":[[8,[32,0,[\"componentName\"]],[],[[\"@filter\",\"@onChange\",\"@value\"],[[32,1],[32,2],[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/production-line/filter/filter-loader/template.hbs"
    }
  });

  _exports.default = _default;
});