define("product-management/pods/components/shop/line-items/zipper-station/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    zipperTopLength: Ember.computed.alias("lineItem.zipperTopLength"),
    hasTopLengthGreaterThanZero: Ember.computed.gt("zipperTopLength", 0),
    hasTopLengthValue: Ember.computed.notEmpty("zipperTopLength"),
    hasSignature: Ember.computed.notEmpty('lineItem.status.signature'),
    hasTopLength: Ember.computed.and("hasTopLengthValue", "hasTopLengthGreaterThanZero"),
    actions: {
      signedWork: function signedWork(data) {
        this.attrs.signedWork(data[1]);
      },
      complete: function complete(lineItem) {
        this.attrs.onComplete(lineItem);
      }
    }
  });

  _exports.default = _default;
});