define("product-management/pods/components/barcode-scanner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y6P6nPZ9",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"w-full h-screen\"],[4,[38,0],[[32,0,[\"handleInsert\"]],[32,0]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"w-full p-4 bg-blue-darker text-white font-semibold\"],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"handleClick\"]]],null]],null],[12],[2,\"\\n    Scan\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"isScanning\",\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/barcode-scanner/template.hbs"
    }
  });

  _exports.default = _default;
});