define("product-management/pods/components/painless-manufacturing/line-item/wizard/featured-product/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NchNvxKI",
    "block": "{\"symbols\":[\"@product\"],\"statements\":[[10,\"div\"],[14,0,\"border-t-2 border-grey-lighter mb-8 p-2 lg:p-4\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flex flex-row shadow rounded-lg p-2\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"w-full flex flex-col flex-no-shrink lg:mr-12\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"h-16 w-full mb-1\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"image\",\"url\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t \"],[8,\"remote-image\",[[24,0,\"mx-auto block h-16 w-auto\"]],[[\"@imageUrl\"],[[32,1,[\"image\",\"url\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"ui/no-image\",[[24,0,\"fill-current block mx-auto text-teal h-16 w-auto\"]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"text-lg flex h-12 items-center mb-4 text-center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"h2\"],[14,0,\"font-semibold text-lg text-black w-full\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"button\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"handleSelect\"]]],null]],[14,0,\"text-center block w-full bg-grey-lighter text-grey-darkest py-2 rounded \\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t text-base border border-grey hover bg-grey-light\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\tSelect\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/line-item/wizard/featured-product/template.hbs"
    }
  });

  _exports.default = _default;
});