define("product-management/helpers/simple-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.simpleFormat = simpleFormat;
  _exports.default = void 0;

  function simpleFormat(value) {
    value = value[0];
    var result = Ember.isPresent(value) ? value : "";
    if (typeof result === "string") result = result.replace(/\r/g, '<br>');
    return Ember.String.htmlSafe(result);
  }

  var _default = Ember.Helper.helper(simpleFormat);

  _exports.default = _default;
});