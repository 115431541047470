define("product-management/pods/reset-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jplgi0T8",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"security-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[14,1,\"login_container\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-4 col-md-offset-4\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"well white_well\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"sign_in_title text-center\"],[12],[2,\"\\n            \"],[10,\"h3\"],[14,0,\"forgot_password_title\"],[12],[2,\"Reset your password\"],[13],[2,\"\\n          \"],[13],[2,\"\\n\\n          \"],[1,[34,2]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[35,4],[35,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],null,[[\"buttonText\",\"pendingButtonText\",\"onSubmit\",\"changeset\"],[\"Reset Password\",\"Resetting...\",[30,[36,0],[[32,0],\"resetPassword\"],null],[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sign-in-bottom-link\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-6 col-md-offset-3\"],[12],[2,\"\\n          \"],[10,\"p\"],[14,0,\"text-center\"],[12],[2,\"\\n          \"],[10,\"small\"],[12],[2,\"\\n            \"],[10,\"strong\"],[12],[2,\"Never mind:\"],[13],[2,\" \"],[6,[37,7],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Take me back to the login page\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"password-form\",\"password-requirements\",\"passwordValidations\",\"model\",\"changeset\",\"with\",\"link-to\"]}",
    "meta": {
      "moduleName": "product-management/pods/reset-password/template.hbs"
    }
  });

  _exports.default = _default;
});