define("product-management/pods/components/wip-table/item-status/component", ["exports", "product-management/mixins/shared/status-icon-lookup", "ember-ui-helpers/mixins/notifyable"], function (_exports, _statusIconLookup, _notifyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var loadableComponentStatuses = ["assembly_loaded", "bottom_bar_loaded", "screen_loaded", "side_track_loaded", "part_loaded"];

  var _default = Ember.Component.extend(_notifyable.default, _statusIconLookup.default, {
    classNames: ["wip-table-order-status"],
    classNameBindings: ["statusValue"],
    actions: {
      forceComplete: function forceComplete(item) {
        var _this = this;

        this.confirm("Are you sure? You cannot reverse this!").then(function (result) {
          if (result) _this.attrs.onForceComplete(item);
        });
      },
      release: function release(item, status) {
        this.attrs.onRelease(item, status);
      }
    },
    itemStatus: Ember.computed.alias("item.status"),
    isDeliveryStatusComplete: Ember.computed("itemStatus.delivery_status", function () {
      var deliveryStatus = this.get("itemStatus.delivery_status");
      if (deliveryStatus === undefined) return null;
      return deliveryStatus === "complete";
    }),
    isNotShipped: Ember.computed("order-status", function () {
      return !["shipped", "invoiced"].includes(this["order-status"]);
    }),
    loadedStatusKey: Ember.computed("status", function () {
      var status = this.status;
      if (status === "final_assembly_status") status = "assembly_status";
      return status.replace(/_status/, "_loaded");
    }),
    isLoadableComponent: Ember.computed("loadedStatusKey", function () {
      return loadableComponentStatuses.includes(this.loadedStatusKey);
    }),
    statusOverride: Ember.computed("shippingMethod", "itemStatus", "status", "isNotShipped", "loadedStatusKey", "isLoadableComponent", function () {
      var shippingMethod = this.shippingMethod;
      var itemStatus = this.itemStatus;
      var status = this.status;
      var isNotShipped = this.isNotShipped;
      var loadedStatusKey = this.loadedStatusKey;
      var isLoadableComponent = this.isLoadableComponent;
      if (shippingMethod != "Delivery") return null;

      if (isNotShipped && isLoadableComponent) {
        return itemStatus[loadedStatusKey] === true ? "on_truck" : null;
      }
    }),
    displayText: Ember.computed("statusValue", function () {
      var statusValue = this.statusValue;
      if (statusValue === undefined) return "";
      statusValue = statusValue.replace("_", " ");
      return statusValue.split(" ").map(function (word) {
        return word.charAt(0).capitalize();
      }).join("");
    }),
    statusValue: Ember.computed("item", "status", "itemStatus", "statusOverride", "timestamp", function () {
      var item = this.item;
      var status = this.status;
      var itemStatus = this.itemStatus;
      var statusOverride = this.statusOverride;
      var timestamp = this.timestamp;
      var statusValue = itemStatus[status];

      if (Ember.isPresent(statusOverride)) {
        statusValue = statusOverride;
      }

      return statusValue;
    }),
    icon: Ember.computed("statusValue", function () {
      return this.iconForStatus(this.statusValue);
    }),
    willInsertElement: function willInsertElement() {
      var _Ember;

      var _this$isLoadableCompo = this.isLoadableComponent,
          isLoadableComponent = _this$isLoadableCompo.isLoadableComponent,
          status = _this$isLoadableCompo.status,
          loadedStatusKey = _this$isLoadableCompo.loadedStatusKey;
      var fieldsToWatch = ["item.status.".concat(status)];
      if (isLoadableComponent) fieldsToWatch.pushObject(loadedStatusKey);
      Ember.defineProperty(this, "timestamp", (_Ember = Ember).computed.apply(_Ember, fieldsToWatch.concat([function () {
        return new Date().getTime();
      }])));
    }
  });

  _exports.default = _default;
});