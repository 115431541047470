define("product-management/pods/components/kpi-charts/ordered-per-week/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T8blGZv/",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"border px-10 py-6 w-full my-3\"],[4,[38,0],[[32,0,[\"handleInsert\"]],[32,0]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mg-chart\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/kpi-charts/ordered-per-week/template.hbs"
    }
  });

  _exports.default = _default;
});