define("product-management/pods/components/painless-manufacturing/line-item/card/mobile-price-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1zZiakHO",
    "block": "{\"symbols\":[\"@lineItem\",\"@order\",\"@subtotal\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"block font-semibold text-lg mb-2\"],[12],[2,\"\\n\\t\\t\"],[1,[32,1,[\"product\",\"name\"]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"span\"],[14,0,\"text-sm block mb-2 text-grey-dark\"],[12],[2,\"\\n\\t\\tItem #:\"],[1,[32,1,[\"position\"]]],[2,\" of \"],[1,[32,2,[\"lineItems\",\"length\"]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[10,\"span\"],[14,0,\"text-sm block text-grey-dark mb-2\"],[12],[2,\"\\n\\t\\tPrice: \"],[1,[30,[36,2],[[32,1,[\"price\"]]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"text-sm text-grey-dark block mb-2\"],[12],[2,\"\\n\\t\\tQuantity: \"],[1,[32,1,[\"quantity\"]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"font-semibold block mb-2\"],[12],[2,\"\\n\\t\\tSubtotal: \"],[1,[30,[36,2],[[32,3]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"showPosition\",\"if\",\"format-money\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/line-item/card/mobile-price-summary/template.hbs"
    }
  });

  _exports.default = _default;
});