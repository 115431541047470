define("product-management/pods/components/ui/forms/uploader/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T2c9g2rZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"relative\"],[12],[2,\"\\n  \"],[11,\"input\"],[24,0,\"opacity-0 block top-0 left-0 absolute w-full h-full cursor-pointer\"],[24,4,\"file\"],[4,[38,1],[\"change\",[30,[36,0],[[32,0,[\"handleUploadFileChange\"]]],null]],null],[12],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"action-button\"],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@spin\"],[[30,[36,3],[[35,2,[\"isRunning\"]],\"spinner\",\"paperclip\"],null],[34,2,[\"isRunning\"]]]],null],[2,\"\\n    \"],[1,[30,[36,3],[[35,2,[\"isRunning\"]],\"Uploading\",[35,4]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"uploadFile\",\"if\",\"buttonText\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/forms/uploader/body/template.hbs"
    }
  });

  _exports.default = _default;
});