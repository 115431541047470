define("product-management/pods/admin/settings/matrices/show/route", ["exports", "product-management/gql/queries/data/matrices/matrix.graphql", "ember-apollo-client"], function (_exports, _matrix, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    apollo: (0, _emberApolloClient.queryManager)(),
    model: function model(params) {
      var variables = {
        id: params.id
      };
      return this.apollo.watchQuery({
        query: _matrix.default,
        variables: variables,
        fetchPolicy: "network-only"
      }, "dataMatricesMatrix");
    }
  });

  _exports.default = _default;
});