define("product-management/pods/components/pricing/basic-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dc3N7sBx",
    "block": "{\"symbols\":[\"blurred\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"Description\"],[13],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"value\",\"class\"],[[35,0,[\"description\"]],\"form-control\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,6],null,[[\"label-text\",\"propertyName\",\"changeset\"],[\"Select a Pricing Method\",\"pricingMethod\",[35,0]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"content\",\"on-blur\",\"select-item\",\"optionValuePath\",\"optionLabelPath\",\"value\",\"placeholder\"],[[35,3],[32,1],[32,1],\"content.value\",\"content.name\",[35,0,[\"pricingMethod\"]],\"Select a pricing method\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,7],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],[[35,1]],[[\"product\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"changeset\",\"pricingMethodComponent\",\"component\",\"pricingMethods\",\"ember-selectize\",\"textarea\",\"validated-input\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pricing/basic-settings/template.hbs"
    }
  });

  _exports.default = _default;
});