define("product-management/pods/components/shop/previously-checked-out/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "voMzgq4U",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-block alert-warning strong\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"exclamation-circle\"],null]],[2,\" Tenga en cuenta!\"],[13],[2,\"\\n    Las unidades ya han sido iniciadas @ \"],[1,[30,[36,2],[[35,1],\"MMMM Do YYYY, h:mm:ss a\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"checkedOutAt\",\"moment-format\",\"previouslyCheckedOut\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/previously-checked-out/template.hbs"
    }
  });

  _exports.default = _default;
});