define("product-management/pods/dashboard/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      //need some code here to redirect the user to the proper route
      //refer back to the MG Front end project for how it was dealt with there
      //refer to the KLS project for dealing with role at the time of authentication
      //
      this.transitionTo(Ember.get(this, "session.dashboardRedirectRoute"));
    }
  });

  _exports.default = _default;
});