define("product-management/pods/components/pm/attachments/uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YcEnzoSD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"relative\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,0,\"opacity-0 block top-0 left-0 absolute w-full h-full cursor-pointer\"],[15,\"onchange\",[30,[36,1],[[32,0],[35,0]],null]],[14,4,\"file\"],[12],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"action-button\"],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@spin\"],[[30,[36,3],[[35,2,[\"isRunning\"]],\"spinner\",\"paperclip\"],null],[34,2,[\"isRunning\"]]]],null],[2,\"\\n    \"],[1,[30,[36,3],[[35,2,[\"isRunning\"]],\"Uploading\",[35,4]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"handleImageUpload\",\"action\",\"uploadFile\",\"if\",\"buttonText\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/attachments/uploader/template.hbs"
    }
  });

  _exports.default = _default;
});