define("product-management/pods/components/pm/ui/initial-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0r29g0Sw",
    "block": "{\"symbols\":[\"@content\",\"@visible\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"pm-ui-initial-icon\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/initial-icon/template.hbs"
    }
  });

  _exports.default = _default;
});