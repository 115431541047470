define("product-management/pods/dealer-admin/order/conversion-kit/index/controller", ["exports", "marygrove-components/mixins/conversion-kit-wizard-controller"], function (_exports, _conversionKitWizardController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_conversionKitWizardController.default, {
    experience: "conversion-kit"
  });

  _exports.default = _default;
});