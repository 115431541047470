define("product-management/pods/dealer-admin/order/awning/index/controller", ["exports", "marygrove-components/mixins/awning-wizard-controller"], function (_exports, _awningWizardController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_awningWizardController.default, {
    experience: "awning"
  });

  _exports.default = _default;
});