define("product-management/pods/dealer-admin/deliveries/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ejARnbwy",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Deliveries\"],null]],[2,\"\\n\"],[1,[30,[36,1],null,[[\"title\"],[\"Deliveries\"]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\",\"page-header\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/dealer-admin/deliveries/template.hbs"
    }
  });

  _exports.default = _default;
});