define("product-management/pods/admin/transportation/common-carrier/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wAuwt9f2",
    "block": "{\"symbols\":[\"list\",\"dealer\",\"dealerItem\",\"order\"],\"statements\":[[2,\"\\n\"],[6,[37,3],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[8,\"pm/shipping/ready-to-ship/list\",[],[[\"@records\"],[[34,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1,[\"dealers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"dealerItem\"]],[],[[\"@dealer\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3,[\"orders\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,3,[\"orderItem\"]],[],[[\"@selectedAddress\",\"@order\",\"@onCreateInvoice\"],[[32,3,[\"selectedAddress\"]],[32,4],[30,[36,0],[\"createInvoice\",[32,4]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[4]}]]],[2,\"\\n\"],[6,[37,3],[[32,3,[\"dialogOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,3,[\"shipmentDialog\"]],[],[[\"@onShip\",\"@dealer\"],[[30,[36,0],[\"shipOrders\"],null],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[3]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[14,0,\"blank-slate\"],[12],[2,\"\\n  There aren't any orders that are ready to ship.\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"-track-array\",\"each\",\"if\",\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/transportation/common-carrier/template.hbs"
    }
  });

  _exports.default = _default;
});