define("product-management/components/ordering/wizards/awning/mounting-details", ["exports", "marygrove-components/components/ordering/wizards/awning/mounting-details"], function (_exports, _mountingDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mountingDetails.default;
    }
  });
});