define("product-management/pods/admin/settings/discounts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xQK5SYhB",
    "block": "{\"symbols\":[\"qbCustomer\",\"PageHeader\"],\"statements\":[[8,\"settings/page-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,[32,2,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Discounts\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[10,\"table\"],[14,0,\"w-1/2 text-lg px-10 striped\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"th\"],[14,0,\"uppercase px-3 pb-4\"],[12],[2,\"Customer\"],[13],[2,\"\\n    \"],[10,\"th\"],[14,0,\"uppercase text-center  pb-4\"],[12],[2,\"Discount (%)\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[14,0,\"px-3 py-1\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n        \"],[10,\"td\"],[14,0,\"px-3 py-1 text-center\"],[12],[1,[30,[36,0],[[32,1,[\"discount\"]]],[[\"precision\"],[2]]]],[13],[2,\"\\n        \"],[10,\"td\"],[14,0,\"py-2\"],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"text-base bg-red rounded text-grey-lightest font-semibold cursor-pointer hover:bg-red-dark px-3 py-1\"],[4,[38,1],[[32,0],\"edit\",[32,1]],null],[12],[2,\"\\n            Modify\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"format-number\",\"action\",\"model\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/discounts/template.hbs"
    }
  });

  _exports.default = _default;
});