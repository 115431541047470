define("product-management/pods/components/shop/current-work-queue/component", ["exports", "product-management/mixins/shop/current-picker-component"], function (_exports, _currentPickerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_currentPickerComponent.default, {});

  _exports.default = _default;
});