define("product-management/pods/components/purchasing/purchase-order/items/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lNrlPCOF",
    "block": "{\"symbols\":[\"item\",\"@purchaseOrder\"],\"statements\":[[10,\"table\"],[14,0,\"table table-bordered table-condensed table-striped\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Item\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Description\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Quantity\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Cost\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Total\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"purchaseOrderItems\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.accounting.purchasing.inventory-items.show.purchase-orders\",[32,1,[\"inventoryItem\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,1,[\"inventoryItem\",\"name\"]]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[1,[32,1,[\"inventoryItem\",\"description\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[1,[32,1,[\"quantity\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[1,[32,1,[\"cost\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[1,[32,1,[\"total\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/purchase-order/items/template.hbs"
    }
  });

  _exports.default = _default;
});