define("product-management/components/fractional-input", ["exports", "ember-fractional-input/components/fractional-input"], function (_exports, _fractionalInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fractionalInput.default;
    }
  });
});