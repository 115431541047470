define("product-management/pods/admin/dealer/orders/controller", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ALL_STATUS = "all";
  var DATE_FILTER_MAP = [{
    prefix: "shipped",
    field: "auditTrail.shipped_at"
  }, {
    prefix: "order",
    field: "orderedAt"
  }, {
    prefix: "completed",
    field: "auditTrail.complete_at"
  }, {
    prefix: "invoiced",
    field: "auditTrail.invoiced_at"
  }];

  var _default = Ember.Controller.extend({
    queryParams: ["status", "page", "searchTerm"],
    status: "all",
    page: 1,
    searchTerm: "",
    dealer: Ember.computed.alias("model"),
    sortCols: ["orderedAt:desc", "dealerReference"],
    sortedOrders: Ember.computed.sort('model', "sortCols"),
    pageCount: Ember.computed.alias("model.meta.page-count"),
    orders: Ember.computed("status", "sortedOrders", "orderDateStart", "orderDateEnd", "completedDateStart", "completedDateEnd", "shippedDateStart", "shippedDateEnd", "invoicedDateStart", "invoicedDateEnd", "noDateFilters", function () {
      var _this = this;

      var status = this.status;
      var sortedOrders = this.sortedOrders;
      var orderDateStart = this.orderDateStart;
      var orderDateEnd = this.orderDateEnd;
      var completedDateStart = this.completedDateStart;
      var completedDateEnd = this.completedDateEnd;
      var shippedDateStart = this.shippedDateStart;
      var shippedDateEnd = this.shippedDateEnd;
      var invoicedDateStart = this.invoicedDateStart;
      var invoicedDateEnd = this.invoicedDateEnd;
      var noDateFilters = this.noDateFilters;
      if (status === ALL_STATUS && noDateFilters) return sortedOrders;
      var result = sortedOrders; // if (isPresent(status) && status != ALL_STATUS)
      //   result = sortedOrders.filterBy("status", status);

      DATE_FILTER_MAP.forEach(function (item) {
        result = _this.filterRecordsByDates({
          records: result,
          start: _this["".concat(item.prefix, "DateStart")],
          end: _this["".concat(item.prefix, "DateEnd")],
          field: item.field
        });
      });
      return result;
    }),
    orderDateEndEmpty: Ember.computed.empty("orderDateEnd"),
    orderDateStartEmpty: Ember.computed.empty("orderDateStart"),
    completedDateStartEmpty: Ember.computed.empty("completedDateStart"),
    completedDateEndEmpty: Ember.computed.empty("completedDateEnd"),
    shippedDateStartEmpty: Ember.computed.empty("shippedDateStart"),
    shippedDateEndEmpty: Ember.computed.empty("shippedDateEnd"),
    invoicedDateStartEmpty: Ember.computed.empty("invoicedDateStart"),
    invoicedDateEndEmpty: Ember.computed.empty("invoicedDateEnd"),
    noDateFilters: Ember.computed.and("orderDateEndEmpty", "orderDateStartEmpty", "completedDateEndEmpty", "completedDateStartEmpty", "shippedDateStartEmpty", "shippedDateEndEmpty", "invoicedDateStartEmpty", "invoicedDateEndEmpty"),
    filterRecordsByDates: function filterRecordsByDates(hash) {
      var records = hash.records,
          start = hash.start,
          end = hash.end,
          field = hash.field;
      if (Ember.isEmpty(start) && Ember.isEmpty(end)) return records;

      var _this$completeFilterD = this.completeFilterDates({
        records: records,
        field: field,
        start: start,
        end: end
      });

      var _this$completeFilterD2 = (0, _slicedToArray2.default)(_this$completeFilterD, 2);

      start = _this$completeFilterD2[0];
      end = _this$completeFilterD2[1];
      return records.filter(function (item) {
        var rawField = item.get(field);
        if (Ember.isEmpty(rawField) || rawField === undefined) return false;
        var fieldValue = (0, _moment.default)(rawField).toDate();
        return fieldValue >= start && end >= fieldValue;
      });
    },
    completeFilterDates: function completeFilterDates(hash) {
      var records = hash.records,
          start = hash.start,
          end = hash.end,
          field = hash.field;
      if (Ember.isEmpty(end)) end = new Date();
      if (Ember.isEmpty(start)) start = this.minimumDateInRecordset({
        records: records,
        field: field
      });
      end = (0, _moment.default)(end).endOf("day").toDate();
      return [start, end];
    },
    minimumDateInRecordset: function minimumDateInRecordset(hash) {
      hash.records.min(hash.field);
    },
    actions: {
      handleSearchReset: function handleSearchReset() {
        this.setProperties({
          searchTerm: null,
          page: 1,
          status: "all"
        });
      },
      handleSearchSubmit: function handleSearchSubmit(e) {
        e.preventDefault();
        var searchTerm = new FormData(e.target).get("searchTerm");
        this.setProperties({
          searchTerm: searchTerm,
          page: 1,
          status: "all"
        });
      },
      changePage: function changePage(page) {
        this.set("page", page);
      },
      changeStatus: function changeStatus(newStatus) {
        this.setProperties({
          page: 1,
          status: newStatus
        });
      }
    },
    filters: Ember.computed(function () {
      return {
        all: "All",
        ordered: "New Orders",
        in_process: "In Production",
        on_hold: "On Hold",
        complete: "Complete",
        on_truck: "On Truck",
        shipped: "Shipped",
        invoiced: "Invoiced",
        archived: "Archived",
        cancelled: "Cancelled"
      };
    })
  });

  _exports.default = _default;
});