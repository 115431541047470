define("product-management/pods/components/stardust/report/filters/dates/filter-options", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFilterOptions = dateFilterOptions;
  var monthToDate = "Month to Date";
  var quarterToDate = "Quarter to Date";
  var yearToDate = "Year to Date";
  var lastYearMonthToDate = "Last Year - Month to Date";
  var lastYearQuarterToDate = "Last Year - Quarter to Date";
  var lastYearYearToDate = "Last Year - Year to Date";
  var thisWeek = "This Week";
  var lastWeek = "Last Week";
  var lastMonth = "Last Month";
  var lastQuarter = "Last Quarter";
  var lastYear = "Last Year";

  function dateFilterOptions() {
    var includeCustom = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    var output = [thisWeek, monthToDate, quarterToDate, yearToDate, lastYearMonthToDate, lastYearQuarterToDate, lastYearYearToDate, lastWeek, lastMonth, lastQuarter, lastYear].map(function (period) {
      return {
        label: period,
        value: Ember.String.dasherize(period)
      };
    });
    if (includeCustom) output.push({
      label: "Custom",
      value: "Custom"
    });
    return output;
  }
});