define("product-management/pods/components/stardust/data-table/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6ojViXou",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,5],null,[[\"header\",\"body\",\"footer\"],[[30,[36,1],[\"stardust/data-table/header\"],[[\"sortBy\",\"sortDirection\",\"sortedField\"],[[35,4],[35,3],[35,2]]]],[30,[36,1],[\"stardust/data-table/body\"],[[\"records\"],[[35,0]]]],[30,[36,1],[\"stardust/data-table/footer\"],[[\"records\"],[[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"records\",\"component\",\"sortedField\",\"sortDirection\",\"sortBy\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/data-table/table/template.hbs"
    }
  });

  _exports.default = _default;
});