define("product-management/pods/shop/deliveries/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gCBtRNhQ",
    "block": "{\"symbols\":[\"delivery\"],\"statements\":[[10,\"div\"],[14,0,\"w-1/2 mx-auto\"],[12],[2,\"\\n\\t\"],[10,\"ul\"],[14,0,\"list-group-item\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[30,[36,2],[\"startOn\",[35,1]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"text-2xl font-semibold hover:no-underline\"]],[[\"@route\",\"@model\"],[\"shop.deliveries.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"flex align-items justify-between\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[32,1,[\"deliveryZone\",\"name\"]]],[2,\" - \"],[1,[32,1,[\"deliveryZone\",\"description\"]]],[2,\"\\n\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"bg-grey-dark text-grey-lighter p-2 rounded font-semibold\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[[32,1,[\"startOnAsString\"]],\"MM/DD/YYYY\"],null]],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"model\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop/deliveries/index/template.hbs"
    }
  });

  _exports.default = _default;
});