define("product-management/pods/admin/settings/product-types/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hIGb0BD6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"Add a new product type\"],[13],[2,\"\\n\\n\"],[8,\"pm/products/product-types/form\",[],[[\"@onSave\",\"@cancelRoute\"],[[30,[36,0],[\"save\"],null],\"admin.settings.product_types\"]],null],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/product-types/new/template.hbs"
    }
  });

  _exports.default = _default;
});