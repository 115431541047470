define("product-management/pods/admin/dealer/address-book/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iDv43Fqs",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"grid grid-cols-3\"],[12],[2,\"\\n  \"],[8,\"dealers/address-book\",[],[[\"@dealer\"],[[34,0]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/dealer/address-book/template.hbs"
    }
  });

  _exports.default = _default;
});