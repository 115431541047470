define("product-management/pods/components/purchasing/critical-inventory/count-information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YB/596e7",
    "block": "{\"symbols\":[\"@item\"],\"statements\":[[8,\"purchasing/critical-inventory/order-point-warning\",[[24,0,\"mb-8\"]],[[\"@item\"],[[32,1]]],null],[2,\"\\n\\n\"],[8,\"purchasing/critical-inventory/count-input\",[],[[\"@item\"],[[32,1]]],null],[2,\"\\n\\n\"],[8,\"purchasing/critical-inventory/count-history\",[[24,0,\"mt-4 pt-4 border-t border-grey-light\"]],[[\"@inventoryChanges\"],[[32,1,[\"inventoryChanges\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/critical-inventory/count-information/template.hbs"
    }
  });

  _exports.default = _default;
});