define("product-management/pods/components/ordering/edit/edit-awning-misc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OZWSbHT6",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"line-item\",\"options\",\"onFieldChange\"],[[35,2],[35,1,[\"optionSet\",\"options\"]],[30,[36,0],[[32,0],\"fieldChange\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"choices\",\"line-item\",\"ordering/wizards/awning/misc-details\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/edit/edit-awning-misc/template.hbs"
    }
  });

  _exports.default = _default;
});