define("product-management/pods/components/sun-pro/shop/stations/up-next/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "slLEan6S",
    "block": "{\"symbols\":[\"item\",\"@currentStation\",\"@items\",\"&attrs\"],\"statements\":[[11,\"div\"],[17,4],[12],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"hr\"],[14,0,\"border-grey-dark text-center text-xl\"],[14,\"data-hr-content\",\"Up Next\"],[14,5,\"--bg:white; --p:0 10px; --trans-x:-50%; --trans-y:-50%;\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex flex-wrap items-baseline\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"sun-pro/shop/stations/unit-card\",[[24,0,\"w-1/2 p-1\"]],[[\"@currentStation\",\"@item\"],[[32,2],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/shop/stations/up-next/template.hbs"
    }
  });

  _exports.default = _default;
});