define("product-management/pods/components/pm/ui/toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n0oiJAjH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[14,6,\"#\"],[15,\"onclick\",[30,[36,1],[[32,0],[35,0]],null]],[12],[2,\"\\n  \"],[1,[34,2]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"toggle\",\"action\",\"text\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});