define("product-management/pods/components/shop/line-items/bottom-bar-station/component", ["exports", "product-management/mixins/shop/hardware/station-component"], function (_exports, _stationComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var stationName = "Bottom Bar";

  var _default = Ember.Component.extend(_stationComponent.default, {
    stationName: stationName,
    isBulk: Ember.computed(function () {
      return this.bulk === undefined ? false : this.bulk;
    }),
    activeCell: Ember.computed(function () {
      return this.bulk === undefined ? null : this.lineItem.workflow.cell;
    })
  });

  _exports.default = _default;
});