define("product-management/validations/password", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // validations/password.js
  var _default = {
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 8
    }), (0, _validators.validateFormat)({
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[0-9a-zA-Z]{8,}$/,
      message: "Your password must have at least 1 capital letter and 1 numeric character"
    })],
    passwordConfirmation: (0, _validators.validateConfirmation)({
      on: 'password'
    })
  };
  _exports.default = _default;
});