define("product-management/pods/components/pm/attachments/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JA3Se9UJ",
    "block": "{\"symbols\":[\"PmUiBoxHeader\",\"&default\"],\"statements\":[[6,[37,2],[[30,[36,1],[\"pm/ui/box/header\"],null]],null,[[\"default\"],[{\"statements\":[[8,[32,1],[],[[\"@icon\"],[\"paperclip\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        Attachments\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/attachments/header/template.hbs"
    }
  });

  _exports.default = _default;
});