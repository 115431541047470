define("product-management/pods/admin/settings/my-profile/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YrCuOhHQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"\\n  My Profile\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.my-profile.daily-digest\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Daily Digest\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/my-profile/index/template.hbs"
    }
  });

  _exports.default = _default;
});