define("product-management/pods/components/painless-manufacturing/line-item/card/mobile-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8BPnf7WW",
    "block": "{\"symbols\":[\"group\",\"attr\",\"@summary\"],\"statements\":[[10,\"div\"],[14,0,\"mt-4 border-t border-grey-lighter p-4\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,3,[\"groups\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"table\"],[14,0,\"w-full\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"td\"],[14,0,\"border-b-2 border-grey-light text-blue font-semibold\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,1,[\"attributes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"td\"],[14,\"valign\",\"top\"],[14,0,\"text-blue-darker pb-2\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"font-semibold block mb-1\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[32,2,[\"name\"]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"span\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[32,2,[\"value\"]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/line-item/card/mobile-summary/template.hbs"
    }
  });

  _exports.default = _default;
});