define("product-management/pods/components/products/stations/sidetrack-generic/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EWEoNG5B",
    "block": "{\"symbols\":[\"details\",\"note\",\"@onIsForStation\"],\"statements\":[[10,\"h3\"],[12],[2,\"Unit Details\"],[13],[2,\"\\n\"],[10,\"table\"],[14,0,\"table table-responsive\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Sequence\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Color\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Height\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Type\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,6],[[35,0,[\"details\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[12],[1,[35,0,[\"sequenceNumber\"]]],[13],[2,\"\\n      \"],[10,\"td\"],[12],[1,[32,1,[\"side_track_color\"]]],[13],[2,\"\\n      \"],[10,\"td\"],[12],[1,[30,[36,1],[[32,1,[\"side_track_height\"]]],null]],[13],[2,\"\\n      \"],[10,\"td\"],[12],[1,[35,0,[\"product\",\"name\"]]],[13],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[30,[36,3],[[30,[36,2],[[32,0],[32,3]],null],[35,0,[\"notes\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"td-note\"],[12],[2,\"\\n\\t  \"],[1,[32,2,[\"note\"]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[1,[30,[36,7],null,[[\"lineItem\"],[[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"lineItem\",\"number-as-fraction\",\"action\",\"filter\",\"-track-array\",\"each\",\"with\",\"products/stations/sidetrack-remotes\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/products/stations/sidetrack-generic/template.hbs"
    }
  });

  _exports.default = _default;
});