define("product-management/pods/components/pricing/size-matrix-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DIRECTION_KEYS = ["Enter", "NumpadEnter", "ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"];
  var ENTER_KEYS = ["Enter", "NumpadEnter"];

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var height = this.height,
          width = this.width;
      var id = "size-matrix-item-h-".concat(height, "-w-").concat(width);
      this.set('elementId', id);
    },
    item: Ember.computed("sizes", "height", "width", function () {
      var sizes = this.sizes;
      var height = this.height;
      var width = this.width;

      if (sizes === undefined) {
        sizes = [];
      }

      var item = sizes.find(function (item) {
        return item.height === height && item.width === width;
      });

      if (item === undefined) {
        item = {
          price: 0
        };
        if (height != undefined) item = Object.assign(item, {
          height: height
        });
        if (width != undefined) item = Object.assign(item, {
          width: width
        });
        sizes.pushObject(item);
      }

      return item;
    }),
    price: Ember.computed.alias("item.price"),
    actions: {
      checkForValue: function checkForValue() {
        var price = this.price;
        Ember.run.later(this, function () {
          if (Ember.isEmpty(price)) {
            Ember.set(this, "price", 0);
          }
        }, 100);
      },
      keyDown: function keyDown(e) {
        var code = event.code;

        if (DIRECTION_KEYS.includes(code)) {
          event.preventDefault();

          if (ENTER_KEYS.includes(code)) {
            code = "ArrowDown";
          }

          var height = this.height,
              width = this.width;
          this.attrs.onKeyDown(height, width, code);
        }
      }
    }
  });

  _exports.default = _default;
});