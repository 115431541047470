define("product-management/mixins/shop/multi-station", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getOtherIds: function getOtherIds(lineItem) {
      return this.filteredLineItems.filter(function (item) {
        return item.id != lineItem.id;
      }).map(function (item) {
        return item.id;
      });
    },
    actions: {
      complete: function complete(lineItem) {
        var otherIds = this.getOtherIds(lineItem);
        this.attrs.onComplete(lineItem, null, otherIds);
      }
    }
  });

  _exports.default = _default;
});