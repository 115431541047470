define("product-management/pods/shop-diagnostic/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p1wWSGvo",
    "block": "{\"symbols\":[\"order\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-offset-3 col-md-6\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"well\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"\\n        Select an order to view\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"class\",\"route\",\"model\"],[\"list-group-item\",\"shop_diagnostic.order\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"h4\"],[12],[1,[32,1,[\"dealer\",\"name\"]]],[13],[2,\"\\n            \"],[10,\"h5\"],[12],[2,\"\\n              PO#: \"],[1,[32,1,[\"dealerReference\"]]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"orders\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop-diagnostic/index/template.hbs"
    }
  });

  _exports.default = _default;
});