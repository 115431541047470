define("product-management/pods/components/line-item/details-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8dR/+IZU",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[35,0]],null]],[2,\" - \"],[1,[34,2]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"key\",\"humanize\",\"valueToDisplay\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/line-item/details-value/template.hbs"
    }
  });

  _exports.default = _default;
});