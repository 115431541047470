define("product-management/pods/components/painless-manufacturing/reports/product-count/by-month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DVpVllge",
    "block": "{\"symbols\":[\"@showChange\",\"@showChangePercentage\",\"@comparing\",\"@startAt\",\"@compareStartAt\",\"@records\"],\"statements\":[[8,\"painless-manufacturing/reports/product-count/by-month/product\",[[24,0,\"my-8 border-b border-grey-light pb-8\"]],[[\"@showChange\",\"@showChangePercentage\",\"@comparing\",\"@startAt\",\"@compareStartAt\",\"@records\",\"@product\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,6],\"Motorized\"]],null],[2,\"\\n\"],[8,\"painless-manufacturing/reports/product-count/by-month/product\",[[24,0,\"my-8 border-b border-grey-light pb-8\"]],[[\"@showChange\",\"@showChangePercentage\",\"@comparing\",\"@startAt\",\"@compareStartAt\",\"@records\",\"@product\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,6],\"Screen Only\"]],null],[2,\"\\n\"],[8,\"painless-manufacturing/reports/product-count/by-month/product\",[[24,0,\"my-8 border-b border-grey-light pb-8\"]],[[\"@showChange\",\"@showChangePercentage\",\"@comparing\",\"@startAt\",\"@compareStartAt\",\"@records\",\"@product\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,6],\"Awnings\"]],null],[2,\"\\n\"],[8,\"painless-manufacturing/reports/product-count/by-month/product\",[[24,0,\"mt-8\"]],[[\"@showChange\",\"@showChangePercentage\",\"@comparing\",\"@startAt\",\"@compareStartAt\",\"@records\",\"@product\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,6],\"Open Roller\"]],null],[2,\"\\n\"],[8,\"painless-manufacturing/reports/product-count/by-month/product\",[[24,0,\"mt-8\"]],[[\"@showChange\",\"@showChangePercentage\",\"@comparing\",\"@startAt\",\"@compareStartAt\",\"@records\",\"@product\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,6],\"Crossarm\"]],null],[2,\"\\n\"],[8,\"painless-manufacturing/reports/product-count/by-month/product\",[[24,0,\"mt-8\"]],[[\"@showChange\",\"@showChangePercentage\",\"@comparing\",\"@startAt\",\"@compareStartAt\",\"@records\",\"@product\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,6],\"Wind Abatement\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/reports/product-count/by-month/template.hbs"
    }
  });

  _exports.default = _default;
});