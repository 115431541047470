define("product-management/pods/components/admin/inventory-items/table-item/component", ["exports", "product-management/utils/fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global dymo */
  var _default = Ember.Component.extend({
    tagName: "tr",
    name: Ember.computed.alias("record.name"),
    sku: Ember.computed.alias("record.sku"),
    details: Ember.computed.alias("record.details"),
    actions: {
      print: function print(sku, name) {
        this.doPrint(sku, name);
      }
    },
    doPrint: function doPrint(sku, name) {
      var fromInvalid = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var promptMessage = fromInvalid ? "Please enter a valid quantity. How many?" : "How many?";
      var quantity = prompt(promptMessage, 1);

      if (isNaN(quantity)) {
        this.doPrint(sku, name, true);
        return;
      }

      this.printLabels(sku, name, parseInt(quantity));
    },
    printLabels: function printLabels(sku, name, quantity) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _fetch.fetchXml)("/assets/labels/inventory-item.label").then(function (labelXml) {
          var printer = _this.getFirstPrinter();

          if (printer === null) {
            resolve();
            return;
          }

          var label = dymo.label.framework.openLabelXml(labelXml);
          label.setObjectText("SKU", sku);
          label.setObjectText("NAME", name);

          for (var i = 0; i < quantity; i++) {
            label.print(printer.name);
          }

          resolve();
        });
      });
    },
    getFirstPrinter: function getFirstPrinter() {
      var printers = dymo.label.framework.getPrinters();

      if (printers.length === 0) {
        alert("There are no printers installed. Please be sure to go back into the order and print your labels.");
        return null;
      }

      return printers[0];
    }
  });

  _exports.default = _default;
});