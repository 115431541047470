define("product-management/pods/components/ordering/edit-part-quantity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uXrk/Aar",
    "block": "{\"symbols\":[],\"statements\":[[10,\"dl\"],[12],[2,\"\\n  \"],[10,\"dt\"],[12],[2,\"Quantity\"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[2,\"\\n\"],[6,[37,5],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"edit-container\"],[12],[2,\"\\n    \"],[11,\"form\"],[4,[38,1],[[32,0],\"updateQuantity\",[35,3],[35,6]],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n      \"],[1,[30,[36,7],null,[[\"value\",\"class\"],[[35,6],\"form-control input-sm\"]]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"small-save-container\"],[12],[2,\"\\n\\t\"],[10,\"button\"],[14,0,\"btn btn-xs btn-save\"],[14,4,\"submit\"],[12],[2,\"Save\"],[13],[2,\"\\n\\t\"],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],[35,8],false],null],[12],[2,\"cancel\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[\"editQuantity line-item\",[35,3]],[[\"order\"],[[35,2]]]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],\"toggleEditing\",true],null],[12],[1,[34,0]],[13],[2,\"\\n    \"],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],\"toggleEditing\",true],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"badge badge-danger\"],[12],[2,\"\\n\\tedit\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"quantity\",\"action\",\"order\",\"line-item\",\"can\",\"if\",\"new-quantity\",\"input\",\"toggleEditing\",\"is-editing\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/edit-part-quantity/template.hbs"
    }
  });

  _exports.default = _default;
});