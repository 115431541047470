define("product-management/pods/components/line-item/details-value/component", ["exports", "product-management/helpers/number-as-fraction"], function (_exports, _numberAsFraction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    valueToDisplay: Ember.computed('value', function () {
      var value = this.value;
      if (Ember.isEmpty(value)) return "";
      return (0, _numberAsFraction.numberAsFraction)([value]);
    })
  });

  _exports.default = _default;
});