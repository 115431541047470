define("product-management/pods/components/ordering/wizards/screen-only/pick-manufacturer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Plai036",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"wizard-step-heading\"],[12],[2,\"Choose the Zipper Configuration\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"zipper-config-pick-manufacturer\"],[12],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"icon\",\"model\",\"field\",\"onSetValue\",\"options\"],[\"fa-check-square\",[35,1],\"details.manufacturer\",[30,[36,2],[[32,0],\"pickManufacturer\",[35,1]],null],[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"manufacturers\",\"line-item\",\"action\",\"icon-radio-button-list\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/wizards/screen-only/pick-manufacturer/template.hbs"
    }
  });

  _exports.default = _default;
});