define("product-management/pods/admin/transportation/delivery-routes/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eDyT0SgQ",
    "block": "{\"symbols\":[\"canDo\"],\"statements\":[[8,\"can-do\",[],[[\"@model\",\"@action\",\"@policy\"],[null,\"update\",\"DeliveryRoute::ChangePosition\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,1,[\"allowed\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"mb-4 sp-sub-nav flex\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"mr-2\"]],[[\"@route\",\"@query\"],[\"admin.transportation.delivery-routes.index\",[30,[36,0],null,[[\"mode\"],[\"table\"]]]]],[[\"default\"],[{\"statements\":[[2,\"Delivery\\n            Routes\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"\"]],[[\"@route\",\"@query\"],[\"admin.transportation.delivery-routes.index\",[30,[36,0],null,[[\"mode\"],[\"sortable-list\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            Production Priority\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[8,\"fulfillment/delivery-routes/list\",[],[[\"@records\",\"@mode\"],[[34,2],[34,3]]],null]],\"hasEval\":false,\"upvars\":[\"hash\",\"if\",\"model\",\"mode\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/transportation/delivery-routes/index/template.hbs"
    }
  });

  _exports.default = _default;
});