define("product-management/mixins/shop/interior-station", ["exports", "product-management/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    productType: Ember.computed.alias("lineItem.product.productType.name"),
    isInteriorUnit: Ember.computed.equal("productType", _constants.interiorUnitType)
  });

  _exports.default = _default;
});