define("product-management/mixins/wip/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Properties
    selectedDealers: Ember.A(),
    selectedStatus: Ember.A(),
    selectedShippingMethods: Ember.A(),
    selectedProductTypes: Ember.A(),
    isFiltered: false,
    results: Ember.computed.alias("model.results"),
    allDealers: Ember.computed.mapBy("results", "dealerName"),
    dealers: Ember.computed.uniq("allDealers"),
    orders: Ember.computed("wipItems", function () {
      return this.wipItems.uniqBy("orderId");
    }),
    "product-types-sort-by": ["name"],
    sortedProductTypes: Ember.computed.sort("model.productTypes", "product-types-sort-by"),
    productTypes: Ember.computed.map("sortedProductTypes", function (item) {
      return {
        id: item.get("id"),
        name: item.get("name")
      };
    }),
    wipItems: Ember.computed("filterStamp", function () {
      return this.applyFilter();
    }),
    actions: {
      forceCompleteUnit: function forceCompleteUnit(item) {
        return this.store.findRecord("lineItem", item.id).then(function (lineItem) {
          return lineItem.forceComplete();
        });
      },
      clearFilters: function clearFilters() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          _this.clearFilters();

          _this.setFilterStamp();

          resolve(true);
        });
      },
      applyFilter: function applyFilter() {
        this.setFilterStamp();
      },
      releaseLineItem: function releaseLineItem(wipItem, statusField) {
        var _this2 = this;

        return this.store.findRecord("lineItem", wipItem.id, {
          adapterOptions: {
            fields: {
              "fields[line-items]": "id"
            }
          }
        }).then(function (lineItem) {
          return lineItem.release({
            status_field: statusField
          }).then(function (data) {
            _this2.store.pushPayload(data);
          });
        });
      }
    },
    getResults: function getResults() {
      var workQueueId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var filters = {
        "filter[exclude_invoiced]": true
      };
      if (workQueueId != null) Object.assign(filters, {
        "filter[by_work_queue]": workQueueId
      });
      return this.store.query("wip", filters).then(function (data) {
        return data;
      }, function () {
        return [];
      });
    },
    setFilterStamp: function setFilterStamp() {
      this.set("filterStamp", new Date().getTime());
    },
    optimizedRefreshResults: function optimizedRefreshResults(data) {
      this.store.pushPayload(data.item);
    },
    allFiltersEmpty: function allFiltersEmpty() {
      var poFilter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var selectedDealers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var selectedStatus = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var selectedShippingMethods = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      var selectedProductTypes = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
      return Ember.isEmpty(poFilter) && Ember.isEmpty(selectedDealers) && Ember.isEmpty(selectedStatus) && Ember.isEmpty(selectedShippingMethods) && Ember.isEmpty(selectedProductTypes);
    },
    clearFilters: function clearFilters() {
      this.setProperties({
        poFilter: null,
        isFiltered: false,
        selectedDealers: Ember.A(),
        selectedStatus: Ember.A(),
        selectedShippingMethods: Ember.A()
      });
    },
    applyFilter: function applyFilter() {
      var model = this.model.results;
      var poFilter = this.poFilter,
          selectedDealers = this.selectedDealers,
          selectedStatus = this.selectedStatus,
          selectedShippingMethods = this.selectedShippingMethods,
          selectedProductTypes = this.selectedProductTypes;

      if (this.allFiltersEmpty(poFilter, selectedDealers, selectedStatus, selectedShippingMethods, selectedProductTypes)) {
        return model.sortBy('position');
      }

      var selectedProductTypeIds = selectedProductTypes.map(function (item) {
        return parseInt(item.id);
      });
      Ember.set(this, "isFiltered", true);

      if (poFilter != undefined) {
        poFilter = poFilter.toLowerCase();
      }

      var result = model.filter(function (item) {
        var result = true;
        if (Ember.isPresent(poFilter)) result = item.get("dealerReference").toLowerCase().includes(poFilter);
        if (Ember.isPresent(selectedDealers)) result = result && selectedDealers.includes(item.get("dealerName"));
        if (Ember.isPresent(selectedStatus)) result = result && selectedStatus.map(function (i) {
          return i.value;
        }).includes(item.get("orderStatus"));
        if (Ember.isPresent(selectedShippingMethods)) result = result && selectedShippingMethods.includes(item.get("shippingMethod"));
        if (Ember.isPresent(selectedProductTypes)) result = result && selectedProductTypeIds.includes(item.get("productTypeId"));
        return result;
      });
      return result.sortBy("position");
    }
  });

  _exports.default = _default;
});