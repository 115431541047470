define("product-management/pods/components/google/address/auto-complete/search-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jw2e5ldX",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[10,\"label\"],[14,\"for\",\"address-lookup\"],[12],[2,\"\\n  Lookup an address\\n\"],[13],[2,\"\\n\"],[11,\"input\"],[24,1,\"address-lookup\"],[24,3,\"address-lookup\"],[24,0,\"p-2 border border-gray-300 w-full\"],[17,1],[24,4,\"text\"],[4,[38,1],[\"keyup\",[30,[36,0],[[32,0,[\"cancelEnter\"]]],null]],null],[4,[38,2],[[32,0,[\"initializeAutoComplete\"]],[32,0]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"did-insert\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/google/address/auto-complete/search-box/template.hbs"
    }
  });

  _exports.default = _default;
});