define("product-management/pods/components/orders/po-number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lZPqUstS",
    "block": "{\"symbols\":[\"poNumberChange\"],\"statements\":[[6,[37,4],[[30,[36,3],[[35,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h3\"],[12],[2,\"PO#: \"],[13],[2,\"\\n  \"],[10,\"h3\"],[12],[1,[30,[36,1],null,[[\"onSave\",\"resource\",\"propertyName\"],[[30,[36,0],[[32,0],\"save\"],null],[32,1],\"dealerReference\"]]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"shamgar/edit-in-place\",\"order\",\"changeset\",\"with\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/orders/po-number/template.hbs"
    }
  });

  _exports.default = _default;
});