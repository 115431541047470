define("product-management/pods/components/app-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xZcmuTj0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[30,[36,2],null,[[\"title\"],[[35,3]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[18,1,[[30,[36,1],null,[[\"header-title\"],[[30,[36,0],[\"app-container-title\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"div\"],[15,0,[31,[[30,[36,4],[[35,5],\"container-fluid\",\"container\"],null],\" app-container\"]]],[14,1,\"page\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\\t\\t\\t\"],[18,1,[[30,[36,1],null,[[\"body\"],[[30,[36,0],[\"app-container-body\"],null]]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"footer no-print\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\t\\t\"],[10,\"hr\"],[12],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,4],[[35,6,[\"isAdmin\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"app-container/view-switcher\",[],[[],[]],null],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[10,\"div\"],[15,0,[31,[\"col-md-9 \",[30,[36,7],[[35,6,[\"isAdmin\"]],\"col-md-offset-3\"],null],\" text-right\"]]],[12],[2,\"\\n\\t\\t\\t\\tVersion: \"],[1,[34,8]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"page-header\",\"title\",\"if\",\"fluid\",\"session\",\"unless\",\"appVersion\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/app-container/template.hbs"
    }
  });

  _exports.default = _default;
});