define("product-management/pods/components/pm/ui/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "60JQ1v9e",
    "block": "{\"symbols\":[\"&default\",\"@records\"],\"statements\":[[6,[37,3],[[30,[36,2],[[32,2]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"table\"],[14,0,\"table-striped table-condensed table\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,1],null,[[\"header\",\"body\"],[[30,[36,0],[\"pm/ui/table/header\"],null],[30,[36,0],[\"pm/ui/table/body\"],[[\"records\"],[[32,2]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,[[30,[36,1],null,[[\"empty\"],[[30,[36,0],[\"pm/ui/table/empty\"],null]]]]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"is-empty\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/table/template.hbs"
    }
  });

  _exports.default = _default;
});