define("product-management/pods/components/tailwind-ui/lists/simple-striped/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qs9/mpLC",
    "block": "{\"symbols\":[\"record\",\"index\",\"&default\",\"@records\"],\"statements\":[[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"tr\"],[15,0,[30,[36,1],[[30,[36,0],[[32,0,[\"cssClass\"]]],null],[32,2]],null]],[12],[2,\"\\n\\t\\t\"],[18,3,[[30,[36,3],null,[[\"main\",\"standard\",\"action\",\"record\"],[[35,2,[\"main\"]],[35,2,[\"standard\"]],[35,2,[\"action\"]],[32,1]]]]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"compute\",\"classes\",\"hash\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/tailwind-ui/lists/simple-striped/body/template.hbs"
    }
  });

  _exports.default = _default;
});