define("product-management/models/work-signoff", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    lineItem: (0, _model.belongsTo)(),
    station: (0, _model.attr)(),
    signature: (0, _model.attr)(),
    createdAt: (0, _model.attr)(),
    employee: (0, _model.attr)(),
    shopCode: (0, _model.attr)()
  });

  _exports.default = _default;
});