define("product-management/pods/components/edit-line-item-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f6UFLdsH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-11\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Edit \"],[1,[30,[36,2],[[30,[36,1],[[35,0]],null]],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-1\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"modal-close\"],[4,[38,3],[[32,0],\"closeModal\"],null],[12],[10,\"h3\"],[12],[2,\"X\"],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[1,[30,[36,7],[[35,6]],[[\"line-item\",\"choices\"],[[35,5],[35,4]]]]],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n      \"],[11,\"button\"],[16,\"disabled\",[34,8]],[24,0,\"btn btn-save\"],[4,[38,3],[[32,0],\"save\",[35,5]],null],[12],[2,\"Save Changes\"],[13],[2,\" or \"],[11,\"a\"],[24,6,\"#\"],[4,[38,3],[[32,0],\"closeModal\"],null],[12],[2,\"cancel\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mode\",\"humanize\",\"titleize\",\"action\",\"choices\",\"model\",\"componentName\",\"component\",\"isInvalid\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/edit-line-item-container/template.hbs"
    }
  });

  _exports.default = _default;
});