define("product-management/pods/admin/settings/users/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qh+WP/iG",
    "block": "{\"symbols\":[\"PageHeader\"],\"statements\":[[1,[30,[36,0],[\"Users\"],null]],[2,\"\\n\\n\"],[8,\"settings/page-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Users\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.users.new\"]],[[\"default\"],[{\"statements\":[[2,\"Create a New User\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[8,\"pm/users/list\",[],[[\"@users\",\"@onAction\",\"@showRoute\"],[[34,1],[30,[36,2],[\"accountAction\"],null],\"admin.settings.users.show\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"users\",\"route-action\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/users/index/template.hbs"
    }
  });

  _exports.default = _default;
});