define("product-management/pods/components/shop/line-items/splicer-station/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y4QF7576",
    "block": "{\"symbols\":[\"@signedWork\",\"@onComplete\"],\"statements\":[[1,[30,[36,1],null,[[\"lineItem\"],[[35,0]]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"initialsSection\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row instructions-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"h4\"],[12],[2,\"This is an interior unit and it uses a Hem Bar. Please build a pocket for the Hem Bar\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"h4\"],[12],[2,\"Please weld the screens and click the 'complete' button below to send them to Final Cutting.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,4],null,[[\"lineItem\",\"on-complete\",\"on-signature-changed\"],[[35,0],[32,2],[32,1]]]]],[2,\"\\n  \\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"lineItem\",\"custom/shop/four-five-warning\",\"isInteriorUnit\",\"if\",\"shop/work-signoff\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/line-items/splicer-station/template.hbs"
    }
  });

  _exports.default = _default;
});