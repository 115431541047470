define("product-management/pods/components/pm/order/summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ALYG3d8G",
    "block": "{\"symbols\":[\"@order\"],\"statements\":[[10,\"h3\"],[14,0,\"text-xl font-semibold mb-1\"],[12],[2,\"Order Summary\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex flex-row\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"Item(s) Subtotal\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ml-auto\"],[12],[1,[30,[36,0],[[32,1,[\"subtotal\"]]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex flex-row hide\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"Shipping & Handling\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ml-auto\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex flex-row font-bold\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"Grand Total\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ml-auto\"],[12],[1,[30,[36,0],[[32,1,[\"subtotal\"]]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"format-money\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/order/summary/template.hbs"
    }
  });

  _exports.default = _default;
});