define("product-management/helpers/label-printers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.labelPrinters = labelPrinters;
  _exports.default = void 0;

  function labelPrinters(params) {
    return params[0].printer.split(",");
  }

  ;

  var _default = Ember.Helper.helper(labelPrinters);

  _exports.default = _default;
});