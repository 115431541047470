define("product-management/utils/shipping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rgaShippingMethods = _exports.shippableMethods = _exports.shippingTranslation = _exports.shippingMethods = void 0;
  var shippingMethods = ["Delivery", "Pick up", "Shipping"];
  _exports.shippingMethods = shippingMethods;
  var shippingTranslation = {
    "Shipping": "Shipping - UPS, FedEx, Common Carrier",
    "Delivery": "Delivery - Company Truck",
    "Pick up": "Pick up"
  };
  _exports.shippingTranslation = shippingTranslation;
  var shippableMethods = ["Delivery", "Shipping"];
  _exports.shippableMethods = shippableMethods;
  var rgaShippingMethods = [{
    value: "Pick up",
    label: "Dropoff at SunPro"
  }, {
    value: "Delivery",
    label: "Pickup by Delivery Driver"
  }, {
    label: "Ship to SunPro by Common Carrier",
    value: "Shipping"
  }];
  _exports.rgaShippingMethods = rgaShippingMethods;
});