define("product-management/pods/components/stardust/report/compared-headers/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yce7uutE",
    "block": "{\"symbols\":[\"@label\",\"@compareStartAt\",\"@compareEndAt\",\"@showChange\",\"@showChangePercentage\",\"@startAt\",\"@endAt\",\"@comparing\"],\"statements\":[[10,\"th\"],[14,0,\"text-sm text-center\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"block mb-1\"],[12],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-xs text-grey-dark\"],[12],[2,\"\\n\"],[6,[37,1],[[32,8]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,6],\"MM/DD/YY\"],null]],[2,\"-\"],[1,[30,[36,0],[[32,7],\"MM/DD/YY\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,1],[[32,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"th\"],[14,0,\"text-sm text-center\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"block mb-1\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-xs text-grey-dark\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,2],\"MM/DD/YY\"],null]],[2,\"\\n      -\\n      \"],[1,[30,[36,0],[[32,3],\"MM/DD/YY\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"th\"],[14,0,\"text-sm text-center\"],[12],[2,\"\\n      Change\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"th\"],[14,0,\"text-sm text-center\"],[12],[2,\"\\n      % Change\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/report/compared-headers/item/template.hbs"
    }
  });

  _exports.default = _default;
});