define("product-management/pods/components/pm/comments/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5cHU7m1n",
    "block": "{\"symbols\":[\"@comment\"],\"statements\":[[6,[37,0],[[32,1,[\"isNew\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"relative flex py-5 items-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-grow border-t border-red-dark\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"flex-shrink mx-4 text-red-dark\"],[12],[2,\"\\n      New Comments\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-grow border-t border-red-dark\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[15,0,[31,[\"my-4 w-4/5 leading-10 \",[30,[36,0],[[32,1,[\"authorIsMe\"]],\"ml-auto\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[[32,1,[\"authorIsMe\"]],\"bg-smoke-light\",\"bg-smoke\"],null],\"\\n       rounded-lg p-4 text-white conversation-comment font-medium\"]]],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[15,0,[31,[\"mt-1\\n      \",[30,[36,0],[[32,1,[\"authorIsMe\"]],\"text-right mr-2\",\"ml-2\"],null],\"\\n       text-grey-darker text-sm block\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"author\",\"fullName\"]]],[2,\"\\n    \"],[8,\"pm/ui/friendly-date\",[],[[\"@date\"],[[32,1,[\"createdAt\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"content\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/comments/item/template.hbs"
    }
  });

  _exports.default = _default;
});