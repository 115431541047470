define("product-management/models/purchase-order", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    number: (0, _model.attr)(),
    status: (0, _model.attr)(),
    sentAt: (0, _model.attr)(),
    receivedAt: (0, _model.attr)(),
    vendor: (0, _model.belongsTo)(),
    purchaseOrderItems: (0, _model.hasMany)(),
    receiveUnit: (0, _emberApiActions.memberAction)({
      path: "receive_unit",
      type: "post"
    }),
    receivedUnits: (0, _emberApiActions.memberAction)({
      path: "received_units",
      type: "get"
    })
  });

  _exports.default = _default;
});