define("product-management/pods/admin/accounting/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qywSjGRH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"p-4 flex\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-1/4 p-4\"],[12],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"list-group tweaked\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\"],[\"list-group-item\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.accounting.settings.deliveries\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          Delivery Billing Rules\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-3/4 p-4\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"active-link\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/settings/template.hbs"
    }
  });

  _exports.default = _default;
});