define("product-management/pods/pm/orders/line-items/sys-admin-tasks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+VZ6ia9K",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"Item Status\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"itemStatus\"]}",
    "meta": {
      "moduleName": "product-management/pods/pm/orders/line-items/sys-admin-tasks/template.hbs"
    }
  });

  _exports.default = _default;
});