define("product-management/pods/components/fulfillment/deliveries/summary-for-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uPvZWPWK",
    "block": "{\"symbols\":[\"data\",\"v\",\"k\",\"&attrs\",\"@summaryItem\"],\"statements\":[[6,[37,3],[[32,5,[\"data\"]]],null,[[\"default\"],[{\"statements\":[[11,\"div\"],[24,0,\"bg-white shadow rounded-lg overflow-hidden\"],[17,4],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"px-2 py-1\"],[12],[2,\"\\n    \"],[10,\"dl\"],[12],[2,\"\\n      \"],[10,\"dt\"],[14,0,\"text-sm leading-5 font-medium text-grey truncate\"],[12],[2,\"\\n        \"],[1,[32,5,[\"name\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"dd\"],[14,0,\"mt-1 text-2xl leading-9 font-semibold text-grey-darkest\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"block mb-2\"],[12],[2,\"\\n          Total:\\n          \"],[1,[32,1,[\"itemCount\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"block mb-2 text-sm text-grey\"],[12],[2,\"\\n          Weight:\\n          \"],[1,[32,1,[\"weight\"]]],[2,\"\\n          lbs\\n        \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"summary\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"block mb-2 text-sm text-grey\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[[32,2],\"label\"],null]],[2,\":\\n          \"],[1,[30,[36,0],[[32,2],\"result\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[2,3]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"get\",\"-each-in\",\"each\",\"let\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/deliveries/summary-for-item/template.hbs"
    }
  });

  _exports.default = _default;
});