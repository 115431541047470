define("product-management/pods/components/shop/line-items/final-cut-station/step-2/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var sideTrackTypeCableGuided = "Cable Guided";
  var noZippersOnTheSide = "No Side Zipper";
  var openRoller = "Open Roller";
  var noZipper = ["None", "No side zipper, but weld to avoid fray"];

  var _default = Ember.Component.extend({
    canComplete: Ember.computed.alias("hasSignature"),
    cannotComplete: Ember.computed.not("canComplete"),
    isCableGuided: Ember.computed.equal("lineItem.details.side_track_type", sideTrackTypeCableGuided),
    noLeftZipper: Ember.computed(function () {
      var value = this.lineItem.details.zipper_left;
      return noZipper.includes(value);
    }),
    noRightZipper: Ember.computed(function () {
      var value = this.lineItem.details.zipper_right;
      return noZipper.includes(value);
    }),
    isNoZippersOnTheSide: Ember.computed.and("noLeftZipper", "noRightZipper"),
    isOpenRoller: Ember.computed.equal("lineItem.details.application_type", openRoller),
    actions: {
      complete: function complete() {
        this.attrs.complete();
      },
      signedWork: function signedWork(data) {
        this.attrs.signedWork(data[1]);
      }
    }
  });

  _exports.default = _default;
});