define("product-management/pods/admin/accounting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lVIvq9qu",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],[\"Accounting\"],null]],[2,\"\\n\"],[6,[37,5],null,[[\"title\",\"fluid\"],[\"Accounting\",true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n      \"],[10,\"ul\"],[14,0,\"nav nav-tabs\"],[12],[2,\"\\n\"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[2,\"          \"],[6,[37,0],null,[[\"current-when\",\"route\"],[\"admin.accounting.invoices admin.accounting.invoice\",\"admin.accounting.invoices\"]],[[\"default\"],[{\"statements\":[[2,\"Invoices\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[2,\"          \"],[6,[37,0],null,[[\"route\"],[\"admin.accounting.purchasing.purchase-orders\"]],[[\"default\"],[{\"statements\":[[2,\"Purchasing\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[2,\"          \"],[6,[37,0],null,[[\"route\"],[\"admin.accounting.settings\"]],[[\"default\"],[{\"statements\":[[2,\"Settings\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[2,\"          \"],[6,[37,0],null,[[\"route\"],[\"admin.accounting.sales-tax\"]],[[\"default\"],[{\"statements\":[[2,\"Sales Tax Exemptions\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"active-link\",\"-outlet\",\"component\",\"page-title\",\"app-container\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/template.hbs"
    }
  });

  _exports.default = _default;
});