define("product-management/pods/orders/show/index/index/notes/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8ztJxZGH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"Start a conversation\"],[13],[2,\"\\n\\n\\n\"],[8,\"pm/notes/form\",[],[[\"@b2b\",\"@dealerId\",\"@notable\",\"@notableType\",\"@onComplete\"],[[32,0,[\"b2b\"]],[34,0,[\"dealer\",\"id\"]],[34,0],\"Order\",[30,[36,1],[[32,0],\"refetchOrder\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/index/index/notes/new/template.hbs"
    }
  });

  _exports.default = _default;
});