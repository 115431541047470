define("product-management/pods/components/ui/control-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ux0kAWH",
    "block": "{\"symbols\":[\"option\",\"@value\",\"@onInput\",\"@options\",\"&default\",\"@label\",\"@inputType\"],\"statements\":[[10,\"div\"],[14,0,\"control-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[1,[32,6]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,7],\"select\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"power-select\",[],[[\"@options\",\"@selected\",\"@onChange\"],[[32,4],[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,5,[[32,1]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"input\"],[16,2,[32,2]],[4,[38,0],[\"input\",[32,3]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/control-group/template.hbs"
    }
  });

  _exports.default = _default;
});