define("product-management/mixins/register-events", ["exports", "ember-google-maps/mixins/register-events"], function (_exports, _registerEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _registerEvents.default;
    }
  });
});