define("product-management/pods/dealer-admin/order/interior-unit/index/controller", ["exports", "marygrove-components/mixins/interior-unit-wizard-controller"], function (_exports, _interiorUnitWizardController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_interiorUnitWizardController.default, {
    experience: "interior-unit",
    defaultStep: "enter-dimensions"
  });

  _exports.default = _default;
});