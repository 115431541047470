define("product-management/utils/painless-manufacturing/production/unit-status-parser", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unitStatusParser = unitStatusParser;

  function unitStatusParser(lineItem) {
    var cellStatuses = lineItem.cells.map(function (item) {
      if ((0, _typeof2.default)(item) == "object") {
        return Ember.String.underscore(item.name);
      } else {
        return Ember.String.underscore(item);
      }
    });
    var status = lineItem.status;
    return Object.keys(status).filter(function (item) {
      return item.endsWith("_status") && item != "overall_status";
    }).map(function (key, index) {
      var s = status[key];
      var stationName = key.replace("_status", "");
      var checkedOutAt = status["".concat(stationName, "_checked_out_at")];
      var completedAt = status["".concat(stationName, "_completed_at")];
      var timeToComplete = 0;
      var position = Ember.isEmpty(cellStatuses) ? index : cellStatuses.indexOf(stationName);
      return {
        name: stationName,
        status: s,
        position: position,
        checkedOutAt: checkedOutAt,
        completedAt: completedAt,
        timeToComplete: timeToComplete
      };
    });
  }
});