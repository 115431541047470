define("product-management/pods/components/shop/deliveries/details-awning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rhGDmY6Y",
    "block": "{\"symbols\":[],\"statements\":[[2,\"W: \"],[1,[35,0,[\"width\"]]],[10,\"br\"],[12],[13],[2,\"\\nFabric: \"],[1,[35,0,[\"fabric_name\"]]],[10,\"br\"],[12],[13],[2,\"\\nFrame Color: \"],[1,[35,0,[\"frame_color\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"strong\"],[12],[2,\"Standard Brackets\"],[13],[2,\"\\n\"],[1,[30,[36,2],[[35,0,[\"extra_standard_bracket_quantity\"]],[35,0,[\"standard_bracket_quantity\"]]],null]],[2,\" \"],[1,[35,0,[\"standard_bracket_color\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,3],[[35,0,[\"roof_mount_bracket_quantity\"]]],null,[[\"default\"],[{\"statements\":[[10,\"strong\"],[12],[2,\"Roof Mount Brackets\"],[13],[2,\"\\n\"],[1,[35,0,[\"roof_mount_bracket_quantity\"]]],[2,\"\\n\"],[1,[35,0,[\"roof_mount_bracket_color\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,0,[\"soffit_bracket_quantity\"]]],null,[[\"default\"],[{\"statements\":[[10,\"strong\"],[12],[2,\"Soffit Brackets\"],[13],[2,\"\\n\"],[1,[35,0,[\"soffit_bracket_quantity\"]]],[2,\"\\n\"],[1,[35,0,[\"soffit_bracket_color\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,0,[\"bay_mount_bracket_quantity\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"strong\"],[12],[2,\"Bay Mount Brackets\"],[13],[2,\"\\n\\tWidth: \"],[1,[30,[36,1],[[35,0,[\"bay_mount_bracket_width\"]]],null]],[2,\"in \\n\\t\"],[1,[35,0,[\"bay_mount_bracket_quantity\"]]],[2,\"\\n\\t\"],[1,[35,0,[\"bay_mount_bracket_color\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"details\",\"number-as-fraction\",\"inc\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/deliveries/details-awning/template.hbs"
    }
  });

  _exports.default = _default;
});