define("product-management/pods/components/pm/ui/code-editor/editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uqLE4nOL",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"lines\",\"value\",\"update\",\"useWrapMode\",\"useWorker\",\"useSoftTabs\",\"tabSize\",\"newLineMode\",\"mode\"],[[35,3],[35,2],[30,[36,1],[[32,0],\"updateEditorValue\"],null],true,true,true,2,\"auto\",[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mode\",\"action\",\"valueForEditor\",\"editorLines\",\"ember-ace\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/code-editor/editor/template.hbs"
    }
  });

  _exports.default = _default;
});