define("product-management/pods/admin/accounting/shipments/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.query("shipment", {
        "filter[by_status]": "shipped_no_cost",
        include: "boxes,customer.dealer,orders"
      });
    }
  });

  _exports.default = _default;
});