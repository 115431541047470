define("product-management/pods/dealer-admin/order/screen-only/index/controller", ["exports", "marygrove-components/mixins/screen-only-wizard-controller"], function (_exports, _screenOnlyWizardController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_screenOnlyWizardController.default, {
    experience: "screen-only",
    defaultStep: "enter-dimensions"
  });

  _exports.default = _default;
});