define("product-management/pods/components/messenger/flag-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZxSzMCPW",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"text-white p-2 border border-grey-dark rounded flex items-center font-sans focus:outline-none active:outline-none\\n    \",[30,[36,0],[[32,1,[\"note\",\"flagged\"]],\"bg-red-dark\",\"bg-blue-light\"],null]]]],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleToggleFlag\"]]],null]],null],[12],[2,\"\\n  \"],[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"fill\",\"none\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"stroke-width\",\"1.5\"],[14,\"stroke\",\"currentColor\"],[14,0,\"w-6 h-6\"],[12],[2,\"\\n    \"],[10,\"path\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"d\",\"M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"ml-2\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,1,[\"note\",\"flagged\"]],\"Remove Flag\",\"Flag Conversation\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/messenger/flag-button/template.hbs"
    }
  });

  _exports.default = _default;
});