define("product-management/pods/components/pm/ui/radio-list/options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z5voPawE",
    "block": "{\"symbols\":[\"option\",\"@onSelect\",\"@selectedOption\",\"&default\",\"@options\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,4,[[30,[36,3],null,[[\"value\",\"radioOption\"],[[32,1],[30,[36,2],[\"pm/ui/radio-list/option\"],[[\"value\",\"selected\",\"onSelect\"],[[32,1],[30,[36,1],[[32,1],[32,3]],null],[30,[36,0],[[32,0],[32,2]],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"eq\",\"component\",\"hash\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/radio-list/options/template.hbs"
    }
  });

  _exports.default = _default;
});