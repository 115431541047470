define("product-management/pods/orders/warranty-request/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F9Ws7piT",
    "block": "{\"symbols\":[\"Container\"],\"statements\":[[1,[30,[36,0],[\"My SunPro\"],null]],[2,\"\\n\"],[1,[30,[36,3],null,[[\"on-search-result-selected\",\"on-logout\",\"on-start-new-order\"],[[30,[36,2],[[32,0],\"searchResultSelected\"],null],[30,[36,2],[[32,0],\"logout\"],null],[30,[36,1],[[32,0,[\"onStart\"]]],null]]]]],[2,\"\\n\"],[8,\"app-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header-title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      Warranty Request #\"],[1,[35,4,[\"displayId\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"w-full text-base font-sans mt-4\"],[12],[2,\"\\n      \"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"fn\",\"action\",\"navigation/dealer-nav\",\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/warranty-request/template.hbs"
    }
  });

  _exports.default = _default;
});