define("product-management/pods/components/pm/order/details/totals/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onlyShowBookPricing: Ember.computed('order.dealer.name', 'order.dealer.qbCustomer.name', function () {
      var dealer = this.get('order.dealer.name');
      var qbCustomer = this.get('order.dealer.qbCustomer.name');
      return qbCustomer === 'Marygrove Products' && dealer !== 'Marygrove Products' || qbCustomer === 'Roll Screen Solutions' && dealer !== 'Roll Screen Solutions';
    })
  });

  _exports.default = _default;
});