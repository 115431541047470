define("product-management/pods/admin-mobile/controller", ["exports", "shamgar/mixins/logout-controller", "shamgar-auto-upgrade/mixins/auto-upgrade-controller"], function (_exports, _logoutController, _autoUpgradeController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_autoUpgradeController.default, _logoutController.default, {
    collapsed: true,
    collapseNavbar: function collapseNavbar() {
      this.set("collapsed", true);
    },
    actions: {
      collapseNavbar: function collapseNavbar() {
        this.collapseNavbar();
      }
    }
  });

  _exports.default = _default;
});