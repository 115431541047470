define("product-management/pods/components/shop/line-items/awning-hardware-station/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    product: Ember.computed.alias("lineItem.product"),
    miscFields: Ember.computed.alias("product.orderConfig.line-item-details.fields"),
    actions: {
      signedWork: function signedWork(data) {
        this.attrs.signedWork(data[1]);
      },
      complete: function complete(lineItem, sendTo) {
        this.attrs.onComplete(lineItem, sendTo);
      }
    }
  });

  _exports.default = _default;
});