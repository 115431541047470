define("product-management/components/admin/settings/depends-on-choice-list", ["exports", "shamgar/components/admin/settings/depends-on-choice-list"], function (_exports, _dependsOnChoiceList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dependsOnChoiceList.default;
    }
  });
});