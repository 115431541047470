define("product-management/pods/components/kpis/statistics/statistic/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3zc6teSk",
    "block": "{\"symbols\":[\"@label\",\"@value\"],\"statements\":[[10,\"div\"],[14,0,\"p-2 my-4 w-full sm:w-full md:w-full lg:w-1/4\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bg-white shadow rounded-lg overflow-hidden\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"px-4 py-5 sm:p-6\"],[12],[2,\"\\n\\t\\t\"],[10,\"dl\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"dt\"],[14,0,\"text-sm leading-5 font-medium text-grey truncate\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[32,1]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"dd\"],[14,0,\"mt-1 text-3xl leading-9 font-semibold text-grey-darkest\"],[12],[2,\"\\n\\t\\t\\t  \"],[1,[32,2]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/kpis/statistics/statistic/template.hbs"
    }
  });

  _exports.default = _default;
});