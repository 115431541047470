define("product-management/pods/components/painless-manufacturing/order/returns/toggle-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7fG2FskL",
    "block": "{\"symbols\":[\"@order\"],\"statements\":[[2,\"\\t\\t\"],[11,\"a\"],[24,0,\"btn btn-default btn-lg\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleToggleReturn\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"returnable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\tPlace a Standard Order\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\tStart a Return\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/order/returns/toggle-button/template.hbs"
    }
  });

  _exports.default = _default;
});