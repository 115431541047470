define("product-management/pods/admin/dealer/discounts/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    doneSaving: false,
    dealer: Ember.computed.alias("model"),
    discounts: Ember.computed.alias("dealer.dealerDiscounts"),
    actions: {
      save: function save(discounts) {
        var _this = this;

        var discountsToBeSaved = Ember.A();
        discounts.forEach(function (discount) {
          if (discount.get("isDirty")) {
            var promise = discount.save();
            discountsToBeSaved.pushObject(promise);
          }
        });
        return Ember.RSVP.all(discountsToBeSaved).then(function () {
          Ember.set(_this, "doneSaving", true);
        });
      }
    }
  });

  _exports.default = _default;
});