define("product-management/pods/components/ui/paged-dataset/navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hlVYvLTu",
    "block": "{\"symbols\":[\"&attrs\",\"@totalRecords\",\"@page\",\"@totalPages\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pl-2 text-sm text-grey-darker\"],[12],[2,\"\\n      Showing\\n      \"],[10,\"span\"],[14,0,\"font-medium\"],[12],[1,[34,3]],[13],[2,\"\\n      to\\n      \"],[10,\"span\"],[14,0,\"font-medium\"],[12],[1,[34,4]],[13],[2,\"\\n      of\\n      \"],[10,\"span\"],[14,0,\"font-medium\"],[12],[1,[32,2]],[13],[2,\"\\n      results\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ml-auto\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[32,3],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[[24,0,\"pagination-button mr-2\"]],[[\"@query\"],[[30,[36,1],null,[[\"page\"],[[35,2]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          Previous\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[30,[36,5],[[32,3],[32,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[[24,0,\"pagination-button\"]],[[\"@query\"],[[30,[36,1],null,[[\"page\"],[[35,0]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          Next\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"nextPage\",\"hash\",\"previousPage\",\"start\",\"end\",\"eq\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/paged-dataset/navigation/template.hbs"
    }
  });

  _exports.default = _default;
});