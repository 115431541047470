define("product-management/pods/components/pm/comments/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wHY8E8RU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"bg-grey-lighter flex flex-between py-3 -mx-3 p-2\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"pm/ui/mentionable-textarea\",[[24,0,\"h-64\"]],[[\"@collection\",\"@onUpdate\"],[[34,0],[30,[36,2],[[32,0],[35,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n  \"],[10,\"button\"],[15,\"onclick\",[30,[36,2],[[32,0],[35,5]],null]],[14,0,\"bg-blue hover:bg-blue-dark mb-2 p-2 text-white  text-sm rounded font-bold\"],[12],[2,\"\\n    Submit\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"mentionCollection\",\"updateContent\",\"action\",\"isPostingComment\",\"unless\",\"create\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/comments/form/template.hbs"
    }
  });

  _exports.default = _default;
});