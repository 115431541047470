define("product-management/pods/components/tags/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UZFpp8OJ",
    "block": "{\"symbols\":[\"@tag\"],\"statements\":[[10,\"div\"],[14,0,\"text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-blue-lighter text-blue-darker rounded m-1\"],[12],[2,\"\\n\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/tags/item/template.hbs"
    }
  });

  _exports.default = _default;
});