define("product-management/pods/components/pm/ui/tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SpLW0DOe",
    "block": "{\"symbols\":[\"@record\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"px-4 pb-3 mt-3 text-sm flex flex-row flex-wrap items-center\"],[12],[2,\"\\n  \"],[18,2,[[30,[36,2],null,[[\"list\",\"newTagForm\"],[[30,[36,1],[\"pm/ui/tags/list\"],[[\"tags\",\"onRemoveTag\"],[[32,1,[\"tags\"]],[30,[36,0],[[32,0],[32,0,[\"removeTag\"]]],null]]]],[30,[36,1],[\"pm/ui/tags/new-tag-form\"],[[\"onAddTag\"],[[30,[36,0],[[32,0],[32,0,[\"addTag\"]]],null]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/tags/template.hbs"
    }
  });

  _exports.default = _default;
});