define("product-management/utils/pm/gql/cache-helper", ["exports", "@babel/runtime/helpers/esm/taggedTemplateLiteral", "graphql-tag"], function (_exports, _taggedTemplateLiteral2, _graphqlTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.readFragment = readFragment;
  _exports.writeFragment = writeFragment;
  _exports.updatePolymorphicCollection = updatePolymorphicCollection;

  function _templateObject2() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n             fragment item on ", "{\n               id\n               ", "{\n               id\n               }\n             }\n            "]);

    _templateObject2 = function _templateObject2() {
      return data;
    };

    return data;
  }

  function _templateObject() {
    var data = (0, _taggedTemplateLiteral2.default)(["\n                fragment item on ", "{\n                 ", "{\n                   id\n                 }\n                }\n              "]);

    _templateObject = function _templateObject() {
      return data;
    };

    return data;
  }

  function readFragment(cache, objectType, objectId, fragment) {
    return cache.readFragment({
      id: "".concat(objectType, ":").concat(objectId),
      fragment: fragment
    });
  }

  function writeFragment(cache, cacheItem, collectionName) {
    var objectType = cacheItem.__typename;
    var objectId = cacheItem.id;
    var dataToWrite = {
      __typename: objectType
    };
    dataToWrite[collectionName] = cacheItem[collectionName];
    cache.writeFragment({
      id: "".concat(objectType, ":").concat(objectId),
      fragment: (0, _graphqlTag.default)(_templateObject(), objectType, collectionName),
      data: dataToWrite
    });
  }

  function updatePolymorphicCollection(cache, object, collectionName) {
    var objectToUpdate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    var objectType = object.objectType,
        id = object.id;
    var readFragmentQuery = buildReadFragmentQueryForCollection(objectType, collectionName);
    var item = readFragment(cache, objectType, id, readFragmentQuery);
    if (item == null) return;

    if (objectToUpdate != null) {
      item[collectionName] = item[collectionName].filter(function (child) {
        return child.id != objectToUpdate.id;
      });
    } else {
      item[collectionName] = object[collectionName];
    }

    writeFragment(cache, item, collectionName);
    return item;
  }

  function buildReadFragmentQueryForCollection(objectType, collectionName) {
    return (0, _graphqlTag.default)(_templateObject2(), objectType, collectionName);
  }
});