define("product-management/mixins/sys-admin-settings-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.session.isSystemManager) this.transitionTo("admin.settings");
    }
  });

  _exports.default = _default;
});