define("product-management/authenticators/devise", ["exports", "ember-simple-auth/authenticators/devise", "ember-simple-auth-components/configuration"], function (_exports, _devise, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devise.default.extend({
    host: _configuration.default.host,
    serverTokenEndpoint: Ember.computed(function () {
      var host = this.host;
      return "".concat(host, "/users/sign_in");
    })
  });

  _exports.default = _default;
});