define("product-management/pods/components/painless-manufacturing/order/returns/filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aYmDWLYB",
    "block": "{\"symbols\":[\"@route\",\"@dealerView\"],\"statements\":[[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@query\"],[[32,1],[30,[36,0],null,[[\"status\"],[\"pending\"]]]]],[[\"default\"],[{\"statements\":[[2,\"Pending\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@query\"],[[32,1],[30,[36,0],null,[[\"status\"],[\"submitted\"]]]]],[[\"default\"],[{\"statements\":[[2,\"Submitted\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\n\\t\"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@query\"],[[32,1],[30,[36,0],null,[[\"status\"],[\"en_route\"]]]]],[[\"default\"],[{\"statements\":[[2,\"En Route\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\n\\t\"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@query\"],[[32,1],[30,[36,0],null,[[\"status\"],[\"received\"]]]]],[[\"default\"],[{\"statements\":[[2,\"Received\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\t\"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@query\"],[[32,1],[30,[36,0],null,[[\"status\"],[\"closed\"]]]]],[[\"default\"],[{\"statements\":[[2,\"Closed\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/order/returns/filter/template.hbs"
    }
  });

  _exports.default = _default;
});