define("product-management/pods/admin/kpis/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ixz05yvl",
    "block": "{\"symbols\":[\"Container\",\"kpis\",\"group\",\"kpi\"],\"statements\":[[8,\"app-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header-title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      KPIs\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,6],[[30,[36,5],[\"groupName\",[30,[36,4],[\"groupName\",[35,0,[\"kpiDefinitions\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"kpis/header\",[],[[\"@group\"],[[32,3]]],null],[2,\"\\n      \"],[10,\"div\"],[14,0,\"mt-5 flex bg-grey-lighter rounded flex-wrap\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"kpis/display\",[[24,0,\"\"]],[[\"@adminRoute\",\"@detailRoute\",\"@kpi\",\"@reportRoute\"],[\"admin.settings.kpis.show\",\"admin.kpis.show\",[32,4],\"admin.reports.show\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"sun-pro/profile/daily-digest/toggle\",[],[[\"@user\",\"@digestableId\",\"@digestableType\",\"@onToggle\"],[[34,0,[\"me\"]],[32,4,[\"id\"]],\"kpiDefinition\",[30,[36,1],[[32,0,[\"handleSubscriptionToggle\"]]],null]]],null],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[4]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[2,3]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"fn\",\"-track-array\",\"each\",\"sort-by\",\"group-by\",\"-each-in\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/kpis/index/template.hbs"
    }
  });

  _exports.default = _default;
});