define("product-management/pods/components/painless-manufacturing/production/status-key/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VYMFlr/l",
    "block": "{\"symbols\":[\"@status\",\"&default\"],\"statements\":[[18,2,[[30,[36,2],null,[[\"color\",\"colorBox\"],[[35,0],[30,[36,1],[\"painless-manufacturing/production/status-key/color-box\"],[[\"status\",\"color\"],[[32,1],[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"color\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/status-key/template.hbs"
    }
  });

  _exports.default = _default;
});