define("product-management/pods/components/navigation/nav-logo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QPuXuddk",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"class\",\"route\"],[\"mt-4 pl-10 navbar-brand\",[35,0]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[14,0,\"w-40 logo\"],[14,\"src\",\"/assets/images/logo.png\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"routeName\",\"link-to\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/navigation/nav-logo/template.hbs"
    }
  });

  _exports.default = _default;
});