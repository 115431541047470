define("product-management/pods/components/shop/packaging/unit-specifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jARVz8qf",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,0,[\"product\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[35,0,[\"product\",\"onlyPicked\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[35,0,[\"product\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"details\",\"productType\"],[[35,0,[\"details\"]],[35,0,[\"product\",\"productType\",\"lineItemName\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"notes\"],[[35,0,[\"notes\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"lineItem\",\"shop/notes-display-for-shipping-out\",\"custom/unit-dimensions\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/packaging/unit-specifications/template.hbs"
    }
  });

  _exports.default = _default;
});