define("product-management/pods/components/shop/input-components/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9vObsRs+",
    "block": "{\"symbols\":[\"c\",\"@onStatusUpdate\",\"@lineItem\",\"@components\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,1,[\"path\"]]],[[\"lineItem\",\"onStatusUpdate\"],[[32,3],[30,[36,0],[[32,2],[32,1,[\"path\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"component\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/input-components/template.hbs"
    }
  });

  _exports.default = _default;
});