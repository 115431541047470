define("product-management/pods/components/admin/inventory-items/table-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yRCZfRiy",
    "block": "{\"symbols\":[\"value\",\"key\"],\"statements\":[[10,\"td\"],[12],[6,[37,2],null,[[\"route\",\"model\"],[\"admin.accounting.purchasing.inventory_items.detail\",[35,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"td\"],[12],[1,[34,3]],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n  \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[35,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[1,[32,2]],[2,\": \"],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[11,\"a\"],[24,6,\"#\"],[4,[38,7],[[32,0],\"print\",[35,3],[35,0]],null],[12],[1,[30,[36,8],[\"print\"],null]],[13],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"name\",\"record\",\"link-to\",\"sku\",\"details\",\"-each-in\",\"each\",\"action\",\"fa-icon\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/admin/inventory-items/table-item/template.hbs"
    }
  });

  _exports.default = _default;
});