define("product-management/pods/admin/production-lines/show/configuration/stations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eS5bJ4sE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"w-1/2 p-4\"],[12],[2,\"\\n\\t\\t\"],[8,\"production-lines/configuration/station-list\",[],[[\"@productionLine\"],[[34,0,[\"productionLine\"]]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"w-1/2 p-4\"],[12],[2,\"\\n\\t\\t\"],[8,\"production-lines/configuration/available-stations\",[],[[\"@stations\",\"@productionLine\"],[[34,0,[\"availableStations\"]],[34,0,[\"productionLine\"]]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/production-lines/show/configuration/stations/template.hbs"
    }
  });

  _exports.default = _default;
});