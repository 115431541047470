define("product-management/pods/line-item/ability", ["exports", "product-management/pods/application/ability"], function (_exports, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var partsProductType = "Parts";
  var awningProductType = "Awning";

  var _default = _ability.default.extend({
    isStatusOrdered: Ember.computed.equal("order.status", "ordered"),
    isStatusQuoted: Ember.computed.equal("order.status", "quoted"),
    isNotOrdered: Ember.computed.not("isStatusOrdered"),
    canAddNote: Ember.computed.and("isAdmin", "isStatusOrdered"),
    canDoSystemActions: Ember.computed("isSystemManager", "order.status", function () {
      var orderStatus = this.order.get("status");
      var isSystemManager = this.isSystemManager;
      return isSystemManager && orderStatus === "complete";
    }),
    canModifyTags: Ember.computed.or("isStatusOrdered", "isStatusQuoted"),
    canSwapOrder: Ember.computed("order.status", "isDealer", "isAdmin", function () {
      var status = this.get("order.status");
      var isDealer = this.isDealer;
      var isAdmin = this.isAdmin;
      if (isDealer) return status === "quoted";else if (isAdmin) return ["quoted", "ordered"].includes(status);else return false;
    }),
    canDuplicateOrDelete: Ember.computed.alias("isNotOrdered"),
    canEditNotes: Ember.computed("order.status", function () {
      return this.get("order.status") === "quoted";
    }),
    canEdit: Ember.computed("product.productType.name", "order.status", "isDealer", "isAdmin", function () {
      var status = this.get("order.status");
      var isDealer = this.isDealer;
      var isAdmin = this.isAdmin;
      var productType = this.get("product.productType.name");
      if (status === undefined) return false;
      if (productType === partsProductType) return false;
      if (productType === awningProductType && isDealer) return false;
      return status.toLowerCase() === "quoted" && isDealer || ["ordered", "in_process", "quoted"].includes(status.toLowerCase()) && isAdmin;
    }),
    canEditQuantity: Ember.computed("order.status", "isDealer", "isAdmin", function () {
      var status = this.get("order.status");
      var isDealer = this.isDealer;
      var isAdmin = this.isAdmin;
      return status.toLowerCase() === "quoted" && isDealer || ["ordered", "in_process", "quoted"].includes(status.toLowerCase()) && isAdmin;
    })
  });

  _exports.default = _default;
});