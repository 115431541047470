define("product-management/pods/shop/packaging/detail/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    order: Ember.computed.alias("model.order"),
    lineItemSort: ["sequenceNumber"],
    lineItems: Ember.computed.sort("order.lineItems", "lineItemSort"),
    shipment: Ember.computed.alias("order.shipment"),
    dealer: Ember.computed.alias("order.dealer"),
    isReadyToShip: Ember.computed("shipment.cost", "shipment.carrier", "shipment.trackingInfo", function () {
      var carrier = this.get("shipment.carrier");
      var cost = this.get("shipment.cost");
      if (Ember.isEmpty(carrier)) return false;
      if (carrier === "Other") return true;
      return carrier === "UPS" ? Ember.isPresent(cost) : true;
    }),
    notReadyToShip: Ember.computed.not("isReadyToShip"),
    shippingCarriers: ["ABF", "UPS", "Southeastern", "Old Dominion", "Other"],
    myStickyOptions: {
      topSpacing: 0
    },
    actions: {
      clearSignature: function clearSignature(order) {
        order.set("customerSignature", null);
      },
      approveSignature: function approveSignature(order, customerSignature, printedName) {
        var _this = this;

        if (Ember.isEmpty(printedName) || Ember.isEmpty(customerSignature)) {
          alert("Please sign and enter your name");
          return;
        }

        order.applyCustomerSignature({
          printed_name: printedName,
          signature: customerSignature
        }).then(function (data) {
          _this.store.pushPayload(data);

          order.set("customerSignature", data.data.attributes.customerSignature);
        });
      },
      carrierSelected: function carrierSelected(shipment, value) {
        if (value === "Southeastern") shipment.set("cost", null);
      },
      saveShipmentInfo: function saveShipmentInfo(shipment) {
        return shipment.content.save().then(function () {
          alert("Saved!");
        });
      },
      markReadyForPickup: function markReadyForPickup(order) {
        var _this2 = this;

        order.changeState({
          event: "ready_for_pickup"
        }).then(function (data) {
          _this2.transitionToRoute("shop");
        });
      },
      markShipmentShipped: function markShipmentShipped(order, shipment) {
        var _this3 = this;

        shipment.content.save().then(function () {
          order.changeState({
            event: "shipment_complete!"
          }).then(function () {
            _this3.transitionToRoute("shop");
          });
        });
      },
      markShipped: function markShipped(order) {
        var _this4 = this;

        order.changeState({
          event: "pickup"
        }).then(function () {
          _this4.transitionToRoute("shop");
        });
      }
    }
  });

  _exports.default = _default;
});