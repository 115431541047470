define("product-management/pods/admin/transportation/delivery-zones/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GwQqsF0c",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"w-1/3 p-4\"],[12],[2,\"\\n\\t\\t\"],[8,\"ui/settings/sub-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\tCreate a new delivery zone\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[8,\"fulfillment/delivery-zones/form\",[],[[\"@deliveryZone\"],[[34,0]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/transportation/delivery-zones/new/template.hbs"
    }
  });

  _exports.default = _default;
});