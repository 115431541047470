define("product-management/pods/admin/accounting/purchasing/inventory-items/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IT+DysrY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"Add an item\"],[13],[2,\"\\n\"],[8,\"admin/inventory-items/item-form\",[],[[\"@inventoryItem\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/purchasing/inventory-items/new/template.hbs"
    }
  });

  _exports.default = _default;
});