define("product-management/pods/components/line-items/delivery-notes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "td",
    notes: Ember.computed("line-item.notes", function () {
      var notes = this.get("line-item.notes");
      return notes.map(function (item) {
        if (item.tags.includes("Deliveries")) return item;
      }).filter(function (item) {
        return item != undefined;
      });
    }),
    hasNotes: Ember.computed.notEmpty("notes"),
    classNameBindings: ["hasNotes:highlighted-delivery-note"]
  });

  _exports.default = _default;
});