define("product-management/pods/components/ui/picklist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T2O1r7tu",
    "block": "{\"symbols\":[\"option\",\"@value\",\"@onChange\",\"&default\"],\"statements\":[[6,[37,2],[[35,1,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"w-full\"],[12],[2,\"\\n    \"],[8,\"power-select\",[],[[\"@options\",\"@selected\",\"@onChange\"],[[34,0],[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,4,[[32,1]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  Loading...\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"options\",\"fetchOptions\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/picklist/template.hbs"
    }
  });

  _exports.default = _default;
});