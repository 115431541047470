define("product-management/pods/components/products/stations/sidetrack-remotes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kNK9T7JV",
    "block": "{\"symbols\":[\"details\"],\"statements\":[[10,\"h3\"],[12],[2,\"Remotes\"],[13],[2,\"\\n\"],[6,[37,0],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"table\"],[14,0,\"table table-responsive\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Motor\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Channels\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Quantity\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"details\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n\\t\"],[10,\"td\"],[12],[1,[32,1,[\"motor_name\"]]],[13],[2,\"\\n\\t\"],[10,\"td\"],[12],[1,[32,1,[\"control_remote_channels\"]]],[13],[2,\"\\n\\t\"],[10,\"td\"],[12],[1,[30,[36,0],[[32,1,[\"remote_quantity\"]],[32,1,[\"remote_quantity\"]],\"1 or See Notes\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[14,0,\"blank-slate\"],[12],[2,\"\\n  There are no remotes required for this unit.\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"lineItem\",\"with\",\"hasRemotes\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/products/stations/sidetrack-remotes/template.hbs"
    }
  });

  _exports.default = _default;
});