define("product-management/mixins/shared/status-icon-lookup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var icons = [{
    status: "in_process",
    icon: "cog",
    spin: true
  }, {
    status: "complete",
    icon: "check-circle",
    spin: false
  }, {
    status: "on_truck",
    icon: "truck",
    spin: false
  }, {
    status: "ready_for_pickup",
    icon: "user-circle",
    spin: false
  }, {
    status: "shipped",
    icon: "thumbs-up",
    spin: false
  }, {
    status: "ready",
    icon: "pause",
    spin: false
  }, {
    status: "invoiced",
    icon: "money",
    spin: false
  }];

  var _default = Ember.Mixin.create({
    iconForStatus: function iconForStatus(status) {
      return icons.find(function (item) {
        return item.status === status;
      });
    }
  });

  _exports.default = _default;
});