define("product-management/pods/application/adapter", ["exports", "@ember-data/adapter/json-api", "ember-simple-auth-components/configuration", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _jsonApi, _configuration, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:devise',
    host: _configuration.default.host,
    namespace: "api/v1",
    urlForCreateRecord: function urlForCreateRecord(type, snapshot) {
      var url = this._super.apply(this, arguments);

      return this.addIncludes(url, snapshot);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var url = this._super.apply(this, arguments);

      return this.addIncludes(url, snapshot);
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var url = this._super.apply(this, arguments);

      return this.addFields(url, snapshot);
    },
    addFields: function addFields(url, snapshot) {
      if (snapshot.adapterOptions === undefined) return url;
      if (url.indexOf("?") == -1) url += "?";
      var fields = snapshot.adapterOptions.fields;

      if (fields) {
        Object.keys(fields).forEach(function (key) {
          url += "&".concat(key, "=").concat(fields[key]);
        });
      }

      return url;
    },
    addIncludes: function addIncludes(url, snapshot) {
      if (snapshot.adapterOptions === undefined) return url;
      var includes = snapshot.adapterOptions.includes;

      if (includes) {
        url += "?include=".concat(includes);
      }

      return url;
    }
  });

  _exports.default = _default;
});