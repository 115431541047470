define("product-management/pods/dealer-admin/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "icFJrHy/",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Settings\"],null]],[2,\"\\n\"],[1,[30,[36,1],null,[[\"title\"],[\"Settings\"]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"w-1/2 mx-auto\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex text-xl mb-4 sp-sub-nav\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"dealer_admin.settings.index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Settings\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"dealer_admin.settings.tax-status\"]],[[\"default\"],[{\"statements\":[[2,\"Tax Status\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\",\"page-header\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/dealer-admin/settings/template.hbs"
    }
  });

  _exports.default = _default;
});