define("product-management/pods/components/shop/line-items/instructions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SIgSYW2L",
    "block": "{\"symbols\":[\"@header\",\"@lineItem\",\"&default\"],\"statements\":[[18,3,[[30,[36,2],null,[[\"display\",\"content\"],[[30,[36,1],[\"shop/line-items/instructions/body\"],[[\"lineItem\",\"instructions\",\"header\"],[[32,2],[35,0],[32,1]]]],[35,0]]]]]]],\"hasEval\":false,\"upvars\":[\"instructions\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/line-items/instructions/template.hbs"
    }
  });

  _exports.default = _default;
});