define("product-management/pods/components/shared/barcode-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N2hj2Rj7",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[4,[38,1],[[32,0],\"onScan\",[35,0]],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\"],[1,[30,[36,2],null,[[\"placeholder\",\"class\",\"value\",\"autofocus\",\"enter\"],[\"SCAN A BARCODE\",\"form-control input-lg\",[35,0],\"true\",[30,[36,1],[[32,0],\"onScan\",[35,0]],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"action\",\"input\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shared/barcode-input/template.hbs"
    }
  });

  _exports.default = _default;
});