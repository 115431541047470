define("product-management/pods/dealer-admin/order/complete-screen/index/controller", ["exports", "marygrove-components/mixins/complete-screen-wizard-controller"], function (_exports, _completeScreenWizardController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_completeScreenWizardController.default, {
    experience: "complete-screen",
    defaultStep: "enter-dimensions"
  });

  _exports.default = _default;
});