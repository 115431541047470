define("product-management/pods/components/shop/line-items/final-cut-station/step-1/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canMoveForward: Ember.computed.alias('isScreenVerified'),
    cannotMoveForward: Ember.computed.not('canMoveForward'),
    actions: {
      verifyScreen: function verifyScreen() {
        Ember.set(this, 'isScreenVerified', true);
      }
    }
  });

  _exports.default = _default;
});