define("product-management/pods/components/stardust/picklist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bl3ZslLE",
    "block": "{\"symbols\":[\"option\",\"@selected\",\"@onChange\",\"&default\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"handleSetup\"]],[32,0]],null],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"power-select\",[],[[\"@selected\",\"@options\",\"@onChange\"],[[32,2],[32,0,[\"list\"]],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,4,[[32,1]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n      Loading...\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"loadList\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/picklist/template.hbs"
    }
  });

  _exports.default = _default;
});