define("product-management/pods/components/sun-pro/ui/search-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TKuq5ubK",
    "block": "{\"symbols\":[\"searchResult\",\"index\",\"&default\"],\"statements\":[[11,\"form\"],[4,[38,3],[\"submit\",[30,[36,2],[[32,0,[\"handleSubmit\"]]],null]],null],[12],[2,\"\\n  \"],[11,\"input\"],[16,2,[34,8]],[24,0,\"form-control\"],[24,3,\"autocomplete-search\"],[24,\"autocomplete\",\"off\"],[24,4,\"text\"],[4,[38,9],[[32,0,[\"handleSearchInsert\"]],[32,0]],null],[4,[38,3],[\"keydown\",[30,[36,2],[[32,0,[\"handleKeydown\"]]],null]],null],[4,[38,3],[\"input\",[30,[36,2],[[32,0,[\"handleSearch\"]]],null]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,14],[[30,[36,12],[[35,13],[30,[36,12],[[30,[36,11],[[35,5]],null],[35,10,[\"isRunning\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"relative w-full\"],[12],[2,\"\\n    \"],[10,\"ul\"],[15,1,[34,4]],[14,0,\"fixed list-group h-128 overflow-y-scroll z-50\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"li\"],[16,0,[31,[\"list-group-item cursor-pointer block\\n            \",[30,[36,1],[[30,[36,0],[[32,0,[\"selectedIndex\"]],[32,2]],null],\"active\"],null]]]],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"handleSelect\"]],[32,1]],null]],null],[12],[2,\"\\n          \"],[18,3,[[32,1]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"fn\",\"on\",\"componentElementId\",\"searchResults\",\"-track-array\",\"each\",\"searchValue\",\"did-insert\",\"search\",\"not\",\"or\",\"hideResults\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/ui/search-select/template.hbs"
    }
  });

  _exports.default = _default;
});