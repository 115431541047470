define("product-management/pods/components/shop/line-items/side-track-station/component", ["exports", "product-management/mixins/shop/hardware/other-units"], function (_exports, _otherUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_otherUnits.default, {
    details: Ember.computed.alias("lineItem.details"),
    sideTrackType: Ember.computed.alias("details.side_track_type"),
    hasFourInchTrackUnits: Ember.computed.or("oldFourInch", "newFourInch"),
    oldFourInch: Ember.computed.equal("lineItem.details.side_track_type", "four_inch"),
    newFourInch: Ember.computed.equal("lineItem.details.side_track_type", "4 Inch Commercial"),
    componentName: Ember.computed("sideTrackType", function () {
      var sideTrackType = this.sideTrackType;
      if (sideTrackType === undefined) return "products/stations/sidetrack-generic";
      var viewName = sideTrackType.replace("_", "-");
      if (sideTrackType === "4 Inch Commercial") viewName = "four_inch";
      if (sideTrackType === "SA Tracks") viewName = "sa";
      return "products/stations/sidetrack-".concat(viewName.toLowerCase());
    }),
    actions: {
      isForStation: function isForStation(note) {
        return note.tags.includes("Side Track");
      }
    }
  });

  _exports.default = _default;
});