define("product-management/pods/components/orders/admin/cancel-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["orders-admin-cancel-button-container"],
    isShowingCancelConfirmation: false,
    confirmationValue: "",
    cancellationNotes: null,
    valuesMatch: Ember.computed("confirmationValue", "originalValue", function () {
      return this.confirmationValue.trim() === this.originalValue.trim().replace(/\s\s+/g, ' ');
    }),
    valuesDoNotMatch: Ember.computed.not("valuesMatch"),
    hasNotEnteredCancellationNotes: Ember.computed.empty("cancellationNotes"),
    cancellationIsNotValid: Ember.computed.or("valuesDoNotMatch", "hasNotEnteredCancellationNotes"),
    actions: {
      cancel: function cancel(cancellationNotes) {
        if (this.cancellationIsNotValid) return;
        this.attrs.onCancel(cancellationNotes);
      }
    }
  });

  _exports.default = _default;
});