define("product-management/pods/unauthorized/controller", ["exports", "shamgar/mixins/logout-controller"], function (_exports, _logoutController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_logoutController.default, {});

  _exports.default = _default;
});