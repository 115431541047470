define("product-management/pods/admin/order/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var orderId = this.paramsFor(this.routeName).order_id;
      this.transitionTo("orders.show.index", orderId);
    }
  });

  _exports.default = _default;
});