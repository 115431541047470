define("product-management/pods/components/hatch/geography/state-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r9tlgylm",
    "block": "{\"symbols\":[\"option\",\"@selected\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"handleSetup\"]],[32,0]],null],[12],[2,\"\\n\\t\"],[8,\"power-select\",[],[[\"@renderInPlace\",\"@selected\",\"@searchEnabled\",\"@searchField\",\"@onChange\",\"@options\"],[true,[32,2],true,\"name\",[30,[36,1],[[32,0,[\"handleSelect\"]]],null],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"fn\",\"states\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/hatch/geography/state-select/template.hbs"
    }
  });

  _exports.default = _default;
});