define("product-management/pods/components/production-lines/configuration/available-products/product-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hCYp79Qf",
    "block": "{\"symbols\":[\"@collection\",\"@open\",\"@onAdd\"],\"statements\":[[10,\"h4\"],[12],[2,\"\\n\\t\"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleToggleOpen\"]]],null]],null],[12],[2,\"\\n\\t\\t\"],[10,\"i\"],[15,0,[31,[\"fa fa-caret-\",[34,2]]]],[12],[13],[2,\"\\n\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\" - (\"],[1,[32,1,[\"products\",\"length\"]]],[2,\")\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[[30,[36,3],[[32,2],\"\",\"hide\"],null]]]],[12],[2,\"\\n\\t\"],[8,\"production-lines/configuration/available-products/product-list\",[],[[\"@products\",\"@onAdd\"],[[32,1,[\"products\"]],[32,3]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"caretDirection\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/production-lines/configuration/available-products/product-group/template.hbs"
    }
  });

  _exports.default = _default;
});