define("product-management/pods/components/sun-pro/accounting/delivery-billing-rules/dealers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mn1tHTOf",
    "block": "{\"symbols\":[\"search\",\"dealer\",\"@rule\"],\"statements\":[[10,\"h4\"],[12],[2,\"\\n  Dealers\\n\"],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[30,[36,5],[\"name\",[32,3,[\"dealers\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"flex items-center justify-between\"],[12],[2,\"\\n        \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.dealer.settings\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,2,[\"name\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[11,\"button\"],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"handleDelete\"]],[32,2]],null]],null],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-times-circle text-red-dark\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"block mb-2 font-semibold\"],[12],[2,\"\\n      Add a dealer\\n    \"],[13],[2,\"\\n\"],[6,[37,9],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"sun-pro/ui/search-select\",[],[[\"@searchParams\",\"@searchQuery\",\"@focused\",\"@onSelect\"],[[34,0],[34,1],[34,2],[30,[36,3],[[32,0,[\"handleDealerSelect\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Adding...\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"excludedItems\",\"searchQuery\",\"focusSearch\",\"fn\",\"on\",\"sort-by\",\"-track-array\",\"each\",\"newDealer\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/accounting/delivery-billing-rules/dealers/template.hbs"
    }
  });

  _exports.default = _default;
});