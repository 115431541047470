define("product-management/pods/unauthorized/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cuKYbTn/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-offset-4 col-md-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"well\"],[12],[2,\"\\n        \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"minus-circle\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"h2\"],[14,0,\"text-center\"],[12],[2,\"\\n          Oops, we don't know where to send you.\\n        \"],[13],[2,\"\\n        \"],[10,\"h3\"],[14,0,\"text-center\"],[12],[2,\"\\n          Looks like we can't get you logged in. Please\\n          \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"\"],[4,[38,1],[[32,0],\"logout\"],null],[12],[2,\"\\n            try again\\n          \"],[13],[2,\"\\n          . If this error continues, contact the system administrator\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"action\"]}",
    "meta": {
      "moduleName": "product-management/pods/unauthorized/template.hbs"
    }
  });

  _exports.default = _default;
});