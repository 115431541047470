define("product-management/pods/components/pm/conversation/comment-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RrPpaUV+",
    "block": "{\"symbols\":[\"@note\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"comments\",\"length\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[32,1,[\"comments\",\"length\"]]],[2,\" comments\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  No comments yet\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/conversation/comment-count/template.hbs"
    }
  });

  _exports.default = _default;
});