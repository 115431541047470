define("product-management/pods/components/pm/ui/navigate-back/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4TfXVyhv",
    "block": "{\"symbols\":[\"@route\"],\"statements\":[[6,[37,2],null,[[\"route\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"fa-icon\",[],[[\"@icon\"],[[34,0]]],null],[2,\"\\n  \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"text\",\"link-to\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/navigate-back/template.hbs"
    }
  });

  _exports.default = _default;
});