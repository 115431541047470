define("product-management/pods/components/fulfillment/delivery-zones/assignments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MeSKjc7X",
    "block": "{\"symbols\":[\"record\",\"@displayField\",\"@type\",\"@records\"],\"statements\":[[6,[37,5],[[32,4]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"font-semibold block mb-2\"],[12],[2,\"\\n\\tAssigned \"],[1,[30,[36,4],[[30,[36,3],[[32,3]],null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n\\t\\t\\t\"],[11,\"a\"],[24,0,\"block cursor-pointer\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleDestroyAssignment\"]],[32,1,[\"id\"]]],null]],null],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-times-circle text-red-dark\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,0,\"block ml-2\"],[12],[1,[30,[36,2],[[32,1],[32,2]],null]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"get\",\"pluralize\",\"capitalize\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/delivery-zones/assignments/template.hbs"
    }
  });

  _exports.default = _default;
});