define("product-management/pods/components/pm/attachments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GL2ZlcHK",
    "block": "{\"symbols\":[\"box\",\"@attachableType\",\"&default\"],\"statements\":[[11,\"div\"],[4,[38,5],[[32,0,[\"handleInsert\"]],[32,0]],null],[12],[2,\"\\n\"],[8,\"pm/ui/box\",[],[[\"@headerIcon\",\"@headerText\"],[\"paperclip\",\"Attachments\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  Loading...\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n  \"],[18,3,[[30,[36,4],null,[[\"body\",\"header\",\"uploader\"],[[30,[36,3],[\"pm/attachments/body\"],[[\"attachable\"],[[35,2]]]],[32,1,[\"header\"]],[30,[36,3],[\"pm/attachments/uploader\"],[[\"attachable\",\"attachableType\",\"onAttachmentAdded\"],[[35,2],[32,2],[30,[36,1],[[32,0],[35,0]],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"attachmentAdded\",\"action\",\"attachable\",\"component\",\"hash\",\"did-insert\",\"isLoading\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/attachments/template.hbs"
    }
  });

  _exports.default = _default;
});