define("product-management/pods/components/ui-link-to/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l+5EzGga",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"href\",\"isActive\",\"transitionTo\"],[[32,0,[\"href\"]],[32,0,[\"isActive\"]],[30,[36,0],[[32,0],\"transitionTo\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui-link-to/template.hbs"
    }
  });

  _exports.default = _default;
});