define("product-management/pods/components/pm/ui/tags/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BLpMGlLi",
    "block": "{\"symbols\":[\"tag\",\"index\",\"@onRemoveTag\",\"&default\",\"@tags\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[18,4,[[30,[36,1],null,[[\"tag\"],[[30,[36,0],[\"pm/ui/tags/tag\"],[[\"tag\",\"index\",\"onRemoveTag\"],[[32,1],[32,2],[32,3]]]]]]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/tags/list/template.hbs"
    }
  });

  _exports.default = _default;
});