define("product-management/pods/components/ui/timeline/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "44FxZ0Dr",
    "block": "{\"symbols\":[\"@eventDescription\",\"@lastItem\",\"@eventName\"],\"statements\":[[10,\"div\"],[14,0,\"relative\"],[12],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,5,\"top: 0.95em;left: 0.95em;\"],[14,0,\"-ml-px absolute mt-1  w-1 h-full bg-indigo-dark\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"items-baseline flex\"],[12],[2,\"\\n    \"],[3,\" Complete Step \"],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mr-2\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-dark rounded-full\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"h-3 w-3 bg-indigo-dark rounded-full\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"block text-xs font-semibold uppercase tracking-wide\"],[12],[2,\"\\n        \"],[1,[32,3]],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"text-sm leading-5 text-gray-500\"],[12],[2,\"\\n          \"],[1,[32,1]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/timeline/item/template.hbs"
    }
  });

  _exports.default = _default;
});