define("product-management/mixins/logout-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    actions: {
      logout: function logout() {
        Ember.set(this, "isLoggingOut", true);
        this.session.invalidate();
        document.location.href = "/";
      }
    }
  });

  _exports.default = _default;
});