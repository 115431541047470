define("product-management/pods/admin/transportation/deliveries/show/orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EzeoD9OJ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"fulfillment/deliveries/order-list\",[],[[\"@delivery\",\"@stops\",\"@stations\",\"@onOrderChange\"],[[34,0,[\"delivery\"]],[34,0,[\"delivery\",\"stops\"]],[34,0,[\"stations\"]],[30,[36,1],[[32,0,[\"handleOnOrderChange\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/transportation/deliveries/show/orders/template.hbs"
    }
  });

  _exports.default = _default;
});