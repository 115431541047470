define("product-management/components/ordering/edit/edit-controls", ["exports", "marygrove-components/components/ordering/edit/edit-controls"], function (_exports, _editControls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _editControls.default;
    }
  });
});