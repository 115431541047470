define("product-management/pods/admin/settings/matrices/index/route", ["exports", "ember-apollo-client", "product-management/gql/queries/data/matrices/list.graphql"], function (_exports, _emberApolloClient, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    apollo: (0, _emberApolloClient.queryManager)(),
    model: function model() {
      return this.apollo.watchQuery({
        query: _list.default,
        fetchPolicy: "network-only"
      }, "dataMatricesList");
    }
  });

  _exports.default = _default;
});