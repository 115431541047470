define("product-management/pods/components/sun-pro/shop/stations/current-station/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sg1jiYfd",
    "block": "{\"symbols\":[\"@currentStation\"],\"statements\":[[10,\"span\"],[14,0,\"text-2xl mr-4 p-4 font-bold\"],[12],[1,[32,1]],[2,\" Station\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex rounded shadow-md\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-white text-2xl bg-blue-dark rounded-l-lg p-4\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-white text-2xl bg-green-dark rounded-r-lg p-4\"],[12],[1,[34,1]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"completedToday\",\"goal\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/shop/stations/current-station/template.hbs"
    }
  });

  _exports.default = _default;
});