define("product-management/pods/components/deliveries/quantity-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u/FoKy/Z",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"status\",\"part_status\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"\\n      QTY:\"],[10,\"br\"],[12],[13],[2,\"\\n      \"],[1,[35,0,[\"quantity\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"queue-item\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/deliveries/quantity-display/template.hbs"
    }
  });

  _exports.default = _default;
});