define("product-management/pods/admin/settings/product-types/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lzJOxxNv",
    "block": "{\"symbols\":[\"productType\"],\"statements\":[[10,\"div\"],[14,0,\"text-sans text-base\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"painless-manufacturing/product-type/card\",[],[[\"@productType\",\"@refetchQueriesOnReorder\"],[[32,1],[32,0,[\"refetchQueries\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/product-types/index/template.hbs"
    }
  });

  _exports.default = _default;
});