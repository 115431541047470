define("product-management/pods/components/fulfillment/delivery-schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8UCSLh+e",
    "block": "{\"symbols\":[\"dates\",\"month\",\"deliveryDate\",\"@deliveryRoute\"],\"statements\":[[10,\"span\"],[14,0,\"font-semibold block mb-2\"],[12],[2,\"\\n\\tDelivery Schedule\\n\"],[13],[2,\"\\n\"],[11,\"div\"],[4,[38,3],[[32,0,[\"handleUpdate\"]],[32,0],[32,4,[\"updatedAt\"]]],null],[12],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,5],[[35,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"mb-4\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"p-1 block font-semibold bg-grey-lighter text-grey-darker\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[32,2]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,0],[[32,3],\"MM/DD/YYYY\"],null]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"-track-array\",\"each\",\"did-update\",\"deliveryDatesByMonth\",\"-each-in\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/delivery-schedule/template.hbs"
    }
  });

  _exports.default = _default;
});