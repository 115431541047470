define("product-management/pods/components/sun-pro/accounting/delivery-billing-rules/dealer-picklist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6RFomcia",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,\"for\",\"\"],[12],[2,\"\\n    Delivery Billing Rule\\n  \"],[13],[2,\"\\n\"],[6,[37,8],[[35,7,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Loading\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"power-select\",[],[[\"@options\",\"@onChange\",\"@selected\"],[[34,3],[30,[36,0],[[32,0,[\"handleChange\"]]],null],[34,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[32,1,[\"ruleType\"]],\"unit\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        -\"],[1,[30,[36,2],[[32,1,[\"rate\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      - Per\\n      \"],[1,[32,1,[\"ruleType\"]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,8],[[30,[36,10],[[35,9,[\"deliveryBillingRule\",\"id\"]],[35,4,[\"id\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"my-4 text-right\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n        Save\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"format-money\",\"options\",\"value\",\"eq\",\"unless\",\"load\",\"if\",\"dealer\",\"not-eq\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/accounting/delivery-billing-rules/dealer-picklist/template.hbs"
    }
  });

  _exports.default = _default;
});