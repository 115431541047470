define("product-management/pods/components/fulfillment/deliveries/order-list/order-items/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PLAaE0G4",
    "block": "{\"symbols\":[\"station\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"grid grid-cols-4 gap-2\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"position\",[35,0]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"w-18 flex border-\",[32,1,[\"status\",\"color\"]],\" border rounded\"]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"w-8 mr-1 bg-\",[32,1,[\"status\",\"color\"]]]]],[12],[2,\" \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"p-2 text-sm w-32\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"font-bold mb-2\"],[12],[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"text-grey\"],[12],[2,\"\\n        \"],[1,[32,1,[\"status\",\"label\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"workStatuses\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/deliveries/order-list/order-items/status/template.hbs"
    }
  });

  _exports.default = _default;
});