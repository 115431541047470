define("product-management/pods/components/wip-report/wip-filter/component", ["exports", "ember-group-by", "ember-get-config"], function (_exports, _emberGroupBy, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var productionStatusFilters = [{
    label: "In Process",
    value: "in_process"
  }, {
    label: "Complete",
    value: "complete"
  }, {
    label: "Ready for pickup",
    value: "ready_for_pickup"
  }, {
    label: "On Truck",
    value: "on_truck"
  }, {
    label: "Shipped",
    value: "shipped"
  }];
  var shippingMethods = ["Delivery", "Shipping", "Pick up"];

  var _default = Ember.Component.extend({
    selectedOrderCount: Ember.computed.alias("ordersToPrint.length"),
    orderCount: Ember.computed.alias("orders.length"),
    allSelected: Ember.computed("orderCount", "selectedOrderCount", function () {
      return this.orderCount === this.selectedOrderCount;
    }),
    selectText: Ember.computed("allSelected", function () {
      return this.allSelected ? "None" : "All";
    }),
    productionStatusFilters: productionStatusFilters,
    shippingMethods: shippingMethods,
    ordersByDealer: (0, _emberGroupBy.default)("orders", "dealerName"),
    ordersToPrint: Ember.A(),
    actions: {
      printTickets: function printTickets(ordersToPrint) {
        var host = _emberGetConfig.default.APP.host;
        location.href = "".concat(host, "/downloads?order_ids=").concat(ordersToPrint);
      },
      closePrintDialog: function closePrintDialog() {
        this.set("isShowingPrintDialog", false);
        this.set("ordersToPrint", Ember.A());
      },
      selectAll: function selectAll(orders, allSelected) {
        if (allSelected) {
          this.set("ordersToPrint", Ember.A());
        } else {
          this.set("ordersToPrint", orders.mapBy("orderId"));
        }
      },
      togglePrint: function togglePrint(orderId, ordersToPrint) {
        var match = ordersToPrint.find(function (item) {
          return item === orderId;
        });

        if (match) {
          ordersToPrint.removeObject(orderId);
        } else {
          ordersToPrint.addObject(orderId);
        }
      },
      openPrint: function openPrint() {
        this.applyFilter();
        this.set("isShowingPrintDialog", true);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      clearFilters: function clearFilters() {
        this.set("isFiltered", false);
        return this.attrs.onClearFilters();
      }
    },
    applyFilter: function applyFilter() {
      this.set("isFiltered", true);
      this.attrs.onApplyFilter();
    }
  });

  _exports.default = _default;
});