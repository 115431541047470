define("product-management/pods/admin/transportation/deliveries/show/address-recon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Za/bCppT",
    "block": "{\"symbols\":[\"canDo\",\"dealer\"],\"statements\":[[8,\"can-do\",[],[[\"@model\",\"@action\",\"@policy\"],[[34,0],\"create\",\"Delivery::ReconcileAddresses\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,1,[\"allowed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0,[\"dealersWithMultipleStops\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"deliveries/address-recon\",[],[[\"@delivery\",\"@dealer\"],[[34,0],[32,2]]],null],[2,\"\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  You do not have permission to dealer address reconciliation.\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/transportation/deliveries/show/address-recon/template.hbs"
    }
  });

  _exports.default = _default;
});