define("product-management/pods/components/fulfillment/delivery-zones/map/dealer-marker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "59A6aiDU",
    "block": "{\"symbols\":[\"M\",\"@markerComponent\",\"@marker\",\"@onToggleInfoWindow\",\"@selected\"],\"statements\":[[8,[32,2],[],[[\"@lat\",\"@lng\",\"@onClick\"],[[32,3,[\"latitude\"]],[32,3,[\"longitude\"]],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"infoWindow\"]],[],[[\"@isOpen\"],[[30,[36,0],[[32,3,[\"id\"]],[32,5]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"outline-none focus:outline-none\"]],[[\"@route\",\"@model\"],[\"admin.dealer\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,3,[\"name\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    Zipcode: \"],[1,[32,3,[\"zipcode\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"eq\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/delivery-zones/map/dealer-marker/template.hbs"
    }
  });

  _exports.default = _default;
});