define("product-management/pods/components/pm/ui/table/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g5OAd8Uo",
    "block": "{\"symbols\":[\"record\",\"index\",\"&default\",\"@records\"],\"statements\":[[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[18,3,[[32,1],[30,[36,0],null,null],[32,2]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/table/body/template.hbs"
    }
  });

  _exports.default = _default;
});