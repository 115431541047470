define("product-management/pods/components/dealers/shipping-address-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KVMoJSKB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"Ship To\"],[13],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[10,\"hr\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"highlight\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"useDefaultAddress\"],null],[12],[10,\"strong\"],[12],[2,\"Use my default address\"],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"Address\"],[13],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"placeholder\",\"class\",\"value\"],[\"Street Address\",\"form-control\",[35,3,[\"shippingAddress\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"City\"],[13],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"placeholder\",\"class\",\"value\"],[\"City\",\"form-control\",[35,3,[\"shippingCity\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"State\"],[13],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"class\",\"show-canada\",\"placeholder\",\"value\"],[\"form-control\",true,\"State\",[35,3,[\"shippingState\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Zip\"],[13],[2,\"\\n      \"],[1,[30,[36,4],null,[[\"placeholder\",\"class\",\"value\"],[\"Zip\",\"form-control\",[35,3,[\"shippingZip\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hasADefaultAddress\",\"if\",\"order\",\"input\",\"states-select\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/shipping-address-form/template.hbs"
    }
  });

  _exports.default = _default;
});