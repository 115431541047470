define("product-management/pods/components/line-items/other-unit-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    underscoredStation: Ember.computed("station", function () {
      return this.station.replace("-", "_");
    }),
    statusField: Ember.computed("underscoredStation", function () {
      return "".concat(this.underscoredStation, "_status");
    }),
    statusFieldValue: Ember.computed("statusField", "line-item.status", function () {
      return this.get("line-item.status")[this.statusField];
    }),
    isComplete: Ember.computed.equal("statusFieldValue", "complete"),
    isShowingRollTube: Ember.computed.equal("station", "roll-tube"),
    isShowingBottomBar: Ember.computed.equal("station", "bottom-bar"),
    isShowingSideTrack: Ember.computed.equal("station", "side-track")
  });

  _exports.default = _default;
});