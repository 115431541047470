define("product-management/pods/admin/transportation/deliveries/show/suggested-orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gt4ZcAZY",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[30,[36,4],[[35,3],\"other-shipping\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"fulfillment/deliveries/suggested-orders\",[],[[\"@title\",\"@orders\",\"@onMoveDelivery\"],[\"Pick Up\",[30,[36,2],[\"shippingMethod\",\"Pick up\",[35,0,[\"orders\"]]],null],[30,[36,1],[[32,0,[\"handleMoveToDelivery\"]]],null]]],null],[2,\"\\n\\n  \"],[8,\"fulfillment/deliveries/suggested-orders\",[],[[\"@title\",\"@orders\",\"@onMoveDelivery\"],[\"Shipping\",[30,[36,2],[\"shippingMethod\",\"Shipping\",[35,0,[\"orders\"]]],null],[30,[36,1],[[32,0,[\"handleMoveToDelivery\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"fulfillment/deliveries/suggested-orders\",[],[[\"@title\",\"@orders\",\"@onMoveDelivery\"],[\"From Future Deliveries\",[34,0,[\"orders\"]],[30,[36,1],[[32,0,[\"handleMoveToDelivery\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[30,[36,7],[[30,[36,6],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"fn\",\"filter-by\",\"type\",\"eq\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/transportation/deliveries/show/suggested-orders/template.hbs"
    }
  });

  _exports.default = _default;
});