define("product-management/pods/components/remote-load/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iXLIqFbS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"isRunning\",\"result\"],[[35,1,[\"isRunning\"]],[35,0]]]]]]],\"hasEval\":false,\"upvars\":[\"result\",\"fetch\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/remote-load/template.hbs"
    }
  });

  _exports.default = _default;
});