define("product-management/pods/components/password-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nhlAQEzt",
    "block": "{\"symbols\":[\"component\",\"state\"],\"statements\":[[11,\"form\"],[4,[38,5],[[32,0],\"submit\",[35,4]],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[1,[30,[36,6],null,[[\"changeset\",\"label-text\",\"placeholder\",\"propertyName\",\"labelText\",\"type\"],[[35,4],\"Enter a password\",\"Enter a password\",\"password\",\"Please create a password below:\",\"password\"]]]],[2,\"\\n\\n  \"],[1,[30,[36,6],null,[[\"label-text\",\"placeholder\",\"changeset\",\"propertyName\",\"labelText\",\"type\"],[\"Enter the password again\",\"Enter the password again\",[35,4],\"passwordConfirmation\",\"Please confirm your password:\",\"password\"]]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"sign_in_container\"],[12],[2,\"\\n\"],[6,[37,7],null,[[\"action\",\"class\"],[[30,[36,5],[[32,0],\"submit\",[35,4]],null],\"btn btn-success\"]],[[\"default\"],[{\"statements\":[[6,[37,3],[[32,2,[\"isDefault\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t  \"],[1,[30,[36,0],[\"check-circle\"],null]],[2,\" \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[32,2,[\"isPending\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t  \"],[1,[30,[36,0],[\"spinner\"],[[\"spin\"],[true]]]],[2,\" \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[32,2,[\"isFulfilled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t  \"],[1,[30,[36,0],[\"check-circle\"],null]],[2,\" \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"buttonText\",\"pendingButtonText\",\"if\",\"changeset\",\"action\",\"validated-input\",\"async-button\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/password-form/template.hbs"
    }
  });

  _exports.default = _default;
});