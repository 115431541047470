define("product-management/pods/components/pm/ui/box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X948w7mR",
    "block": "{\"symbols\":[\"@headerText\",\"@headerIcon\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"mb-4 border-2 rounded\"],[12],[2,\"\\n  \"],[18,3,[[30,[36,1],null,[[\"header\"],[[30,[36,0],[\"pm/ui/box/header\"],[[\"headerIcon\",\"headerText\"],[[32,2],[32,1]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/box/template.hbs"
    }
  });

  _exports.default = _default;
});