define("product-management/pods/components/custom/shop/cut-sheet/interior-unit/component", ["exports", "product-management/mixins/custom/shop/cut-sheet-title"], function (_exports, _cutSheetTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_cutSheetTitle.default, {
    details: Ember.computed.alias("lineItem.details"),
    notes: Ember.computed.alias("lineItem.notes"),
    title: Ember.computed.alias("lineItem.cutSheetTitle"),
    sequenceNumber: Ember.computed.alias("lineItem.sequenceNumber"),
    recessedSideTrack: Ember.computed.equal('details.side_track_type', 'recessed')
  });

  _exports.default = _default;
});