define("product-management/pods/components/kpis/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mvMX209m",
    "block": "{\"symbols\":[\"@group\"],\"statements\":[[10,\"h3\"],[14,0,\"text-xl leading-6 font-medium text-grey-darkest flex\"],[12],[2,\"\\n\"],[6,[37,3],[[35,4,[\"systemManager\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"span\"],[14,0,\"mr-2\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleGroupRefresh\"]]],null]],null],[12],[2,\"\\n\\n\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-refresh\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[10,\"span\"],[12],[2,\"\\n\\t\\t\"],[1,[32,1]],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"recalculate\",\"if\",\"session\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/kpis/header/template.hbs"
    }
  });

  _exports.default = _default;
});