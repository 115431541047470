define("product-management/mixins/shop/previous-checkout-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    previouslyCheckedOut: Ember.computed("lineItem.status", "station", function () {
      var status = this.get('lineItem.status');
      var station = this.station;
      return Ember.isPresent(status["".concat(station, "_checked_out_at")]);
    })
  });

  _exports.default = _default;
});