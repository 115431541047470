define("product-management/pods/components/pricing/size-matrix-width-only-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2r1IEg4N",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Width\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"labelText\",\"value\"],[\"min\",[35,0,[\"min_width\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"labelText\",\"value\"],[\"max\",[35,0,[\"max_width\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pricing\",\"wrapped-input\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pricing/size-matrix-width-only-settings/template.hbs"
    }
  });

  _exports.default = _default;
});