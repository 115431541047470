define("product-management/initializers/setup-ember-can", ["exports", "ember-can/initializers/setup-ember-can"], function (_exports, _setupEmberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _setupEmberCan.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _setupEmberCan.initialize;
    }
  });
});