define("product-management/pods/components/accounting/invoicing/email-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dealer: Ember.computed.alias("invoice.dealer"),
    billingEmail: Ember.computed.alias("dealer.billingEmail"),
    emailMessage: Ember.computed("invoice", function () {
      var invoice = this.invoice;
      var dear = "";
      var dealer = invoice.get("dealer");
      var billingName = dealer.get("billingName");
      dear = "Customer";
      return "\nDear ".concat(dear, ":\n\nYour invoice ").concat(invoice.get('number'), " is attached.\n\nThank you for your business!\n    ").trim();
    }),
    emailSubject: Ember.computed("invoice.number", function () {
      return "[SunPro Invoice #".concat(this.get('invoice.number'), "]");
    })
  });

  _exports.default = _default;
});