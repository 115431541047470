define("product-management/pods/components/audit-trail-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c7C05O+N",
    "block": "{\"symbols\":[],\"statements\":[[10,\"strong\"],[12],[1,[30,[36,3],[[35,2]],null]],[13],[2,\":\\n\"],[6,[37,6],[[30,[36,5],[[35,4],\"at\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,0],\"MM/DD/YYYY [at] h:mma\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"displayValue\",\"moment-format\",\"keySuffixDisplay\",\"humanize\",\"key-suffix\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/audit-trail-item/template.hbs"
    }
  });

  _exports.default = _default;
});