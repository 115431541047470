define("product-management/pods/components/pm/ui/date-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n+nUs96F",
    "block": "{\"symbols\":[\"@minDate\"],\"statements\":[[8,\"pikaday-input\",[],[[\"@value\",\"@onSelection\",\"@useUtc\",\"@firstDay\",\"@format\",\"@class\",\"@readonly\",\"@minDate\"],[[34,0],[30,[36,2],[[32,0],[35,1]],null],true,0,\"MM/DD/YYYY\",\"form-control bg-white-important\",\"readonly\",[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"setDate\",\"action\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/date-picker/template.hbs"
    }
  });

  _exports.default = _default;
});