define("product-management/pods/dealer-admin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z/sIc1j8",
    "block": "{\"symbols\":[],\"statements\":[[8,\"dealers/pending-orders-notice\",[],[[],[]],null],[2,\"\\n\"],[1,[30,[36,0],[\"My SunPro\"],null]],[2,\"\\n\"],[8,\"navigation/dealer-nav\",[],[[\"@dealer\",\"@on-search-result-selected\",\"@on-logout\",\"@on-start-new-order\"],[[34,1,[\"dealer\"]],[30,[36,2],[[32,0],\"searchResultSelected\"],null],[30,[36,2],[[32,0],\"logout\"],null],[30,[36,2],[[32,0],\"onStart\"],null]]],null],[2,\"\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"model\",\"action\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/dealer-admin/template.hbs"
    }
  });

  _exports.default = _default;
});