define("product-management/pods/components/pm/ui/modal/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M1LbcdkN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"absolute pin-b pin-l w-full mb-4 p-6\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/modal/footer/template.hbs"
    }
  });

  _exports.default = _default;
});