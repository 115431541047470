define("product-management/pods/components/stations/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nuanh0UY",
    "block": "{\"symbols\":[\"@station\"],\"statements\":[[8,\"stations/configuration\",[],[[\"@station\",\"@onUpdate\"],[[32,1],[30,[36,0],[[32,0,[\"handleUpdateConfig\"]]],null]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"Key\"],[13],[2,\"\\n\\t\"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[32,1,[\"key\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-right mt-4\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n\\t\\tSave\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stations/form/template.hbs"
    }
  });

  _exports.default = _default;
});