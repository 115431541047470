define("product-management/pods/components/accounting/invoicing/email-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s1FQAEXX",
    "block": "{\"symbols\":[\"@on-download\",\"@on-close\",\"@on-send-email\"],\"statements\":[[6,[37,6],null,[[\"title\",\"action-button-default-text\",\"action-button-fulfilled-text\",\"action-button-pending-text\",\"subject\",\"message\",\"model\",\"to\",\"cc\",\"on-send-email\",\"on-close\"],[\"Send Invoice\",\"Send Invoice\",\"Invoice Sent!\",\"Sending...\",[35,5],[35,4],[35,0],[35,3],\"\",[32,3],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-center download-invoice-email\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"text-center\"],[12],[2,\"Invoice\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,5,\"margin-bottom: 15px;\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],[32,1],[35,0]],null],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/images/invoice.png\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"massive ui massive-button button\"],[4,[38,1],[[32,0],[32,1],[35,0]],null],[12],[1,[30,[36,2],[\"download\"],null]],[2,\" View Invoice\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"invoice\",\"action\",\"fa-icon\",\"billingEmail\",\"emailMessage\",\"emailSubject\",\"email-modal\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/accounting/invoicing/email-modal/template.hbs"
    }
  });

  _exports.default = _default;
});