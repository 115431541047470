define("product-management/pods/admin/accounting/settings/deliveries/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QJkJkOjB",
    "block": "{\"symbols\":[\"rule\"],\"statements\":[[10,\"h3\"],[12],[2,\"\\n  Delivery Billing Rules\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-right my-6\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"admin.accounting.settings.deliveries.new\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-plus-circle\"],[12],[13],[2,\" Add Rule\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"table\"],[14,0,\"table table-striped table-condensed table-bordered\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Name\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Rate\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Rule Type\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.accounting.settings.deliveries.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[[32,1,[\"rate\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          Per\\n          \"],[1,[32,1,[\"ruleType\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"format-money\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/settings/deliveries/index/template.hbs"
    }
  });

  _exports.default = _default;
});