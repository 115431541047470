define("product-management/pods/components/painless-manufacturing/line-item/wizard/returnable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RXkXamIZ",
    "block": "{\"symbols\":[\"product\",\"@onSelect\"],\"statements\":[[8,\"painless-manufacturing/line-item/wizard/header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\nSelect a Product\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mt-12\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flex flex-wrap\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"products\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"w-1/4\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"painless-manufacturing/line-item/wizard/featured-product\",[],[[\"@product\",\"@onSelect\"],[[32,1],[30,[36,0],[[32,2]],null]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/line-item/wizard/returnable/template.hbs"
    }
  });

  _exports.default = _default;
});