define("product-management/pods/components/purchasing/critical-inventory/order-point-warning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L214LUKv",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[11,\"div\"],[24,0,\"rounded bg-red font-bold text-white p-4 w-full text-2xl\"],[17,1],[12],[2,\"\\n\\t\"],[10,\"i\"],[14,0,\"fa fa-exclamation-triangle\"],[12],[13],[2,\"\\n\\tThis item is at or below the Order Point!\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"belowOrderPoint\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/critical-inventory/order-point-warning/template.hbs"
    }
  });

  _exports.default = _default;
});