define("product-management/pods/components/ordering/select-experience-link-to-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    "base-route": null,
    experiences: Ember.computed("", function () {
      return [{
        text: "Complete Unit",
        "image-name": "complete-screen",
        route: "".concat(this["base-route"], ".complete_screen")
      }, {
        text: "Screen Only",
        "image-class": "img-circle",
        "image-name": "screen-roll",
        route: "".concat(this["base-route"], ".screen_only")
      }, {
        text: "Parts",
        "image-name": "parts",
        route: "".concat(this["base-route"], ".select_part")
      }, {
        text: "Interior Unit",
        "image-name": "interior-unit",
        route: "".concat(this["base-route"], ".interior_unit")
      }, {
        text: "Retractable Awning",
        "image-name": "awning",
        route: "".concat(this["base-route"], ".awning")
      }];
    })
  });

  _exports.default = _default;
});