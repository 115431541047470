define("product-management/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xYDwYwb3",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[35,0],\"production\"],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"w-full bg-red fixed top-0 text-white font-bold text-center\"],[14,5,\"z-index:9999;\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[35,0]],null]],[2,\" ENVIRONMENT\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[30,[36,5],[[30,[36,4],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"environment\",\"uppercase\",\"not-eq\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/templates/application.hbs"
    }
  });

  _exports.default = _default;
});