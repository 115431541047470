define("product-management/helpers/is-active-swatch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isActiveSwatch = isActiveSwatch;
  _exports.default = void 0;

  function isActiveSwatch(params, hash) {
    var id = hash.id,
        name = hash.name,
        color = hash.color,
        selectedScreen = hash.selectedScreen;
    return selectedScreen === "".concat(id, "_").concat(name, "_").concat(color);
  }

  var _default = Ember.Helper.helper(isActiveSwatch);

  _exports.default = _default;
});