define("product-management/pods/components/accounting/sales-tax/certificates/pending/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ra5FTYL5",
    "block": "{\"symbols\":[\"record\",\"@records\"],\"statements\":[[10,\"table\"],[14,0,\"table table-striped table-bordered table-condensed\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"th\"],[12],[2,\"Dealer\"],[13],[2,\"\\n            \"],[10,\"th\"],[12],[2,\"Tax Exempt\"],[13],[2,\"\\n            \"],[10,\"th\"],[12],[2,\"Submited By\"],[13],[2,\"\\n            \"],[10,\"th\"],[12],[2,\"Submitted At\"],[13],[2,\"\\n            \"],[10,\"th\"],[14,\"colspan\",\"2\"],[12],[2,\"\\n            \"],[13],[2,\"\\n\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[30,[36,0],[\"dealer.name\",[32,2]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"accounting/sales-tax/certificates/pending/row\",[],[[\"@record\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/accounting/sales-tax/certificates/pending/template.hbs"
    }
  });

  _exports.default = _default;
});