define("product-management/pods/components/shop-codes/new-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isValidated: false,
    isValid: Ember.computed.and("new-shop-code.isValid", "isValidated"),
    isNotValid: Ember.computed.not("isValid"),
    actions: {
      validate: function validate() {
        var newShopCode = this["new-shop-code"];
        newShopCode.validate();
        this.set("isValidated", true);
      }
    }
  });

  _exports.default = _default;
});