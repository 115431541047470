define("product-management/pods/components/pm/order/work-queue/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "deP38SSx",
    "block": "{\"symbols\":[\"@order\"],\"statements\":[[6,[37,9],[[32,1,[\"workQueue\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"sub-header\"],[12],[2,\"Work Queue\"],[13],[2,\"\\n\\n\"],[6,[37,9],[[30,[36,8],[\"changeWorkQueue order\",[32,1]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[6,[37,0],null,[[\"route\",\"model\"],[\"admin.wip.queues.detail\",[32,1,[\"workQueue\",\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"workQueue\",\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[11,\"button\"],[24,0,\"change-button hover:bg-red-dark\"],[4,[38,4],[[32,0],\"toggleChangeWorkQueue\"],null],[12],[2,\"Change\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n\\t\"],[8,\"work-queues/pick-list\",[],[[\"@onChange\",\"@selection\"],[[30,[36,1],[[32,0,[\"handleWorkQueueChange\"]]],null],[34,2]]],null],[2,\"\\n\\n\\t\\t\"],[11,\"button\"],[24,0,\"btn btn-xs btn-danger\"],[4,[38,4],[[32,0],[35,3]],null],[12],[2,\"Save\"],[13],[2,\" \\n\\t\\tor \"],[11,\"a\"],[24,6,\"#\"],[4,[38,4],[[32,0],[35,5]],null],[12],[2,\"cancel\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[6,[37,0],null,[[\"route\",\"model\"],[\"admin.wip.queues.detail\",[32,1,[\"workQueue\",\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"workQueue\",\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"fn\",\"newWorkQueue\",\"saveWorkQueue\",\"action\",\"toggleChangeWorkQueue\",\"isChangingWorkQueue\",\"unless\",\"can\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/order/work-queue/template.hbs"
    }
  });

  _exports.default = _default;
});