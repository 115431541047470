define("product-management/pods/components/tailwind-ui/lists/narrow/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VU/Hgo9/",
    "block": "{\"symbols\":[\"&default\",\"@title\",\"@subtitle\"],\"statements\":[[10,\"li\"],[14,0,\"py-4 flex items-center\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"mr-2\"],[12],[2,\"\\n\\t\\t\"],[18,1,null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flex flex-col\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"leading-5 font-medium text-grey-darkest\"],[12],[1,[32,2]],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"leading-5 text-grey\"],[12],[1,[32,3]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/tailwind-ui/lists/narrow/item/template.hbs"
    }
  });

  _exports.default = _default;
});