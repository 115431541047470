define("product-management/pods/components/orders/simple-dialog-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dP0EhSc1",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,6,\"#\"],[4,[38,2],[[32,0],[30,[36,1],[[35,0]],null],true],null],[12],[2,\"\\n  \"],[1,[35,3,[\"dealerReference\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,5],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,4],null,[[\"order\",\"on-cancel\"],[[35,3],[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null],false],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isShowingDialog\",\"mut\",\"action\",\"order\",\"orders/simple-dialog\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/orders/simple-dialog-trigger/template.hbs"
    }
  });

  _exports.default = _default;
});