define("product-management/pods/product-type/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    dealerDiscounts: (0, _model.hasMany)(),
    products: (0, _model.hasMany)(),
    componentName: Ember.computed("name", function () {
      return this.name.toLowerCase().dasherize();
    }),
    defaultRoute: (0, _model.attr)(),
    lineItemName: Ember.computed("name", function () {
      return this.name === "Complete Screens" ? "Complete Unit" : this.name;
    })
  });

  _exports.default = _default;
});