define("product-management/pods/login/controller", ["exports", "ember-simple-auth/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routeAfterAuthentication: Ember.computed(function () {
      return _configuration.default.routeAfterAuthentication;
    }),
    application: Ember.inject.controller("application"),
    session: Ember.inject.service(),
    isLoggingIn: Ember.computed.alias("application.isLoggingIn"),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        Ember.set(this, "application.isLoggingIn", true);
        var transition = Ember.get(this, "session.attemptedTransition");
        var identification = this.identification,
            password = this.password;
        this.session.authenticate('authenticator:devise', identification, password).then(function () {
          _this.set("application.isLoggingIn", false);

          _this.set("errorMessage", null);

          transition == null ? _this.transitionToRoute(_this.routeAfterAuthentication) : transition.retry();
        }).catch(function (reason) {
          _this.set("application.isLoggingIn", false);

          _this.set("errorMessage", "Invalid username or password!");

          _this.set("password", "");
        });
      }
    }
  });

  _exports.default = _default;
});