define("product-management/pods/components/pm/ui/table/header/column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JB/cq1Ft",
    "block": "{\"symbols\":[\"@class\",\"&default\"],\"statements\":[[10,\"th\"],[15,0,[32,1]],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/table/header/column/template.hbs"
    }
  });

  _exports.default = _default;
});