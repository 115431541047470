define("product-management/pods/components/painless-manufacturing/inputs/hint/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g34lbdAQ",
    "block": "{\"symbols\":[\"@field\"],\"statements\":[[6,[37,0],[[32,1,[\"hint\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"my-2 bg-yellow-lighter font-semibold p-2 rounded text-grey-darker\"],[12],[2,\"\\n    \"],[2,[32,1,[\"hint\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/inputs/hint/template.hbs"
    }
  });

  _exports.default = _default;
});