define("product-management/pods/partners/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bijpUjAq",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"SunPro Partner Portal\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"w-full fixed top-0\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex justify-between items-center bg-grey-lighter p-4 border-b border-grey-dark\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n      \"],[10,\"a\"],[14,6,\"/\"],[14,0,\"block mt-4\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,0,\"w-40 logo\"],[14,\"src\",\"/assets/images/logo.png\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"a\"],[24,0,\"text-lg ml-6 cursor-pointer\"],[24,6,\"#\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleLogout\"]]],null]],null],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"sign-out text-xl text-grey-dark hover:text-grey-darker stroke-current\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"p-8\"],[12],[2,\"\\n  \"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\",\"fn\",\"on\",\"fa-icon\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/partners/template.hbs"
    }
  });

  _exports.default = _default;
});