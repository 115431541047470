define("product-management/pods/components/pricing/size-matrix-height-only/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+zvKTrNs",
    "block": "{\"symbols\":[\"height\",\"height\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"well\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"\\n      Matrix Settings\\n      \"],[13],[2,\"\\n      \"],[10,\"dl\"],[12],[2,\"\\n      \\t\"],[10,\"dt\"],[12],[2,\"\\n      \\tHeight\\n      \\t\"],[13],[2,\"\\n      \\t\"],[10,\"dd\"],[12],[2,\"\\n      \\t\"],[1,[34,3]],[2,\" - \"],[1,[34,4]],[2,\"\\n      \\t\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"table-responsive\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"Height Matrix\"],[13],[2,\"\\n  \"],[10,\"table\"],[14,0,\"table table-condensed table-bordered table-striped table-responsive price-matrix-table\"],[12],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"td\"],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"td\"],[12],[2,\"\\n\\t  \"],[1,[30,[36,2],null,[[\"onKeyDown\",\"height\",\"sizes\"],[[30,[36,1],[[32,0],\"keyDown\"],null],[32,1],[35,0]]]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sizes\",\"action\",\"pricing/size-matrix-item\",\"minHeight\",\"maxHeight\",\"heights\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pricing/size-matrix-height-only/template.hbs"
    }
  });

  _exports.default = _default;
});