define("product-management/components/quill/toolbar/button-or-select", ["exports", "@cloudcreativity/ember-quill/components/quill/toolbar/button-or-select"], function (_exports, _buttonOrSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _buttonOrSelect.default;
    }
  });
});