define("product-management/pods/components/sun-pro/profile/daily-digest/toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kTESQ2Ry",
    "block": "{\"symbols\":[\"&attrs\",\"@user\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,4],[[35,3,[\"isRunning\"]]],null],[32,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"i\"],[16,0,[31,[\"cursor-pointer fa \",[34,0]]]],[17,1],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleClick\"]]],null]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"iconClasses\",\"fn\",\"on\",\"loadUser\",\"not\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/profile/daily-digest/toggle/template.hbs"
    }
  });

  _exports.default = _default;
});