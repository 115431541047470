define("product-management/templates/resource-cards/customer-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8iU+rKZ0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"\\n  Add a new customer\\n\"],[13],[2,\"\\n\"],[8,\"wrapped-input\",[],[[\"@autoFocus\",\"@value\",\"@labelText\",\"@placeholder\"],[true,[34,0,[\"name\"]],\"Name\",\"Enter a name...\"]],null],[2,\"\\n\\n\"],[8,\"wrapped-input\",[],[[\"@value\",\"@labelText\",\"@placeholder\"],[[34,0,[\"phone\"]],\"Phone\",\"Enter a phone number...\"]],null],[2,\"\\n\"],[8,\"wrapped-input\",[],[[\"@value\",\"@labelText\",\"@placeholder\"],[[34,0,[\"email\"]],\"Email\",\"Enter an email address...\"]],null],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[8,\"wrapped-input\",[],[[\"@value\",\"@labelText\",\"@placeholder\"],[[34,0,[\"address\"]],\"Address\",\"Enter a street address...\"]],null],[2,\"\\n\"],[8,\"wrapped-input\",[],[[\"@value\",\"@labelText\",\"@placeholder\"],[[34,0,[\"city\"]],\"City\",\"Enter a city...\"]],null],[2,\"\\n\"],[8,\"wrapped-input\",[],[[\"@value\",\"@labelText\",\"@placeholder\"],[[34,0,[\"zip\"]],\"Zip\",\"Enter a zip code...\"]],null],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"resource\"]}",
    "meta": {
      "moduleName": "product-management/templates/resource-cards/customer-new.hbs"
    }
  });

  _exports.default = _default;
});