define("product-management/pods/admin/accounting/settings/deliveries/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e/l0WVaY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"\\n  Add Delivery Rule:\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"w-1/2 p-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n    \"],[8,\"sun-pro/accounting/delivery-billing-rules/form\",[],[[\"@rule\"],[[34,0]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/settings/deliveries/new/template.hbs"
    }
  });

  _exports.default = _default;
});