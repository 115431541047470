define("product-management/models/shop-code", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    firstName: (0, _model.attr)(),
    lastName: (0, _model.attr)(),
    code: (0, _model.attr)(),
    active: (0, _model.attr)(),
    regenerate: (0, _emberApiActions.memberAction)({
      path: "regenerate",
      type: "patch"
    }),
    toggleStatus: (0, _emberApiActions.memberAction)({
      path: "toggle_status",
      type: "patch"
    }),
    validate: (0, _emberApiActions.memberAction)({
      path: "validate",
      type: "post"
    })
  });

  _exports.default = _default;
});