define("product-management/utils/download-file", ["exports", "downloadjs"], function (_exports, _downloadjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.downloadFile = downloadFile;
  _exports.downloadFile2 = downloadFile2;
  var fileTypes = {
    "pdf": "application/pdf",
    "csv": "application/csv"
  };

  function downloadFile(url, fileName, session, fileType) {
    var ajax = new XMLHttpRequest();
    ajax.open('GET', url);
    session.authorize("authorizer:devise", function (headerName, headerValue) {
      ajax.setRequestHeader(headerName, headerValue);
    });
    ajax.responseType = 'blob';

    ajax.onload = function (e) {
      (0, _downloadjs.default)(e.target.response, fileName, fileTypes[fileType.toLowerCase()]);
    };

    return ajax.send();
  }

  function downloadFile2(url, fileName, session) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var ajax = new XMLHttpRequest();
      ajax.open('GET', url);
      var token = session.get('data.authenticated.token');
      ajax.setRequestHeader("Authorization", "Bearer ".concat(token));
      ajax.responseType = 'blob';

      ajax.onload = function (e) {
        if (this.status === 500) {
          reject();
        } else {
          (0, _downloadjs.default)(e.target.response, fileName, 'application/pdf');
          resolve();
        }
      };

      ajax.onerror = function (e) {
        reject();
      };

      ajax.send();
    });
  }
});