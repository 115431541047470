define("product-management/pods/components/pm/ui/code-editor/editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaultLines = 40;

  var _default = Ember.Component.extend({
    editorLines: Ember.computed("lines", function () {
      return Ember.isEmpty(this.lines) ? defaultLines : this.lines;
    }),
    valueForEditor: Ember.computed("value", function () {
      if (Ember.isEmpty(this.value)) return "";
      return this.value;
    }),
    actions: {
      updateEditorValue: function updateEditorValue(value) {
        this.set("editorValue", value);
        if (this.onUpdate != undefined) this.onUpdate(value);
      }
    }
  });

  _exports.default = _default;
});