define("product-management/pods/admin/production-lines/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3cdIghbh",
    "block": "{\"symbols\":[\"Container\",\"productionLine\"],\"statements\":[[1,[30,[36,0],[\"WIP\"],null]],[2,\"\\n\\n\"],[8,\"app-container\",[],[[\"@fluid\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header-title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      Production Lines\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"border-b border-grey-lighter\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"flex justify-between\"],[12],[2,\"\\n        \"],[10,\"nav\"],[14,0,\"-mb-px flex\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"link-to\",[[24,0,\"sublink whitespace-no-wrap ml-8 py-4 px-1 font-medium text-sm leading-5 text-indigo focus:outline-none focus:text-indigo-darker focus:border-indigo-dark hover:no-underline focus:no-underline\"]],[[\"@route\",\"@model\"],[\"admin.production-lines.show\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,2,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"model\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/production-lines/template.hbs"
    }
  });

  _exports.default = _default;
});