define("product-management/pods/components/pm/ui/select-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ItI9XvtP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"button\"],[15,\"onclick\",[30,[36,1],[[32,0],[35,0]],null]],[15,0,[31,[\"p-2 text-center \",[34,3],\" \",[34,2]]]],[12],[2,\"\\n  \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"check-circle\"]],null],[2,\"\\n\"],[6,[37,4],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"select\",\"action\",\"iconClass\",\"iconSize\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/select-button/template.hbs"
    }
  });

  _exports.default = _default;
});