define("product-management/mixins/needs-station-cookies-route", ["exports", "product-management/utils/constants", "js-cookie"], function (_exports, _constants, _jsCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var deliveriesStation = "Deliveries";
  var packagingStation = "Packaging";
  var pickupStation = "Pickup";

  var _default = Ember.Mixin.create({
    deliveriesStation: deliveriesStation,
    packagingStation: packagingStation,
    stationCookieName: _constants.stationCookieName,
    workQueueCookieName: _constants.workQueueCookieName,
    workQueueNameCookieName: _constants.workQueueNameCookieName,
    productionLineCookieName: _constants.productionLineCookieName,
    beforeModel: function beforeModel() {
      var station = _jsCookie.default.get(this.stationCookieName);

      if (station === undefined) this.transitionTo("shop.pick_station");
      if (station === deliveriesStation) this.transitionTo("shop.deliveries");
      if (station === packagingStation) this.transitionTo("shop.packaging");
      if (station === pickupStation) this.transitionTo("shop.pickup");

      var workQueue = _jsCookie.default.get(this.workQueueCookieName);

      var workQueueName = _jsCookie.default.get(this.workQueueNameCookieName);

      var productionLine = _jsCookie.default.get(this.productionLineCookieName);

      if ((workQueue === undefined || workQueueName === undefined) && productionLine === undefined) this.transitionTo("shop.pick_work_queue");
      Ember.set(this, "station", station);
      Ember.set(this, "workQueue", workQueue);
      Ember.set(this, "workQueueName", workQueueName);
      Ember.set(this, "productionLine", productionLine);
    }
  });

  _exports.default = _default;
});