define("product-management/pods/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hTNGbhUD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"spinner\"],[[\"spin\"],[true]]]],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    Loading...\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/loading/template.hbs"
    }
  });

  _exports.default = _default;
});