define("product-management/pods/components/shared/select-all-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isChecked: Ember.computed("collection", "itemId", "collection.length", function () {
      var _this = this;

      return this.collection.find(function (item) {
        return item === _this.itemId;
      }) != undefined;
    }),
    tagName: "",
    actions: {
      toggleItem: function toggleItem(itemId, isChecked, collection) {
        if (isChecked) {
          collection.removeObject(itemId);
        } else {
          collection.addObject(itemId);
        }
      }
    }
  });

  _exports.default = _default;
});