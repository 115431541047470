define("product-management/pods/components/painless-manufacturing/production/production-line/advanced-configuration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZnffngRe",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-ace\",[],[[\"@lines\",\"@value\",\"@update\",\"@enableDefaultAutocompletion\",\"@useWrapMode\",\"@useWorker\",\"@useSoftTabs\",\"@tabSize\",\"@newLineMode\",\"@mode\",\"@editorClass\"],[\"20\",[32,0,[\"configuration\"]],[30,[36,0],[[32,0,[\"handleEditorUpdate\"]]],null],true,true,true,true,2,\"auto\",\"ace/mode/ruby\",\"text-sm\"]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"mt-4 text-right\"],[12],[2,\"\\n\\t\"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"Save\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/production-line/advanced-configuration/template.hbs"
    }
  });

  _exports.default = _default;
});