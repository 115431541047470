define("product-management/pods/shop/packaging/index/controller", ["exports", "product-management/mixins/shared/web-socket-controller"], function (_exports, _webSocketController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_webSocketController.default, {
    pickups: Ember.computed.alias("model.pickups"),
    shippings: Ember.computed.alias("model.shippings"),
    currentWorkQueueName: Ember.computed.alias("model.currentWorkQueueName"),
    currentWorkQueueId: Ember.computed.alias("model.currentWorkQueueId"),
    currentStation: Ember.computed.alias("model.station"),
    pickupList: Ember.computed("pickups", "poFilter", function () {
      var poFilter = this.poFilter;
      var collection = this.pickups;

      if (!Ember.isEmpty(poFilter)) {
        poFilter = poFilter.toLowerCase();
        collection = collection.filter(function (pickup) {
          return pickup.dealerReference.toLowerCase().includes(poFilter);
        });
      }

      return collection.sortBy("dealer.name");
    }),
    refreshResults: function refreshResults() {
      this.getPickups();
      this.getShippings();
    },
    getPickups: function getPickups() {
      return this.store.query("order", {
        "filter[by_shipping_method]": "Pick up",
        "filter[ready_for_pickup]": true,
        include: "line-items.product.product-type,dealer",
        "fields[orders]": "dealer-reference,line-items,dealer,shipping-method",
        "fields[line-items]": "order,details,product",
        "fields[dealers]": "name,orders"
      });
    }
  });

  _exports.default = _default;
});