define("product-management/pods/components/pricing/price-per-square-foot-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jSZnvDuS",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[4,[38,2],[[32,0],\"save\",[35,1],[35,0]],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Screen Type\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"value\"],[[35,3],\"content\",\"content.name\",[35,0,[\"screen\"]]]]]],[2,\"\\n\\n  \"],[1,[30,[36,5],null,[[\"value\",\"labelText\"],[[35,0,[\"minimum_square_feet\"]],\"Minimum Square Feet\"]]]],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"value\",\"labelText\"],[[35,0,[\"price_per_square_foot\"]],\"Price per Sq Ft.\"]]]],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"value\",\"labelText\"],[[35,0,[\"oversized_price_per_square_foot\"]],\"Oversized Price per Sq Ft.\"]]]],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"value\",\"labelText\"],[[35,0,[\"oversized_height\"]],\"Oversized Height\"]]]],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"value\",\"labelText\"],[[35,0,[\"modifier_value\"]],\"Modifier Value\"]]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[14,4,\"submit\"],[12],[2,\"\\n      Add\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"option\",\"product\",\"action\",\"availableScreens\",\"ember-selectize\",\"wrapped-input\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pricing/price-per-square-foot-form/template.hbs"
    }
  });

  _exports.default = _default;
});