define("product-management/pods/dealer-admin/order/select-part/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortColumn: ["name"],
    filter: "",
    filteredParts: Ember.computed("filter", function () {
      if (Ember.isEmpty(this.filter)) return this.model;
      var filterLowered = this.filter.toLowerCase();
      return this.model.filter(function (part) {
        return part.name.toLowerCase().includes(filterLowered);
      });
    }),
    parts: Ember.computed.sort("filteredParts", "sortColumn"),
    actions: {
      close: function close() {
        this.set("filter", null);
        this.transitionToRoute("dealer_admin.order");
      }
    }
  });

  _exports.default = _default;
});