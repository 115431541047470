define("product-management/pods/my-account/change-password/controller", ["exports", "ember-ui-helpers/mixins/notifyable"], function (_exports, _notifyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_notifyable.default, {});

  _exports.default = _default;
});