define("product-management/pods/admin/transportation/delivery-exceptions/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["status"],
    status: ""
  });

  _exports.default = _default;
});