define("product-management/pods/components/pm/ui/array-to-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TS31prUQ",
    "block": "{\"symbols\":[\"item\",\"&default\",\"@items\"],\"statements\":[[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[2,\"\\n        \"],[18,2,[[32,1]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/array-to-list/template.hbs"
    }
  });

  _exports.default = _default;
});