define("product-management/utils/filterable-spread", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterableSpread = filterableSpread;

  function filterableSpread(fieldsToDelete, obj) {
    var result = obj;
    fieldsToDelete.forEach(function (field) {
      return delete result[field];
    });
    return result;
  }
});