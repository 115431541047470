define("product-management/pods/partners/transportation/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y9Xb3/aS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"My Deliveries\"],[13],[2,\"\\n\"],[8,\"fulfillment/deliveries/schedule\",[],[[\"@showRoute\"],[\"partners.transportation.show\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/partners/transportation/index/template.hbs"
    }
  });

  _exports.default = _default;
});