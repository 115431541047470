define("product-management/pods/admin/reports/daily-sales/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6nswU9h1",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4,[\"isSystemManager\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"mt-4\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"mb-2 block font-semibold\"],[12],[2,\"\\n        Enter the date range for the report\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex items-end\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mr-2\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"\\n            Start Date:\\n        \"],[13],[2,\"\\n        \"],[8,\"pikaday-input\",[],[[\"@value\",\"@onSelection\",\"@firstDay\",\"@format\",\"@class\"],[[34,0],[30,[36,1],[[32,0,[\"setDate\"]],\"startAt\"],null],0,\"MM/DD/YYYY\",\"form-control\"]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mr-4\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"\\n            End Date:\\n        \"],[13],[2,\"\\n        \"],[8,\"pikaday-input\",[],[[\"@value\",\"@onSelection\",\"@firstDay\",\"@format\",\"@class\"],[[34,2],[30,[36,1],[[32,0,[\"setDate\"]],\"endAt\"],null],0,\"MM/DD/YYYY\",\"form-control\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,0,\"p-2 bg-indigo-darkest text-white rounded cursor-pointer hover:text-white\"],[4,[38,3],[\"click\",[30,[36,1],[[32,0,[\"handleDownload\"]]],null]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-download\"],[12],[13],[2,\"\\n        Excel\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"startAt\",\"fn\",\"endAt\",\"on\",\"session\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/reports/daily-sales/template.hbs"
    }
  });

  _exports.default = _default;
});