define("product-management/pods/shop/deliveries/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dtdUBEkv",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Deliveries\"],null]],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"mt-8 text-right\"],[12],[2,\"\\n  \"],[8,\"fulfillment/clear-station\",[[24,0,\"btn btn-danger\"]],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop/deliveries/template.hbs"
    }
  });

  _exports.default = _default;
});