define("product-management/pods/components/tailwind-ui/lists/simple-striped/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2qbzdgDv",
    "block": "{\"symbols\":[\"@records\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"flex flex-col\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"shadow overflow-hidden border-b border-grey-lighter sm:rounded-lg\"],[12],[2,\"\\n        \"],[10,\"table\"],[14,0,\"min-w-full divide-y divide-gray-200\"],[12],[2,\"\\n          \\n\\t\\t\\t\\t\"],[18,2,[[30,[36,1],null,[[\"body\",\"header\"],[[30,[36,0],[\"tailwind-ui/lists/simple-striped/body\"],[[\"records\"],[[32,1]]]],[30,[36,0],[\"tailwind-ui/lists/simple-striped/header\"],null]]]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/tailwind-ui/lists/simple-striped/template.hbs"
    }
  });

  _exports.default = _default;
});