define("product-management/pods/admin/settings/my-profile/daily-digest/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tycw19Mo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.my-profile\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    My Profile\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  :: Daily Digest\\n\"],[13],[2,\"\\n\\n\"],[8,\"sun-pro/profile/daily-digest/form\",[],[[\"@digest\"],[[34,0]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/my-profile/daily-digest/template.hbs"
    }
  });

  _exports.default = _default;
});