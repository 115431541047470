define("product-management/pods/components/purchasing/purchase-order/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VPp7hm9A",
    "block": "{\"symbols\":[\"@items\",\"@purchaseOrder\",\"&default\"],\"statements\":[[18,3,[[30,[36,1],null,[[\"newItem\",\"body\"],[[30,[36,0],[\"purchasing/purchase-order/form/item-form\"],[[\"purchaseOrder\",\"items\"],[[32,2],[32,1]]]],[30,[36,0],[\"purchasing/purchase-order/form/body\"],[[\"purchaseOrder\",\"items\"],[[32,2],[32,1]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/purchase-order/form/template.hbs"
    }
  });

  _exports.default = _default;
});