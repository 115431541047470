define("product-management/pods/application/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    session: Ember.inject.service(),
    isAdmin: Ember.computed.alias("session.isAdmin"),
    isShop: Ember.computed.alias("session.isShop"),
    isDealer: Ember.computed.alias("session.isDealer"),
    isSystemManager: Ember.computed.alias("session.systemManager"),
    isShopManager: Ember.computed.alias("session.isShopManager")
  });

  _exports.default = _default;
});