define("product-management/pods/components/pricing/basic-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var pricingMethods = [{
    name: "Size Matrix",
    value: "size-matrix"
  }, {
    name: "Price Only",
    value: "price-only"
  }, {
    name: "Price Per Square Foot",
    value: "price-per-square-foot"
  }, {
    name: "Custom",
    value: "custom"
  }];

  var _default = Ember.Component.extend({
    pricingMethodComponent: Ember.computed("changeset.pricingMethod", function () {
      var pricingMethod = this.get("changeset.pricingMethod");

      if (Ember.isEmpty(pricingMethod) || pricingMethod === "custom") {
        return "";
      }

      return "pricing/".concat(pricingMethod, "-settings");
    }),
    pricingMethods: pricingMethods,
    actions: {
      pricingMethodSelected: function pricingMethodSelected() {}
    }
  });

  _exports.default = _default;
});