define("product-management/pods/components/sun-pro/ui/update-in-place/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Ib75pis",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"form\"],[24,0,\"flex items-center\"],[4,[38,2],[\"submit\",[30,[36,1],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,3],\"input\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"input\"],[16,2,[34,0]],[24,0,\"form-control mr-1\"],[17,1],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleInput\"]]],null]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[[35,3],\"textarea\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"textarea\"],[16,2,[34,0]],[24,0,\"form-control mr-1\"],[17,1],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleInput\"]]],null]],null],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,5],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"ml-1 font-semibold\"],[12],[2,\"\\n    Saved...\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"btn text-sm text-white bg-red rounded p-2\"],[14,4,\"submit\"],[12],[2,\"\\n      Save\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"fn\",\"on\",\"inputType\",\"eq\",\"if\",\"isShowingSaved\",\"valueChanged\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/ui/update-in-place/template.hbs"
    }
  });

  _exports.default = _default;
});