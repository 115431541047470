define("product-management/templates/resource-cards/customer-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LA7nXfD/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[14,6,\"#\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[35,1,[\"name\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"description\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[35,1,[\"address\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[35,1,[\"city\"]]],[2,\", \"],[1,[35,1,[\"state\"]]],[2,\" \"],[1,[35,1,[\"zip\"]]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[35,1,[\"phone\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-mobile-phone\"],[12],[13],[2,\" \"],[1,[35,1,[\"phone\"]]],[10,\"br\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,1,[\"email\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"envelope\"],null]],[2,\"\\n      \"],[10,\"a\"],[15,6,[31,[\"mailto:\",[34,1,[\"email\"]]]]],[12],[2,\" \"],[1,[35,1,[\"email\"]]],[13],[10,\"br\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"resource\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/templates/resource-cards/customer-card.hbs"
    }
  });

  _exports.default = _default;
});