define("product-management/pods/components/ordering/select-experience-link-to/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I6PTzHQO",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,[[\"class\",\"route\"],[\"list-group-item\",[35,3]]],[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"flex align-baseline\"],[12],[2,\"\\n  \"],[10,\"img\"],[15,0,[31,[\"block mr-4 \",[34,0]]]],[15,\"src\",[31,[\"/assets/images/\",[34,1],\".png\"]]],[12],[13],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"pt-2\"],[12],[1,[34,2]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"image-class\",\"image-name\",\"text\",\"route\",\"link-to\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/select-experience-link-to/template.hbs"
    }
  });

  _exports.default = _default;
});