define("product-management/pods/components/stardust/data-table/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G+5J7J4Z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"relative w-full\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,0,\"form-control\"],[14,\"placeholder\",\"Enter search term\"],[15,\"oninput\",[30,[36,0],[[32,0],\"handleInput\"],null]],[15,2,[34,1]],[12],[13],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"absolute pin-t pin-r bg-grey-dark h-full rounded\"],[12],[2,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,0],\"clearSearch\"],null]],[14,0,\"text-white px-4 py-2\"],[12],[2,\"x\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/data-table/search/template.hbs"
    }
  });

  _exports.default = _default;
});