define("product-management/pods/orders/show/payments/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pjgi4qgY",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[8,\"bs-modal\",[],[[\"@onHide\",\"@backdropClose\"],[[30,[36,0],[[32,0,[\"handleClose\"]]],null],false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"flexible-forms/form\",[],[[\"@camelizeDetails\",\"@formId\",\"@isEditing\",\"@details\",\"@onSave\"],[true,1,true,[34,1],[30,[36,0],[[32,0,[\"handleSave\"]]],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"paymentDetails\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/payments/edit/template.hbs"
    }
  });

  _exports.default = _default;
});