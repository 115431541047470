define("product-management/pods/components/work-flows/work-flow-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ztsqbdQz",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"handleInsert\"]],[32,0]],null],[12],[2,\"\\n\"],[1,[30,[36,4],null,[[\"lines\",\"value\",\"update\",\"useWrapMode\",\"useWorker\",\"useSoftTabs\",\"tabSize\",\"newLineMode\",\"mode\"],[20,[35,3],[30,[36,2],[[32,0],[35,1]],null],true,true,true,2,\"auto\",\"ace/mode/json\"]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"updateConfig\",\"action\",\"configForEditor\",\"ember-ace\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/work-flows/work-flow-editor/template.hbs"
    }
  });

  _exports.default = _default;
});