define("product-management/components/ordering/wizards/awning/control-location", ["exports", "marygrove-components/components/ordering/wizards/awning/control-location"], function (_exports, _controlLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _controlLocation.default;
    }
  });
});