define("product-management/pods/components/hatch/geography/county-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KWMmQt5S",
    "block": "{\"symbols\":[\"option\",\"@selectedState\",\"@selected\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"handleSetup\"]],[32,0]],null],[4,[38,1],[[32,0,[\"handleSetup\"]],[32,0],[32,2]],null],[12],[2,\"\\n\\t\"],[8,\"power-select\",[],[[\"@selected\",\"@renderInPlace\",\"@searchEnabled\",\"@searchField\",\"@onChange\",\"@options\"],[[32,3],true,true,\"name\",[30,[36,2],[[32,0,[\"handleSelect\"]]],null],[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"fn\",\"counties\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/hatch/geography/county-select/template.hbs"
    }
  });

  _exports.default = _default;
});