define("product-management/pods/components/dealers/status-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pfn2HE8O",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[16,0,[31,[\"p-2 block w-full text-white \",[30,[36,1],[[35,0],\"bg-blue-dark\",\"bg-red-dark\"],null],\" rounded\"]]],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"handleClick\"]]],null]],null],[12],[2,\"\\n  \"],[1,[30,[36,1],[[35,0],\"Enable\",\"Disable\"],null]],[2,\" Account\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"disabled\",\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/status-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});