define("product-management/pods/admin/settings/forms/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S/HdcvJT",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flexible-forms/settings/form\",[],[[\"@form\",\"@onAfterSave\"],[[34,0],[30,[36,1],[[32,0,[\"handleAfterSave\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/forms/new/template.hbs"
    }
  });

  _exports.default = _default;
});