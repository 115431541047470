define("product-management/pods/shop/pick-work-queue/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2ycj95f5",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Pick Work Queue\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-full\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"p-4\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"Pick Your Production Line\"],[13],[2,\"\\n      \"],[8,\"painless-manufacturing/production/production-lines/picker\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mb-4 mx-auto w-full text-center text-xl\"],[12],[2,\"\\n      - or -\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"p-4\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"Select another Task\"],[13],[2,\"\\n      \"],[8,\"fulfillment/global-station-picker\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop/pick-work-queue/template.hbs"
    }
  });

  _exports.default = _default;
});