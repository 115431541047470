define("product-management/pods/shop-diagnostic/order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w6n7Aolx",
    "block": "{\"symbols\":[\"lineItem\",\"index\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-6 col-md-offset-3\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"well\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"Select a Line Item\"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"list-group-item\"],[12],[2,\"\\n\\t  \"],[10,\"h4\"],[12],[2,\"LineItem - #\"],[1,[32,2]],[13],[2,\"\\n\\t  \"],[6,[37,0],null,[[\"route\",\"model\"],[\"shop_diagnostic.cut_sheet\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"Cut Sheet\"]],\"parameters\":[]}]]],[2,\"\\n\\t  \"],[10,\"br\"],[12],[13],[2,\"\\n\\t  \"],[6,[37,0],null,[[\"route\",\"model\"],[\"shop_diagnostic.line_item\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"Stations\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"lineItems\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop-diagnostic/order/template.hbs"
    }
  });

  _exports.default = _default;
});