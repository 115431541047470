define("product-management/pods/components/products/order-config/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var largerSections = ["all", "summary-config", "stepValidation"];
  var largerSectionLineCount = 50;
  var smallerSectionLineCount = 30;

  var _default = Ember.Component.extend({
    lineCount: Ember.computed("section", function () {
      return largerSections.includes(this.section) ? largerSectionLineCount : smallerSectionLineCount;
    }),
    orderConfigForEditor: Ember.computed("product.orderConfig", "section", function () {
      return this.stringifyOrderConfig(this.get("product.orderConfig"), this.section);
    }),
    stringifyOrderConfig: function stringifyOrderConfig(orderConfig, section) {
      if (section === "all") {
        return JSON.stringify(orderConfig, null, '\t');
      } else {
        if (orderConfig[section] === undefined) {
          orderConfig[section] = {};
        }

        return JSON.stringify(orderConfig[section], null, '\t');
      }
    },
    actions: {
      updateConfig: function updateConfig(value) {
        this.set("orderConfigForEditor", value);
      }
    }
  });

  _exports.default = _default;
});