define("product-management/pods/dealer-admin/order/complete-screen/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OllXlVbF",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"onCloseModal\",\"title\"],[[30,[36,2],[\"closeModal\"],null],\"Build your complete unit\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"route-action\",\"line-item-wizard-container\"]}",
    "meta": {
      "moduleName": "product-management/pods/dealer-admin/order/complete-screen/template.hbs"
    }
  });

  _exports.default = _default;
});