define("product-management/pods/admin/dealers/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["includeDisabled"],
    includeDisabled: false,
    sortCol: ["name"],
    actions: {
      toggleDisabled: function toggleDisabled() {
        this.toggleProperty("includeDisabled");
      }
    },
    filteredByName: Ember.computed("model", "dealerNameFilter", function () {
      var filtered = this.model;
      var dealerNameFilter = this.dealerNameFilter;
      if (Ember.isEmpty(dealerNameFilter)) return filtered;
      dealerNameFilter = dealerNameFilter.toLowerCase();
      return filtered.filter(function (item) {
        return item.name.toLowerCase().includes(dealerNameFilter);
      });
    }),
    dealers: Ember.computed.sort("filteredByName", "sortCol")
  });

  _exports.default = _default;
});