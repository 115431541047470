define("product-management/pods/components/products/station-config/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GG+VMW/O",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"Stations\"],[13],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"multiple\",\"selection\"],[[35,1],\"content\",\"content\",true,[35,0,[\"stations\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"product\",\"underscoredProductionStations\",\"ember-selectize\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/products/station-config/template.hbs"
    }
  });

  _exports.default = _default;
});