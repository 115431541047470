define("product-management/validators/custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateConditional;

  function validateConditional(opts) {
    return function (key, newValue, oldValue, changes, content) {
      var dependency = opts.dependency;
      var field = dependency.field;
      var includes = dependency.includes;
      var currentDependencyValue = content.get(field);

      if (!includes.includes(currentDependencyValue)) {
        return true;
      }

      var fieldToCheck = opts.fieldToCheck != undefined ? opts.fieldToCheck : key;
      return Ember.isPresent(content.get(fieldToCheck));
    };
  }
});