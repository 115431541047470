define("product-management/pods/admin/settings/hooks/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "im3CSMK3",
    "block": "{\"symbols\":[\"pmTable\",\"record\",\"PageHeader\"],\"statements\":[[8,\"settings/page-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,[32,3,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Hooks\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,[32,3,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"btn btn-primary\"],[4,[38,0],[[32,0],\"createNewHook\"],null],[12],[2,\"Add New Hook\"],[13],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"\\n\\n\\n\\n\"],[8,\"pm/ui/table\",[],[[\"@records\"],[[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Name\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Events\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Dealer\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"td\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.settings.hooks.show\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t  \"],[1,[32,2,[\"name\"]]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[32,2,[\"events\"]]],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[32,2,[\"dealer\",\"name\"]]],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/hooks/index/template.hbs"
    }
  });

  _exports.default = _default;
});