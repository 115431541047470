define("product-management/pods/components/line-items/delivery-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ff0RnayK",
    "block": "{\"symbols\":[\"note\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"exclamation-circle\"],[[\"size\"],[2]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1,[\"note\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"notes\",\"-track-array\",\"each\",\"hasNotes\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/line-items/delivery-notes/template.hbs"
    }
  });

  _exports.default = _default;
});