define("product-management/pods/admin/accounting/invoices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HkFYL/4P",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"margin-top-20 text-right\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[35,2],\"ready_for_quickbooks\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],null,[[\"query\",\"route\"],[[30,[36,0],null,[[\"status\"],[\"drafts\"]]],\"admin.accounting.invoices\"]],[[\"default\"],[{\"statements\":[[2,\"View drafts\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],null,[[\"query\",\"route\"],[[30,[36,0],null,[[\"status\"],[\"ready_for_quickbooks\"]]],\"admin.accounting.invoices\"]],[[\"default\"],[{\"statements\":[[2,\"View invoices ready for quickbooks\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[1,[30,[36,10],null,[[\"invoicesRoute\",\"hideSummary\",\"hideNewInvoice\",\"isShowingPoNumber\",\"hideDueDate\",\"newInvoiceRoute\",\"invoiceRoute\",\"sortColumn\",\"sortAsc\",\"isLoading\",\"summaryData\",\"invoices\",\"status\"],[\"admin.accounting.invoices\",true,true,true,true,\"admin.accounting.invoices\",\"admin.accounting.invoice\",[35,9],[35,8],[35,7],[35,6],[35,5],[35,2]]]]],[2,\"\\n\"],[1,[30,[36,12],[[30,[36,11],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-hash\",\"link-to\",\"status\",\"eq\",\"if\",\"invoices\",\"summaryData\",\"isLoading\",\"sortAsc\",\"sortColumn\",\"invoice-list\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/invoices/template.hbs"
    }
  });

  _exports.default = _default;
});