define("product-management/pods/components/pm/notes/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4CWG+uOk",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"py-3 -mx-3 p-2 border-grey-darkest rounded mb-3\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"pm/ui/mentionable-textarea\",[],[[\"@collection\",\"@onUpdate\"],[[34,0],[30,[36,2],[[32,0],[35,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"bg-blue hover:bg-blue-dark mb-2 p-2 text-white  text-sm rounded font-bold\"],[4,[38,6],[\"click\",[30,[36,5],[[32,0,[\"create\"]]],null]],null],[12],[2,\"\\n    Save\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"mentionCollection\",\"updateContent\",\"action\",\"clear\",\"unless\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/notes/form/template.hbs"
    }
  });

  _exports.default = _default;
});