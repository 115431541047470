define("product-management/pods/admin/accounting/purchasing/inventory-items/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NV9b9hZH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"show-navigation mb-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.accounting.purchasing.inventory-items.show.index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Details\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\t\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.accounting.purchasing.inventory-items.show.purchase-orders\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    POs\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/purchasing/inventory-items/show/template.hbs"
    }
  });

  _exports.default = _default;
});