define("product-management/pods/components/pricing/size-matrix-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KbFHEa70",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"focus-out\",\"key-down\",\"class\",\"value\"],[[30,[36,1],[[32,0],\"checkForValue\"],null],[30,[36,1],[[32,0],\"keyDown\"],null],\"price-matrix-field\",[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"price\",\"action\",\"input\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pricing/size-matrix-item/template.hbs"
    }
  });

  _exports.default = _default;
});