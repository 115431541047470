define("product-management/pods/components/line-items/other-units/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LhV7mVWT",
    "block": "{\"symbols\":[\"lineItem\"],\"statements\":[[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n  \"],[10,\"h5\"],[14,0,\"text-center\"],[12],[2,\"Other Units On This Order\"],[13],[2,\"\\n  \"],[10,\"table\"],[14,0,\"table table-condensed table-bordered table-striped\"],[12],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"td\"],[14,0,\"other-unit-number\"],[12],[2,\"\\n            #\"],[1,[32,1,[\"sequenceNumber\"]]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[1,[30,[36,1],null,[[\"line-item\",\"station\"],[[32,1],[35,0]]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"station\",\"line-items/other-unit-details\",\"lineItems\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/line-items/other-units/template.hbs"
    }
  });

  _exports.default = _default;
});