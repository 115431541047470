define("product-management/pods/components/painless-manufacturing/inputs/errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9OQSG9Yg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,3],null,[[\"present\",\"messages\"],[[30,[36,2],[[30,[36,1],[[32,0,[\"errors\",\"length\"]],0],null],[30,[36,1],[[32,0,[\"warnings\",\"length\"]],0],null]],null],[30,[36,0],[\"painless-manufacturing/inputs/errors/messages\"],[[\"errors\",\"warnings\"],[[32,0,[\"errors\"]],[32,0,[\"warnings\"]]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"gt\",\"or\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/inputs/errors/template.hbs"
    }
  });

  _exports.default = _default;
});