define("product-management/pods/components/shop/line-items/instructions/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A0sE0CjV",
    "block": "{\"symbols\":[\"step\",\"@lineItem\",\"@instructionsText\",\"@instructions\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"mt-4 p-2 bg-grey-lighter rounded mb-4\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-2xl block mb-2\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"text-grey-darker font-semibold\"],[12],[2,\"\\n        Product:\\n      \"],[13],[2,\"\\n      \"],[1,[32,2,[\"product\",\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-2xl block mb-2\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"text-grey-darker font-semibold\"],[12],[2,\"\\n        Quantity:\\n      \"],[13],[2,\"\\n      \"],[1,[32,2,[\"quantity\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"span\"],[14,0,\"block font-semibold text-2xl mb-4\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[32,3],[32,3],\"Instructions\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n      \"],[2,[32,1,[\"description\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"header\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/line-items/instructions/body/template.hbs"
    }
  });

  _exports.default = _default;
});