define("product-management/pods/components/purchasing/inventory-items/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TtkW2Tv3",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[4,[38,1],[\"submit\",[30,[36,0],[[32,0,[\"handleSubmit\"]]],null]],null],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"form-group mr-2\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[12],[2,\"Name\"],[13],[2,\"\\n\\t\\t\\t\"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,2,[\"name\"]]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"form-group mr-2\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[12],[2,\"Description\"],[13],[2,\"\\n\\t\\t\\t\"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,2,[\"description\"]]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"form-group mr-2\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[12],[2,\"SKU\"],[13],[2,\"\\n\\t\\t\\t\"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,2,[\"sku\"]]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"form-group mr-2\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[12],[2,\"Lead Time (In Days)\"],[13],[2,\"\\n\\t\\t\\t\"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,2,[\"leadTime\"]]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"mt-2\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"button\"],[14,0,\"bg-blue text-white font-semibold rounded p-2\"],[14,4,\"submit\"],[12],[2,\"Add Item\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"item\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/inventory-items/form/template.hbs"
    }
  });

  _exports.default = _default;
});