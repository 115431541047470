define("product-management/helpers/html-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.htmlFormat = htmlFormat;
  _exports.default = void 0;

  function htmlFormat(value, opts) {
    var result = value[0];
    if (typeof result === "string") result = result.replace(/\n/g, '<br>');
    return result;
  }

  var _default = Ember.Helper.helper(htmlFormat);

  _exports.default = _default;
});