define("product-management/helpers/pct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function pct(pct, opts) {
    opts = opts || {};
    var value = pct || 0;
    if (opts.wholeNumber === undefined || !opts.wholeNumber) value *= 100.0;
    value = parseFloat(value);
    var numDecimals = opts.decimals || 0;
    return "".concat(value.toFixed(value % 1 === 0 ? 0 : numDecimals), "%");
  });

  _exports.default = _default;
});