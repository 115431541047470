define("product-management/pods/admin-mobile/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "06vhSUWv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"Select an option\"],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\"],[\"list-group-item\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[6,[37,0],null,[[\"route\"],[\"admin_mobile.inventory\"]],[[\"default\"],[{\"statements\":[[2,\"Inventory\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"active-link\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin-mobile/index/template.hbs"
    }
  });

  _exports.default = _default;
});