define("product-management/helpers/shamgar/simple-format", ["exports", "shamgar-components/helpers/shamgar/simple-format"], function (_exports, _simpleFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _simpleFormat.default;
    }
  });
  Object.defineProperty(_exports, "shamgarSimpleFormat", {
    enumerable: true,
    get: function get() {
      return _simpleFormat.shamgarSimpleFormat;
    }
  });
});