define("product-management/pods/admin/accounting/deliveries/on-truck/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    orders: Ember.computed.alias("model.orders"),
    dealersOnOrders: Ember.computed.mapBy("orders", "dealer.content"),
    ordersByDealer: Ember.computed("orders", "dealersOnOrders", function () {
      var orders = this.orders;
      var dealersOnOrders = this.dealersOnOrders;
      var dealers = dealersOnOrders.uniqBy("id").sortBy("name");
      return dealers.map(function (dealer) {
        return {
          dealer: dealer,
          orders: orders.filterBy("dealer.id", dealer.id)
        }; //.sort("dealerReference")};
      });
    }),
    hasNoData: Ember.computed.empty("ordersByDealer"),
    actions: {
      toggleShipped: function toggleShipped(order) {
        var _this = this;

        var event = "mark_delivered";
        return order.changeState({
          event: event
        }).then(function (data) {
          _this.store.pushPayload(data);

          _this.store.unloadAll("deliveryQueue");
        });
      }
    }
  });

  _exports.default = _default;
});