define("product-management/pods/admin/product/label-config/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8zWEzomz",
    "block": "{\"symbols\":[\"PmUiCodeEditor\",\"codeEditor\"],\"statements\":[[6,[37,5],[[30,[36,4],[\"pm/ui/code-editor\"],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Label Config\"],[13],[2,\"\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"editor\"]],[],[[\"@value\",\"@lines\",\"@mode\"],[[34,0,[\"labelConfig\"]],10,\"ace/mode/ruby\"]],null],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-primary btn-sm\"],[4,[38,1],[[32,0],\"saveLabelConfig\",[32,2,[\"editorValue\"]]],null],[12],[2,\"\\n\"],[6,[37,3],[[35,2,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          Saving...\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          Save\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"saveLabelConfig\",\"if\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/product/label-config/template.hbs"
    }
  });

  _exports.default = _default;
});