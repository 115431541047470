define("product-management/pods/components/custom/shop/cut-sheet/parts-unit/component", ["exports", "product-management/mixins/custom/shop/cut-sheet-title"], function (_exports, _cutSheetTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_cutSheetTitle.default, {
    details: Ember.computed.alias("lineItem.details"),
    productName: Ember.computed.alias("lineItem.product.name"),
    quantity: Ember.computed.alias("lineItem.quantity"),
    notes: Ember.computed.alias("lineItem.notes"),
    title: Ember.computed.alias("lineItem.cutSheetTitle"),
    sequenceNumber: Ember.computed.alias("lineItem.sequenceNumber")
  });

  _exports.default = _default;
});