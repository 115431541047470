define("product-management/pods/admin/dealer/delegates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/1BpZx83",
    "block": "{\"symbols\":[],\"statements\":[[8,\"dealers/delegates/list\",[],[[\"@dealer\"],[[34,0]]],null],[2,\"\\n\\n\"],[8,\"dealers/delegates/add-form\",[[24,0,\"mt-8\"]],[[\"@dealer\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/dealer/delegates/template.hbs"
    }
  });

  _exports.default = _default;
});