define("product-management/pods/admin/settings/goals/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0HgTtqR5",
    "block": "{\"symbols\":[\"goal\"],\"statements\":[[10,\"div\"],[14,0,\"flex justify-ends\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"w-5/6\"],[12],[2,\"\\n    Goals\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"admin.settings.goals.new\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Add Goal\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"name\",[35,0]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@model\"],[\"admin.settings.goals.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    No Goals have been defined yet.\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"sort-by\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/goals/index/template.hbs"
    }
  });

  _exports.default = _default;
});