define("product-management/pods/components/work-queues/pick-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eLddK14Z",
    "block": "{\"symbols\":[\"@onChange\",\"@selection\"],\"statements\":[[1,[30,[36,2],null,[[\"content\",\"optionLabelPath\",\"optionValuePath\",\"selection\",\"select-item\",\"class\"],[[35,1],\"content.name\",\"content.id\",[32,2],[30,[36,0],[[32,1]],null],\"w-full\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"workQueues\",\"ember-selectize\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/work-queues/pick-list/template.hbs"
    }
  });

  _exports.default = _default;
});