define("product-management/pods/components/ui/filter-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["isFilterOptionBoxOpen:filter-box-open"],
    isFilterOptionBoxOpen: false,
    watchFilterOptionBoxOpen: Ember.observer("isFilterOptionBoxOpen", function () {
      var isFilterOptionBoxOpen = this.isFilterOptionBoxOpen;
      if (!isFilterOptionBoxOpen) return;
      Ember.run.later(this, function () {
        this.element.querySelector("input").focus();
      }, 200);
    }),
    actions: {
      clearAndClose: function clearAndClose() {
        this.set("isFilterOptionBoxOpen", false);
        this.attrs["on-clear-filter"]();
      }
    }
  });

  _exports.default = _default;
});