define("product-management/components/create-modal", ["exports", "shamgar-pricing-matrix/components/create-modal"], function (_exports, _createModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _createModal.default;
    }
  });
});