define("product-management/pods/array/transform", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(value) {
      if (Ember.isArray(value)) {
        return Ember.A(value);
      } else {
        return Ember.A();
      }
    },
    serialize: function serialize(value) {
      if (Ember.isArray(value)) {
        return Ember.A(value);
      } else {
        return Ember.A();
      }
    }
  });

  _exports.default = _default;
});