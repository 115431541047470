define("product-management/pods/shop/pick-work-queue/controller", ["exports", "js-cookie", "product-management/mixins/shared/web-socket-controller"], function (_exports, _jsCookie, _webSocketController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var workQueueCookieName = "WORK_QUEUE";
  var workQueueNameCookieName = "WORK_QUEUE_NAME";
  var productionLineCookieName = "PM_PRODUCTION_LINE";

  var _default = Ember.Controller.extend(_webSocketController.default, {
    currentWorkQueue: Ember.computed.alias("model.cookie"),
    workQueuesData: Ember.computed.alias("model.workQueues"),
    sortCol: ["name"],
    sortedWorkQueues: Ember.computed.sort("workQueuesData", "sortCol"),
    workQueues: Ember.computed.map("sortedWorkQueues", function (item) {
      return {
        value: item.id,
        displayValue: item.get("name")
      };
    }),
    workQueueNotSelected: Ember.computed.empty("currentWorkQueue"),
    getWorkQueues: function getWorkQueues() {
      return this.store.query("work-queue", {
        "filter[by_started]": true
      });
    },
    refreshWorkQueues: function refreshWorkQueues(data) {
      var _this = this;

      this.getWorkQueues().then(function (records) {
        Ember.set(_this, "model.workQueues", records);
      });
    },
    actions: {
      select: function select(workQueue) {
        var workQueues = this.workQueues;
        var selectedQueue = workQueues.find(function (item) {
          return item.value === workQueue;
        });

        _jsCookie.default.set(workQueueCookieName, workQueue);

        _jsCookie.default.set(workQueueNameCookieName, selectedQueue.displayValue);

        _jsCookie.default.remove(productionLineCookieName);

        this.transitionToRoute("shop");
      }
    }
  });

  _exports.default = _default;
});