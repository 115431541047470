define("product-management/pods/components/ui/link-to-entity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nok9fcvD",
    "block": "{\"symbols\":[\"@entityId\"],\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@model\"],[[34,0],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"route\",\"linkText\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/link-to-entity/template.hbs"
    }
  });

  _exports.default = _default;
});