define("product-management/pods/components/pm/ui/tags/new-tag-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LMIiyV8/",
    "block": "{\"symbols\":[\"@canEdit\"],\"statements\":[[6,[37,6],[[32,1]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[32,0,[\"addingTag\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ml-2\"],[12],[2,\"\\n    \"],[10,\"form\"],[15,\"onsubmit\",[30,[36,1],[[32,0],[35,2]],null]],[12],[2,\"\\n      \"],[11,\"input\"],[24,0,\"px-2 py-1 text-sm border mr-4 bg-grey-lightest text-black\"],[16,\"oninput\",[30,[36,1],[[32,0],[35,3]],null]],[16,2,[32,0,[\"newTag\"]]],[4,[38,4],[[32,0,[\"setFocus\"]]],null],[12],[13],[2,\"\\n\\n      \"],[10,\"a\"],[15,\"onclick\",[30,[36,1],[[32,0],[35,5]],null]],[14,0,\"cursor-pointer text-blue no-underline hover:no-underline\"],[12],[2,\"cancel\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"a\"],[15,\"onclick\",[30,[36,1],[[32,0],[35,0]],null]],[14,0,\"cursor-pointer text-blue no-underline hover:no-underline ml-3\"],[12],[2,\"+ Add Tag\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"addNewTag\",\"action\",\"addTag\",\"handleNewTagInput\",\"did-insert\",\"cancelAddTag\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/tags/new-tag-form/template.hbs"
    }
  });

  _exports.default = _default;
});