define("product-management/pods/components/fulfillment/deliveries/map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uX6O9bhu",
    "block": "{\"symbols\":[\"G\",\"marker\",\"@delivery\"],\"statements\":[[8,\"g-map\",[],[[\"@lat\",\"@lng\",\"@zoom\"],[\"28.4955191241134752\",\"-81.53729525177304965\",[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,3,[\"stops\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"fulfillment/deliveries/map/marker\",[],[[\"@marker\",\"@selectedMarker\",\"@onToggleMarker\",\"@mapComponent\"],[[32,2],[34,0],[30,[36,1],[[32,0,[\"handleToggleMarker\"]],[32,2]],null],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"selectedMarker\",\"fn\",\"zoom\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/deliveries/map/template.hbs"
    }
  });

  _exports.default = _default;
});