define("product-management/pods/admin/settings/price-multipliers/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q0XChTkH",
    "block": "{\"symbols\":[\"multiplier\"],\"statements\":[[10,\"div\"],[14,0,\"text-right mb-4\"],[12],[2,\"\\n\\t\"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"admin.settings.price-multipliers.new\"]],[[\"default\"],[{\"statements\":[[2,\"New\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"table\"],[14,0,\"table-condensed table-striped table\"],[12],[2,\"\\n\\t\"],[10,\"thead\"],[12],[2,\"\\n\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Name\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Amount\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"link-to\",[],[[\"@model\",\"@route\"],[[32,1,[\"id\"]],\"admin.settings.price-multipliers.show\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\\t\\t \"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"td\"],[12],[1,[30,[36,0],[[32,1,[\"amount\"]]],null]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pct\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/price-multipliers/index/template.hbs"
    }
  });

  _exports.default = _default;
});