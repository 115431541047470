define("product-management/pods/admin/settings/product-weights/weights/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BEBQYdF7",
    "block": "{\"symbols\":[\"productWeight\"],\"statements\":[[10,\"div\"],[14,0,\"text-right mb-4\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"admin.settings.product-weights.weights.new\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Add New\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"table\"],[14,0,\"table table-striped table-bordered table-condensed\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"th\"],[12],[2,\"Product\"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"product.name\",[35,0]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"\\n                \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.settings.product-weights.weights.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[1,[32,1,[\"product\",\"name\"]]],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"td\"],[12],[2,\"\\n                \"],[1,[32,1,[\"description\"]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[8,\"blank-slate\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    No product weights have been configured yet\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"sort-by\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/product-weights/weights/index/template.hbs"
    }
  });

  _exports.default = _default;
});