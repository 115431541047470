define("product-management/pods/components/navigation/quick-quote-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qdj2X2PD",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"a\"],[24,0,\"cursor-pointer\"],[17,1],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleQuickQuoteClick\"]]],null]],null],[12],[2,\"\\n\\tQuick Quote\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/navigation/quick-quote-link/template.hbs"
    }
  });

  _exports.default = _default;
});