define("product-management/pods/my-account/change-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x0kYiWxC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Change my Password\"],[13],[2,\"\\n    \"],[11,\"form\"],[4,[38,0],[[32,0],\"changePassword\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"To change your password, please fill out the form below:\"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-group\"],[14,5,\"margin-top:15px;\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"Current Password\"],[13],[2,\"\\n\\t\"],[1,[30,[36,2],null,[[\"type\",\"id\",\"class\",\"value\",\"placeholder\"],[\"password\",\"current-password\",\"form-control\",[35,1],\"Current Password\"]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"New Password\"],[13],[2,\"\\n\\t\"],[1,[30,[36,2],null,[[\"type\",\"class\",\"value\",\"placeholder\"],[\"password\",\"form-control\",[35,3],\"New Password\"]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n\\t\"],[10,\"button\"],[14,0,\"btn btn-primary\"],[12],[2,\"Change Password\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"currentPassword\",\"input\",\"newPassword\"]}",
    "meta": {
      "moduleName": "product-management/pods/my-account/change-password/template.hbs"
    }
  });

  _exports.default = _default;
});