define("product-management/pods/pickup-order/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    poNumbers: (0, _model.attr)(),
    status: (0, _model.attr)(),
    dealer: (0, _model.attr)(),
    orderCount: (0, _model.attr)(),
    completeScreenCount: (0, _model.attr)(),
    screenOnlyCount: (0, _model.attr)(),
    partsCount: (0, _model.attr)(),
    splitPoNumbers: Ember.computed("poNumbers", function () {
      return this.poNumbers.split(", ");
    })
  });

  _exports.default = _default;
});