define("product-management/pods/components/fulfillment/deliveries/map/marker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ssn5p61X",
    "block": "{\"symbols\":[\"M\",\"@mapComponent\",\"@marker\",\"@onToggleMarker\",\"@selectedMarker\"],\"statements\":[[8,[32,2,[\"marker\"]],[],[[\"@lat\",\"@lng\",\"@onClick\"],[[32,3,[\"lat\"]],[32,3,[\"lng\"]],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,[32,1,[\"infoWindow\"]],[],[[\"@isOpen\"],[[30,[36,0],[[32,3],[32,5]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"strong\"],[14,0,\"block mb-1\"],[12],[2,\"Dealer:\"],[13],[2,\"\\n\\t\\t\\t\"],[1,[32,3,[\"title\"]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"strong\"],[14,0,\"block mb-1\"],[12],[2,\"Address:\"],[13],[2,\"\\n\\t\\t\\t\"],[1,[32,3,[\"address\"]]],[10,\"br\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[1,[32,3,[\"city\"]]],[2,\", \"],[1,[32,3,[\"state\"]]],[2,\" \"],[1,[32,3,[\"zip\"]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"strong\"],[14,0,\"block mb-1\"],[12],[2,\"Orders:\"],[13],[2,\"\\n\\t\\t\\t\"],[1,[32,3,[\"orders\",\"length\"]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"eq\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/deliveries/map/marker/template.hbs"
    }
  });

  _exports.default = _default;
});