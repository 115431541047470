define("product-management/pods/admin/controller", ["exports", "product-management/mixins/logout-controller", "shamgar-auto-upgrade/mixins/auto-upgrade-controller"], function (_exports, _logoutController, _autoUpgradeController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_autoUpgradeController.default, _logoutController.default, {
    actions: {
      searchResultSelected: function searchResultSelected(result) {
        if (result === null) {
          return;
        }

        var searchableId = result.searchableId,
            searchableType = result.searchableType;

        switch (searchableType) {
          case "Product":
            {
              this.transitionToRoute("admin.product.settings", searchableId);
              break;
            }

          case "Invoice":
            {
              this.transitionToRoute("admin.accounting.invoice", searchableId);
              break;
            }

          case "Order":
            {
              this.transitionToRoute("orders.show", searchableId);
              break;
            }

          case "Dealer":
            {
              this.transitionToRoute("admin.dealer", searchableId);
              break;
            }

          case "PurchaseOrder":
            {
              this.transitionToRoute("admin.accounting.purchasing.purchase-orders.show", searchableId);
              break;
            }
        }
      }
    }
  });

  _exports.default = _default;
});