define("product-management/pods/components/pricing/price-per-square-foot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BqUFHduv",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[10,\"h3\"],[12],[2,\"Price Per Square Foot Settings\"],[13],[2,\"\\n\\n\\n\"],[6,[37,4],[[30,[36,3],[[35,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"form\"],[4,[38,0],[[32,0],\"saveFilterField\",[32,1]],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"value\",\"labelText\"],[[32,1,[\"pricing\",\"config\",\"filter_field\"]],\"Filter Field\"]]]],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"value\",\"labelText\"],[[32,1,[\"pricing\",\"config\",\"modifier_field\"]],\"Modifier Field\"]]]],[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[14,4,\"submit\"],[12],[2,\"Save\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"product\",\"onDelete\"],[[35,2],[30,[36,0],[[32,0],\"deleteOption\"],null]]]]],[2,\"\\n\\n\\n\"],[1,[30,[36,8],null,[[\"product\",\"newOption\",\"onAdd\",\"availableScreens\"],[[35,2],[35,7],[30,[36,0],[[32,0],\"addOption\"],null],[35,6]]]]],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"wrapped-input\",\"product\",\"changeset\",\"with\",\"pricing/price-per-square-foot/option-list\",\"availableScreens\",\"newOption\",\"pricing/price-per-square-foot/add-option\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pricing/price-per-square-foot/template.hbs"
    }
  });

  _exports.default = _default;
});