define("product-management/pods/components/custom/unit-dimensions/awning-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MLCkLxh0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"dl\"],[12],[2,\"\\n  \"],[10,\"dt\"],[12],[2,\"Width\"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[1,[30,[36,1],[[35,0,[\"width\"]]],null]],[2,\" \"],[13],[2,\"\\n  \"],[10,\"dt\"],[12],[2,\"Fabric\"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[1,[35,0,[\"fabric_name\"]]],[13],[2,\"\\n  \"],[10,\"dt\"],[12],[2,\"Frame Color\"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[1,[35,0,[\"frame_color\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"details\",\"number-as-fraction\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/custom/unit-dimensions/awning-display/template.hbs"
    }
  });

  _exports.default = _default;
});