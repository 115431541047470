define("product-management/pods/components/sun-pro/ui/table-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JE0f69jz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"input\"],[24,0,\"form-control\"],[24,\"placeholder\",\"Search...\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleFilter\"]]],null]],null],[12],[13],[2,\"\\n\"],[18,1,[[30,[36,3],null,[[\"records\"],[[35,2]]]]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"records\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/ui/table-filter/template.hbs"
    }
  });

  _exports.default = _default;
});