define("product-management/pods/components/pm/conversation/new-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T5BIoEWu",
    "block": "{\"symbols\":[\"@route\",\"@buttonClass\"],\"statements\":[[8,\"link-to\",[[16,0,[31,[\"action-button \",[32,2]]]]],[[\"@route\",\"@query\"],[[32,1],[32,0,[\"query\"]]]],[[\"default\"],[{\"statements\":[[2,\"Start a Conversation\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/conversation/new-button/template.hbs"
    }
  });

  _exports.default = _default;
});