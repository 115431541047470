define("product-management/utils/reports/hourly-labor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hourKeys = hourKeys;

  function hourKeys(start, end) {
    var num = end - start + 1;
    return Array(num).fill(start).map(function (x, y) {
      return x + y;
    });
  }
});