define("product-management/pods/shop/deliveries/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {} //actions:{
    //  //itemType being screen,assembly,bottom_bar,side_track
    //  //
    //  itemLoaded(lineItem,itemType){
    //  }
    //}

  });

  _exports.default = _default;
});