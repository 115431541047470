define("product-management/pods/components/admin/vendors/table-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "tr",
    name: Ember.computed.alias("record.name")
  });

  _exports.default = _default;
});