define("product-management/pods/components/sun-pro/ui/quill-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p/NjqG0f",
    "block": "{\"symbols\":[\"Ql\",\"Tb\",\"@text\"],\"statements\":[[8,\"quill\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"toolbar\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,[32,2,[\"group\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"header\"]],[],[[\"@values\"],[[30,[36,1],[[35,0],1,2,3,4],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,2,[\"group\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"bold\"]],[],[[],[]],null],[2,\"\\n      \"],[8,[32,2,[\"italic\"]],[],[[],[]],null],[2,\"\\n      \"],[8,[32,2,[\"underline\"]],[],[[],[]],null],[2,\"\\n      \"],[8,[32,2,[\"color\"]],[],[[],[]],null],[2,\"\\n      \"],[8,[32,2,[\"align\"]],[],[[\"@values\"],[[34,1]]],null],[2,\"\\n      \"],[8,[32,2,[\"link\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,2,[\"group\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"list\"]],[[24,2,\"ordered\"]],[[],[]],null],[2,\"\\n      \"],[8,[32,2,[\"list\"]],[[24,2,\"bullet\"]],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,2,[\"group\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"image\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"editor\"]],[],[[\"@name\",\"@text\",\"@onChange\",\"@placeholder\",\"@theme\"],[[34,2],[32,3],[32,0,[\"handleChange\"]],[34,3],[34,4]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"Normal\",\"array\",\"editorName\",\"placeholder\",\"theme\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/ui/quill-editor/template.hbs"
    }
  });

  _exports.default = _default;
});