define("product-management/utils/constants/cookies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lastPrinterCookieName = _exports.workQueueCookieName = _exports.workQueueNameCookieName = _exports.productionLineCookieName = _exports.stationCookieName = void 0;
  var stationCookieName = "SHOP_STATION";
  _exports.stationCookieName = stationCookieName;
  var productionLineCookieName = "PM_PRODUCTION_LINE";
  _exports.productionLineCookieName = productionLineCookieName;
  var workQueueNameCookieName = "WORK_QUEUE_NAME";
  _exports.workQueueNameCookieName = workQueueNameCookieName;
  var workQueueCookieName = "WORK_QUEUE";
  _exports.workQueueCookieName = workQueueCookieName;
  var lastPrinterCookieName = "LAST_PRINTER";
  _exports.lastPrinterCookieName = lastPrinterCookieName;
});