define("product-management/pods/components/audits/delivery-changes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5uAWhFpJ",
    "block": "{\"symbols\":[\"@changes\"],\"statements\":[[10,\"div\"],[14,0,\"grid grid-cols-2\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-lg font-semibold block mb-2\"],[12],[2,\"\\n      From\\n    \"],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"from\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[32,1,[\"from\",\"deliveryZone\",\"description\"]]],[2,\"\\n      -\\n      \"],[1,[30,[36,0],[[32,1,[\"from\",\"startOn\"]],\"MM/DD/YYYY\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      No Delivery\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-lg font-semibold block mb-2\"],[12],[2,\"\\n      To\\n    \"],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"to\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[32,1,[\"to\",\"deliveryZone\",\"description\"]]],[2,\"\\n      -\\n      \"],[1,[30,[36,0],[[32,1,[\"to\",\"startOn\"]],\"MM/DD/YYYY\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      No Delivery\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/audits/delivery-changes/template.hbs"
    }
  });

  _exports.default = _default;
});