define("product-management/pods/components/pm/ui/radio-list/option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YVVFxC4p",
    "block": "{\"symbols\":[\"@value\",\"&default\",\"@selected\"],\"statements\":[[10,\"a\"],[15,\"onclick\",[30,[36,1],[[32,0],[35,0]],null]],[14,6,\"#\"],[15,0,[31,[\"no-underline hover:no-underline focus:no-underline \",[30,[36,2],[[32,3],\"text-red-dark hover:text-red-dark active:text-red-dark focus:text-red-dark\",\"text-grey hover:text-grey active:text-grey focus:text-grey\"],null]]]],[12],[2,\" \\n\"],[8,\"fa-icon\",[],[[\"@icon\"],[\"check-circle\"]],null],[2,\" \\n\"],[6,[37,2],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onClick\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/radio-list/option/template.hbs"
    }
  });

  _exports.default = _default;
});