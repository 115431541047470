define("product-management/pods/components/purchasing/critical-inventory/order-point-status-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yw2NRs7R",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[8,\"power-select-multiple\",[],[[\"@options\",\"@selected\",\"@searchEnabled\",\"@searchField\",\"@onChange\"],[[34,0],[34,1],true,\"label\",[30,[36,2],[[32,0,[\"handleSelect\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"options\",\"selected\",\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/critical-inventory/order-point-status-filter/template.hbs"
    }
  });

  _exports.default = _default;
});