define("product-management/pods/components/wip-report/wip-table/component", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var statuses = ["overall", "rough_cut", "final_cut", "splicer", "zipper", "screen", "interior", "awning_fabric", "awning_hardware", "roll_tube", "bottom_bar", "side_track", "recessed_final_assembly", "final_assembly", "part", "misc"];
  var headers = {
    overall: "Production",
    rough_cut: "RC",
    final_cut: "FC",
    splicer: "SP",
    zipper: "ZIP",
    screen: "SCRN",
    interior: "INT",
    awning_fabric: "AWN FBRC",
    awning_hardware: "AWN HW",
    roll_tube: "RT",
    bottom_bar: "BB",
    side_track: "ST",
    final_assembly: "FA",
    recessed_final_assembly: "RFA",
    part: "PT",
    misc: "MISC"
  };
  var defaultSort = ["shipOn:asc", "dealerReference", "unitOrder"];
  var defaultDescSort = ["shipOn:desc", "dealerReference", "unitOrder"];

  var _default = Ember.Component.extend({
    headers: headers,
    showWorkQueue: false,
    showPausedIndicator: false,
    dealerSort: Ember.computed("sortCols", function () {
      return this.sortCols.includes("dealerName:asc") ? ["dealerName:desc"] : ["dealerName:asc"];
    }),
    normalSort: Ember.computed("sortCols", function () {
      if (this.sortCols.includes("dealerName:asc")) return defaultSort;else {
        return this.sortCols.includes("shipOn:asc") ? defaultDescSort : defaultSort;
      }
    }),
    actions: {
      handleSort: function handleSort() {
        var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var sortDefintion = null;

        if (field === "dealer") {
          sortDefintion = [].concat((0, _toConsumableArray2.default)(this.dealerSort), defaultSort);
        } else sortDefintion = this.normalSort;

        this.set("sortCols", sortDefintion);
      },
      closeQueue: function closeQueue(workQueue) {
        return this.attrs.onCloseQueue(workQueue);
      }
    },
    sortCols: defaultSort,
    wipItems: Ember.computed.sort("items", "sortCols"),
    statusLoopHeaders: Ember.computed(function () {
      var _this = this;

      return statuses.map(function (item) {
        return _this.headers[item];
      });
    }),
    statusLoop: Ember.computed(function () {
      return statuses.map(function (item) {
        if (item === "screen_loaded") return item;
        return "".concat(item, "_status");
      });
    })
  });

  _exports.default = _default;
});