define("product-management/pods/components/products/complete-screen-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wUJbmiVS",
    "block": "{\"symbols\":[\"blurred\",\"blurred\"],\"statements\":[[6,[37,4],null,[[\"label-text\",\"propertyName\",\"changeset\"],[\"Select a screen type\",\"productType\",[35,0]]],[[\"default\"],[{\"statements\":[[1,[30,[36,2],null,[[\"on-blur\",\"select-item\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"selection\",\"placeholder\"],[[32,2],[32,2],[35,3],\"content.id\",\"content.name\",[35,0,[\"screen\"]],\"Select a mesh type\"]]]],[2,\"\\n\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\\n\\n\"],[6,[37,4],null,[[\"label-text\",\"propertyName\",\"changeset\"],[\"Select a motor\",\"productType\",[35,0]]],[[\"default\"],[{\"statements\":[[1,[30,[36,2],null,[[\"content\",\"on-blur\",\"select-item\",\"optionValuePath\",\"optionLabelPath\",\"selection\",\"placeholder\"],[[35,1],[32,1],[32,1],\"content.id\",\"content.name\",[35,0,[\"motor\"]],\"Select a motor\"]]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"type\",\"checked\"],[\"checkbox\",[35,0,[\"canSwapManualGearBox\"]]]]]],[2,\" Can swap with manual gear box?\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"product\",\"motors\",\"ember-selectize\",\"screens\",\"validated-input\",\"input\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/products/complete-screen-settings/template.hbs"
    }
  });

  _exports.default = _default;
});