define("product-management/pods/components/painless-manufacturing/work-order/instruction-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eEe3nLph",
    "block": "{\"symbols\":[\"instruction\",\"idx\"],\"statements\":[[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"mt-12 w-full mb-3 border-b border-grey-lighter pb-2 text-base font-thin text-grey-darker\"],[12],[2,\"\\n    Instructions\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"painless-manufacturing/work-order/cell/instruction\",[],[[\"@instruction\",\"@index\",\"@withoutCheckboxes\"],[[32,1],[32,2],\"true\"]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"instructions\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/work-order/instruction-summary/template.hbs"
    }
  });

  _exports.default = _default;
});