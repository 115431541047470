define("product-management/pods/components/stardust/data-table/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h7jitHfE",
    "block": "{\"symbols\":[\"row\",\"&default\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[18,2,[[32,1]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"records\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/data-table/body/template.hbs"
    }
  });

  _exports.default = _default;
});