define("product-management/pods/shop/notes-unit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kHsl2etJ",
    "block": "{\"symbols\":[\"note\"],\"statements\":[[10,\"h1\"],[14,0,\"highlight\"],[12],[2,\"This is a notes only order! Please read the notes below for instructions!\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-9\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"Notes\"],[13],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[1,[32,1,[\"note\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"Dealer:\"],[13],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n      \"],[1,[35,3,[\"order\",\"dealer\",\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"lineItem\",\"on-complete\",\"on-signature-changed\"],[[35,3],[30,[36,4],[[32,0],\"complete\"],null],[30,[36,4],[[32,0],\"signedWork\",[35,3]],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"notes\",\"-track-array\",\"each\",\"line-item\",\"action\",\"shop/work-signoff\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop/notes-unit/template.hbs"
    }
  });

  _exports.default = _default;
});