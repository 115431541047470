define("product-management/pods/components/orders/po-number/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      save: function save(changeset) {
        return this.attrs.onSave(changeset);
      }
    }
  });

  _exports.default = _default;
});