define("product-management/pods/components/stardust/data-table/header/th-sortable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sPvHa9S6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n\"],[1,[34,3]],[2,\"\\n\"],[10,\"span\"],[14,0,\"ml-2 w-8\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[35,5],[35,4]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[35,0],\"asc\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-caret-up\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[35,0],\"desc\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-caret-down\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sortDirection\",\"eq\",\"if\",\"label\",\"sortedField\",\"field\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/data-table/header/th-sortable/template.hbs"
    }
  });

  _exports.default = _default;
});