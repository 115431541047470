define("product-management/pods/components/stardust/report/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g0AvXLsZ",
    "block": "{\"symbols\":[\"@field\"],\"statements\":[[6,[37,2],[[32,1,[\"showTotals\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1,[\"type\"]],\"currency\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,3],[[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1,[\"type\"]],\"float\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[34,0]],[2,\"\\n\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"sum\",\"eq\",\"if\",\"format-money\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/report/footer/template.hbs"
    }
  });

  _exports.default = _default;
});