define("product-management/pods/admin/settings/forms/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CoDi/nR+",
    "block": "{\"symbols\":[\"record\"],\"statements\":[[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n\\t\"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.forms.new\"]],[[\"default\"],[{\"statements\":[[2,\"New\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,3],[[35,0,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"table\"],[14,0,\"table-full\"],[12],[2,\"\\n\\t\"],[10,\"thead\"],[12],[2,\"\\n\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Name\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.settings.forms.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[10,\"p\"],[14,0,\"blank-slate\"],[12],[2,\"\\n\\tYou haven't configured any forms in the system yet.\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/forms/index/template.hbs"
    }
  });

  _exports.default = _default;
});