define("product-management/pods/components/shop/current-station/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TLZedJyf",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"h2\"],[12],[2,\"Current Station\"],[13],[2,\"\\n\"],[10,\"h3\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"route\"],[\"btn btn-block btn-danger\",\"shop.pick_station\"]],[[\"default\"],[{\"statements\":[[2,\"Change Station\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"clearfix well change-station-horizontal\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pull-left\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Current Station is \"],[10,\"span\"],[14,0,\"current-station\"],[12],[1,[34,0]],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n    \"],[6,[37,1],null,[[\"class\",\"route\"],[\"btn btn-danger\",\"shop.pick_station\"]],[[\"default\"],[{\"statements\":[[2,\"Change Station\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"currentStation\",\"link-to\",\"isVertical\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/current-station/template.hbs"
    }
  });

  _exports.default = _default;
});