define("product-management/pods/components/shop/shop-code-entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dt4163BM",
    "block": "{\"symbols\":[\"@shopCodeEntryCssClass\"],\"statements\":[[10,\"label\"],[15,0,[32,1]],[12],[2,\"\\n  Enter Your Shop Code\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n  \"],[11,\"input\"],[24,\"autocomplete\",\"off\"],[16,2,[34,3]],[24,\"maxlength\",\"4\"],[24,0,\"form-control\"],[16,4,[34,4]],[4,[38,6],[\"input\",[30,[36,5],[[32,0],\"handleInput\"],null]],null],[12],[13],[2,\"\\n\"],[2,\"  \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[2,\"\\n\"],[6,[37,2],[[35,7]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"thumbs-up\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"thumbs-down\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"isValid\",\"if\",\"code\",\"inputType\",\"action\",\"on\",\"validationRun\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/shop-code-entry/template.hbs"
    }
  });

  _exports.default = _default;
});