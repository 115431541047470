define("product-management/pods/admin/accounting/invoices/controller", ["exports", "ember-invoicing/mixins/invoice-list-controller"], function (_exports, _invoiceListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var statusMap = {
    "drafts": "drafts",
    "ready_for_quickbooks": "outstanding"
  };

  var _default = Ember.Controller.extend(_invoiceListController.default, {
    statusMap: statusMap,
    status: "drafts",
    actions: {}
  });

  _exports.default = _default;
});