define("product-management/pods/components/ordering/start-order/component", ["exports", "product-management/validations/start-order", "ember-changeset-validations", "ember-changeset"], function (_exports, _startOrder, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    StartOrderValidations: _startOrder.default,
    init: function init() {
      this._super.apply(this, arguments);

      var model = this.order;
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_startOrder.default), _startOrder.default);
      this.changeset.validate();
    },
    tagName: "",
    actions: {
      cancel: function cancel() {
        this.attrs.onCancel();
      },
      start: function start(changeset) {
        this.attrs.onStart(changeset);
      }
    }
  });

  _exports.default = _default;
});