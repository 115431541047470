define("product-management/pods/components/dealers/address-book/address/contact-info-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P7J1tQU0",
    "block": "{\"symbols\":[\"@onCancel\"],\"statements\":[[11,\"form\"],[4,[38,1],[\"submit\",[30,[36,0],[[32,0,[\"handleSubmit\"]]],null]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      First Name\\n    \"],[13],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,2]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      Last Name\\n    \"],[13],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,3]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      Phone\\n    \"],[13],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,4]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      Email\\n    \"],[13],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,5]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"my-2\"],[12],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-xs btn-primary\"],[12],[2,\"\\n      Save\\n    \"],[13],[2,\"\\n    or\\n    \"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,1],[\"click\",[32,1]],null],[12],[2,\"\\n      cancel\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"firstName\",\"lastName\",\"phoneNumber\",\"email\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/address-book/address/contact-info-form/template.hbs"
    }
  });

  _exports.default = _default;
});