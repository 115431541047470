define("product-management/pods/orders/show/index/index/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fQMEsSWS",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[8,\"bs-modal\",[],[[\"@onHide\",\"@backdropClose\",\"@closeTitle\"],[[30,[36,0],[\"cancel\"],null],\"false\",\"Close\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[12],[1,[35,1,[\"dealerReference\"]]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/index/index/notes/template.hbs"
    }
  });

  _exports.default = _default;
});