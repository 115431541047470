define("product-management/pods/components/painless-manufacturing/inputs/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kNINsISD",
    "block": "{\"symbols\":[\"@required\",\"&default\"],\"statements\":[[10,\"label\"],[15,0,[31,[\"block mb-1 text-base font-semibold text-grey-darkest\\n  \",[30,[36,0],[[32,1],\"pm-required-label\",\"\"],null],\"\\n\"]]],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/inputs/label/template.hbs"
    }
  });

  _exports.default = _default;
});