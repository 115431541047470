define("product-management/pods/components/ui/local-table-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jBVcIH8v",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\"],[1,[30,[36,1],null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[35,0],\"Search for an item\"]]]],[2,\" \\n\"],[11,\"a\"],[24,0,\"input-group-addon\"],[24,6,\"#\"],[4,[38,2],[[32,0],\"clearFilter\"],null],[12],[1,[30,[36,3],[\"times-circle\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,[[35,4]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"data-filter\",\"input\",\"action\",\"fa-icon\",\"filtered-records\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/local-table-filter/template.hbs"
    }
  });

  _exports.default = _default;
});