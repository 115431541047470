define("product-management/pods/components/admin/inventory-items/table-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m4EFjw/+",
    "block": "{\"symbols\":[\"record\"],\"statements\":[[10,\"table\"],[14,0,\"table table-condensed table-striped\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Name\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Part #\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Details\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"record\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"admin/inventory-items/table-item\",\"sortedRecords\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/admin/inventory-items/table-list/template.hbs"
    }
  });

  _exports.default = _default;
});