define("product-management/pods/admin/transportation/vehicles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ngwc6E7n",
    "block": "{\"symbols\":[\"vehicleType\"],\"statements\":[[10,\"div\"],[14,0,\"flex vehicle-nav\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"mx-2\"],[12],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@model\",\"@route\"],[[32,1,[\"id\"]],\"admin.transportation.vehicles.show\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"name\"]]],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n  \"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"pluralize\",\"model\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/transportation/vehicles/template.hbs"
    }
  });

  _exports.default = _default;
});