define("product-management/pods/components/sun-pro/accounting/delivery-billing-rules/unit-prices/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PKMPeghy",
    "block": "{\"symbols\":[\"search\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"w-2/3\"],[12],[2,\"\\n\"],[6,[37,6],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"sun-pro/ui/search-select\",[],[[\"@searchParams\",\"@searchQuery\",\"@focused\",\"@onSelect\"],[[34,3],[34,4],[34,5],[30,[36,1],[[32,0,[\"handleProductSelect\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"p-1\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"h-32\"],[12],[2,\"\\n          \"],[1,[35,0,[\"name\"]]],[2,\"\\n          \"],[11,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleClearProduct\"]]],null]],null],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-times-circle text-red-dark\"],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"w-1/6\"],[12],[2,\"\\n    \"],[11,\"input\"],[16,2,[34,7]],[24,0,\"form-control\"],[4,[38,8],[[32,0,[\"handleInsertRate\"]],[32,0]],null],[4,[38,2],[\"keydown\",[30,[36,1],[[32,0,[\"handleKeydown\"]]],null]],null],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleRate\"]]],null]],null],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"w-1/6\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[34,9]],[24,0,\"btn btn-sm btn-primary\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n      Save\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"product\",\"fn\",\"on\",\"excludedItems\",\"query\",\"focusSearch\",\"unless\",\"rate\",\"did-insert\",\"invalid\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/accounting/delivery-billing-rules/unit-prices/form/template.hbs"
    }
  });

  _exports.default = _default;
});