define("product-management/pods/search/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    label: (0, _model.attr)(),
    searchableType: (0, _model.attr)(),
    searchableId: (0, _model.attr)(),
    details: (0, _model.attr)(),
    status: Ember.computed("details.status", function () {
      var status = this.get("details.status");
      if (status) return Ember.String.capitalize(status);
    })
  });

  _exports.default = _default;
});