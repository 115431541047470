define("product-management/pods/admin/settings/shop-codes/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wi87DQcj",
    "block": "{\"symbols\":[],\"statements\":[[8,\"shop-codes/new-form\",[],[[\"@on-cancel\",\"@on-add\",\"@new-shop-code\",\"@cancel-route\"],[[30,[36,0],[\"cancel\"],null],[30,[36,0],[\"add\"],null],[30,[36,3],[[35,2],[35,1]],null],[34,4]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"validation\",\"new-shop-code\",\"changeset\",\"cancel-route\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/shop-codes/new/template.hbs"
    }
  });

  _exports.default = _default;
});