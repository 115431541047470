define("product-management/pods/components/ordering/return-of-goods/download-rga/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "feAyHwNB",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"button\"],[24,0,\"btn btn-default\"],[17,1],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleRgaDownload\"]]],null]],null],[12],[2,\"\\n  Download RGA\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/return-of-goods/download-rga/template.hbs"
    }
  });

  _exports.default = _default;
});