define("product-management/pods/admin/accounting/purchasing/purchase-orders/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lRAi7uHs",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"Start a Purchase Order\"],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"\\nTo create a purchase order begin by selecting a vendor\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[12],[2,\"Vendor\"],[13],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,2],null,[[\"content\",\"selection\",\"optionValuePath\",\"optionLabelPath\"],[[35,1],[35,0],\"content.id\",\"content.name\"]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[11,\"button\"],[24,0,\"btn-save\"],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n\\tCreate\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"vendor\",\"model\",\"ember-selectize\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/purchasing/purchase-orders/new/template.hbs"
    }
  });

  _exports.default = _default;
});