define("product-management/pods/components/dealers/accounting/display/cards-on-file/table/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "in+BvNWy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Card Type\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Last 4\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Expiration\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Name on Card\"],[13],[2,\"\\n        \"],[10,\"th\"],[14,0,\"text-center\"],[12],[2,\"Primary Payment Method\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/accounting/display/cards-on-file/table/header/template.hbs"
    }
  });

  _exports.default = _default;
});