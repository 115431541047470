define("product-management/pods/components/dealers/address-book/contact-info-for-address/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cs65WaIs",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"my-1\"],[12],[2,\"\\n  \"],[8,\"dealers/address-book/address\",[[24,0,\"\"]],[[\"@dealer\",\"@address\",\"@onSaveComplete\"],[[34,0],[34,1],[30,[36,2],[[32,0,[\"handleSave\"]]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dealer\",\"address\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/address-book/contact-info-for-address/template.hbs"
    }
  });

  _exports.default = _default;
});