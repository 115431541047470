define("product-management/pods/components/shop/station-hold/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4gYlTuqJ",
    "block": "{\"symbols\":[\"modal\",\"@lineItem\",\"@station\",\"@completeRoute\",\"&attrs\"],\"statements\":[[11,\"button\"],[24,0,\"btn btn-warning\"],[17,5],[4,[38,2],[\"click\",[30,[36,0],[[32,0,[\"handleLoad\"]]],null]],null],[12],[2,\"\\n  Put On Hold\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[8,\"bs-modal\",[],[[\"@size\",\"@onHide\",\"@backdropClose\"],[\"lg\",[30,[36,0],[[32,0,[\"handleClose\"]]],null],false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,0,\"font-semibold text-xl\"],[12],[2,\"\\n      Put On Hold\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"painless-manufacturing/production/station-hold\",[],[[\"@lineItem\",\"@station\",\"@noTrigger\",\"@onComplete\",\"@completeRoute\"],[[32,2],[30,[36,1],null,[[\"name\"],[[32,3]]]],true,[30,[36,0],[[32,0,[\"handleComplete\"]]],null],[32,4]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,2],[\"click\",[30,[36,0],[[32,0,[\"handleClose\"]]],null]],null],[12],[2,\"\\n      cancel\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"hash\",\"on\",\"isShowing\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/station-hold/template.hbs"
    }
  });

  _exports.default = _default;
});