define("product-management/pods/shop/pick-work-queue/route", ["exports", "js-cookie"], function (_exports, _jsCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var workQueueCookieName = "WORK_QUEUE";

  var _default = Ember.Route.extend({
    model: function model() {
      var controller = this.controllerFor(this.routeName);
      var consumer = this.modelFor("shop");
      return Ember.RSVP.hash({
        cookie: _jsCookie.default.get(workQueueCookieName)
      });
    },
    deactivate: function deactivate() {
      this.controller.unsubscribeFromChannel("queues");
    }
  });

  _exports.default = _default;
});