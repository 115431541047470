define("product-management/pods/components/production-lines/configuration/available-products/product-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e9miY3Fg",
    "block": "{\"symbols\":[\"product\",\"@onAdd\",\"@products\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[30,[36,2],[\"name\",[32,3]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flex align-items\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[12],[2,\"\\n\\t\\t\\t\"],[11,\"a\"],[24,0,\"mr-2 btn btn-default btn-sm\"],[4,[38,1],[\"click\",[30,[36,0],[[32,2],[32,1]],null]],null],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-plus-circle\"],[12],[13],[2,\" Add\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.settings.products.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/production-lines/configuration/available-products/product-list/template.hbs"
    }
  });

  _exports.default = _default;
});