define("product-management/pods/components/ui/forms/uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+loHO0U7",
    "block": "{\"symbols\":[\"@buttonText\",\"&default\"],\"statements\":[[18,2,[[30,[36,4],null,[[\"file\",\"fileContent\",\"body\"],[[35,1],[35,3],[30,[36,2],[\"ui/forms/uploader/body\"],[[\"file\",\"onUploadFileChange\",\"buttonText\"],[[35,1],[30,[36,0],[[32,0,[\"handleUploadFileChange\"]]],null],[32,1]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"fileToUpload\",\"component\",\"fileContent\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/forms/uploader/template.hbs"
    }
  });

  _exports.default = _default;
});