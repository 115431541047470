define("product-management/pods/components/purchasing/critical-inventory/tracking-toggle-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dRpsXjnO",
    "block": "{\"symbols\":[\"@item\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"rounded \",[30,[36,0],[[32,1,[\"critical\"]],\"bg-red-dark\",\"bg-blue\"],null],\" text-sm text-white p-2 font-bold text-center\"]]],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleTrack\"]]],null]],null],[12],[2,\"\\n\\t\"],[10,\"i\"],[15,0,[31,[\"fa \",[34,3]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\",\"iconClass\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/critical-inventory/tracking-toggle-button/template.hbs"
    }
  });

  _exports.default = _default;
});