define("product-management/pods/components/shop/line-items/recessed-final-assembly-station/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortCol: ["id"],
    lineItems: Ember.computed.sort("order.lineItems", "sortCol")
  });

  _exports.default = _default;
});