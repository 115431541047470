define("product-management/pods/shop-diagnostic/cut-sheet/controller", ["exports", "product-management/mixins/custom/shop/cut-sheet-title"], function (_exports, _cutSheetTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_cutSheetTitle.default, {
    lineItem: Ember.computed.alias("model"),
    order: Ember.computed.alias('lineItem.order'),
    sequenceNumber: Ember.computed.alias("lineItem.sequenceNumber")
  });

  _exports.default = _default;
});