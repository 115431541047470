define("product-management/pods/admin/orders/report/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var productionStatusFilters = [{
    label: "Quoted",
    value: "quoted"
  }, {
    label: "Ordered",
    value: "ordered"
  }, {
    label: "In Process",
    value: "in_process"
  }, {
    label: "Complete",
    value: "complete"
  }, {
    label: "Ready for Pickup",
    value: "ready_for_pickup"
  }, {
    label: "On Truck",
    value: "on_truck"
  }, {
    label: "Shipped",
    value: "shipped"
  }, {
    label: "Invoiced",
    value: "invoiced"
  }];
  var sections = ["ordered", "in_process", "complete", "shipped", "invoiced"];
  var shippingMethods = ["Delivery", "Pick up", "Shipping"];

  var _default = Ember.Controller.extend({
    accountManager: null,
    sections: sections,
    shippingMethods: shippingMethods,
    productionStatusFilters: productionStatusFilters,
    sortCol: ["auditTrail.orderedAt"],
    orders: Ember.computed.sort("orderData", "sortCol"),
    dealerSortColumn: ["name"],
    dealers: Ember.computed.sort("model.dealers", "dealerSortColumn"),
    selectedDealers: Ember.A(),
    selectedStatuses: Ember.A(),
    selectedShippingMethods: Ember.A(),
    selectedDealerIds: Ember.computed.map("selectedDealers", function (item) {
      return item.id;
    }),
    selectedStatusIds: Ember.computed.map("selectedStatuses", function (item) {
      return item.value;
    }),
    hasSelectedDealersFilter: Ember.computed.notEmpty("selectedDealerIds"),
    hasSelectedStatusFilter: Ember.computed.notEmpty("selectedStatusIds"),
    hasSelectedShippingMethods: Ember.computed.notEmpty("selectedShippingMethods"),
    hasOrderedDateStart: Ember.computed.notEmpty("orderedDateStart"),
    hasOrderedDateEnd: Ember.computed.notEmpty("orderedDateEnd"),
    hasCompletedDateStart: Ember.computed.notEmpty("completedDateStart"),
    hasCompletedDateEnd: Ember.computed.notEmpty("completedDateEnd"),
    hasShippedDateStart: Ember.computed.notEmpty("shippedDateStart"),
    hasShippedDateEnd: Ember.computed.notEmpty("shippedDateEnd"),
    hasInvoicedDateStart: Ember.computed.notEmpty("invoicedDateStart"),
    hasInvoicedDateEnd: Ember.computed.notEmpty("invoicedDateEnd"),
    hasFilter: Ember.computed.or("hasSelectedStatusFilter", "hasSelectedDealersFilter", "hasSelectedShippingMethods", "hasOrderedDateStart", "hasOrderedDateEnd", "hasCompletedDateStart", "hasCompletedDateEnd", "hasShippedDateStart", "hasShippedDateEnd", "hasInvoicedDateStart", "hasInvoicedDateEnd"),
    getOrders: function getOrders(hash) {
      var _this = this;

      var selectedDealerIds = hash.selectedDealerIds,
          selectedStatusIds = hash.selectedStatusIds,
          selectedShippingMethods = hash.selectedShippingMethods,
          accountManager = hash.accountManager;
      Ember.set(this, "isLoading", true);

      var _this$getDateSets = this.getDateSets(),
          completeAtDates = _this$getDateSets.completeAtDates,
          invoicedAtDates = _this$getDateSets.invoicedAtDates,
          shippedAtDates = _this$getDateSets.shippedAtDates,
          orderedAtDates = _this$getDateSets.orderedAtDates;

      return this.store.query("orderSummary", {
        "filter[by_status]": selectedStatusIds,
        "filter[by_shipping_method]": selectedShippingMethods,
        "filter[by_dealer]": selectedDealerIds,
        "filter[by_dates]": {
          complete_at: completeAtDates,
          ordered_at: orderedAtDates,
          shipped_at: shippedAtDates,
          invoiced_at: invoicedAtDates
        },
        "filter[by_account_manager]": Ember.isPresent(accountManager) ? accountManager.id : null
      }).then(function (orders) {
        Ember.set(_this, "orderData", orders);
        Ember.set(_this, "isLoading", false);
      });
    },
    convertDateToTime: function convertDateToTime(date) {
      if (date != undefined) date = date.getTime();
      return date;
    },
    getDateField: function getDateField(hash) {
      var prefix = hash.prefix,
          period = hash.period;
      return Ember.get(this, "".concat(prefix, "Date").concat(period));
    },
    getDateSets: function getDateSets() {
      var completeAtDates = this.getDateSet({
        prefix: "completed"
      });
      var invoicedAtDates = this.getDateSet({
        prefix: "invoiced"
      });
      var shippedAtDates = this.getDateSet({
        prefix: "shipped"
      });
      var orderedAtDates = this.getDateSet({
        prefix: "ordered"
      });
      return {
        completeAtDates: completeAtDates,
        invoicedAtDates: invoicedAtDates,
        shippedAtDates: shippedAtDates,
        orderedAtDates: orderedAtDates
      };
    },
    getDateSet: function getDateSet(hash) {
      var _this$getRawDateSet = this.getRawDateSet(hash),
          start = _this$getRawDateSet.start,
          end = _this$getRawDateSet.end;

      start = this.convertDateToTime(start);
      end = this.convertDateToTime(end);
      return {
        start: start,
        end: end
      };
    },
    getRawDateSet: function getRawDateSet(hash) {
      var prefix = hash.prefix;
      var start = this.getDateField({
        prefix: prefix,
        period: "Start"
      });
      var end = this.getDateField({
        prefix: prefix,
        period: "End"
      });
      return {
        start: start,
        end: end
      };
    },
    actions: {
      runReport: function runReport(selectedDealerIds, selectedStatusIds, selectedShippingMethods, accountManager) {
        this.getOrders({
          selectedDealerIds: selectedDealerIds,
          selectedStatusIds: selectedStatusIds,
          selectedShippingMethods: selectedShippingMethods,
          accountManager: accountManager
        });
      }
    }
  });

  _exports.default = _default;
});