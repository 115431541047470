define("product-management/pods/users/confirmation/route", ["exports", "ember-ui-helpers/mixins/notifyable"], function (_exports, _notifyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_notifyable.default, {
    notifier: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("confirmation", params.token_id);
    },
    actions: {
      error: function error() {
        this.error("Sorry it appears like this is an invalid invitation.");
        this.transitionTo("login");
      }
    }
  });

  _exports.default = _default;
});