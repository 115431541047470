define("product-management/pods/components/shop/line-items/interior-station/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    screenStatus: Ember.computed.alias("lineItem.status.interior_rough_cut_status"),
    isScreenComplete: Ember.computed.equal("screenStatus", "complete"),
    actions: {
      signedWork: function signedWork(data) {
        this.attrs.signedWork(data[1]);
      },
      complete: function complete(lineItem, sendTo) {
        this.attrs.onComplete(lineItem, sendTo);
      }
    }
  });

  _exports.default = _default;
});