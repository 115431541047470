define("product-management/pods/line-item/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    order: (0, _model.belongsTo)(),
    product: (0, _model.belongsTo)(),
    position: (0, _model.attr)(),
    workSignoffs: (0, _model.hasMany)(),
    details: (0, _model.attr)("raw", {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    cells: (0, _model.attr)(),
    price: (0, _model.attr)(),
    quotedPrice: (0, _model.attr)(),
    summaryContent: (0, _model.attr)("raw"),
    workflowContent: (0, _model.attr)("raw"),
    notes: (0, _model.attr)("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    tags: (0, _model.attr)(),
    isStandardSidetrack: Ember.computed.equal("details.side_track_type", "standard"),
    isRecessedSidetrack: Ember.computed.equal("details.side_track_type", "recessed"),
    isFourFiveHood: Ember.computed.equal("details.hood_size", "4.5"),
    quantity: (0, _model.attr)(),
    notesReviewedAt: (0, _model.attr)(),
    sequenceNumber: (0, _model.attr)(),
    notesReviewedBy: (0, _model.belongsTo)("user", {
      async: true
    }),
    notesReviewer: (0, _model.attr)(),
    loadedFields: Ember.computed("status", function () {
      return Object.keys(this.status).filter(function (item) {
        return item.endsWith("_loaded");
      });
    }),
    noLoadedFields: Ember.computed("loadedFields", function () {
      return Ember.isEmpty(this.loadedFields);
    }),
    isFullyLoaded: Ember.computed("loadedFields", "status", "noLoadedFields", function () {
      var loadedFields = this.loadedFields;
      var status = this.status;
      var noLoadedFields = this.noLoadedFields;
      if (noLoadedFields) return false;
      return loadedFields.every(function (item) {
        return status[item];
      });
    }),
    hasWorkSignoffs: Ember.computed.notEmpty("workSignoffs"),
    isNotFullyLoaded: Ember.computed.not("isFullyLoaded"),
    reviewed: Ember.computed("notesReviewedAt", {
      get: function get() {
        return Ember.isPresent(this.notesReviewedAt);
      },
      set: function set(value) {
        var at = value ? new Date() : null;
        Ember.set(this, "notesReviewedAt", at);
        return value;
      }
    }),
    needToReviewNotes: Ember.computed("details.dealer_notes", "notesReviewedBy", "notesReviewedAt", "reviewed", function () {
      if (Ember.isEmpty(this.get("details.dealer_notes"))) return false;
      return Ember.isEmpty(this.notesReviewedAt);
    }),
    status: (0, _model.attr)("raw"),
    dealerNotes: Ember.computed("details.dealer_notes", function () {
      var notes = this.get("details.dealer_notes");
      return Ember.isPresent(notes) ? notes : "";
    }),
    rollTubeSizeForShop: Ember.computed("details.roll_tube_size", function () {
      var rollTubeSize = this.get("details.roll_tube_size");
      if (rollTubeSize == "4 (Standard Rolltube for Under 20ft)") return "4";
      return rollTubeSize;
    }),
    crankHandleSize: Ember.computed.alias("details.crank_handle_size"),
    dropScreen: Ember.computed.alias("details.drop_screen"),
    extraRemote: Ember.computed.alias("details.extra_remote"),
    windSensor: Ember.computed.alias("details.wind_sensor"),
    dropScreenSolarMesh: Ember.computed.alias("details.drop_screen_solar_mesh"),
    projection: Ember.computed.alias("details.projection"),
    unitType: Ember.computed.alias("details.unit_type"),
    frameSize: Ember.computed.alias("details.frame_size"),
    frameColor: Ember.computed.alias("details.frame_color"),
    mountingBrackets: Ember.computed.alias('details.mounting_brackets'),
    fabricNumber: Ember.computed.alias("details.fabric_number"),
    fabricName: Ember.computed.alias("details.fabric_name"),
    fabric: Ember.computed.alias("details.fabric"),
    valance: Ember.computed.alias("details.valance"),
    editNotes: Ember.computed.alias("details.dealer_notes"),
    controlLocation: Ember.computed.alias("details.control_location"),
    needRemote: Ember.computed.alias("details.need_remote"),
    meshName: Ember.computed.alias("details.mesh_name"),
    meshColor: Ember.computed.alias("details.mesh_color"),
    controlRemoteChannels: Ember.computed.alias("details.control_remote_channels"),
    hoodSize: Ember.computed.alias("details.hood_size"),
    hoodColor: Ember.computed.alias("details.hood_color"),
    fasciaColor: Ember.computed.alias("details.fascia_color"),
    sideTrackPunched: Ember.computed.alias("details.side_track_punched"),
    sideTrackAngle: Ember.computed.alias("details.side_track_angle"),
    sideTrackAnglePunched: Ember.computed.alias("details.side_track_angle_punched"),
    sideTrackUChannel: Ember.computed.alias("details.side_track_u_channel"),
    sideTrackBrackets: Ember.computed.alias("details.side_track_brackets"),
    recessedEndCaps: Ember.computed.alias("details.recessed_end_caps"),
    sideTrackColor: Ember.computed.alias("details.side_track_color"),
    bottomBarColor: Ember.computed.alias("details.bottom_bar_color"),
    probeColor: Ember.computed.alias("details.probe_color"),
    bottomBarSeal: Ember.computed.alias("details.bottom_bar_seal"),
    width: Ember.computed.alias("details.width"),
    leftHeight: Ember.computed.alias("details.left_height"),
    rightHeight: Ember.computed.alias("details.right_height"),
    height: Ember.computed.alias("details.height"),
    motorId: Ember.computed.alias("details.motor_id"),
    motorName: Ember.computed.alias("details.motor_name"),
    remoteQuantity: Ember.computed.alias("details.remote_quantity"),
    sideTrackType: Ember.computed.alias("details.side_track_type"),
    controlType: Ember.computed.alias("details.control_type"),
    zipperTop: Ember.computed.alias("details.zipper_top"),
    zipperBottom: Ember.computed.alias("details.zipper_bottom"),
    zipperRight: Ember.computed.alias("details.zipper_right"),
    zipperLeft: Ember.computed.alias("details.zipper_left"),
    zipperColor: Ember.computed.alias("details.zipper_color"),
    screenType: Ember.computed.alias("details.screen_type"),
    rollTubeSize: Ember.computed.alias("details.roll_tube_size"),
    meshProduct: Ember.computed.alias("details.mesh_product_id"),
    subtotal: Ember.computed("quantity", "quotedPrice", function () {
      return this.quantity * this.quotedPrice;
    }),
    swapOrders: (0, _emberApiActions.memberAction)({
      type: "put",
      path: "swap_orders"
    }),
    forceComplete: (0, _emberApiActions.memberAction)({
      type: "put",
      path: "force_complete"
    }),
    updateDealerNotes: (0, _emberApiActions.memberAction)({
      type: "put",
      path: "update_dealer_notes"
    }),
    markLoaded: (0, _emberApiActions.memberAction)({
      type: "put",
      path: "mark_loaded"
    }),
    release: (0, _emberApiActions.memberAction)({
      type: "put",
      path: "release"
    }),
    completeStep: (0, _emberApiActions.memberAction)({
      type: "put",
      path: "complete_step"
    }),
    checkout: (0, _emberApiActions.memberAction)({
      type: "put",
      path: "check_out"
    }),
    duplicate: (0, _emberApiActions.memberAction)({
      type: "post",
      path: "duplicate"
    })
  });

  _exports.default = _default;
});