define("product-management/pods/components/pm/order/line-item/quantity-and-price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VqWechkt",
    "block": "{\"symbols\":[\"@lineItem\"],\"statements\":[[6,[37,4],[[30,[36,3],[[32,0,[\"price\"]],[30,[36,2],[[32,0,[\"price\"]],0],null]],null]],null,[[\"default\"],[{\"statements\":[[11,\"td\"],[4,[38,0],[[32,0,[\"handleInsert\"]],[32,0]],null],[12],[1,[32,1,[\"quantity\"]]],[13],[2,\"\\n  \"],[10,\"td\"],[12],[1,[30,[36,1],[[32,0,[\"price\"]]],null]],[13],[2,\"\\n  \"],[10,\"td\"],[12],[1,[30,[36,1],[[32,0,[\"subtotal\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"format-money\",\"gt\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/order/line-item/quantity-and-price/template.hbs"
    }
  });

  _exports.default = _default;
});