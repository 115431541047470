define("product-management/pods/components/ui-link-to/component", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    href: Ember.computed('args.[]', function () {
      var _this$router;

      return (_this$router = this.router).urlFor.apply(_this$router, (0, _toConsumableArray2.default)(this.args));
    }),
    isActive: Ember.computed('router.currentRouteName', function () {
      var _this$router2;

      return (_this$router2 = this.router).isActive.apply(_this$router2, (0, _toConsumableArray2.default)(this.args));
    }),
    actions: {
      transitionTo: function transitionTo() {
        var _this$router3;

        (_this$router3 = this.router).transitionTo.apply(_this$router3, (0, _toConsumableArray2.default)(this.args));
      }
    }
  }).reopenClass({
    positionalParams: 'args'
  });

  _exports.default = _default;
});