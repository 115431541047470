define("product-management/pods/components/stardust/report/compared-footers/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2wpBKWbF",
    "block": "{\"symbols\":[\"@cellClass\",\"@showChange\",\"@showChangePercentage\",\"@comparing\"],\"statements\":[[10,\"td\"],[15,0,[32,1]],[12],[2,\"\\n  \"],[1,[34,3]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"td\"],[15,0,[32,1]],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[15,0,[32,0,[\"changeClass\"]]],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[15,0,[32,1]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"changeTotal\",\"comparedField\",\"if\",\"field\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/report/compared-footers/item/template.hbs"
    }
  });

  _exports.default = _default;
});