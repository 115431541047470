define("product-management/pods/components/pm/comments/trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gs7rd73k",
    "block": "{\"symbols\":[\"@onFocusComment\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"a\"],[14,6,\"#\"],[15,\"onclick\",[30,[36,0],[[32,0],[32,1]],null]],[14,0,\"appearance-none flex-1 flex items-center  py-2 text-center text-lg text-grey-dark no-underline hover:no-underline\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"comment\"],[[\"class\"],[\"mr-3\"]]]],[2,\" Comment\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"fa-icon\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/comments/trigger/template.hbs"
    }
  });

  _exports.default = _default;
});