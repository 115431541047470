define("product-management/pods/orders/show/index/index/review-notes/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord("line-item", params.line_item_id, {
        include: "notes-reviewed-by"
      });
    }
  });

  _exports.default = _default;
});