define("product-management/pods/components/painless-manufacturing/production/unit-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oEt5TIAX",
    "block": "{\"symbols\":[\"station\",\"@lineItem\",\"@hideStatusLabel\",\"&default\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"mt-3 flex flex-wrap\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[30,[36,5],[\"position\",[35,4]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,4,[[30,[36,2],null,[[\"station\",\"stationStatusComponent\"],[[32,1],[30,[36,1],[\"painless-manufacturing/production/unit-status/station-status\"],[[\"station\",\"lineItem\"],[[32,1],[35,0]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"painless-manufacturing/production/unit-status/station-status\",[[24,0,\"w-48\"]],[[\"@station\",\"@lineItem\",\"@hideStatusLabel\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"lineItem\",\"component\",\"hash\",\"if\",\"stations\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/unit-status/template.hbs"
    }
  });

  _exports.default = _default;
});