define("product-management/pods/components/stations/configuration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iepoMqDr",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-ace\",[],[[\"@lines\",\"@value\",\"@update\",\"@enableDefaultAutocompletion\",\"@useWrapMode\",\"@useWorker\",\"@useSoftTabs\",\"@tabSize\",\"@newLineMode\",\"@mode\",\"@editorClass\"],[\"20\",[32,0,[\"configuration\"]],[30,[36,0],[[32,0,[\"handleEditorUpdate\"]]],null],true,true,true,true,2,\"auto\",\"ace/mode/ruby\",\"text-sm\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stations/configuration/template.hbs"
    }
  });

  _exports.default = _default;
});