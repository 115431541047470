define("product-management/pods/components/line-items/other-units/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // otherLineItems: Ember.computed.filter("content", function(item){
    //   return item.id != this.get("lineItem.id") &&
    //    item.get(
    // }),
    otherLineItems: Ember.computed("content", "station", "lineItem.id", function () {
      var content = this.content;
      var station = this.station;
      var currentId = this.get("lineItem.id");
      var status = "".concat(station.replace("-", "_"), "_status");
      return content.filter(function (item) {
        return item.id != currentId && item.get('status')[status] != undefined;
      });
    }),
    otherLineItemsSortBy: ["sequenceNumber"],
    lineItems: Ember.computed.sort("otherLineItems", "otherLineItemsSortBy")
  });

  _exports.default = _default;
});