define("product-management/pods/admin/accounting/purchasing/inventory-items/import/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sprjXwWY",
    "block": "{\"symbols\":[\"Importer\"],\"statements\":[[10,\"h4\"],[12],[2,\"\\n  Import Costs\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"my-2\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.accounting.purchasing.inventory-items\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    back to items\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n  \"],[8,\"ui/forms/csv-uploader\",[],[[\"@buttonText\",\"@requiredFields\",\"@importMutation\"],[\"Import CSV Inventory Costs File\",[30,[36,0],[\"sku\",\"description\",\"unitOfMeasure\",\"price\"],null],[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,1,[\"imported\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"block my-4 font-semibold text-2xl\"],[12],[2,\"\\n        The inventory items have been updated!\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"array\",\"importMutation\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/purchasing/inventory-items/import/template.hbs"
    }
  });

  _exports.default = _default;
});