define("product-management/pods/admin/orders/report/route", ["exports", "ember-apollo-client", "product-management/pods/admin/orders/report/account-managers.graphql"], function (_exports, _emberApolloClient, _accountManagers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var orders = null;
      return Ember.RSVP.hash({
        dealers: this.store.query("dealer", {
          reload: true,
          "fields[dealers]": "name,id"
        }),
        accountManagers: this.apollo.watchQuery({
          query: _accountManagers.default
        }, "accountManagers")
      });
    },
    resetController: function resetController(controller) {
      controller.setProperties({
        selectedDealers: Ember.A(),
        selectedStatuses: Ember.A(),
        selectedShippingMethods: Ember.A(),
        orderStartDate: null,
        orderEndDate: null,
        completedDateStart: null,
        completedDateEnd: null,
        shippedDateStart: null,
        shippedDateEnd: null,
        invoicedDateStart: null,
        invoicedDateEnd: null,
        accountManager: null
      });
    }
  });

  _exports.default = _default;
});