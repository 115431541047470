define("product-management/helpers/fixed-decimal-places", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function fixedDecimalPlaces(number, _ref) {
    var decimalPlaces = _ref.decimalPlaces,
        forceDecimals = _ref.forceDecimals;
    number = Number(number);
    var wholeNumber = number % 1 === 0;
    if (wholeNumber && (forceDecimals === undefined || forceDecimals)) return number;
    return number.toFixed(decimalPlaces || 2);
  });

  _exports.default = _default;
});