define("product-management/pods/components/action-notice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6iGFZVjE",
    "block": "{\"symbols\":[\"@message\",\"&default\",\"@show\"],\"statements\":[[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[11,\"div\"],[24,0,\"block-save-notice \"],[4,[38,0],[[32,0,[\"handleInsert\"]],[32,0]],null],[12],[2,\"\\n\\t\\t\\t\"],[18,2,null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[11,\"span\"],[24,0,\"save-notice \"],[4,[38,0],[[32,0,[\"handleInsert\"]],[32,0]],null],[12],[1,[32,1]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/action-notice/template.hbs"
    }
  });

  _exports.default = _default;
});