define("product-management/pods/components/shop/work-signoff/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X+a8GMja",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,16],[[35,15,[\"isAdmin\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[8,\"link-to\",[[24,0,\"btn btn-primary btn-lg btn-block\"]],[[\"@route\"],[\"shop\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  Back\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[14,0,\"col-sm-6 col-sm-offset-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"signature-pad-container\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"text-muted text-center\"],[12],[2,\"\\n      Please initial below before completing.\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,7],null,[[\"exportFormat\",\"color\",\"background-color\",\"decor-color\",\"showUndoButton\",\"lineWidth\",\"width\",\"height\",\"changeListener\",\"command\"],[\"svgbase64\",[35,6],[35,5],[35,4],[35,3],[35,2],\"100%\",\"100%\",[30,[36,1],[[32,0],\"onSignatureChanged\"],null],[35,0]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"col-sm-3\"],[12],[2,\"\\n  \"],[1,[30,[36,10],null,[[\"isValid\",\"code\"],[[35,9],[35,8]]]]],[2,\"\\n  \"],[1,[30,[36,14],null,[[\"can-complete\",\"on-complete\",\"not-ready-to-complete\"],[[35,13],[30,[36,1],[[32,0],\"complete\",[35,12]],null],[35,11]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"command\",\"action\",\"lineWidth\",\"showUndoButton\",\"lineColor\",\"backgroundColor\",\"color\",\"ember-jsignature\",\"shop-code\",\"isCodeValid\",\"shop/shop-code-entry\",\"notReadyToComplete\",\"lineItem\",\"can-complete\",\"shop/station-signature\",\"session\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/work-signoff/template.hbs"
    }
  });

  _exports.default = _default;
});