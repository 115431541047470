define("product-management/pods/components/power-select-from-option-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PZwNcNRq",
    "block": "{\"symbols\":[\"option\",\"@renderInPlace\",\"@onChange\",\"@selected\"],\"statements\":[[6,[37,1],[[32,0,[\"fetchOptions\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[8,\"pm/ui/loading-message\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[8,\"power-select\",[],[[\"@renderInPlace\",\"@options\",\"@onChange\",\"@selected\"],[[32,2],[34,0],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"options\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/power-select-from-option-list/template.hbs"
    }
  });

  _exports.default = _default;
});