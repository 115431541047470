define("product-management/pods/components/pm/order/create-invoice-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2hk/gEdD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[14,6,\"#\"],[15,\"disabled\",[34,0,[\"isRunning\"]]],[15,\"onclick\",[30,[36,1],[[35,0]],null]],[12],[2,\"\\n\"],[6,[37,2],[[35,0,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      Creating Invoice...\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    Create Invoice\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"createInvoice\",\"perform\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/order/create-invoice-button/template.hbs"
    }
  });

  _exports.default = _default;
});