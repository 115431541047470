define("product-management/pods/picklists/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "48uOcnUz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex justify-between items-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex picker-toggle text-xl\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@query\"],[\"picklists\",[30,[36,0],null,[[\"shippingMethod\"],[\"Delivery\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Delivery\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"mx-2\"],[12],[2,\"\\n      |\\n    \"],[13],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@query\"],[\"picklists\",[30,[36,0],null,[[\"shippingMethod\"],[\"Shipping\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Shipping\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"mx-2\"],[12],[2,\"\\n      |\\n    \"],[13],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@query\"],[\"picklists\",[30,[36,0],null,[[\"shippingMethod\"],[\"Pick up\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Pick up\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-1/3\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"font-semibold\"],[12],[2,\"\\n      Select your label printer\\n    \"],[13],[2,\"\\n    \"],[8,\"print/printers\",[[24,0,\"mt-1 mb-4\"]],[[\"@format\"],[\"select\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"fulfillment/picklist\",[],[[\"@mode\",\"@container\"],[[34,1],[34,2]]],null]],\"hasEval\":false,\"upvars\":[\"hash\",\"mode\",\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/picklists/show/template.hbs"
    }
  });

  _exports.default = _default;
});