define("product-management/pods/components/sun-pro/qc/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rJ7nuU5r",
    "block": "{\"symbols\":[\"canDo\",\"Menu\",\"@lineItem\"],\"statements\":[[8,\"can-do\",[],[[\"@model\",\"@modelClass\",\"@action\",\"@policy\"],[[32,3],\"LineItem\",\"show\",\"LineItem::Qc\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,1,[\"allowed\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[15,0,[31,[[30,[36,2],[[30,[36,1],[[35,0],0],null],\"bg-red-dark\",\"bg-green-dark\"],null],\" text-white font-semibold block w-full rounded p-2 text-lg flex justify-between\"]]],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      QC\\n    \"],[13],[2,\"\\n    \"],[8,\"stardust/ui/flyout-menu\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-48 text-grey-dark\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[35,0],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[[24,0,\"block text-sm flex justify-between items-center border-b border-grey-light pb-2 mb-2 hover:no-underline\"]],[[\"@route\",\"@model\"],[\"orders.show.index.index.qc-reports\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          View Reports\\n          \"],[10,\"label\"],[14,0,\"bg-green-dark px-4 py-2 rounded-full text-white text-xs hover:text-white\"],[12],[2,\"\\n            \"],[1,[34,0]],[2,\"\\n          \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[8,\"link-to\",[[24,0,\"block text-sm pb-2\"]],[[\"@route\",\"@model\"],[\"orders.show.index.index.new-qc-report\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          New Report\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"reportCount\",\"gt\",\"if\",\"loadQc\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/qc/button/template.hbs"
    }
  });

  _exports.default = _default;
});