define("product-management/pods/shop/controller", ["exports", "product-management/mixins/logout-controller", "shamgar-auto-upgrade/mixins/auto-upgrade-controller", "ember-get-config"], function (_exports, _logoutController, _autoUpgradeController, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_autoUpgradeController.default, _logoutController.default, {
    session: Ember.inject.service(),
    appVersion: Ember.computed(function () {
      var versionRegExp = /\d+[.]\d+[.]\d+/;
      return _emberGetConfig.default.APP.appVersion.match(versionRegExp)[0].trim();
    })
  });

  _exports.default = _default;
});