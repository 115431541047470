define("product-management/mixins/ordering/complete-screen/base-data-entry", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    valueHashKey: [],
    pickValue: function pickValue(valueHash) {
      if (this.attrs.onSelect != undefined) this.attrs.onSelect(valueHash);
    },
    actions: {
      fieldChange: function fieldChange(choice, lineItem, option) {
        if (this.attrs.onFieldChange != undefined) this.attrs.onFieldChange(choice, lineItem, option);
      },
      pickOtherValue: function pickOtherValue(key, value) {
        var valueHash = (0, _defineProperty2.default)({}, key, value);
        this.pickValue(valueHash);
      },
      pickValue: function pickValue(value) {
        var valueHash = {};
        var valueHashKey = this.valueHashKey;
        valueHashKey.forEach(function (item) {
          valueHash[item] = value;
        });
        this.pickValue(valueHash);
      }
    }
  });

  _exports.default = _default;
});