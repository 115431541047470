define("product-management/pods/components/invoices/shipping-card-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["list-group-item"],
    tagName: "li"
  });

  _exports.default = _default;
});