define("product-management/pods/components/pm/order/invoice-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JLethoMQ",
    "block": "{\"symbols\":[\"@order\"],\"statements\":[[6,[37,3],[[30,[36,2],[[35,1,[\"isAdmin\"]],[32,1,[\"invoice\"]]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"sub-header\"],[12],[2,\"Invoice\"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[6,[37,0],null,[[\"route\",\"model\"],[\"admin.accounting.invoice\",[32,1,[\"invoice\",\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"invoice\",\"number\"]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"session\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/order/invoice-link/template.hbs"
    }
  });

  _exports.default = _default;
});