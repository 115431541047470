define("product-management/pods/components/work-queues/move-queue-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NLCdPjCE",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"type\",\"checked\",\"change\"],[\"checkbox\",[35,2],[30,[36,1],[[32,0],\"toggleOrder\",[35,0]],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"orderId\",\"action\",\"isChecked\",\"input\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/work-queues/move-queue-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});