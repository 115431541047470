define("product-management/pods/components/painless-manufacturing/production/production-line/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EpVeMiRR",
    "block": "{\"symbols\":[\"@hideStatusLabel\",\"@stations\",\"@productionLineId\"],\"statements\":[[8,\"action-cable-subscriber\",[],[[\"@name\",\"@params\",\"@channel\",\"@received\"],[\"productionLine\",[30,[36,6],null,[[\"id\"],[[35,4,[\"id\"]]]]],\"ProductionLineChannel\",[30,[36,5],[[32,0,[\"handleWebSocket\"]]],null]]],null],[2,\"\\n\"],[11,\"div\"],[4,[38,7],[[32,0,[\"updateData\"]],[32,3],[32,0]],null],[12],[2,\"\\n\"],[6,[37,11],[[30,[36,10],[[35,4],[30,[36,9],[[35,8,[\"isRunning\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"painless-manufacturing/production/production-line/filter\",[[24,0,\"mb-8\"]],[[\"@customFilters\",\"@filters\",\"@deliveries\",\"@productionLine\",\"@onFilter\",\"@onClearFilter\",\"@dealers\",\"@totalQuantity\"],[[34,2],[34,3],[34,4,[\"deliveries\"]],[34,4],[30,[36,5],[[32,0,[\"handleFilter\"]]],null],[30,[36,5],[[32,0,[\"handleClearFilter\"]]],null],[34,4,[\"dealers\"]],[34,4,[\"totalCount\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[11,\"div\"],[4,[38,12],[[32,0,[\"setup\"]],[32,0]],null],[12],[2,\"\\n\"],[6,[37,11],[[30,[36,10],[[35,8,[\"isRunning\"]],[30,[36,14],[[35,13],0],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"mx-auto\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"spinner\"],[[\"spin\"],[true]]]],[2,\"\\n        Loading...\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"painless-manufacturing/production/production-line/units\",[],[[\"@hideStatusLabel\",\"@lineItems\",\"@stations\"],[[32,1],[34,0],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"lineItems\",\"fa-icon\",\"customFilters\",\"standardFilters\",\"productionLine\",\"fn\",\"hash\",\"did-update\",\"_fetchRecords\",\"not\",\"and\",\"if\",\"did-insert\",\"page\",\"eq\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/production-line/template.hbs"
    }
  });

  _exports.default = _default;
});