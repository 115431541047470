define("product-management/pods/components/production-lines/configuration/available-products/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tVSFG0p5",
    "block": "{\"symbols\":[\"collection\",\"@onAdd\",\"@selectedProducts\"],\"statements\":[[11,\"div\"],[4,[38,1],[[32,0,[\"handleUpdate\"]],[32,3],[32,0]],null],[12],[2,\"\\n\\n\"],[10,\"span\"],[14,0,\"text-xl font-semibold block pb-2 mb-2 border-b border-grey-lighter\"],[12],[2,\"\\n\\tAvailable Products\\n\"],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[30,[36,3],[\"name\",[35,2]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"production-lines/configuration/available-products/product-group\",[],[[\"@collection\",\"@open\",\"@onAdd\",\"@onToggleGroup\"],[[32,1],[32,1,[\"open\"]],[32,2],[30,[36,0],[[32,0,[\"handleToggleGroup\"]],[32,1,[\"name\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-update\",\"groupedAvailableProducts\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/production-lines/configuration/available-products/template.hbs"
    }
  });

  _exports.default = _default;
});