define("product-management/pods/components/fulfillment/clear-station/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VDzfjitu",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"a\"],[17,1],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleClearStation\"]]],null]],null],[12],[2,\"Change Station\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/clear-station/template.hbs"
    }
  });

  _exports.default = _default;
});