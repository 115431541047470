define("product-management/pods/components/stardust/data-table/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YDIhKsrS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,4],null,[[\"th-sortable\"],[[30,[36,3],[\"stardust/data-table/header/th-sortable\"],[[\"sortBy\",\"sortedField\",\"sortDirection\"],[[35,2],[35,1],[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sortDirection\",\"sortedField\",\"sortBy\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/data-table/header/template.hbs"
    }
  });

  _exports.default = _default;
});