define("product-management/pods/orders/show/audits/delivery-history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yKZtu1we",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1,[\"deliveryHistory\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"rounded my-2 p-4 border border-grey-light\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex justify-between\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"text-xl font-semibold block mb-2\"],[12],[2,\"\\n        Version:\\n        \"],[1,[32,1,[\"version\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[32,1,[\"user\",\"fullName\"]]],[2,\"\\n        on\\n        \"],[1,[30,[36,0],[[32,1,[\"createdAt\"]],\"dddd MMMM Do, YYYY [at] h:mm:ss a\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n    \"],[8,\"audits/delivery-changes\",[],[[\"@changes\"],[[32,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/audits/delivery-history/template.hbs"
    }
  });

  _exports.default = _default;
});