define("product-management/pods/components/invoices/invoice-operations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BD+yoyKd",
    "block": "{\"symbols\":[\"@on-mark-sent\",\"@on-open-email-dialog\",\"@on-download\"],\"statements\":[[6,[37,1],[[30,[36,5],[\"edit invoice\",[35,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,\"disabled\",[34,0]],[16,0,[31,[\"massive primary massive-button ui button \",[30,[36,1],[[35,0],\"disabled\"],null]]]],[4,[38,2],[[32,0],[32,1]],null],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"check-circle\"],null]],[2,\" Mark Sent\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"button\"],[16,\"disabled\",[34,0]],[16,0,[31,[\"massive positive massive-button ui button \",[30,[36,1],[[35,0],\"disabled\"],null]]]],[4,[38,2],[[32,0],[32,2]],null],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"envelope\"],null]],[2,\" Send\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[11,\"button\"],[24,0,\"massive massive-button ui button\"],[4,[38,2],[[32,0],[32,3]],null],[12],[1,[30,[36,3],[\"download\"],null]],[2,\" Download\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"if\",\"action\",\"fa-icon\",\"invoice\",\"can\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/invoices/invoice-operations/template.hbs"
    }
  });

  _exports.default = _default;
});