define("product-management/pods/shop/pickup-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3dc+lkVo",
    "block": "{\"symbols\":[\"NavigateBack\",\"PmShippingPickupList\",\"PmShopNavigationFooter\",\"list\",\"order\"],\"statements\":[[6,[37,5],[[30,[36,4],[\"pm/ui/navigate-back\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[\"pm/shipping/pickup/list\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[\"pm/shop/navigation/footer\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\n\\n\\n\"],[10,\"h2\"],[12],[2,\"Pickup for \"],[1,[35,0,[\"name\"]]],[13],[2,\"\\n\\n\"],[8,[32,1],[],[[\"@route\",\"@text\"],[\"shop.pickup\",\"Back to other dealers\"]],null],[2,\"\\n\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"mt-4\"],[12],[2,\"\\n\"],[8,[32,2],[],[[\"@records\",\"@dealer\",\"@onShip\"],[[34,0,[\"orders\"]],[34,0],[30,[36,1],[\"onShip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,4,[\"orders\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,4,[\"orderItem\"]],[],[[\"@order\"],[[32,5]]],null],[2,\"\\n\"]],\"parameters\":[5]}]]]],\"parameters\":[4]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,[32,3],[],[[\"@hideWorkQueue\"],[true]],null],[2,\"\\n\\n\"]],\"parameters\":[3]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"dealer\",\"route-action\",\"-track-array\",\"each\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop/pickup-details/template.hbs"
    }
  });

  _exports.default = _default;
});