define("product-management/pods/components/painless-manufacturing/line-item/card/mobile-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PtSc0tFt",
    "block": "{\"symbols\":[\"@lineItem\",\"@onDuplicate\",\"@onDestroy\",\"@summary\",\"&attrs\",\"@subtotal\",\"@order\",\"@canEdit\"],\"statements\":[[11,\"div\"],[17,5],[24,0,\"border border-grey-lighter mt-4 mb-6 rounded print-padding\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"w-1/3 p-2\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"remote-image\",[[24,0,\"block mx-auto w-auto\"]],[[\"@imageUrl\"],[[32,1,[\"product\",\"image\",\"url\"]]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\\n\\t\\t\"],[8,\"painless-manufacturing/line-item/card/mobile-price-summary\",[],[[\"@lineItem\",\"@subtotal\",\"@order\",\"@showPosition\"],[[32,1],[32,6],[32,7],true]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[6,[37,1],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"painless-manufacturing/line-item/card/mobile-summary\",[],[[\"@summary\"],[[32,4]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,8]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"p-2\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"flex flex-row items-center py-2 justify-between text-center\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"block btn btn-success text-grey-darker text-center text-sm mr-4 w-1/4\"]],[[\"@route\",\"@model\"],[\"orders.show.line-items.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"Edit\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[11,\"a\"],[24,0,\"btn-duplicate mr-4 w-1/4\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n\\t\\t\\t\\tDuplicate\\n\\t\\t\\t\"],[13],[2,\" \\n\\t\\t\\t\"],[11,\"a\"],[24,0,\"btn-delete w-1/4\"],[4,[38,0],[\"click\",[32,3]],null],[12],[2,\"\\n\\t\\t\\t\\tRemove\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/line-item/card/mobile-view/template.hbs"
    }
  });

  _exports.default = _default;
});