define("product-management/pods/admin/settings/kpis/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "srcBI84r",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex w-full mb-4 items-center\"],[12],[2,\"\\n\\t\"],[10,\"h3\"],[12],[2,\"KPI Definition Configuration - \"],[1,[35,0,[\"title\"]]],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"ml-auto\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"flex mb-2\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"mr-2 sublink\"]],[[\"@route\"],[\"admin.settings.kpis.show.index\"]],[[\"default\"],[{\"statements\":[[2,\"Configuration\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,0,\"block\"],[12],[2,\"|\"],[13],[2,\"\\n\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"ml-2 sublink\"]],[[\"@route\"],[\"admin.settings.kpis.show.assignments\"]],[[\"default\"],[{\"statements\":[[2,\"Assignments\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[8,\"kpis/settings/recalculate-button\",[[24,0,\"w-full\"]],[[\"@kpiDefinitionId\"],[[34,0,[\"id\"]]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/kpis/show/template.hbs"
    }
  });

  _exports.default = _default;
});