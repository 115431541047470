define("product-management/pods/components/shop-codes/table-list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8WEOlnt0",
    "block": "{\"symbols\":[\"changeset\",\"@on-toggle-status\"],\"statements\":[[10,\"td\"],[12],[2,\"\\n  \"],[1,[35,1,[\"code\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,7],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[35,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"form-inline\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"value\",\"class\",\"placeholder\"],[[32,1,[\"firstName\"]],\"form-control\",\"First name\"]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"value\",\"class\",\"placeholder\"],[[32,1,[\"lastName\"]],\"form-control\",\"Last name\"]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-xs btn-primary\"],[4,[38,0],[[32,0],\"save\",[32,1]],null],[12],[2,\"Save\"],[13],[2,\" or \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],[30,[36,4],[[35,3]],null],false],null],[12],[2,\"cancel\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"editName\"],null],[12],[1,[35,1,[\"firstName\"]]],[2,\" \"],[1,[35,1,[\"lastName\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"td\"],[12],[11,\"a\"],[24,0,\"activate-link\"],[24,6,\"#\"],[4,[38,0],[[32,0],[32,2],[35,1]],null],[12],[1,[34,8]],[2,\" Code\"],[13],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"shop-code\",\"input\",\"isEditing\",\"mut\",\"changeset\",\"with\",\"if\",\"toggleStatusLinkText\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop-codes/table-list-item/template.hbs"
    }
  });

  _exports.default = _default;
});