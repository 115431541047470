define("product-management/pods/admin/dealer/api/route", ["exports", "product-management/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    shippingMethods: _constants.shippingMethods,
    model: function model() {
      return this.modelFor("admin.dealer").dealer;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        dealer: model,
        shippingMethods: _constants.shippingMethods
      });
    },
    actions: {
      save: function save(dealer) {
        var _this = this;

        return dealer.save().then(function () {
          _this.controllerFor(_this.routeName).set("isSaved", true);
        });
      }
    }
  });

  _exports.default = _default;
});