define("product-management/pods/components/pm/ui/modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V6AqtfIY",
    "block": "{\"symbols\":[\"@onClose\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"fixed pin z-10 overflow-auto bg-smoke-light flex justify-end top-90 \"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[34,2]],[12],[2,\"\\n\"],[6,[37,3],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"a\"],[14,6,\"#\"],[15,\"onClick\",[30,[36,1],[[32,0],[35,0]],null]],[14,0,\"absolute pin-t pin-b pin-r p-4 z-10\"],[12],[2,\"\\n      \"],[10,\"svg\"],[14,0,\"h-12 w-12 fill-current text-grey hover:text-grey-darkest\"],[14,\"role\",\"button\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 20 20\"],[12],[10,\"title\"],[12],[2,\"Close\"],[13],[10,\"path\"],[14,\"d\",\"M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z\"],[12],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[18,2,[[30,[36,5],null,[[\"header\",\"footer\"],[[30,[36,4],[\"pm/ui/modal/header\"],null],[30,[36,4],[\"pm/ui/modal/footer\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"close\",\"action\",\"wrapperClass\",\"if\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/modal/template.hbs"
    }
  });

  _exports.default = _default;
});