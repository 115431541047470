define("product-management/pods/components/shop/packaging/unit-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    productType: Ember.computed.alias("lineItem.product.productType.lineItemName"),
    componentName: Ember.computed("productType", function () {
      var productType = this.productType;
      var name = "general";
      if (productType === "Parts") name = "parts";
      if (productType === "Interior Unit") name = "interior-unit";
      if (productType === "Awning") name = "awning";
      return "shop/packaging/unit-details/".concat(name, "-display");
    })
  });

  _exports.default = _default;
});