define("product-management/pods/admin/production-lines/show/configuration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K0+bK0D0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\t\"],[8,\"link-to\",[[24,0,\"sublink mr-2 whitespace-no-wrap font-medium text-sm leading-5 text-indigo focus:outline-none focus:text-indigo-darker focus:border-indigo-dark hover:no-underline focus:no-underline\"]],[[\"@route\"],[\"admin.production-lines.show.configuration.products\"]],[[\"default\"],[{\"statements\":[[2,\"Products\"]],\"parameters\":[]}]]],[2,\" \\n\\t\"],[8,\"link-to\",[[24,0,\"mr-2 sublink whitespace-no-wrap font-medium text-sm leading-5 text-indigo focus:outline-none focus:text-indigo-darker focus:border-indigo-dark hover:no-underline focus:no-underline\"]],[[\"@route\"],[\"admin.production-lines.show.configuration.stations\"]],[[\"default\"],[{\"statements\":[[2,\"Stations\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,\"link-to\",[[24,0,\"sublink whitespace-no-wrap font-medium text-sm leading-5 text-indigo focus:outline-none focus:text-indigo-darker focus:border-indigo-dark hover:no-underline focus:no-underline\"]],[[\"@route\"],[\"admin.production-lines.show.configuration.advanced\"]],[[\"default\"],[{\"statements\":[[2,\"Advanced\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"mt-4 border-t border-grey\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/production-lines/show/configuration/template.hbs"
    }
  });

  _exports.default = _default;
});