define("product-management/pods/components/wip/queue-tab/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    icon: Ember.computed("workQueue.started", function () {
      return this.get("workQueue.started") ? "pause" : "play";
    }),
    status: Ember.computed("workQueue.started", function () {
      return this.get("workQueue.started") ? "Active" : "Paused";
    }),
    actions: {
      toggleStatus: function toggleStatus(workQueue) {
        this.attrs.onToggle(workQueue);
      }
    }
  });

  _exports.default = _default;
});