define("product-management/pods/components/painless-manufacturing/production/production-lines/picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hRZWZr7T",
    "block": "{\"symbols\":[\"productionLine\"],\"statements\":[[11,\"div\"],[4,[38,2],[[32,0,[\"setupComponent\"]],[32,0]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-1/2 mx-auto\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"a\"],[24,0,\"list-group-item cursor-pointer text-2xl\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleProductionLineSelection\"]],[32,1]],null]],null],[12],[2,\"\\n          \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"did-insert\",\"productionLines\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/production-lines/picker/template.hbs"
    }
  });

  _exports.default = _default;
});