define("product-management/pods/components/app-container/component", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: [],
    fluid: false,
    appVersion: Ember.computed(function () {
      var versionRegExp = /\d+[.]\d+[.]\d+/;
      return _emberGetConfig.default.APP.appVersion.match(versionRegExp)[0].trim();
    })
  });

  _exports.default = _default;
});