define("product-management/pods/components/painless-manufacturing/product/configuration/snippets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.basicShell = _exports.computedShell = _exports.computedFieldShell = _exports.summaryShell = _exports.summaryAttributeShell = _exports.configurationShell = _exports.stepShell = _exports.fieldShell = _exports.accountingShell = _exports.bomItemShell = void 0;
  var bomItemShell = "\n  bom_item \"BOM ITEM NAME\" do\n    sku \"\"\n    price 0\n\t\tquantity 1\n  end\n";
  _exports.bomItemShell = bomItemShell;
  var accountingShell = "\naccounting do\n    ".concat(bomItemShell, "\nend\n");
  _exports.accountingShell = accountingShell;
  var fieldShell = "\n  field :FIELD_NAME do\n      type #Numeric,String\n      required true\n      visible true\n\n      input do\n        label \"\"\n        component \"\" #select,radio-options,text,fractional-dimension,numeric,textarea\n      end\n      \n\t\t\t#options do\n\t\t\t#  choice \"\"\n\t\t\t#end\n\n\t\t\t#option_set do\n\t\t\t#  active true\n\t\t\t#  choice_set OptionList.find_by(name: \"\").items\n\t\t\t#end\n  end\n";
  _exports.fieldShell = fieldShell;
  var stepShell = "\n  step \"STEP NAME\" do\n\tend\n";
  _exports.stepShell = stepShell;
  var configurationShell = "\nconfigurable do\n    ".concat(stepShell, "\nend\n");
  _exports.configurationShell = configurationShell;
  var summaryAttributeShell = "\n    attribute \"\" do\n      visible -> { }\n      value -> { }\n    end\n";
  _exports.summaryAttributeShell = summaryAttributeShell;
  var summaryShell = "\nsummary do\n  group \"\" do\n\t  ".concat(summaryAttributeShell, "\n  end\nend\n");
  _exports.summaryShell = summaryShell;
  var computedFieldShell = "\n  field :FIELD_NAME do\n\n\tend\n";
  _exports.computedFieldShell = computedFieldShell;
  var computedShell = "\ncomputed do\n  ".concat(computedFieldShell, "\nend\n");
  _exports.computedShell = computedShell;
  var basicShell = "".concat(configurationShell).concat(summaryShell).concat(accountingShell);
  _exports.basicShell = basicShell;
});