define("product-management/pods/components/admin/purchase-order-items/table-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JSxYmdhE",
    "block": "{\"symbols\":[\"@on-delete\",\"@on-save\",\"@on-print\"],\"statements\":[[10,\"td\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],[32,1]],null],[12],[1,[30,[36,1],[\"trash-o\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"selection\",\"content\",\"select-value\",\"optionValuePath\",\"optionLabelPath\"],[[35,3,[\"inventoryItem\"]],[35,2],[30,[36,0],[[32,0],[32,2]],null],\"content.id\",\"content.name\"]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[1,[35,3,[\"inventoryItem\",\"sku\"]]],[13],[2,\"\\n\"],[10,\"td\"],[12],[1,[30,[36,5],null,[[\"value\",\"focus-out\",\"class\"],[[35,3,[\"quantity\"]],[30,[36,0],[[32,0],[32,2]],null],\"form-control input-sm\"]]]],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],[32,3]],null],[12],[1,[30,[36,1],[\"print\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"fa-icon\",\"inventory-items\",\"item\",\"ember-selectize\",\"input\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/admin/purchase-order-items/table-item/template.hbs"
    }
  });

  _exports.default = _default;
});