define("product-management/pods/components/shop/previously-checked-out/component", ["exports", "product-management/mixins/shop/previous-checkout-component"], function (_exports, _previousCheckoutComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_previousCheckoutComponent.default, {
    lineItem: Ember.computed.alias("line-item"),
    checkedOutAt: Ember.computed("line-item.status", "station", function () {
      return this.get("line-item.status")["".concat(this.station, "_checked_out_at")];
    })
  });

  _exports.default = _default;
});