define("product-management/pods/components/app-container/view-switcher/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iVnzOUrg",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"flex items-center font-semibold text-lg\"],[12],[2,\"\\n\\t\\t\"],[10,\"i\"],[14,0,\"block fa fa-spinner fa-spin fa-fw mr-2\"],[12],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"block\"],[12],[2,\"Switching Views...\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"strong\"],[12],[2,\"Switch Views\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"w-40 ml-2\"],[12],[2,\"\\n\\t\\t\"],[8,\"power-select\",[],[[\"@options\",\"@onChange\"],[[34,0],[30,[36,1],[[32,0,[\"handleRouteChange\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[32,1]],[2,\"\\n\\t\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"routes\",\"fn\",\"isRedirecting\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/app-container/view-switcher/template.hbs"
    }
  });

  _exports.default = _default;
});