define("product-management/pods/components/orders/notes/edit-dealer-note/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      updateNote: function updateNote(lineItem, notes) {
        var _this = this;

        this.set("isNoteUpdating", true);
        return this.attrs["on-update"](lineItem, notes).then(function () {
          _this.set("isNoteUpdating", false);
        }).catch(function () {
          _this.set("isNoteUpdating", false);

          alert("An error occurred");
        });
      }
    }
  });

  _exports.default = _default;
});