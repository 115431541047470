define("product-management/models/inventory-item-count", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    sku: (0, _model.attr)(),
    count: (0, _model.attr)()
  });

  _exports.default = _default;
});