define("product-management/pods/dealer-admin/pending/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FGyY4KAO",
    "block": "{\"symbols\":[\"order\"],\"statements\":[[6,[37,7],[[35,4,[\"orders\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"main-instructions my-4\"],[12],[2,\"\\n    \"],[1,[34,3]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"table\"],[14,0,\"table table-condensed table-striped table-bordered\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"th\"],[12],[2,\"#\"],[13],[2,\"\\n            \"],[10,\"th\"],[12],[2,\"PO #\"],[13],[2,\"\\n            \"],[10,\"th\"],[12],[2,\"Order Date\"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4,[\"orders\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[14,0,\"w-16\"],[12],[2,\"\\n                \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"orders.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[1,[32,1,[\"id\"]]],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1,[\"dealerReference\"]]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[30,[36,2],[[32,1,[\"orderedAt\"]],\"MM/DD/YYYY\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[8,\"blank-slate\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    There are no order Pending \"],[1,[30,[36,1],[[35,0]],null]],[2,\" at this time!\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"subStatus\",\"humanize\",\"moment-format\",\"instructionalMessage\",\"model\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/dealer-admin/pending/index/template.hbs"
    }
  });

  _exports.default = _default;
});