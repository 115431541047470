define("product-management/pods/components/purchasing/inventory-items/active-toggle-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qa+aJvSe",
    "block": "{\"symbols\":[\"@item\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"p-1 w-full block rounded text-xs text-white font-semibold \",[30,[36,0],[[32,1,[\"active\"]],\"bg-red-dark\",\"bg-blue\"],null]]]],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleToggle\"]]],null]],null],[12],[2,\"\\n\\t\"],[1,[30,[36,0],[[32,1,[\"active\"]],\"Deactivate\",\"Activate\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/inventory-items/active-toggle-button/template.hbs"
    }
  });

  _exports.default = _default;
});