define("product-management/pods/components/ordering/edit-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1G9F5FmL",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[30,[36,7],[\"edit lineItem\",[35,1]],[[\"order\"],[[35,6]]]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[6,[37,5],null,[[\"query\",\"route\",\"model\"],[[30,[36,4],null,[[\"mode\"],[[35,3]]]],[35,2],[35,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-text\",\"line-item\",\"edit-route\",\"mode\",\"-hash\",\"link-to\",\"order\",\"can\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/edit-link/template.hbs"
    }
  });

  _exports.default = _default;
});