define("product-management/pods/components/print/printers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F94xbne/",
    "block": "{\"symbols\":[\"printer\",\"option\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"flex flex-wrap\"],[17,3],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[35,8],\"select\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"w-full\"],[12],[2,\"\\n      \"],[8,\"power-select\",[[24,0,\"form-control\"]],[[\"@selected\",\"@onChange\",\"@options\"],[[32,0,[\"selectedPrinter\"]],[30,[36,3],[[32,0,[\"handleToggleSelected\"]]],null],[34,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2]],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[16,0,[31,[\"p-2 text-xs mx-2 my-2 rounded\\n          \",[30,[36,2],[[30,[36,1],[[32,1],[35,0]],null],\"bg-blue-dark text-white\",\"bg-white text-blue-dark border-blue-dark border\"],null]]]],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"handleToggleSelected\"]],[32,1]],null]],null],[12],[2,\"\\n        \"],[1,[32,1]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"selectedPrinter\",\"eq\",\"if\",\"fn\",\"on\",\"printers\",\"-track-array\",\"each\",\"format\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/print/printers/template.hbs"
    }
  });

  _exports.default = _default;
});