define("product-management/helpers/hourly-labor-station-total", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hourlyLaborStationTotal = hourlyLaborStationTotal;
  _exports.default = void 0;

  function hourlyLaborStationTotal(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        hours = _ref2[0],
        data = _ref2[1];

    if (data == null) return 0;
    return hours.reduce(function (accu, i) {
      if (data[i]) return accu + data[i];else return accu;
    }, 0);
  }

  var _default = Ember.Helper.helper(hourlyLaborStationTotal);

  _exports.default = _default;
});