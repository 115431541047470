define("product-management/pods/shop/deliveries/show/map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2H37p610",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"my-4 text-right\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"shop.deliveries.show\"]],[[\"default\"],[{\"statements\":[[2,\"Back to Truck Loading\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[12],[2,\"\\n    \"],[8,\"fulfillment/deliveries/map\",[],[[\"@zoom\",\"@delivery\"],[4,[34,0]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop/deliveries/show/map/template.hbs"
    }
  });

  _exports.default = _default;
});