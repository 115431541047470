define("product-management/pods/admin/accounting/purchasing/vendors/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f9kN7Zq4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"\\n  Vendors\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n  \"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-primary\",\"admin.accounting.purchasing.vendors.new\"]],[[\"default\"],[{\"statements\":[[2,\"Add Vendor\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[8,\"admin/vendors/table-list\",[],[[\"@records\"],[[34,1]]],null]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/purchasing/vendors/index/template.hbs"
    }
  });

  _exports.default = _default;
});