define("product-management/pods/wip/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    dealerName: (0, _model.attr)(),
    dealerReference: (0, _model.attr)(),
    orderId: (0, _model.attr)(),
    position: (0, _model.attr)(),
    workQueueId: (0, _model.attr)(),
    unitOrder: (0, _model.attr)(),
    workQueueStarted: (0, _model.attr)(),
    workQueueActive: (0, _model.attr)(),
    workQueue: (0, _model.attr)(),
    shippingMethod: (0, _model.attr)(),
    orderStatus: (0, _model.attr)(),
    shipOn: (0, _model.attr)(),
    needBy: (0, _model.attr)(),
    productTypeId: (0, _model.attr)(),
    productTypeName: (0, _model.attr)(),
    details: (0, _model.attr)("raw"),
    status: (0, _model.attr)("raw")
  });

  _exports.default = _default;
});