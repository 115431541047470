define("product-management/pods/components/wip-table/order-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dZl8KNs3",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[[35,1,[\"icon\"]]],[[\"size\"],[1]]]],[2,\"\\n\"],[10,\"span\"],[12],[1,[30,[36,4],[[35,3]],null]],[13],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,0,\"btn btn-xs btn-warning\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"markReady\"],null],[12],[2,\"Ready for Pickup\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"icon\",\"fa-icon\",\"status\",\"humanize\",\"allowReadyForPickup\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/wip-table/order-status/template.hbs"
    }
  });

  _exports.default = _default;
});