define("product-management/pods/admin/settings/goals/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JttyeFYw",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"flex justify-ends items-center\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"w-5/6\"],[12],[2,\"\\n    Goal Detail ::\\n    \"],[1,[32,1,[\"goal\",\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.goals\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Back to goals\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"my-8\"],[12],[2,\"\\n  \"],[8,\"goals/form\",[],[[\"@goal\"],[[32,1,[\"goal\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"my-8\"],[12],[2,\"\\n  \"],[8,\"goals/goal-manager\",[],[[\"@goal\"],[[34,0,[\"goal\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/goals/show/template.hbs"
    }
  });

  _exports.default = _default;
});