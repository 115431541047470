define("product-management/pods/components/painless-manufacturing/line-item/wizard/step2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HmOG8oGI",
    "block": "{\"symbols\":[\"product\",\"@onSelect\",\"@onNavigateToPrevious\"],\"statements\":[[8,\"painless-manufacturing/line-item/wizard/header\",[],[[\"@navigatePreviousText\",\"@onNavigateToPrevious\"],[\"Back to Product Type Selection\",[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\tSelect a Product\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mt-12\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flex flex-row flex-wrap\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"productType\",\"products\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"w-full lg:w-1/4\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"painless-manufacturing/line-item/wizard/featured-product\",[],[[\"@product\",\"@onSelect\"],[[32,1],[30,[36,0],[[32,2]],null]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/line-item/wizard/step2/template.hbs"
    }
  });

  _exports.default = _default;
});