define("product-management/pods/dealer-admin/order/route", ["exports", "product-management/mixins/ui/scroll-route"], function (_exports, _scrollRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollRoute.default, {
    redirect: function redirect(model, transition) {
      this.transitionTo("orders.show", model.order.id);
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        order: this.loadOrder(params.order_id)
      });
    },
    loadOrder: function loadOrder(orderId) {
      return this.store.findRecord("order", orderId, {
        reload: true,
        include: "dealer.qb-customer,line-items.product.product-type"
      });
    },
    resetController: function resetController(controller) {
      controller.closeSettingsModal();
    }
  });

  _exports.default = _default;
});