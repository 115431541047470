define("product-management/pods/components/purchasing/critical-inventory/freetext-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BGXv7084",
    "block": "{\"symbols\":[],\"statements\":[[11,\"input\"],[24,\"placeholder\",\"Search by name, sku or description\"],[16,2,[34,0]],[24,0,\"form-control\"],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleInput\"]]],null]],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/critical-inventory/freetext-filter/template.hbs"
    }
  });

  _exports.default = _default;
});