define("product-management/pods/components/orders/date-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: "date-filter",
    actions: {
      clear: function clear() {
        this.setProperties({
          startDateValue: null,
          endDateValue: null
        });
      },
      setDate: function setDate(field, value) {
        Ember.set(this, field, value);
      }
    }
  });

  _exports.default = _default;
});