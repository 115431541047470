define("product-management/pods/components/painless-manufacturing/line-item/wizard/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YCpdx83S",
    "block": "{\"symbols\":[\"@onNavigateToPrevious\",\"@navigatePreviousText\",\"@headerMini\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"flex flex-row items-start \",[30,[36,2],[[32,3],\" mb-4\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,3],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"text-2xl font-light text-grey-dark mb-2\"],[12],[2,\"\\n      Product Wizard\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"h2\"],[14,0,\"text-3xl font-normal mt-0 mb-2\"],[12],[2,\"\\n      \"],[18,4,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ml-auto\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"mb-2\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"text-grey-darker font-light px-3 py-1 border border-grey-light rounded hover:border-grey \"],[4,[38,1],[\"click\",[32,1]],null],[12],[2,\"\\n        <<  \"],[1,[32,2]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n      \"],[11,\"a\"],[24,0,\"cursor-pointer text-sm\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"backToOrder\"]]],null]],null],[12],[2,\"\\n        Back to Order\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"backToOrder\"]]],null]],[14,0,\"text-grey-darker font-light px-3 py-1 border border-grey-light rounded hover:border-grey \"],[12],[2,\"\\n      <<  Back to Order\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/line-item/wizard/header/template.hbs"
    }
  });

  _exports.default = _default;
});