define("product-management/pods/dealer-admin/order/screen-only/index/route", ["exports", "marygrove-components/mixins/screen-only-wizard-route"], function (_exports, _screenOnlyWizardRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_screenOnlyWizardRoute.default, {
    resetController: function resetController(controller) {
      controller.setProperties({
        selectedScreen: null
      });
    }
  });

  _exports.default = _default;
});