define("product-management/pods/components/stardust/data-table/pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d/wqkpMn",
    "block": "{\"symbols\":[\"pageNumber\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"ui pagination menu\"],[12],[2,\"\\n\\n\"],[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[24,0,\"icon item\"],[4,[38,1],[[32,0],\"pageClicked\",[35,5]],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"left chevron icon\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[16,0,[31,[\"item \",[30,[36,4],[[30,[36,3],[[35,2],[32,1]],null],\"active\",\"\"],null]]]],[4,[38,1],[[32,0],\"pageClicked\",[32,1]],null],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[24,0,\"icon item\"],[4,[38,1],[[32,0],\"pageClicked\",[35,0]],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"right chevron icon\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pageUp\",\"action\",\"page\",\"eq\",\"if\",\"pageDown\",\"pagesToShow\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/data-table/pagination/template.hbs"
    }
  });

  _exports.default = _default;
});