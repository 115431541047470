define("product-management/mixins/shop/hardware/station-component", ["exports", "product-management/mixins/shop/hardware/other-units"], function (_exports, _otherUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_otherUnits.default, {
    actions: {
      isForStation: function isForStation(note) {
        return note.tags.includes(this.stationName);
      }
    }
  });

  _exports.default = _default;
});