define("product-management/pods/components/sun-pro/shop/change-production-line/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QmJcBjGj",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"button\"],[24,0,\"bg-green-dark text-white p-4 block text-center rounded w-full font-semibold\"],[17,1],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleClick\"]]],null]],null],[12],[2,\"\\n  Change Production Line\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/shop/change-production-line/template.hbs"
    }
  });

  _exports.default = _default;
});