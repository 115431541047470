define("product-management/pods/components/stardust/ui/code-editor/editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wjE8Db/9",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"setEditorValue\"]],[32,0]],null],[12],[2,\"\\n\"],[1,[30,[36,8],null,[[\"lines\",\"value\",\"update\",\"useWrapMode\",\"useWorker\",\"useSoftTabs\",\"tabSize\",\"newLineMode\",\"mode\",\"ready\"],[[35,7],[35,6],[30,[36,5],[[32,0],[35,4]],null],true,true,true,2,\"auto\",[35,3],[30,[36,2],[[35,1]],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"setEditor\",\"fn\",\"mode\",\"updateEditorValue\",\"action\",\"editorValue\",\"editorLines\",\"ember-ace\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/ui/code-editor/editor/template.hbs"
    }
  });

  _exports.default = _default;
});