define("product-management/pods/components/painless-manufacturing/production/status-key/color-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2xzPrwEt",
    "block": "{\"symbols\":[\"@color\",\"&attrs\",\"@status\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"tw-tooltip bg-\",[32,1]]]],[17,2],[12],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"tw-tooltiptext tw-tooltiptext-bottom\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,0],[[32,3]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"humanize\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/status-key/color-box/template.hbs"
    }
  });

  _exports.default = _default;
});