define("product-management/pods/components/kpis/display/value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Py2m5xu",
    "block": "{\"symbols\":[\"@value\",\"@valueType\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"currency\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[1,[30,[36,0],[[32,1]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"format-money\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/kpis/display/value/template.hbs"
    }
  });

  _exports.default = _default;
});