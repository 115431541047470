define("product-management/pods/admin/accounting/purchasing/inventory-items/show/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "trDdyq53",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"\\n  Item Details\\n\"],[13],[2,\"\\n\"],[8,\"admin/inventory-items/item-form\",[],[[\"@inventoryItem\"],[[34,0]]],null],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[8,\"admin/inventory-items/pricing\",[],[[\"@inventoryItem\"],[[34,0]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/purchasing/inventory-items/show/index/template.hbs"
    }
  });

  _exports.default = _default;
});