define("product-management/pods/admin/reports/product-count-report/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TQc4lyUR",
    "block": "{\"symbols\":[\"Container\"],\"statements\":[[8,\"app-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header-title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"\\n        Product Count Report\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ml-auto no-print\"],[12],[2,\"\\n        \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.reports\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          Back to Reports List\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"painless-manufacturing/reports/product-count\",[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/admin/reports/product-count-report/template.hbs"
    }
  });

  _exports.default = _default;
});