define("product-management/pods/components/orders/list-of-line-items/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rvPyxYbM",
    "block": "{\"symbols\":[\"lw\",\"line-item\",\"index\",\"@onSaveQuantity\",\"@on-goto-swapped-order\",\"@on-update-quantity\"],\"statements\":[[6,[37,11],null,[[\"collection\"],[[35,6]]],[[\"default\"],[{\"statements\":[[6,[37,10],[[30,[36,9],[[32,1,[\"body\"]],\"expected `lw.body` to be a contextual component but found a string. Did you mean `(component lw.body)`? ('product-management/pods/components/orders/list-of-line-items/template.hbs' @ L2:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],null,[[\"index\",\"order\",\"on-update-quantity\",\"on-goto-swapped-order\",\"edit-route\",\"screens\",\"line-item\",\"isQuote\",\"onSaveQuantity\",\"onDuplicate\",\"onDelete\"],[[32,3],[35,4],[32,6],[32,5],\"dealer_admin.order.edit_line_item\",[35,3],[32,2],[35,2],[32,4],[30,[36,1],[[32,0],\"duplicateLineItem\"],null],[30,[36,1],[[32,0],\"deleteLineItem\"],null]]]]],[2,\"\\n\"]],\"parameters\":[2,3]}]]]],\"parameters\":[]}]]],[6,[37,10],[[30,[36,9],[[32,1,[\"empty\"]],\"expected `lw.empty` to be a contextual component but found a string. Did you mean `(component lw.empty)`? ('product-management/pods/components/orders/list-of-line-items/template.hbs' @ L19:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    You haven't added any products to your order yet!\"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[6,[37,0],null,[[\"route\"],[\"dealer_admin.order.select_experience\"]],[[\"default\"],[{\"statements\":[[2,\"Add a product\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"action\",\"isQuote\",\"screens\",\"order\",\"ordering/line-item-detail\",\"lineItems\",\"-track-array\",\"each\",\"-assert-implicit-component-helper-argument\",\"component\",\"list-wrapper\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/orders/list-of-line-items/template.hbs"
    }
  });

  _exports.default = _default;
});