define("product-management/pods/components/shop-codes/table-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0CyNmIir",
    "block": "{\"symbols\":[\"shop-code\",\"@on-toggle-status\",\"@on-regenerate\"],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"table\"],[14,0,\"table table-condensed table-striped\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Code\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Employee\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],null,[[\"shop-code\",\"on-regenerate\",\"on-toggle-status\"],[[32,1],[32,3],[32,2]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],null,null,[[\"default\"],[{\"statements\":[[2,\"    No shop codes have been added yet, please click the button above to add your first shop code.\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,7],[[32,0],\"toggleDeactivated\"],null],[12],[1,[34,8]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"blank-slate\",\"shop-codes/table-list-item\",\"sortedShopCodes\",\"-track-array\",\"each\",\"hasData\",\"if\",\"action\",\"toggleText\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop-codes/table-list/template.hbs"
    }
  });

  _exports.default = _default;
});