define("product-management/pods/components/ordering/edit/edit-awning-misc/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      fieldChange: function fieldChange(value, lineItem, field) {
        var directField = Ember.String.camelize(field.mappedField);
        lineItem.set(directField, value);
        this.clearSuboptions(field.mappedField, directField);
      }
    },
    clearSuboptions: function clearSuboptions(field, directField) {
      var options = this.get("choices.optionSet.options");
      var lineItem = this["line-item"];
      options.filterBy("dependsOn", field).forEach(function (subField) {
        if (!subField.onlyIf.includes(lineItem.get(directField))) {
          var directSubField = Ember.String.camelize(subField.mappedField);
          lineItem.set(directSubField, null);
        }
      });
    }
  });

  _exports.default = _default;
});