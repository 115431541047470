define("product-management/pods/components/pricing/price-per-square-foot-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      save: function save(product, option) {
        return this.attrs.onSave(product, option);
      }
    }
  });

  _exports.default = _default;
});