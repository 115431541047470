define("product-management/pods/components/products/order-config/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NwqONuOa",
    "block": "{\"symbols\":[\"@on-save\"],\"statements\":[[10,\"label\"],[12],[2,\"Edit - \"],[1,[34,0]],[13],[2,\"\\n\"],[1,[30,[36,4],null,[[\"lines\",\"value\",\"update\",\"useWrapMode\",\"useWorker\",\"useSoftTabs\",\"tabSize\",\"newLineMode\",\"mode\"],[[35,3],[35,2],[30,[36,1],[[32,0],\"updateConfig\"],null],true,true,true,2,\"auto\",\"ace/mode/json\"]]]],[2,\"\\n\\n\\n\"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,1],[[32,0],[32,1],[35,5],[35,2],[35,0]],null],[12],[2,\"Save\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"section\",\"action\",\"orderConfigForEditor\",\"lineCount\",\"ember-ace\",\"product\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/products/order-config/template.hbs"
    }
  });

  _exports.default = _default;
});