define("product-management/pods/admin/dealers/new/controller", ["exports", "product-management/validations/dealer"], function (_exports, _dealer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    DealerValidation: _dealer.default,
    "cancel-route": "admin.dealers",
    dealer: Ember.computed.alias("model.dealer"),
    qbCustomers: Ember.computed.alias("model.qbCustomers"),
    actions: {
      submit: function submit(changeset) {
        var _this = this;

        changeset.save().then(function () {
          _this.transitionToRoute("admin.dealer", changeset.get("id"));
        });
      },
      cancel: function cancel() {
        this.transitionToRoute(this["cancel-route"]);
      }
    }
  });

  _exports.default = _default;
});