define("product-management/pods/components/fulfillment/drivers/picklist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wGBKfbb+",
    "block": "{\"symbols\":[\"option\",\"@onChange\",\"@selected\"],\"statements\":[[8,\"stardust/picklist\",[],[[\"@query\",\"@onChange\",\"@selected\",\"@queryName\"],[[34,0],[32,2],[32,3],\"drivers\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[1,[32,1,[\"fullName\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"query\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/drivers/picklist/template.hbs"
    }
  });

  _exports.default = _default;
});