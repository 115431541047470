define("product-management/pods/admin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZegujARL",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"SunPro Admin\"],null]],[2,\"\\n\"],[1,[30,[36,2],null,[[\"on-search-result-selected\",\"on-logout\"],[[30,[36,1],[[32,0],\"searchResultSelected\"],null],[30,[36,1],[[32,0],\"logout\"],null]]]]],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"action\",\"navigation/admin-nav\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/template.hbs"
    }
  });

  _exports.default = _default;
});