define("product-management/pods/admin/transportation/vehicles/show/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nvfK8niY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"block font-semibold text-2xl\"],[12],[2,\"\\n  \"],[1,[35,0,[\"name\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"transportation/vehicles/form\",[],[[\"@vehicle\"],[[34,0]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/transportation/vehicles/show/show/template.hbs"
    }
  });

  _exports.default = _default;
});