define("product-management/pods/components/shop/line-items/important-instructions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PKkmtoMv",
    "block": "{\"symbols\":[\"Instructions\",\"@header\",\"@lineItem\",\"@activeCell\"],\"statements\":[[8,\"shop/line-items/instructions\",[],[[\"@lineItem\",\"@activeCell\"],[[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"content\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"display\"]],[],[[\"@header\",\"@instructionsText\"],[[32,2],\"Important Instructions\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/line-items/important-instructions/template.hbs"
    }
  });

  _exports.default = _default;
});