define("product-management/pods/components/custom/shop/four-five-warning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J9kL6spG",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1,[\"isFourFiveHood\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-block alert-warning\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"exclamation-circle\"],null]],[2,\" 4.5\\\" Hood, uses pocket top\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"lineItem\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/custom/shop/four-five-warning/template.hbs"
    }
  });

  _exports.default = _default;
});