define("product-management/pods/components/shipping/pickup-tile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["col-md-6"],
    extraLongPoNumbers: Ember.computed.gte("pickup.poNumbers.length", 50)
  });

  _exports.default = _default;
});