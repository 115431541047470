define("product-management/pods/admin/dealer/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dealer: Ember.computed.alias("model.dealer"),
    session: Ember.inject.service(),
    actions: {
      save: function save(changeset) {
        return changeset.save();
      }
    }
  });

  _exports.default = _default;
});