define("product-management/gql/fragments/orders/payment-summary.graphql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "FragmentDefinition",
      "name": {
        "kind": "Name",
        "value": "PaymentSummary"
      },
      "typeCondition": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Order"
        }
      },
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "balance"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "total"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "subtotal"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "discount"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "shippingCost"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "paymentsTotal"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "paidInFull"
          },
          "arguments": [],
          "directives": []
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 121
    }
  };
  var _default = doc;
  _exports.default = _default;
});