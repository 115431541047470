define("product-management/pods/admin/dealer/discounts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hk/iNVHA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Enter the percentage discounts for each product type\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"onSave\",\"discounts\"],[[30,[36,1],[[32,0],\"save\"],null],[35,0]]]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"discounts\",\"action\",\"admin/dealer-discounts\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/dealer/discounts/template.hbs"
    }
  });

  _exports.default = _default;
});