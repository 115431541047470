define("product-management/pods/admin/dealer/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        dealer: this.store.findRecord("dealer", params.dealer_id, {
          reload: true,
          include: "dealer-group,users,qb-customer,dealer-discounts.product-type,sales-rep"
        }),
        qbCustomers: this.store.findAll("qbCustomer")
      });
    }
  });

  _exports.default = _default;
});