define("product-management/pods/components/pricing/price-per-square-foot/option-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDeleting: false,
    toggleIsDeleting: function toggleIsDeleting() {
      this.toggleProperty("isDeleting");
    },
    actions: {
      deleteOption: function deleteOption(product, id) {
        var _this = this;

        this.toggleIsDeleting();
        return this.attrs.onDelete(product, id).then(function () {
          _this.toggleIsDeleting();
        });
      }
    }
  });

  _exports.default = _default;
});