define("product-management/pods/components/pm/ui/inline-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "czYp0Tzu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,5],null,[[\"editMode\",\"displayMode\"],[[30,[36,3],[\"pm/ui/inline-editor/edit-mode\"],[[\"isVisible\",\"onToggleMode\"],[[35,4],[30,[36,2],[[32,0],[35,1]],null]]]],[30,[36,3],[\"pm/ui/inline-editor/display-mode\"],[[\"onToggleMode\",\"isVisible\"],[[30,[36,2],[[32,0],[35,1]],null],[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isDisplaying\",\"toggleMode\",\"action\",\"component\",\"isEditing\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/inline-editor/template.hbs"
    }
  });

  _exports.default = _default;
});