define("product-management/templates/resource-cards/customer-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GsKz/yny",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"search-result\"],[12],[2,\"\\n  \"],[1,[35,0,[\"name\"]]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"search-details\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n    \\n\\t  \"],[10,\"i\"],[14,0,\"fa fa-mobile-phone\"],[12],[13],[2,\" \"],[1,[35,0,[\"phone\"]]],[10,\"br\"],[12],[13],[2,\"\\n\\t  \"],[1,[35,0,[\"city\"]]],[2,\", \"],[1,[35,0,[\"state\"]]],[2,\"\\n    \\n      \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/templates/resource-cards/customer-search.hbs"
    }
  });

  _exports.default = _default;
});