define("product-management/pods/components/fulfillment/deliveries/schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vh5299mx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-right mb-8 pb-8 border-b border-grey-light\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"font-semibold mr-2\"],[12],[2,\"\\n    Select a view:\\n  \"],[13],[2,\"\\n  \"],[11,\"a\"],[16,0,[31,[\"cursor-pointer \",[30,[36,2],[[30,[36,1],[[32,0,[\"view\"]],[35,0]],null],\" active\"],null]]]],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"handleChangeView\"]],[35,0]],null]],null],[12],[2,\"\\n    Calendar\\n  \"],[13],[2,\"\\n  |\\n  \"],[11,\"a\"],[16,0,[31,[\"cursor-pointer \",[30,[36,2],[[30,[36,1],[[32,0,[\"view\"]],[35,5]],null],\" active\"],null]]]],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"handleChangeView\"]],[35,5]],null]],null],[12],[2,\"\\n    This Week\\n  \"],[13],[2,\"\\n  |\\n  \"],[11,\"a\"],[16,0,[31,[\"cursor-pointer \",[30,[36,2],[[30,[36,1],[[32,0,[\"view\"]],[35,6]],null],\" active\"],null]]]],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"handleChangeView\"]],[35,6]],null]],null],[12],[2,\"\\n    List\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[11,\"div\"],[24,0,\"w-full h-full\"],[4,[38,7],[[32,0,[\"handleSetup\"]],[32,0]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"calendarView\",\"eq\",\"if\",\"fn\",\"on\",\"thisWeekView\",\"listView\",\"did-insert\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/deliveries/schedule/template.hbs"
    }
  });

  _exports.default = _default;
});