define("product-management/pods/pm/users/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sOR61e6H",
    "block": "{\"symbols\":[\"user\",\"@showRoute\",\"@onAction\"],\"statements\":[[10,\"div\"],[14,0,\"text-right mb-4\"],[12],[2,\"\\n  \"],[10,\"a\"],[14,0,\"btn btn-default\"],[15,\"onclick\",[30,[36,1],[[32,0],[35,0]],null]],[14,6,\"#\"],[12],[1,[34,2]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[10,\"table\"],[14,0,\"table table-condensed table-striped\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Name\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Email\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Status\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Role\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Last Active\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"pm/users/list/row\",[],[[\"@showRoute\",\"@onAction\",\"@user\"],[[32,2],[32,3],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"toggleStatus\",\"action\",\"toggleStatusText\",\"userList\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/pm/users/list/template.hbs"
    }
  });

  _exports.default = _default;
});