define("product-management/pods/components/shop/shop-code/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HEqGhItZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,4],null,[[\"entry\",\"isValid\",\"value\"],[[30,[36,3],[\"shop/shop-code-entry\"],[[\"onValidationResult\"],[[30,[36,2],[[32,0,[\"handleValidationResult\"]]],null]]]],[35,1],[35,0]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"isValid\",\"fn\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/shop-code/template.hbs"
    }
  });

  _exports.default = _default;
});