define("product-management/pods/components/fulfillment/global-station-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CIOA8I3b",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"w-1/2 mx-auto\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,0,\"list-group-item cursor-pointer text-2xl\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleStationSelection\"]],\"shop.deliveries\"],null]],null],[12],[2,\"\\n      Deliveries\\n    \"],[13],[2,\"\\n    \"],[11,\"a\"],[24,0,\"list-group-item cursor-pointer text-2xl\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleStationSelection\"]],\"picklists\"],null]],null],[12],[2,\"\\n      Picker\\n    \"],[13],[2,\"\\n    \"],[11,\"a\"],[24,0,\"list-group-item cursor-pointer text-2xl\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleStationSelection\"]],\"shop.pickup\"],null]],null],[12],[2,\"\\n      Dealer Pickup\\n    \"],[13],[2,\"\\n    \"],[11,\"a\"],[24,0,\"list-group-item cursor-pointer text-2xl\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleStationSelection\"]],\"shop.returned-items\"],null]],null],[12],[2,\"\\n      Receive Returned Items\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/global-station-picker/template.hbs"
    }
  });

  _exports.default = _default;
});