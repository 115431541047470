define("product-management/mixins/custom/shop/cut-sheet-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    cutSheetTitle: Ember.computed('order', 'sequenceNumber', function () {
      var order = this.order;
      var sequenceNumber = this.sequenceNumber;
      return "Cut Sheet Item ".concat(sequenceNumber, " of ").concat(order.get("lineItems.length"), ", PO# ").concat(order.get("dealerReference"), ", ").concat(order.get("dealer.name"));
    })
  });

  _exports.default = _default;
});