define("product-management/pods/components/shop/line-items/splicer-station/component", ["exports", "product-management/mixins/shop/interior-station"], function (_exports, _interiorStation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_interiorStation.default, {});

  _exports.default = _default;
});