define("product-management/pods/components/pm/shipping/pickup/order-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n3tzgClK",
    "block": "{\"symbols\":[\"item\",\"@selectButton\",\"@order\",\"@onSelect\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[8,[32,2],[],[[\"@iconSize\",\"@onClick\"],[\"3x\",[30,[36,0],[[32,0],[32,4],[32,3]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"w-1/6\"],[12],[1,[32,3,[\"dealerReference\"]]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"w-2/6\"],[12],[2,\"\\n    \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3,[\"itemSummary\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[1,[32,1,[\"product\"]]],[2,\" (\"],[1,[32,1,[\"quantity\"]]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/shipping/pickup/order-item/template.hbs"
    }
  });

  _exports.default = _default;
});