define("product-management/pods/components/pm/ui/forms/form-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EmoEf7Gv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"pm-form-group\"],[12],[2,\"\\n\"],[18,1,[[30,[36,1],null,[[\"label\"],[[30,[36,0],[\"pm.ui.forms.label\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/forms/form-group/template.hbs"
    }
  });

  _exports.default = _default;
});