define("product-management/pods/components/invoices/shipping-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6/Yoy5Sg",
    "block": "{\"symbols\":[\"order\"],\"statements\":[[10,\"div\"],[14,0,\"rounded\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"px-4 py-2 bg-grey-light rounded-t\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"text-xl font-semibold\"],[12],[2,\"Shipping\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"ul\"],[14,0,\"list-group bg-grey-light rounded-b\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,0],null,[[\"order\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"invoices/shipping-card-item\",\"orders\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/invoices/shipping-card/template.hbs"
    }
  });

  _exports.default = _default;
});