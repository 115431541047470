define("product-management/pods/components/goals/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QaHlI6gR",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[6,[37,6],[[35,5,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"\\n        Name\\n      \"],[13],[2,\"\\n      \"],[11,\"input\"],[16,2,[34,0,[\"name\"]]],[24,0,\"form-control\"],[24,4,\"text\"],[4,[38,2],[\"change\",[30,[36,1],[[32,0,[\"handleInput\"]],\"name\"],null]],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"\\n        Value Path\\n      \"],[13],[2,\"\\n      \"],[11,\"input\"],[16,2,[34,0,[\"valuePath\"]]],[24,0,\"form-control\"],[24,4,\"text\"],[4,[38,2],[\"change\",[30,[36,1],[[32,0,[\"handleInput\"]],\"valuePath\"],null]],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"\\n        KPI\\n      \"],[13],[2,\"\\n      \"],[8,\"power-select\",[],[[\"@options\",\"@searchEnabled\",\"@searchField\",\"@selected\",\"@onChange\"],[[30,[36,4],[\"name\",[35,3]],null],true,\"name\",[34,0,[\"kpi\"]],[30,[36,1],[[32,0,[\"handleChange\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n        Save\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  Loading...\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"goal\",\"fn\",\"on\",\"kpis\",\"sort-by\",\"loadData\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/goals/form/template.hbs"
    }
  });

  _exports.default = _default;
});