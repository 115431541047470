define("product-management/pods/components/painless-manufacturing/navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/K5GaQHA",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,5],[[30,[36,4],[[32,0,[\"hideNav\"]]],null],[32,0,[\"session\",\"isAuthenticated\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,0,[\"session\",\"isAdmin\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"on-search-result-selected\",\"on-logout\"],[[30,[36,0],[[32,0,[\"searchResultSelected\"]]],null],[30,[36,0],[[32,0,[\"logout\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"on-search-result-selected\",\"on-logout\"],[[30,[36,0],[[32,0,[\"searchResultSelected\"]]],null],[30,[36,0],[[32,0,[\"logout\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"navigation/dealer-nav\",\"navigation/admin-nav\",\"if\",\"not\",\"and\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/navigation/template.hbs"
    }
  });

  _exports.default = _default;
});