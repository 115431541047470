define("product-management/pods/components/ordering/edit-part-quantity/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    quantity: Ember.computed.alias("line-item.quantity"),
    toggleEditing: function toggleEditing(isEditing) {
      this.set("is-editing", isEditing);

      if (isEditing) {
        var originalQuantity = this.quantity;
        this.set("new-quantity", originalQuantity);
        Ember.run.later(this, function () {
          this.$("input:visible:first").focus();
        }, 150);
      }
    },
    actions: {
      toggleEditing: function toggleEditing(isEditing) {
        this.toggleEditing(isEditing);
      },
      updateQuantity: function updateQuantity(lineItem, quantity) {
        var _this = this;

        return this.attrs["on-update"](lineItem, quantity).then(function () {
          _this.toggleEditing(false);
        });
      }
    }
  });

  _exports.default = _default;
});