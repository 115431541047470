define("product-management/pods/components/ordering/return-of-goods/warranty-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rubfwQ2K",
    "block": "{\"symbols\":[\"@lineItem\",\"&attrs\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"p-2 text-sm bg-red-dark text-white rounded hover:text-white\"],[17,2]],[[\"@route\",\"@model\"],[\"orders.show.line-items.warranty-claim\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Warranty Claim\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isVisible\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/return-of-goods/warranty-button/template.hbs"
    }
  });

  _exports.default = _default;
});