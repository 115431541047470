define("product-management/pods/components/purchasing/critical-inventory/count-history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gh5lJPfR",
    "block": "{\"symbols\":[\"inventoryChange\",\"@inventoryChanges\",\"&attrs\"],\"statements\":[[11,\"div\"],[17,3],[12],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"font-semibold text-lg block mb-2\"],[12],[2,\"Count History\"],[13],[2,\"\\n\\n\\n\"],[6,[37,4],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"table\"],[14,0,\"table table-striped table-condensed\"],[12],[2,\"\\n\\t\\t\"],[10,\"thead\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Count\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Previous Count\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Counted By\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Counted At\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"createdAt:desc\",[32,2]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"td\"],[12],[1,[32,1,[\"currentCount\"]]],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"td\"],[12],[1,[32,1,[\"previousCount\"]]],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"td\"],[12],[1,[32,1,[\"recordedBy\",\"fullName\"]]],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"td\"],[12],[1,[30,[36,0],[[32,1,[\"createdAt\"]],\"MM/DD/YYYY\"],null]],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\tThere is no inventory history for this item\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"sort-by\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/critical-inventory/count-history/template.hbs"
    }
  });

  _exports.default = _default;
});