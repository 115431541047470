define("product-management/utils/constants", ["exports", "product-management/utils/product-type-constants", "product-management/utils/shipping", "product-management/utils/status", "product-management/utils/css-classes", "product-management/utils/constants/cookies"], function (_exports, _productTypeConstants, _shipping, _status, _cssClasses, _cookies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "interiorUnitType", {
    enumerable: true,
    get: function get() {
      return _productTypeConstants.interiorUnitType;
    }
  });
  Object.defineProperty(_exports, "shippingMethods", {
    enumerable: true,
    get: function get() {
      return _shipping.shippingMethods;
    }
  });
  Object.defineProperty(_exports, "shippingTranslation", {
    enumerable: true,
    get: function get() {
      return _shipping.shippingTranslation;
    }
  });
  Object.defineProperty(_exports, "shippableMethods", {
    enumerable: true,
    get: function get() {
      return _shipping.shippableMethods;
    }
  });
  Object.defineProperty(_exports, "statusColorMap", {
    enumerable: true,
    get: function get() {
      return _status.statusColorMap;
    }
  });
  Object.defineProperty(_exports, "positiveClass", {
    enumerable: true,
    get: function get() {
      return _cssClasses.positiveClass;
    }
  });
  Object.defineProperty(_exports, "negativeClass", {
    enumerable: true,
    get: function get() {
      return _cssClasses.negativeClass;
    }
  });
  Object.defineProperty(_exports, "lastPrinterCookieName", {
    enumerable: true,
    get: function get() {
      return _cookies.lastPrinterCookieName;
    }
  });
  Object.defineProperty(_exports, "stationCookieName", {
    enumerable: true,
    get: function get() {
      return _cookies.stationCookieName;
    }
  });
  Object.defineProperty(_exports, "productionLineCookieName", {
    enumerable: true,
    get: function get() {
      return _cookies.productionLineCookieName;
    }
  });
  Object.defineProperty(_exports, "workQueueNameCookieName", {
    enumerable: true,
    get: function get() {
      return _cookies.workQueueNameCookieName;
    }
  });
  Object.defineProperty(_exports, "workQueueCookieName", {
    enumerable: true,
    get: function get() {
      return _cookies.workQueueCookieName;
    }
  });
  _exports.wirelessPrinters = _exports.deliveredStatuses = _exports.printServerURI = void 0;
  var printServerURI = "https://print-server-one.app.sunproproducts.com/api/print"; // export const printServerURI =
  // "http://192.168.1.129/api/print";

  _exports.printServerURI = printServerURI;
  var deliveredStatuses = ["shipped", "invoiced"];
  _exports.deliveredStatuses = deliveredStatuses;
  var wirelessPrinters = {
    Delivery: "DYMO-DELIV",
    Shipping: "DYMO-SHIP"
  };
  _exports.wirelessPrinters = wirelessPrinters;
});