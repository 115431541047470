define("product-management/pods/components/sun-pro/ui/list-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I4NBASPF",
    "block": "{\"symbols\":[\"@searchFilter\",\"@sortBy\",\"@height\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n  \"],[18,4,[[30,[36,2],null,[[\"selectedList\",\"availableList\"],[[30,[36,1],[\"sun-pro/ui/list-selector/list\"],[[\"mode\",\"height\",\"options\",\"onClick\",\"sortBy\"],[\"selected\",[32,3],[32,0,[\"selected\"]],[30,[36,0],[[32,0,[\"handleRemove\"]]],null],[32,2]]]],[30,[36,1],[\"sun-pro/ui/list-selector/list\"],[[\"mode\",\"height\",\"options\",\"onClick\",\"sortBy\",\"searchFilter\"],[\"available\",[32,3],[32,0,[\"options\"]],[30,[36,0],[[32,0,[\"handleSelect\"]]],null],[32,2],[32,1]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/ui/list-selector/template.hbs"
    }
  });

  _exports.default = _default;
});