define("product-management/pods/components/ui/inline-query/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lt/ZSpAj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,2,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,1],null,[[\"results\"],[[35,0]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"results\",\"hash\",\"runQuery\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/inline-query/template.hbs"
    }
  });

  _exports.default = _default;
});