define("product-management/pods/components/painless-manufacturing/matrices/table/row-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A2lptLjf",
    "block": "{\"symbols\":[\"@onInput\"],\"statements\":[[10,\"input\"],[15,\"oninput\",[32,1]],[14,0,\"form-control-sm w-16\"],[15,2,[34,0]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/matrices/table/row-input/template.hbs"
    }
  });

  _exports.default = _default;
});