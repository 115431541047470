define("product-management/pods/components/ordering/extra-bracket-list-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kdpMsRid",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[14,0,\"w-1/4\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-sm\"],[12],[2,\"\\n\\t\\t\"],[1,[35,0,[\"label\"]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"w-1/4 quantity\"],[12],[2,\"\\n  \"],[10,\"input\"],[15,2,[34,1]],[15,\"oninput\",[30,[36,2],[[32,0],\"handleInput\",\"quantity\"],null]],[14,0,\"form-control\"],[14,\"pattern\",\"\\\\d*\"],[14,4,\"text\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"colors w-1/2\"],[12],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"content\",\"value\",\"select-value\"],[[35,4],[35,3],[30,[36,2],[[32,0],\"updateValue\",\"color\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"option\",\"quantity\",\"action\",\"color\",\"colors\",\"ember-selectize\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/extra-bracket-list-option/template.hbs"
    }
  });

  _exports.default = _default;
});