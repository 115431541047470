define("product-management/pods/invoice/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    isDraft: Ember.computed.equal("model.status", "drafts"),
    canEdit: Ember.computed("model.quickbooksTransactionOn", "model.quickbooksTransactionId", function () {
      return Ember.isEmpty(this.get("model.quickbooksTransactionId")) && Ember.isEmpty(this.get("model.quickbooksTransactionOn"));
    }),
    canUnmerge: Ember.computed.and("isDraft", "canEdit")
  });

  _exports.default = _default;
});