define("product-management/pods/components/ui/navigation/alpha-numeric-paging/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ge7Ihaw7",
    "block": "{\"symbols\":[\"item\",\"@onClick\"],\"statements\":[[10,\"table\"],[14,0,\"table table-bordered rounded\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"collection\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"td\"],[24,0,\"cursor-pointer text-center p-2 uppercase\"],[4,[38,1],[\"click\",[30,[36,0],[[32,2],[32,1]],null]],null],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/navigation/alpha-numeric-paging/template.hbs"
    }
  });

  _exports.default = _default;
});