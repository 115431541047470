define("product-management/pods/components/orders/date-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B8GBgTW0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"inputs-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input-container\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"value\",\"onSelection\",\"placeholder\",\"firstDay\",\"format\",\"class\"],[[35,2],[30,[36,1],[[32,0],\"setDate\",\"startDateValue\"],null],\"Start\",0,\"MM/DD/YYYY\",\"form-control\"]]]],[2,\" \\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input-divider\"],[12],[2,\"\\n      - \\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input-container\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"value\",\"onSelection\",\"placeholder\",\"firstDay\",\"format\",\"class\"],[[35,4],[30,[36,1],[[32,0],\"setDate\",\"endDateValue\"],null],\"End\",0,\"MM/DD/YYYY\",\"form-control\"]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-xs btn-default\"],[4,[38,1],[[32,0],\"clear\"],null],[12],[2,\"Clear\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"action\",\"startDateValue\",\"pikaday-input\",\"endDateValue\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/orders/date-filter/template.hbs"
    }
  });

  _exports.default = _default;
});