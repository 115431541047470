define("product-management/pods/user/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*UserManagerModel,{*/
  var _default = _model.default.extend({
    firstName: (0, _model.attr)(),
    lastName: (0, _model.attr)(),
    fullName: (0, _model.attr)(),
    email: (0, _model.attr)(),
    status: (0, _model.attr)(),
    role: (0, _model.attr)(),
    "confirmed?": (0, _model.attr)("boolean"),
    confirmedAt: (0, _model.attr)(),
    lastSignInAt: (0, _model.attr)(),
    dealer: (0, _model.belongsTo)(),
    activate: (0, _emberApiActions.memberAction)({
      path: "activate",
      type: "put"
    }),
    statusChange: (0, _emberApiActions.memberAction)({
      path: "status_change",
      type: "put"
    }),
    resetUserPassword: (0, _emberApiActions.memberAction)({
      path: "reset_user_password",
      type: "put"
    }),
    resendInvite: (0, _emberApiActions.memberAction)({
      path: "resend_invite",
      type: "put"
    })
  });

  _exports.default = _default;
});