define("product-management/pods/components/pm/shipping/ready-to-ship/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mQgPLEOL",
    "block": "{\"symbols\":[\"@records\",\"&default\"],\"statements\":[[18,2,[[30,[36,2],null,[[\"dealers\",\"dealerItem\"],[[30,[36,1],[\"name\",[32,1]],null],[30,[36,0],[\"pm/shipping/ready-to-ship/dealer-item\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"sort-by\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/shipping/ready-to-ship/list/template.hbs"
    }
  });

  _exports.default = _default;
});