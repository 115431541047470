define("product-management/pods/invoice/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    dealer: (0, _model.belongsTo)(),
    invoiceLineItems: (0, _model.hasMany)(),
    orders: (0, _model.hasMany)(),
    createdOn: (0, _model.attr)(),
    dueDate: (0, _model.attr)(),
    sentOn: (0, _model.attr)(),
    amount: (0, _model.attr)(),
    billingName: (0, _model.attr)(),
    paymentsTotal: (0, _model.attr)(),
    amountDue: (0, _model.attr)(),
    status: (0, _model.attr)(),
    billingAddress1: (0, _model.attr)(),
    billingCity: (0, _model.attr)(),
    billingState: (0, _model.attr)(),
    billingZip: (0, _model.attr)(),
    number: (0, _model.attr)(),
    poNumber: (0, _model.attr)(),
    total: (0, _model.attr)(),
    quickbooksTransactionOn: (0, _model.attr)(),
    quickbooksTransactionId: (0, _model.attr)(),
    tax: (0, _model.attr)(),
    grandTotal: (0, _model.attr)(),
    unmergableOrders: (0, _model.attr)(),
    numberAndPos: Ember.computed("number", "poNumber", function () {
      return "".concat(this.number, " - ").concat(this.poNumber);
    }),
    rates: Ember.computed("invoiceLineItems", "invoiceLineItems.@each.rate", function () {
      return this.invoiceLineItems.map(function (item) {
        var rate = Ember.isEmpty(item.get("rate")) ? 0 : parseFloat(item.get("rate"));
        return rate * item.get("quantity");
      });
    }),
    markSent: (0, _emberApiActions.memberAction)({
      path: "mark_sent"
    }),
    merge: (0, _emberApiActions.memberAction)({
      path: "merge",
      method: "put"
    }),
    unmerge: (0, _emberApiActions.memberAction)({
      path: "unmerge",
      method: "put"
    })
  });

  _exports.default = _default;
});