define("product-management/components/ordering/wizards/complete-screen/sidetrack-type", ["exports", "marygrove-components/components/ordering/wizards/complete-screen/sidetrack-type"], function (_exports, _sidetrackType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sidetrackType.default;
    }
  });
});