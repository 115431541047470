define("product-management/pods/components/painless-manufacturing/reports/product-count/by-month/product/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n+09aqEz",
    "block": "{\"symbols\":[\"@showChange\",\"@showChangePercentage\"],\"statements\":[[10,\"td\"],[14,\"colspan\",\"2\"],[15,0,[31,[\"text-center text-xs bottom-box \",[34,5]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],[[35,3]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[30,[36,0],[[32,1],\"wrap-in-parens ml-2\"],null]],[12],[2,\"\\n      \"],[1,[30,[36,2],[[35,1]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"changePercentage\",\"pct\",\"change\",\"zero-if-empty\",\"compareClass\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/reports/product-count/by-month/product/item/template.hbs"
    }
  });

  _exports.default = _default;
});