define("product-management/pods/dealer-admin/order/select-experience/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xMFs81gR",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[6,[37,5],null,[[\"onHide\"],[[30,[36,4],[[32,0],\"close\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('product-management/pods/dealer-admin/order/select-experience/template.hbs' @ L2:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h4\"],[12],[2,\"What would you like to add to your order?\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[30,[36,2],[[32,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('product-management/pods/dealer-admin/order/select-experience/template.hbs' @ L5:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"base-route\"],[\"dealer_admin.order\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[30,[36,2],[[32,1,[\"footer\"]],\"expected `modal.footer` to be a contextual component but found a string. Did you mean `(component modal.footer)`? ('product-management/pods/dealer-admin/order/select-experience/template.hbs' @ L12:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[6,[37,0],null,[[\"route\"],[\"dealer_admin.order\"]],[[\"default\"],[{\"statements\":[[2,\"cancel\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"ordering/select-experience-link-to-list\",\"-assert-implicit-component-helper-argument\",\"component\",\"action\",\"bs-modal\"]}",
    "meta": {
      "moduleName": "product-management/pods/dealer-admin/order/select-experience/template.hbs"
    }
  });

  _exports.default = _default;
});