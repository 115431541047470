define("product-management/pods/orders/show/line-items/edit/route", ["exports", "product-management/pods/orders/show/line-items/edit/query.graphql", "ember-apollo-client"], function (_exports, _query, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      return this.apollo.watchQuery({
        query: _query.default,
        variables: {
          id: params.lineItemId
        }
      }, "lineItem");
    } // setupController(controller,model){
    // this._super(controller, model);
    // 	const {groupName} = this.paramsFor(this.routeName)
    // }

  });

  _exports.default = _default;
});