define("product-management/pods/components/ui/async-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zs4+126s",
    "block": "{\"symbols\":[\"@pendingText\",\"@defaultText\",\"&attrs\",\"@isRunning\"],\"statements\":[[11,\"button\"],[16,\"disabled\",[34,0]],[17,3],[24,4,\"submit\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleClick\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,3],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"isDisabled\",\"fn\",\"on\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/async-button/template.hbs"
    }
  });

  _exports.default = _default;
});