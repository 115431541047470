define("product-management/helpers/in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inArray = inArray;
  _exports.default = void 0;

  function inArray(params, hash) {
    var array = params[0];
    var value = params[1];
    return array.includes(value);
  }

  var _default = Ember.Helper.helper(inArray);

  _exports.default = _default;
});