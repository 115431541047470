define("product-management/pods/components/products/order-config-sections/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var sections = [{
    value: "steps",
    label: "Steps"
  }, {
    value: "skipSteps",
    label: "Skip Steps"
  }, {
    value: "stepValidation",
    label: "Step Validation"
  }, {
    value: "summary-config",
    label: "Summary Config"
  }, {
    value: "line-item-details",
    label: "Line Item Details"
  }];

  var _default = Ember.Component.extend({
    sections: sections
  });

  _exports.default = _default;
});