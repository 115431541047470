define("product-management/helpers/value-is-object", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.valueIsObject = valueIsObject;
  _exports.default = void 0;

  function valueIsObject(params, hash) {
    if (hash.value === undefined) return false;else return (0, _typeof2.default)(hash.value) === "object";
  }

  var _default = Ember.Helper.helper(valueIsObject);

  _exports.default = _default;
});