define("product-management/pods/components/sun-pro/shop/stations/other-stations/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZaX44mKa",
    "block": "{\"symbols\":[\"&attrs\",\"@station\"],\"statements\":[[11,\"div\"],[24,0,\"bg-white cursor-pointer rounded p-4 border border-gray-light flex justify-between\"],[17,1],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleToggleStation\"]]],null]],null],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"font-semibold\"],[12],[2,\"\\n    \"],[1,[32,2,[\"key\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex font-semibold\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"py-1 w-8 bg-grey-light text-white text-center rounded-l\"],[12],[2,\"\\n      \"],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"py-1 w-8 bg-teal-dark text-white text-center rounded-r\"],[12],[2,\"\\n      \"],[1,[32,2,[\"settings\",\"dailyGoal\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"completedToday\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/shop/stations/other-stations/item/template.hbs"
    }
  });

  _exports.default = _default;
});