define("product-management/pods/components/dealers/pending-orders-notice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M/eCZr/v",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[35,0,[\"ordersPendingConfirmation\"]]],null,[[\"default\"],[{\"statements\":[[8,\"ui/red-message\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"pt-4\"],[12],[2,\"\\n        You currently have \"],[1,[30,[36,1],[[35,0,[\"ordersPendingConfirmation\",\"length\"]],\"order\"],null]],[2,\" pending confirmation.\\n\\n        \"],[10,\"div\"],[14,0,\"my-2\"],[12],[2,\"\\n            \"],[8,\"link-to\",[[24,0,\"btn btn-default\"]],[[\"@route\",\"@query\"],[\"dealer_admin.pending\",[30,[36,2],null,[[\"subStatus\"],[\"confirmation\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                View Orders\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,0,[\"ordersPendingPayment\"]]],null,[[\"default\"],[{\"statements\":[[8,\"ui/red-message\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"pt-4\"],[12],[2,\"\\n        You currently have \"],[1,[30,[36,1],[[35,0,[\"ordersPendingPayment\",\"length\"]],\"order\"],null]],[2,\" pending payment.\\n        \"],[10,\"div\"],[14,0,\"my-2\"],[12],[2,\"\\n\\n\\n            \"],[8,\"link-to\",[[24,0,\"btn btn-default\"]],[[\"@route\",\"@query\"],[\"dealer_admin.pending\",[30,[36,2],null,[[\"subStatus\"],[\"payment\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                View Orders\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dealer\",\"pluralize\",\"hash\",\"if\",\"setup\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/pending-orders-notice/template.hbs"
    }
  });

  _exports.default = _default;
});