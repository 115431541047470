define("product-management/pods/components/stardust/report/compared-fields/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AjjiqYQJ",
    "block": "{\"symbols\":[\"@showChange\",\"@showChangePercentage\",\"@comparing\"],\"statements\":[[10,\"td\"],[12],[2,\"\\n  \"],[1,[34,3]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[15,0,[32,0,[\"compareClass\"]]],[12],[2,\"\\n      \"],[1,[32,0,[\"change\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[15,0,[32,0,[\"compareClass\"]]],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,0,[\"changePercentage\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"pct\",\"comparedValue\",\"if\",\"reportedValue\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/report/compared-fields/item/template.hbs"
    }
  });

  _exports.default = _default;
});