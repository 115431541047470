define("product-management/pods/components/pricing/price-multiplier/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ci9cTCF7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"Name\"],[13],[2,\"\\n\\t\"],[11,\"input\"],[24,0,\"form-control\"],[16,2,[34,0]],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleInput\"]],\"name\"],null]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"Amount\"],[13],[2,\"\\n\\t\"],[11,\"input\"],[16,2,[34,3]],[24,0,\"form-control\"],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleInput\"]],\"amount\"],null]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"Save\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"name\",\"fn\",\"on\",\"amount\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pricing/price-multiplier/form/template.hbs"
    }
  });

  _exports.default = _default;
});