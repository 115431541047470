define("product-management/pods/components/sun-pro/accounting/delivery-billing-rules/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kn17rq0W",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    Name\\n  \"],[13],[2,\"\\n  \"],[11,\"input\"],[24,0,\"form-control\"],[16,2,[34,3]],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleInput\"]],\"name\"],null]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    Rule Type\\n  \"],[13],[2,\"\\n  \"],[8,\"power-select\",[],[[\"@options\",\"@onChange\",\"@selected\"],[[34,4],[30,[36,1],[[32,0,[\"handleInput\"]],\"ruleType\"],null],[34,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,5],\"unit\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      Rate\\n    \"],[13],[2,\"\\n    \"],[11,\"input\"],[24,0,\"form-control\"],[16,2,[34,0]],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleInput\"]],\"rate\"],null]],null],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n  \"],[11,\"button\"],[16,\"disabled\",[34,8]],[24,0,\"btn btn-primary\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"Save\"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"rate\",\"fn\",\"on\",\"name\",\"options\",\"ruleType\",\"eq\",\"unless\",\"isInvalid\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/accounting/delivery-billing-rules/form/template.hbs"
    }
  });

  _exports.default = _default;
});