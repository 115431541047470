define("product-management/pods/components/orders/notes/edit-dealer-note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C3CnVLp1",
    "block": "{\"symbols\":[\"cs\"],\"statements\":[[6,[37,7],[[30,[36,6],[[35,2]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"class\",\"rows\",\"value\"],[\"form-control\",5,[32,1,[\"dealerNotes\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn-sm btn btn-default\"],[4,[38,3],[[32,0],\"updateNote\",[35,2],[32,1,[\"dealerNotes\"]]],null],[12],[2,\"\\n      Update\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"refresh\"],[[\"spin\"],[true]]]],[2,\" Saving the note\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"textarea\",\"line-item\",\"action\",\"isNoteUpdating\",\"unless\",\"changeset\",\"with\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/orders/notes/edit-dealer-note/template.hbs"
    }
  });

  _exports.default = _default;
});