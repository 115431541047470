define("product-management/pods/orders/show/audit-trail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LuuowgRL",
    "block": "{\"symbols\":[],\"statements\":[[8,\"bs-modal-simple\",[],[[\"@onHide\",\"@title\"],[[30,[36,0],[\"cancel\"],null],\"Audit Trail\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"audit-trail\",[],[[\"@resource\"],[[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"order\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/audit-trail/template.hbs"
    }
  });

  _exports.default = _default;
});