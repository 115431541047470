define("product-management/pods/components/sun-pro/shop/stations/other-stations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fiBDEL5I",
    "block": "{\"symbols\":[\"station\",\"@stationGoals\"],\"statements\":[[10,\"div\"],[14,0,\"flex flex-wrap\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"sun-pro/shop/stations/other-stations/item\",[[24,0,\"w-1/2\"]],[[\"@station\",\"@stationGoals\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"stations\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/shop/stations/other-stations/template.hbs"
    }
  });

  _exports.default = _default;
});