define("product-management/pods/shop-diagnostic/station/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["station"],
    station: null,
    lineItem: Ember.computed.alias("model"),
    componentName: Ember.computed("station", function () {
      return "shop/line-items/".concat(this.station);
    })
  });

  _exports.default = _default;
});