define("product-management/pods/components/shop/view-other-orders-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OHu3B3gg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n      \"],[6,[37,0],null,[[\"class\",\"current-when\",\"route\"],[\"btn btn-success btn-lg\",\"shop.index\",\"shop\"]],[[\"default\"],[{\"statements\":[[2,\"View other orders\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/view-other-orders-button/template.hbs"
    }
  });

  _exports.default = _default;
});