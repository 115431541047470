define("product-management/validations/start-order", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    dealerReference: (0, _validators.validatePresence)({
      presence: true,
      message: "Please enter a valid PO#"
    })
  };
  _exports.default = _default;
});