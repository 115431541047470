define("product-management/pods/components/dealers/address-book/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6BKLPwiR",
    "block": "{\"symbols\":[\"address\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,0,[\"addressBook\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"dealers/address-book/address\",[[24,0,\"p-2 border border-grey-light m-2\"]],[[\"@dealer\",\"@address\",\"@onSaveComplete\"],[[34,0],[32,1],[30,[36,1],[[32,0,[\"handleSave\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"dealer\",\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/address-book/template.hbs"
    }
  });

  _exports.default = _default;
});