define("product-management/pods/admin/accounting/purchasing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sFbIXs6L",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row margin-top-20\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"list-group tweaked\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\"],[\"list-group-item\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[6,[37,0],null,[[\"route\"],[\"admin.accounting.purchasing.purchase-orders\"]],[[\"default\"],[{\"statements\":[[2,\"Purchase Orders\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,[[\"class\"],[\"list-group-item\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[6,[37,0],null,[[\"route\"],[\"admin.accounting.purchasing.inventory-items\"]],[[\"default\"],[{\"statements\":[[2,\"Items\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,[[\"class\"],[\"list-group-item\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[6,[37,0],null,[[\"route\"],[\"admin.accounting.purchasing.vendors\"]],[[\"default\"],[{\"statements\":[[2,\"Vendors\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-9\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"active-link\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/purchasing/template.hbs"
    }
  });

  _exports.default = _default;
});