define("product-management/pods/components/pricing/size-matrix/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sizes: Ember.computed.alias("pricing.sizes"),
    minHeight: Ember.computed.alias("pricing.min_height"),
    maxHeight: Ember.computed.alias("pricing.max_height"),
    minWidth: Ember.computed.alias("pricing.min_width"),
    maxWidth: Ember.computed.alias("pricing.max_width"),
    isEditingLimits: false,
    widths: Ember.computed("minWidth", "maxWidth", function () {
      return this.buildRange(this.minWidth, this.maxWidth);
    }),
    heights: Ember.computed("minHeight", "maxHeight", function () {
      return this.buildRange(this.minHeight, this.maxHeight);
    }),
    buildRange: function buildRange(min, max) {
      min = parseInt(min);
      max = parseInt(max);
      var result = Ember.A();
      var upperLimit = max + 1;

      for (var i = min; i < upperLimit; i++) {
        result.pushObject(i);
      }

      return result;
    },
    actions: {
      keyDown: function keyDown(height, width, direction) {
        switch (direction) {
          case "ArrowUp":
            height--;
            break;

          case "ArrowDown":
            height++;
            break;

          case "ArrowLeft":
            width--;
            break;

          case "ArrowRight":
            width++;
            break;
        }

        var id = "#size-matrix-item-h-".concat(height, "-w-").concat(width);
        var input = document.querySelector("".concat(id, " input"));

        if (input != undefined) {
          if (input.val() === "0") {
            input.val("");
          }

          input.focus();
        }
      },
      editLimits: function editLimits() {
        Ember.set(this, "isEditingLimits", true);
      },
      cancelEditLimits: function cancelEditLimits() {
        Ember.set(this, "isEditingLimits", false);
      },
      saveLimits: function saveLimits(changeset) {
        var _this = this;

        this.attrs.onMatrixLimitSave(changeset).then(function () {
          Ember.set(_this, "isEditingLimits", false);
        });
      }
    }
  });

  _exports.default = _default;
});