define("product-management/pods/components/invoice/line-item/component", ["exports", "ember-changeset"], function (_exports, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var lineItem = this.lineItem;
      var invoiceItem = lineItem.get("invoiceItem");
      Ember.set(this, "invoiceItem", invoiceItem);
      this.changeset = new _emberChangeset.default(lineItem);
    },
    tagName: "",
    isDirty: Ember.computed.alias("changeset.isDirty"),
    autoSaveDirty: Ember.observer("isDirty", function () {
      if (this.changeset.isDirty) Ember.run.debounce(this, this.save, 1000);
    }),
    save: function save() {
      var _this = this;

      var lineItem = this.changeset;
      var invoice = this.invoice;
      this.attrs.onUpdate(lineItem, invoice).then(function () {
        if (!_this.isDestroyed) {
          Ember.set(_this, "isSaved", true);
          var takeAway = Ember.run.later(_this, function () {
            Ember.set(this, "isSaved", false);
          }, 2000);
          Ember.set(_this, "takeAway", takeAway);
        }
      });
    },
    willDestroy: function willDestroy() {
      Ember.run.cancel(this.takeAway);
    },
    actions: {
      itemSelected: function itemSelected(selected) {
        Ember.set(this, "changeset.invoiceItem", selected);
        this.save();
      },
      delete: function _delete(lineItem) {
        this.attrs.onDelete(lineItem);
      }
    }
  });

  _exports.default = _default;
});