define("product-management/helpers/number-as-fraction", ["exports", "fraction.js"], function (_exports, _fraction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numberAsFraction = numberAsFraction;
  _exports.default = void 0;

  function numberAsFraction(params) {
    var input;
    if (Array.isArray(params)) input = params[0];else input = params;
    if (isNaN(input) || input === true || input === false) return input;
    var fraction = new _fraction.default(input);
    return fraction.toFraction(true);
  }

  var _default = Ember.Helper.helper(numberAsFraction);

  _exports.default = _default;
});