define("product-management/pods/components/stardust/report/field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RsT1oS0P",
    "block": "{\"symbols\":[\"@field\",\"@record\"],\"statements\":[[8,[32,0,[\"wrapperComponent\"]],[],[[\"@route\",\"@model\",\"@target\"],[[32,1,[\"link\"]],[30,[36,6],[[32,2],[32,1,[\"name\"]]],null],[32,1,[\"target\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"type\"]],\"currency\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,5],[[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"type\"]],\"date\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,4],[[35,0],\"MM/DD/YYYY\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"type\"]],\"percentage\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t  \"],[1,[30,[36,1],[[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\"],[1,[34,0]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"pct\",\"eq\",\"if\",\"moment-format\",\"format-money\",\"get\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/report/field/template.hbs"
    }
  });

  _exports.default = _default;
});