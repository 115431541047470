define("product-management/pods/components/icon-radio-button-list/component", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var displayValueRegex = /^\[(.*)\]$/gm;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var field = this.field;
      Ember.defineProperty(this, 'modelValue', Ember.computed.alias("model.".concat(field)));
    },
    options: [],
    classNames: ["ember-icon-radio-buttons"],
    icon: "fa-check-circle",
    parseOption: function parseOption(item) {
      try {
        var json = JSON.parse(item);

        if ((0, _typeof2.default)(json) === "object") {
          return json;
        } else return {
          value: item,
          displayValue: item
        };
      } catch (e) {
        return {
          value: item,
          displayValue: item
        };
      }
    },
    actions: {
      setValue: function setValue() {
        if (this.attrs.onSetValue != undefined) {
          this.attrs.onSetValue(this.modelValue);
        }
      }
    },
    mappedOptions: Ember.computed("options", function () {
      var _this = this;

      var options = this.options;
      var result = Ember.A();
      options.map(function (item) {
        if (typeof item === "string") {
          result.pushObject(_this.parseOption(item));
        } else if (typeof item === "number") {
          result.pushObject({
            value: item,
            displayValue: item
          });
        } else {
          result.pushObject(item);
        }
      });
      return result;
    }),
    isStandardOptionSelected: Ember.computed("modelValue", function () {
      var result = false;
      var modelValue = this.modelValue;
      return this.mappedOptions.any(function (item) {
        return Ember.get(item, "value") == modelValue;
      });
    })
  });

  _exports.default = _default;
});