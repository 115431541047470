define("product-management/pods/components/shared/signature-pad/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["signature-pad-container"],
    headerText: "Please sign below",
    command: null,
    actions: {
      clearSignature: function clearSignature() {
        this.set('command', {
          command: 'clear',
          args: []
        });
      },
      handleInput: function handleInput(e) {
        var value = e.target.value;
        if (this.attrs.updatePrintedName) this.attrs.updatePrintedName(value);else this.resource.set(this["printed-name-field"], value);
      },
      signedWork: function signedWork(resource, signatureField, data) {
        if (this.attrs.updateSignature) this.attrs.updateSignature(data);else resource.set(signatureField, data);
      }
    }
  });

  _exports.default = _default;
});