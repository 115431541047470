define("product-management/helpers/only-if-empty-if-undefined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.onlyIfEmptyIfUndefined = onlyIfEmptyIfUndefined;
  _exports.default = void 0;

  function onlyIfEmptyIfUndefined(params, hash) {
    if (hash.onlyIf === undefined) return Ember.A();else return hash.onlyIf;
  }

  var _default = Ember.Helper.helper(onlyIfEmptyIfUndefined);

  _exports.default = _default;
});