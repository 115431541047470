define("product-management/pods/components/ordering/extra-bracket-list-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "12QnanTT",
    "block": "{\"symbols\":[\"option\",\"@lineItem\"],\"statements\":[[11,\"div\"],[4,[38,7],[[32,0,[\"handleInsert\"]],[32,0]],null],[12],[2,\"\\n\"],[6,[37,9],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t \"],[10,\"table\"],[14,0,\"table table-condensed w-full\"],[12],[2,\"\\n\\t\\t \"],[10,\"thead\"],[12],[2,\"\\n\\t\\t\\t \"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\\t \"],[10,\"th\"],[12],[2,\"Bracket Type\"],[13],[2,\"\\n\\t\\t\\t\\t \"],[10,\"th\"],[12],[2,\"Quantity\"],[13],[2,\"\\n\\t\\t\\t\\t \"],[10,\"th\"],[12],[2,\"Color\"],[13],[2,\"\\n\\t\\t\\t \"],[13],[2,\"\\n\\t\\t \"],[13],[2,\"\\n\\t\\t \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t \"],[1,[30,[36,3],null,[[\"option\",\"colors\",\"lineItem\",\"onUpdate\"],[[32,1],[35,2],[32,2],[30,[36,1],[[32,0],[35,0]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\\t \"],[13],[2,\"\\n\\t \"],[13],[2,\"\\n\\t \"],[10,\"div\"],[14,0,\"mt-2\"],[12],[2,\"\\n\\t\\t \"],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],\"resetBrackets\"],null],[12],[2,\"Reset to standard included bracket count\"],[13],[2,\"\\n\\t \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t \"],[10,\"h3\"],[12],[2,\"Loading...\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onUpdate\",\"action\",\"colors\",\"ordering/extra-bracket-list-option\",\"options\",\"-track-array\",\"each\",\"did-insert\",\"isLoading\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ordering/extra-bracket-list-options/template.hbs"
    }
  });

  _exports.default = _default;
});