define("product-management/pods/orders/show/cutdown/configure/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tbJCzd/Q",
    "block": "{\"symbols\":[],\"statements\":[[8,\"painless-manufacturing/cutdown/wizard/item-configuration\",[],[[\"@orderId\",\"@cutdownItem\",\"@onComplete\"],[[34,0,[\"order\",\"id\"]],[34,0],[30,[36,1],[[32,0,[\"handleConfigurationComplete\"]]],null]]],null]],\"hasEval\":false,\"upvars\":[\"model\",\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/cutdown/configure/template.hbs"
    }
  });

  _exports.default = _default;
});