define("product-management/pods/shop/packaging/detail/route", ["exports", "product-management/mixins/ui/scroll-route"], function (_exports, _scrollRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollRoute.default, {
    model: function model(params) {
      var order = this.store.findRecord("order", params.order_id, {
        reload: true,
        include: "shipment,line-items.product.product-type,dealer,line-items.product.work-flow",
        adapterOptions: {
          "fields[work-flow]": "details",
          "fields[dealers]": "name",
          "fields[product]": "line-item,product-type,only-picked,name",
          "fields[product-type]": "product,name"
        }
      });
      return Ember.RSVP.hash({
        order: order
      });
    }
  });

  _exports.default = _default;
});