define("product-management/utils/fetch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchText = fetchText;
  _exports.fetchXml = fetchXml;

  function fetchText(url) {
    return fetch(url, 'application/text');
  }

  function fetchXml(url) {
    return fetch(url, 'application/xml');
  }

  function fetch(url, responseType) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var ajax = new XMLHttpRequest();
      ajax.open('GET', url);
      ajax.responseType = responseType;

      ajax.onload = function (e) {
        if (e.target.status === 404 || e.target.status === 500) {
          reject(e.target.response);
        } else {
          resolve(e.target.response);
        }
      };

      ajax.onerror = function (e) {
        reject(e.target.response);
      };

      ajax.send();
    });
  }
});