define("product-management/pods/components/wip-report/shipping-method/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wmKlV1lC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[15,\"src\",[31,[\"/assets/images/icons/shipping/\",[34,0],\".png\"]]],[12],[13],[2,\"\\n\"],[10,\"small\"],[12],[2,\"\\n  \"],[1,[35,1,[\"shippingMethod\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"item\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/wip-report/shipping-method/template.hbs"
    }
  });

  _exports.default = _default;
});