define("product-management/pods/components/products/station-config/component", ["exports", "product-management/utils/stations"], function (_exports, _stations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Station = Ember.Object.extend({});

  var _default = Ember.Component.extend({
    underscoredProductionStations: _stations.underscoredProductionStations.map(function (station) {
      return station.value;
    })
  });

  _exports.default = _default;
});