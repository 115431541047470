define("product-management/pods/components/custom/shop/cut-sheet/cut-sheet-general/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    productType: Ember.computed.alias("lineItem.product.productType"),
    productTypeName: Ember.computed.alias("productType.name"),
    cutSheetComponent: Ember.computed("productTypeName", function () {
      var productTypeName = this.productTypeName;
      var base = "custom/shop/cut-sheet";

      switch (productTypeName) {
        case "Interior Unit":
          return "".concat(base, "/interior-unit");

        case "Awning":
          return "".concat(base, "/awning-unit");

        case "Parts":
          return "".concat(base, "/parts-unit");

        default:
          return base;
      }
    })
  });

  _exports.default = _default;
});