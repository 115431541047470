define("product-management/pods/admin/settings/option-lists/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eVXZWI8s",
    "block": "{\"symbols\":[\"PageHeader\"],\"statements\":[[8,\"settings/page-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Add a New Option List\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"mb-4\"],[12],[2,\"\\n\\t\"],[11,\"input\"],[24,0,\"form-control\"],[24,\"placeholder\",\"name\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]],\"name\"],null]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"mb-4\"],[12],[2,\"\\n\\t\"],[11,\"input\"],[24,0,\"form-control\"],[24,\"placeholder\",\"group name\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]],\"groupName\"],null]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"mt-4 text-right flex items-center justify-end\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"mr-2\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"stardust/ui/async-button\",[[24,0,\"btn btn-primary\"]],[[\"@pendingText\",\"@defaultText\",\"@isPending\",\"@onClick\",\"@textColor\"],[\"Saving...\",\"Save\",[34,2,[\"isRunning\"]],[30,[36,3],[[32,0],\"handleSave\"],null],\"white\"]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\tor \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.option-lists\"]],[[\"default\"],[{\"statements\":[[2,\"cancel\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"save\",\"action\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/option-lists/new/template.hbs"
    }
  });

  _exports.default = _default;
});