define("product-management/pods/shop-diagnostic/order/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    order: Ember.computed.alias("model"),
    lineItems: Ember.computed.alias("order.lineItems")
  });

  _exports.default = _default;
});