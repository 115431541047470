define("product-management/pods/components/fulfillment/deliveries/lock-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kPREUxNg",
    "block": "{\"symbols\":[\"@delivery\"],\"statements\":[[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleToggle\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"locked\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-lock\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"i\"],[14,0,\"fa fa-unlock\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/deliveries/lock-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});