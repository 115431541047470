define("product-management/pods/components/shop/station-and-queue-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uvCuJNGk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"nav\"],[14,0,\"navbar navbar-default navbar-fixed-bottom footer-nav\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n        \"],[8,\"shop/current-station\",[],[[\"@orientation\",\"@currentStation\"],[\"horizontal\",[34,3]]],null],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[35,1],[35,0]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"shop/current-work-queue\",[],[[\"@orientation\",\"@currentWorkQueueName\"],[\"horizontal\",[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[8,\"shop/current-production-line\",[],[[\"@orientation\",\"@currentProductionLine\"],[\"horizontal\",[34,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"currentProductionLine\",\"currentWorkQueueName\",\"if\",\"currentStation\",\"or\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/station-and-queue-picker/template.hbs"
    }
  });

  _exports.default = _default;
});