define("product-management/pods/admin/kpis/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SF/Nwvpu",
    "block": "{\"symbols\":[\"Container\"],\"statements\":[[8,\"app-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,[32,1,[\"header-title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[10,\"h2\"],[12],[2,\"KPI Detail - \"],[1,[35,0,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/kpis/show/template.hbs"
    }
  });

  _exports.default = _default;
});