define("product-management/pods/admin/settings/code-snippets/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rXCud0ww",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex justify-ends\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"w-5/6\"],[12],[2,\"Code Snippets\"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"admin.settings.code-snippets.new\"]],[[\"default\"],[{\"statements\":[[2,\"Add Code Snippet\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"stardust/code-snippets/list\",[],[[\"@records\",\"@showRoute\"],[[34,0],\"admin.settings.code-snippets.show\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"blank-slate\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      No Code Snippets have been added yet\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/code-snippets/index/template.hbs"
    }
  });

  _exports.default = _default;
});