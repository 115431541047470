define("product-management/pods/admin/accounting/invoice/route", ["exports", "product-management/mixins/ui/scroll-route", "product-management/gql/queries/accounting/invoices/item.graphql", "ember-apollo-client"], function (_exports, _scrollRoute, _item, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, _scrollRoute.default, {
    model: function model(params) {
      var invoiceRecord = this.store.findRecord("invoice", params.invoice_id, {
        reload: true,
        include: "orders.line-items.product.product-type,orders.line-items.notes-reviewed-by,orders.shipment,invoice-line-items.invoice-item,dealer"
      });
      var controller = this.controllerFor(this.routeName);
      var variables = {
        id: params.invoice_id
      };
      var invoiceGraph = this.apollo.watchQuery({
        query: _item.default,
        variables: variables,
        fetchPolicy: "network-only"
      }, "accountingInvoicesItem");
      return Ember.RSVP.hash({
        invoiceGraph: invoiceGraph,
        invoice: invoiceRecord,
        orders: invoiceRecord.then(function (invoice) {
          var orders = invoice.get("orders");
          orders.forEach(function (order) {
            var lineItems = order.get("lineItems");
            lineItems.forEach(function (lineItem) {
              lineItem.get("product");
            });
          });
          return orders;
        }),
        invoiceItems: this.store.findAll("invoiceItem"),
        otherOpenInvoices: invoiceRecord.then(function (i) {
          return controller.loadOpenInvoices(i);
        })
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.setProperties({
          isShowingEmailModal: false
        });
      }
    }
  });

  _exports.default = _default;
});