define("product-management/components/ordering/wizards/complete-screen/screen-details", ["exports", "marygrove-components/components/ordering/wizards/complete-screen/screen-details"], function (_exports, _screenDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _screenDetails.default;
    }
  });
});