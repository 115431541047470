define("product-management/pods/components/shared/signature-pad/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lInJZjhV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h5\"],[14,0,\"text-muted text-center pb-2\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[1,[30,[36,5],null,[[\"exportFormat\",\"width\",\"height\",\"command\",\"changeListener\"],[\"svgbase64\",\"100%\",\"100%\",[35,4],[30,[36,3],[[32,0],\"signedWork\",[35,2],[35,1]],null]]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-center mb-2\"],[12],[2,\"\\n\\t\"],[10,\"small\"],[12],[2,\"\\n\\t\\t\"],[11,\"a\"],[24,6,\"#\"],[4,[38,3],[[32,0],\"clearSignature\"],null],[12],[1,[30,[36,6],[\"times-circle\"],null]],[2,\" clear signature\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"form-group printed-name\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[12],[2,\"Your name\"],[13],[2,\"\\n\\t\\t\"],[10,\"input\"],[14,0,\"form-control\"],[15,2,[34,7]],[15,\"oninput\",[30,[36,3],[[32,0],\"handleInput\"],null]],[14,4,\"text\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"headerText\",\"signature-field\",\"resource\",\"action\",\"command\",\"ember-jsignature\",\"fa-icon\",\"printedName\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shared/signature-pad/template.hbs"
    }
  });

  _exports.default = _default;
});