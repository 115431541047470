define("product-management/pods/components/invoices/shipping-card-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2UkiX8/p",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"PO: \"],[1,[35,0,[\"dealerReference\"]]],[13],[2,\"\\n\"],[10,\"strong\"],[12],[2,\"Shipping Method: \"],[13],[2,\" \"],[1,[35,0,[\"shippingMethod\"]]],[2,\"\\n\"],[6,[37,2],[[30,[36,3],[[35,0,[\"shippingMethod\"]],\"Shipping\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"strong\"],[12],[2,\"Carrier\"],[13],[2,\"\\n  \"],[1,[35,0,[\"shipment\",\"carrier\"]]],[2,\" \\n  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"strong\"],[12],[2,\"Tracking #\"],[13],[2,\"\\n  \"],[1,[35,0,[\"shipment\",\"trackingInfo\"]]],[2,\"\\n\"],[6,[37,2],[[35,0,[\"shipment\",\"cost\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"Cost\"],[13],[2,\"\\n    \"],[1,[30,[36,1],[[35,0,[\"shipment\",\"cost\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"order\",\"format-money\",\"if\",\"eq\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/invoices/shipping-card-item/template.hbs"
    }
  });

  _exports.default = _default;
});