define("product-management/pods/components/orders/admin-shipping-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zNn1y6Fl",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[35,0,[\"shippingAddress\"]],\"Address\"]]]],[2,\"\\n\"],[1,[30,[36,1],null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[35,0,[\"shippingCity\"]],\"City\"]]]],[2,\"\\n\"],[1,[30,[36,1],null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[35,0,[\"shippingState\"]],\"State\"]]]],[2,\"\\n\"],[1,[30,[36,1],null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[35,0,[\"shippingZip\"]],\"Zip\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"changeset\",\"input\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/orders/admin-shipping-form/template.hbs"
    }
  });

  _exports.default = _default;
});