define("product-management/pods/orders/show/index/index/add-notes/controller", ["exports", "ember-ui-helpers/mixins/notifyable", "ember-apollo-client", "product-management/gql/mutations/line-items/review-notes.graphql"], function (_exports, _notifyable, _emberApolloClient, _reviewNotes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { stations, underscoredStations } from "product-management/utils/stations"
  var _default = Ember.Controller.extend(_notifyable.default, {
    apollo: (0, _emberApolloClient.queryManager)(),
    // tagValues: stations,
    // underscoredStations,
    lineItem: Ember.computed.alias("model"),
    tagValues: Ember.computed("lineItem.cells", function () {
      return this.lineItem.cells.map(function (item) {
        var value = item.name.toLowerCase().replace(/ /gi, "-");
        return {
          key: item.name,
          value: value
        };
      });
    }),
    actions: {
      saveNote: function saveNote(lineItem, note) {
        var _this = this;

        return new Promise(function (resolve, reject) {
          if (Ember.isEmpty(note.note)) {
            _this.errorMessage("Please enter a note");

            return resolve(false);
          }

          if (Ember.isEmpty(note.tags)) {
            _this.errorMessage("Please tag someone");

            return resolve(false);
          }

          lineItem.get("notes").pushObject(note);
          return lineItem.save().then(function () {
            return resolve(true);
          }, function () {
            return resolve(false);
          });
        });
      },
      deleteNote: function deleteNote(lineItem, note) {
        lineItem.get("notes").removeObject(note);
        lineItem.save();
      },
      cancel: function cancel() {
        this.transitionToRoute("orders.show.index");
      }
    }
  });

  _exports.default = _default;
});