define("product-management/pods/shop-diagnostic/station/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0jGtwHgw",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"route\",\"model\"],[\"shop_diagnostic.line_item\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"Back\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[10,\"h1\"],[12],[1,[30,[36,3],[[35,2]],null]],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[1,[30,[36,5],[[35,4]],[[\"order\",\"lineItem\",\"details\"],[[35,0,[\"order\"]],[35,0],[35,0,[\"details\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"lineItem\",\"link-to\",\"station\",\"humanize\",\"componentName\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop-diagnostic/station/template.hbs"
    }
  });

  _exports.default = _default;
});