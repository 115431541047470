define("product-management/pods/components/painless-manufacturing/cutdown/wizard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k9BRqPx+",
    "block": "{\"symbols\":[\"@orderId\"],\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"painless-manufacturing/cutdown/wizard/step1\",[],[[\"@orderId\",\"@onNext\"],[[32,1],[30,[36,1],[[32,0,[\"handleStep1Next\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"painless-manufacturing/cutdown/wizard/step2\",[],[[\"@orderId\",\"@cutdownItems\"],[[32,1],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"cutdownItems\",\"fn\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/cutdown/wizard/template.hbs"
    }
  });

  _exports.default = _default;
});