define("product-management/pods/components/transportation/vehicles/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RXAlYP/K",
    "block": "{\"symbols\":[\"option\",\"@vehicle\"],\"statements\":[[11,\"form\"],[4,[38,1],[\"submit\",[30,[36,0],[[32,0,[\"handleSubmit\"]]],null]],null],[4,[38,2],[[30,[36,0],[[32,0,[\"setupForm\"]],[32,2]],null]],null],[4,[38,3],[[30,[36,0],[[32,0,[\"setupForm\"]],[32,2]],null],[32,2]],null],[12],[2,\"\\n  \"],[8,\"ui/control-group\",[],[[\"@label\",\"@value\",\"@onInput\"],[\"Name\",[32,0,[\"name\"]],[30,[36,0],[[32,0,[\"handleInput\"]],\"name\"],null]]],null],[2,\"\\n  \"],[8,\"ui/control-group\",[],[[\"@label\",\"@value\",\"@onInput\"],[\"Description\",[32,0,[\"description\"]],[30,[36,0],[[32,0,[\"handleInput\"]],\"description\"],null]]],null],[2,\"\\n  \"],[8,\"ui/control-group\",[],[[\"@label\",\"@value\",\"@onInput\"],[\"GPS ID\",[32,0,[\"gpsId\"]],[30,[36,0],[[32,0,[\"handleInput\"]],\"gpsId\"],null]]],null],[2,\"\\n\\n  \"],[8,\"ui/control-group\",[],[[\"@label\",\"@inputType\",\"@value\",\"@options\",\"@onInput\"],[\"Vehicle Type\",\"select\",[32,0,[\"vehicleType\"]],[34,4],[30,[36,0],[[32,0,[\"handleVehicleTypeChange\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1,[\"name\"]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[14,4,\"submit\"],[12],[2,\"\\n      Save\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"did-insert\",\"did-update\",\"vehicleTypes\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/transportation/vehicles/form/template.hbs"
    }
  });

  _exports.default = _default;
});