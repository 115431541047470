define("product-management/pods/components/invoices/invoice-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8hqLLA0G",
    "block": "{\"symbols\":[\"lineItem\",\"@on-add-line-item\",\"@on-delete\",\"@on-update\"],\"statements\":[[10,\"table\"],[14,0,\"table table-condensed invoice-items\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[14,5,\"width:25%\"],[12],[2,\"\\n        Quickbooks Item\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Description\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Quantity\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Rate\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"\\n        Total\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"text-center\"],[12],[2,\"\\n        Taxable\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,4],null,[[\"invoiceItems\",\"lineItem\",\"invoice\",\"canEdit\",\"onUpdate\",\"onDelete\"],[[35,3],[32,1],[35,1],[30,[36,2],[\"edit invoice\",[35,1]],null],[30,[36,0],[[32,0],[32,4]],null],[30,[36,0],[[32,0],[32,3]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,8],[[30,[36,2],[\"edit invoice\",[35,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"ui button massive primary\"],[4,[38,0],[[32,0],[32,2]],null],[12],[2,\"\\n    Add Line Item\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"invoice\",\"can\",\"invoice-items\",\"invoice/line-item\",\"line-items\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/invoices/invoice-detail/template.hbs"
    }
  });

  _exports.default = _default;
});