define("product-management/pods/components/pricing/price-per-square-foot/option-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W1EnyQGm",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"h4\"],[12],[2,\"Options by Screen\"],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,2,[\"pricing\",\"options\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],null,[[\"option\",\"product\",\"onDelete\"],[[32,1],[35,2],[30,[36,1],[[32,0],\"deleteOption\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"   \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n     No options set yet\\n   \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[1,[30,[36,0],[\"spinner\"],[[\"spin\"],[true]]]],[2,\" Deleting\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"action\",\"product\",\"pricing/price-per-square-foot/option-item\",\"-track-array\",\"each\",\"isDeleting\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pricing/price-per-square-foot/option-list/template.hbs"
    }
  });

  _exports.default = _default;
});