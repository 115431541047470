define("product-management/pods/session/service", ["exports", "ember-simple-auth/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var shopRoles = ["zipperer", "rough cutter", "final cutter", "packager"];
  var transportationPartnerRoles = ["transportation_admin", "transportation_liason", "transportation_driver"];

  var _default = _session.default.extend({
    shopRoles: shopRoles,
    store: Ember.inject.service(),
    myEmail: Ember.computed.alias("data.authenticated.email"),
    role: Ember.computed.alias("data.authenticated.role"),
    systemManager: Ember.computed.alias("data.authenticated.system_manager"),
    shopManager: Ember.computed.alias("data.authenticated.shop_manager"),
    isSystemManager: Ember.computed.alias("systemManager"),
    isShopManager: Ember.computed.alias("shopManager"),
    isAdmin: Ember.computed.equal("role", "admin"),
    isShop: Ember.computed.equal("role", "shop"),
    isDriver: Ember.computed.equal("role", "driver"),
    isDealer: Ember.computed.equal("role", "dealer"),
    dashboardRedirectRoute: Ember.computed("role", function () {
      var role = this.role;
      if (transportationPartnerRoles.includes(role)) return "partners.transportation";

      switch (role) {
        case "admin":
          return "admin";

        case "dealer":
          return "dealer_admin";

        case "shop":
          return "shop";

        case "driver":
          return "driver";

        default:
          return this.getDefaultRoute(role);
      }
    }),
    getDefaultRoute: function getDefaultRoute(role) {
      if (role === undefined) return "unauthorized";
      return this.shopRoles.includes(role.toLowerCase()) ? "shop" : "unauthorized";
    }
  });

  _exports.default = _default;
});