define("product-management/pods/components/fulfillment/deliveries/suggested-orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qlhqWKus",
    "block": "{\"symbols\":[\"dealer\",\"@orders\",\"@onMoveDelivery\",\"@title\"],\"statements\":[[10,\"span\"],[14,0,\"text-2xl border-b-2 border-grey-lighter pb-1  font-semibold block mb-4\"],[12],[2,\"\\n  \"],[1,[32,4]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[30,[36,2],[\"name\",[35,1]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"fulfillment/deliveries/other-shipping/order-list\",[],[[\"@title\",\"@orders\",\"@onMoveDelivery\"],[[32,1,[\"name\"]],[30,[36,0],[\"dealer.id\",[32,1,[\"id\"]],[32,2]],null],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"filter-by\",\"dealers\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/deliveries/suggested-orders/template.hbs"
    }
  });

  _exports.default = _default;
});