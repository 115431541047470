define("product-management/mixins/validated-save-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      validatedSave: function validatedSave(changeset) {
        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            changeset.save();
          } else {
            alert('Invalid.');
          }
        });
      }
    }
  });

  _exports.default = _default;
});