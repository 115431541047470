define("product-management/pods/components/ui/settings/sub-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kvb2BOrp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"span\"],[14,0,\"block font-semibold text-xl mb-2 pb-2 border-b border-grey\"],[12],[2,\"\\n\\t\"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/settings/sub-header/template.hbs"
    }
  });

  _exports.default = _default;
});