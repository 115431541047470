define("product-management/pods/components/pm/ui/inline-editor/edit-mode/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rpejvCY+",
    "block": "{\"symbols\":[\"@placeholder\",\"@onToggleMode\",\"@isVisible\"],\"statements\":[[6,[37,5],[[32,3]],null,[[\"default\"],[{\"statements\":[[10,\"input\"],[15,\"oninput\",[30,[36,1],[[32,0],[35,0]],null]],[15,\"onkeypress\",[30,[36,1],[[32,0],[35,2]],null]],[15,2,[34,3]],[14,0,\"form-control\"],[15,\"placeholder\",[32,1]],[14,4,\"text\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mt-2 text-right\"],[12],[2,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[30,[36,1],[[32,0],[35,4]],null]],[14,0,\"btn btn-primary btn-xs\"],[12],[2,\"Save\"],[13],[2,\" or \"],[10,\"a\"],[14,6,\"#\"],[15,\"onclick\",[30,[36,1],[[32,0],[32,2]],null]],[12],[2,\"cancel\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"handleInput\",\"action\",\"handleKeypress\",\"value\",\"handleUpdate\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/inline-editor/edit-mode/template.hbs"
    }
  });

  _exports.default = _default;
});