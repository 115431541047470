define("product-management/pods/components/painless-manufacturing/shop/pin/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c7y2qKa/",
    "block": "{\"symbols\":[\"@number\",\"@onclick\"],\"statements\":[[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,2],[32,1]],null]],[14,0,\"border rounded-full border-grey-light flex justify-center items-center w-24 h-24 text-2xl font-normal hover:bg-grey-lighter focus:outline-none shadow\"],[12],[2,\"\\n  \"],[1,[32,1]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/shop/pin/button/template.hbs"
    }
  });

  _exports.default = _default;
});