define("product-management/pods/components/tailwind-ui/lists/simple-striped/header/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vKRaNx+W",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"th\"],[14,0,\"px-6 py-3 bg-grey-lightest text-left text-xs leading-4 font-medium text-grey uppercase tracking-wider\"],[12],[2,\"\\n\\t\"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/tailwind-ui/lists/simple-striped/header/item/template.hbs"
    }
  });

  _exports.default = _default;
});