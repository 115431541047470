define("product-management/pods/components/pm/ui/friendly-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C04lsR+W",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,0],\"MM/DD/YYYY hh:mm a\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dateValue\",\"moment-format\",\"date\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/friendly-date/template.hbs"
    }
  });

  _exports.default = _default;
});