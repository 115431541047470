define("product-management/pods/components/painless-manufacturing/cutdown/wizard/item-configuration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dhfZeenK",
    "block": "{\"symbols\":[\"@cutdownItem\",\"@orderId\"],\"statements\":[[8,\"painless-manufacturing/line-item/wizard/step3\",[],[[\"@lineItemId\",\"@productId\",\"@orderId\",\"@details\",\"@quantity\",\"@onBackToOrder\",\"@headerText\",\"@headerMini\",\"@onSave\"],[[32,1,[\"id\"]],[32,1,[\"cutdownProduct\",\"id\"]],[32,2],[34,0],1,[30,[36,1],[[32,0,[\"handleBackToOrder\"]]],null],[34,2],true,[30,[36,1],[[32,0,[\"handleSave\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"details\",\"fn\",\"headerText\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/cutdown/wizard/item-configuration/template.hbs"
    }
  });

  _exports.default = _default;
});