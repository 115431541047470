define("product-management/pods/components/pricing/price-per-square-foot/option-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNames: ["list-group-item"],
    actions: {
      deleteOption: function deleteOption(product, id) {
        return this.attrs.onDelete(product, id);
      }
    }
  });

  _exports.default = _default;
});