define("product-management/pods/admin/accounting/deliveries/on-truck/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return new Ember.RSVP.hash({
        orders: this.store.query("order", {
          reload: true,
          include: "line-items.product.product-type,dealer,work-queue",
          "filter[status]": "on_truck"
        })
      });
    }
  });

  _exports.default = _default;
});