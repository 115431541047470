define("product-management/pods/admin/settings/matrices/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wH3hLgcY",
    "block": "{\"symbols\":[\"matrix\",\"PageHeader\"],\"statements\":[[8,\"settings/page-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,[32,2,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Data Matrices\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,[32,2,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"admin.settings.matrices.new\"]],[[\"default\"],[{\"statements\":[[2,\"Add a New Matrix\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[10,\"p\"],[14,0,\"instructions\"],[12],[2,\"\\nSelect a price matrix below, or add a new one.\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-right mb-8\"],[12],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t  \"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@model\"],[\"admin.settings.matrices.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/matrices/index/template.hbs"
    }
  });

  _exports.default = _default;
});