define("product-management/gql/fragments/dealers/tax-filing.graphql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "FragmentDefinition",
      "name": {
        "kind": "Name",
        "value": "TaxExemptionFields"
      },
      "typeCondition": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Dealer"
        }
      },
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "needsTaxExemptionUpdate"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "taxItem"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "name"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "rate"
              },
              "arguments": [],
              "directives": []
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "latestTaxExemptionFiling"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "taxExempt"
              },
              "arguments": [],
              "directives": []
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "dealerTaxExemptionFilings"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "submittedAt"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "status"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "expiresOn"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "taxExempt"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "certificateNumber"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "certificateState"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "certificate"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "file"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "submitter"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "fullName"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 397
    }
  };
  var _default = doc;
  _exports.default = _default;
});