define("product-management/pods/order/ability", ["exports", "@babel/runtime/helpers/esm/defineProperty", "product-management/pods/application/ability"], function (_exports, _defineProperty2, _ability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _ApplicationAbility$e;

  var statusOrdered = "ordered";
  var statusesPermittedToChangePoNumber = ["quoted", statusOrdered, "in_process"];

  var _default = _ability.default.extend((_ApplicationAbility$e = {
    isStatusOrdered: Ember.computed("model.status", function () {
      return this.status === statusOrdered;
    }),
    status: Ember.computed.alias("model.status"),
    returnable: Ember.computed.alias("model.returnable"),
    shippingMethod: Ember.computed.alias("model.shippingMethod"),
    wasImported: Ember.computed.alias("model.auditTrail.imported_at"),
    canRequestNeedByDate: Ember.computed("dealer.canRequestNeedByDate", function () {
      return this.dealer.get("canRequestNeedByDate");
    }),
    canEditShipDate: Ember.computed("status", function () {
      return !["cancelled", "shipped", "invoiced", "quoted"].includes(this.status.toLowerCase());
    }),
    canChangeShippingMethod: Ember.computed("status", function () {
      return !["invoiced"].includes(this.status);
    }),
    canChangeShippingAddress: Ember.computed("status", "shippingMethod", function () {
      return !["shipped", "invoiced"].includes(this.status) && this.shippingMethod === "Shipping";
    }),
    isOrderedOrInProcess: Ember.computed("status", function () {
      return ["in_process", "ordered"].includes(this.status);
    }),
    canChangePoNumber: Ember.computed("status", "wasImported", function () {
      return statusesPermittedToChangePoNumber.includes(this.status) && !this.wasImported;
    }),
    canViewShipOn: Ember.computed.alias("isAdmin")
  }, (0, _defineProperty2.default)(_ApplicationAbility$e, "canChangeShippingMethod", Ember.computed.alias("isAdmin")), (0, _defineProperty2.default)(_ApplicationAbility$e, "hasShipDate", Ember.computed.notEmpty("model.shipOn")), (0, _defineProperty2.default)(_ApplicationAbility$e, "hasWorkQueue", Ember.computed.notEmpty("model.workQueue.content")), (0, _defineProperty2.default)(_ApplicationAbility$e, "noPendingNotes", Ember.computed.alias("model.noPendingNotes")), (0, _defineProperty2.default)(_ApplicationAbility$e, "invoice", Ember.computed.alias("model.invoice")), (0, _defineProperty2.default)(_ApplicationAbility$e, "hasNoInvoice", Ember.computed.empty("invoice.id")), (0, _defineProperty2.default)(_ApplicationAbility$e, "canApprove", Ember.computed.and("isAdmin", "noPendingNotes", "isStatusOrdered", "hasShipDate", "hasWorkQueue")), (0, _defineProperty2.default)(_ApplicationAbility$e, "canExpedite", Ember.computed.and("isAdmin", "isOrderedOrInProcess")), (0, _defineProperty2.default)(_ApplicationAbility$e, "canPrintDeliveryTicket", Ember.computed("status", function () {
    return !["quoted", "ordered"].includes(this.status);
  })), (0, _defineProperty2.default)(_ApplicationAbility$e, "canAdminCancel", Ember.computed("status", "hasNoInvoice", "wasImported", "isAdmin", function () {
    return !["quoted", "cancelled", "closed"].includes(this.status) && this.hasNoInvoice && !this.wasImported && this.isAdmin;
  })), (0, _defineProperty2.default)(_ApplicationAbility$e, "wasNotImported", Ember.computed.not("wasImported")), (0, _defineProperty2.default)(_ApplicationAbility$e, "canAdminSendBack", Ember.computed.and("wasNotImported", "isStatusOrdered")), (0, _defineProperty2.default)(_ApplicationAbility$e, "wasNotCancelledByAdmin", Ember.computed("model.auditTrail", function () {
    return this.auditTrail.cancelled_by_admin === undefined || !this.auditTrail.cancelled_by_admin;
  })), (0, _defineProperty2.default)(_ApplicationAbility$e, "canReinstate", Ember.computed("status", "isDealer", "wasNotCancelledByAdmin", function () {
    return this.status === "cancelled" && this.isDealer && this.wasNotCancelledByAdmin;
  })), (0, _defineProperty2.default)(_ApplicationAbility$e, "canChangeWorkQueue", Ember.computed("status", "isAdmin", function () {
    return !["invoiced", "ordered", "quoted", "cancelled"].includes(this.status) && this.isAdmin;
  })), (0, _defineProperty2.default)(_ApplicationAbility$e, "canCreateInvoice", Ember.computed("returnable", "status", "isAdmin", "hasNoInvoice", function () {
    return !this.returnable && !["ordered", "quoted", "invoiced", "cancelled"].includes(this.status) && this.hasNoInvoice;
  })), (0, _defineProperty2.default)(_ApplicationAbility$e, "canSendBackToOrdered", Ember.computed("status", "isSystemManager", function () {
    return this.status == "in_process" && this.isSystemManager;
  })), (0, _defineProperty2.default)(_ApplicationAbility$e, "canSplit", Ember.computed("status", "isAdmin", "lineItems.length", function () {
    return this.status === "ordered" && this.isAdmin && this.model.lineItems.length > 1 || this.status === "in_process" && this.hasNoInvoice && (this.isSystemManager || this.isShopManager) && this.model.lineItems.length > 1;
  })), _ApplicationAbility$e));

  _exports.default = _default;
});