define("product-management/pods/dealer-admin/index/route", ["exports", "ember-ui-helpers/mixins/notifyable"], function (_exports, _notifyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_notifyable.default, {
    redirect: function redirect() {
      this.transitionTo("dealer_admin.orders");
    }
  });

  _exports.default = _default;
});