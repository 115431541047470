define("product-management/pods/components/stardust/data-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JbY/99I+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,5],null,[[\"table\",\"search\",\"pagination\"],[[30,[36,0],[\"stardust/data-table/table\"],[[\"sortBy\",\"sortDirection\",\"sortedField\",\"records\"],[[30,[36,4],[[32,0,[\"updateSortingDefinition\"]]],null],[35,3],[35,2],[35,1]]]],[30,[36,0],[\"stardust/data-table/search\"],null],[30,[36,0],[\"stardust/data-table/pagination\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"sortedRecords\",\"sortedField\",\"sortDirection\",\"fn\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/data-table/template.hbs"
    }
  });

  _exports.default = _default;
});