define("product-management/pods/shop/route", ["exports", "product-management/mixins/shared/top-level-route"], function (_exports, _topLevelRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_topLevelRoute.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (this.session.isDealer || this.session.isDriver) this.transitionTo("dashboard");
    }
  });

  _exports.default = _default;
});