define("product-management/pods/dealer-admin/settings/index/controller", ["exports", "ember-ui-helpers/mixins/notifyable"], function (_exports, _notifyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_notifyable.default, {
    dealer: Ember.computed.alias("model"),
    actions: {
      save: function save(dealer) {
        var _this = this;

        return dealer.save().then(function () {
          _this.info("Your settings have been saved!");

          _this.transitionToRoute("dealer_admin");
        });
      }
    }
  });

  _exports.default = _default;
});