define("product-management/pods/orders/show/index/index/qc-reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aWyr1fpF",
    "block": "{\"symbols\":[],\"statements\":[[8,\"sun-pro/qc/reports\",[],[[\"@lineItem\",\"@onClose\"],[[34,0],[30,[36,1],[[32,0,[\"handleClose\"]]],null]]],null]],\"hasEval\":false,\"upvars\":[\"model\",\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/index/index/qc-reports/template.hbs"
    }
  });

  _exports.default = _default;
});