define("product-management/pods/components/pm/ui/code-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LY5MtRa/",
    "block": "{\"symbols\":[\"@editorValue\",\"@lines\",\"@value\",\"&default\"],\"statements\":[[18,4,[[30,[36,1],null,[[\"editorValue\",\"editor\"],[[32,1],[30,[36,0],[\"pm/ui/code-editor/editor\"],[[\"value\",\"lines\",\"editorValue\"],[[32,3],[32,2],[32,1]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/code-editor/template.hbs"
    }
  });

  _exports.default = _default;
});