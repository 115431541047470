define("product-management/pods/components/orders/order-confirm-modal/component", ["exports", "product-management/validations/order-shipping"], function (_exports, _orderShipping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    OrderShippingValidations: _orderShipping.default,
    allShippingMethods: [{
      method: "Shipping",
      lookup: "canShip"
    }, {
      method: "Delivery",
      lookup: "canDeliver"
    }, {
      method: "Pick up",
      lookup: "canPickup"
    }],
    hasOneMethod: Ember.computed.equal('shippingMethods.length', 1),
    hasNoShippingMethod: Ember.computed.empty('order.shippingMethod'),
    cannotPlaceOrder: Ember.computed.or('hasNoTermsAndMethod', 'needsShippingAddressInfo'),
    hasNotAcceptedTerms: Ember.computed.not("acceptedTerms"),
    hasNoTermsAndMethod: Ember.computed.or('hasNotAcceptedTerms', 'hasNoShippingMethod'),
    hasShippingAddress: Ember.computed.notEmpty("order.shippingAddress"),
    hasShippingCity: Ember.computed.notEmpty("order.shippingCity"),
    hasShippingState: Ember.computed.notEmpty("order.shippingState"),
    hasShippingZip: Ember.computed.notEmpty("order.shippingZip"),
    shippingAddressInfo: Ember.computed.and('hasShippingAddress', 'hasShippingCity', 'hasShippingState', 'hasShippingZip'),
    hasNoShippingAddressInfo: Ember.computed.equal('shippingAddressInfo', false),
    needsShippingAddressInfo: Ember.computed.and('isShipping', 'hasNoShippingAddressInfo'),
    isShipping: Ember.computed("order.shippingMethod", function () {
      return this.get("order.shippingMethod") === "Shipping";
    }),
    isPickUp: Ember.computed("order.shippingMethod", function () {
      return this.get("order.shippingMethod") === "Pick up";
    }),
    isDelivery: Ember.computed("order.shippingMethod", function () {
      return this.get("order.shippingMethod") === "Delivery";
    }),
    shippingMethods: Ember.computed("dealer", function () {
      var _this = this;

      return this.allShippingMethods.filter(function (item) {
        return _this.dealer.get(item.lookup);
      }).map(function (item) {
        return item.method;
      });
    }),
    actions: {
      placeOrder: function placeOrder(order) {
        return this.attrs.onPlaceOrder(order);
      },
      closeSettingsModal: function closeSettingsModal() {
        this.attrs.onClose();
      },
      openTerms: function openTerms() {
        Ember.set(this, 'isReadingTerms', true);
      },
      closeTerms: function closeTerms() {
        Ember.set(this, 'isReadingTerms', false);
      }
    }
  });

  _exports.default = _default;
});