define("product-management/pods/components/shop/line-items/unit-modifications-notice/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    recessed: false,
    notRecessed: Ember.computed.not("recessed"),
    hasExceptions: Ember.computed.or("noRollTube", "noHoodHousing", "noScreen", "noBottomBar", "noSideTracks"),
    noRollTube: Ember.computed.equal("details.roll_tube_size", "None"),
    noHoodHousing: Ember.computed.equal("details.hood_size", "None"),
    noScreen: Ember.computed.equal("details.mesh_type", "No"),
    noBottomBar: Ember.computed.equal("details.remove_bottom_bar", true),
    noSideTracks: Ember.computed.equal("details.side_track_type", "None")
  });

  _exports.default = _default;
});