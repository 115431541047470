define("product-management/pods/components/purchasing/inventory-items/inline-update/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zur3e8p4",
    "block": "{\"symbols\":[\"@placeholder\",\"@field\",\"@item\"],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleToggleEdit\"]]],null]],null],[12],[2,\"\\n\\t\"],[1,[30,[36,4],[[30,[36,3],[[32,3],[32,2]],null],[30,[36,3],[[32,3],[32,2]],null],\"-- Edit --\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[11,\"form\"],[4,[38,1],[\"submit\",[30,[36,0],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n\\t\\t\"],[11,\"input\"],[16,\"placeholder\",[32,1]],[24,0,\"w-full p-2 border border-grey-light mr-2\"],[24,4,\"text\"],[4,[38,2],[[32,0,[\"handleInsert\"]],[32,0]],null],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]]],null]],null],[4,[38,1],[\"keydown\",[30,[36,0],[[32,0,[\"handleKeydown\"]]],null]],null],[12],[13],[2,\"\\n\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"mt-1\"],[12],[2,\"\\n\\t\\t\"],[10,\"button\"],[14,0,\"rounded p-1 text-sm text-white bg-blue\"],[14,4,\"submit\"],[12],[2,\"Save\"],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"block mt-1 text-xs\"],[12],[2,\"\\n\\t\\t\\tor \"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleToggleEdit\"]]],null]],null],[12],[2,\"cancel\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"did-insert\",\"get\",\"if\",\"isEditing\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/purchasing/inventory-items/inline-update/template.hbs"
    }
  });

  _exports.default = _default;
});