define("product-management/pods/admin/settings/reports/show/snippets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.basicShell = void 0;
  var basicShell = "\nreport do\n  header do\n\t  \"Report Name\"\n\tend\n\n\tvisible do\n\t\ttrue\n\tend\n\n\tfield \"\" do\n\t  header \"\"\n\t\ttype \"\" #string,date,currency\n\t\t#link \"\" #route to \n\t\t#link_text_field \"\"\n\tend\n\n\tsql do\n\t  q = %(\n\t\t)\n\tend\nend\n"; // export const bomItemShell = `
  //   bom_item "BOM ITEM NAME" do
  //     sku ""
  //     price 0
  // 		quantity 1
  //   end
  // `
  // export const accountingShell = `
  // accounting do
  //     ${bomItemShell}
  // end
  // `
  // export const fieldShell = `
  //   field :FIELD_NAME do
  //       type #Numeric,String
  //       required true
  //       visible true
  //       input do
  //         label ""
  //         component "" #select,radio-options,text,fractional-dimension,numeric,textarea
  //       end
  // 			#options do
  // 			#  choice ""
  // 			#end
  // 			#option_set do
  // 			#  active true
  // 			#  choice_set OptionList.find_by(name: "").items
  // 			#end
  //   end
  // `
  // export const stepShell = `
  //   step "STEP NAME" do
  // 	    ${fieldShell}
  // 	end
  // `
  // export const configurationShell = `
  // configurable do
  //     ${stepShell}
  // end
  // `
  // export const summaryAttributeShell = `
  //     attribute "" do
  //       visible -> { }
  //       value -> { }
  //     end
  // `
  // export const summaryShell = `
  // summary do
  //   group "" do
  // 	  ${summaryAttributeShell}
  //   end
  // end
  // `
  // export const computedFieldShell = `
  //   field :FIELD_NAME do
  // 	end
  // `
  // export const computedShell = `
  // computed do
  //   ${computedFieldShell}
  // end
  // `
  // export const basicShell = `${configurationShell}${summaryShell}${accountingShell}`

  _exports.basicShell = basicShell;
});