define("product-management/pods/admin/settings/shop-codes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cJSIq9Nt",
    "block": "{\"symbols\":[\"PageHeader\"],\"statements\":[[8,\"settings/page-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Shop Codes\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"admin.settings.shop_codes.new\"]],[[\"default\"],[{\"statements\":[[2,\"Add a Shop Code\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[8,\"shop-codes/table-list\",[],[[\"@shop-codes\",\"@on-regenerate\",\"@on-toggle-status\"],[[34,0],[30,[36,1],[\"regenerate\"],null],[30,[36,1],[\"toggleStatus\"],null]]],null],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"shop-codes\",\"route-action\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/shop-codes/template.hbs"
    }
  });

  _exports.default = _default;
});