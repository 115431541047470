define("product-management/pods/shop/deliveries/show/picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e9YpRpn2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex justify-end\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-1/3\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"font-semibold\"],[12],[2,\"\\n      Select your label printer\\n    \"],[13],[2,\"\\n    \"],[8,\"print/printers\",[[24,0,\"mt-1 mb-4\"]],[[\"@format\"],[\"select\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"fulfillment/picklist\",[],[[\"@container\"],[[34,0]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop/deliveries/show/picker/template.hbs"
    }
  });

  _exports.default = _default;
});