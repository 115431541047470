define("product-management/pods/admin/transportation/delivery-routes/show/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "roAy5BwF",
    "block": "{\"symbols\":[\"canDo\"],\"statements\":[[10,\"span\"],[14,0,\"font-semibold text-xl\"],[12],[2,\"\\n  Delivery Route Details\\n\"],[13],[2,\"\\n\"],[8,\"can-do\",[],[[\"@model\",\"@action\",\"@policy\"],[[34,0],\"update\",\"DeliveryRoute\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"fulfillment/delivery-routes/form\",[],[[\"@canEdit\",\"@deliveryRoute\"],[[32,1,[\"allowed\"]],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/transportation/delivery-routes/show/index/template.hbs"
    }
  });

  _exports.default = _default;
});