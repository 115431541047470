define("product-management/pods/components/pricing/price-per-square-foot/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    newOption: {},
    _transformOption: function _transformOption(option) {
      option.id = option.screen.id;
      option.name = option.screen.get("name");
      delete option.screen;
      return option;
    },
    selectedScreenIds: Ember.computed.mapBy("product.pricing.options", 'id'),
    availableScreens: Ember.computed("selectedScreenIds", "screens", function () {
      return this.screens.filter(function (item) {
        return item; // return !this.get("selectedScreenIds").includes(item.id);
      });
    }),
    actions: {
      saveFilterField: function saveFilterField(changeset) {
        return this.attrs.onSave(changeset).then(function () {
          alert("saved");
        });
      },
      addOption: function addOption(product, newOption) {
        var _this = this;

        newOption = this._transformOption(newOption);
        return this.attrs.onAddOption(product, newOption).then(function () {
          _this.set("newOption", {});
        });
      },
      deleteOption: function deleteOption(product, id) {
        return this.attrs.onDeleteOption(product, id);
      }
    }
  });

  _exports.default = _default;
});