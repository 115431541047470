define("product-management/helpers/get-shamgar-route", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getShamgarRoute = getShamgarRoute;
  _exports.default = void 0;

  function getShamgarRoute(params, hash) {
    var namespace = "";

    if (hash.namespace) {
      namespace = _emberGetConfig.default.shamgar["".concat(hash.namespace, "Namespace")] + ".";
    }

    return "".concat(namespace).concat(params[0]);
  }

  var _default = Ember.Helper.helper(getShamgarRoute);

  _exports.default = _default;
});