define("product-management/pods/shop/packaging/index/route", ["exports", "js-cookie"], function (_exports, _jsCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var station = _jsCookie.default.get("SHOP_STATION");

      var currentWorkQueueName = _jsCookie.default.get("WORK_QUEUE_NAME");

      var currentWorkQueueId = _jsCookie.default.get("WORK_QUEUE");

      var consumer = this.modelFor("shop");
      var controller = this.controllerFor(this.routeName);
      controller.subscribeToChannel("wip", "WipChannel", controller.refreshResults.bind(controller), consumer);
      return new Ember.RSVP.hash({
        station: station,
        currentWorkQueueName: currentWorkQueueName,
        currentWorkQueueId: currentWorkQueueId,
        pickups: controller.getPickups()
      });
    },
    deactivate: function deactivate() {
      this.controller.unsubscribeFromChannel("packaging");
    }
  });

  _exports.default = _default;
});