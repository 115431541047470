define("product-management/pods/components/orders/ship-on-date/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    shipOn: Ember.computed("order.shipOn", function () {
      return (0, _moment.default)(this.get("order.shipOn")).toString();
    })
  });

  _exports.default = _default;
});