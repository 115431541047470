define("product-management/pods/my-account/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //Add role constants here
  // const ADMIN_ROLE = "Admin";
  var _default = _model.default.extend({
    role: (0, _model.attr)("string") // isAdmin: equal("role",ADMIN_ROLE)

  });

  _exports.default = _default;
});