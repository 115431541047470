define("product-management/pods/components/kpi-charts/products-manufactured/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vblqS7FA",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"border px-10 py-6 w-full my-3\"],[4,[38,2],[[32,0,[\"handleInsert\"]],[32,0]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-64\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"products\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],null,[[\"content\",\"select-item\",\"optionValuePath\",\"optionLabelPath\",\"selection\",\"placeholder\"],[[32,0,[\"products\"]],[30,[36,0],[[32,0],[32,0,[\"handleProductSelected\"]]],null],\"content.name\",\"content.name\",[32,0,[\"selectedProduct\"]],\"Products\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mg-chart\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"ember-selectize\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/kpi-charts/products-manufactured/template.hbs"
    }
  });

  _exports.default = _default;
});