define("product-management/pods/shop/pick-station/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({// currentStation: computed(function () {
    // }),
    // // currentStation: alias("model.currentStation"),
    // "stations-sort-by": ["position"],
    // sortedStations: sort("model", "stations-sort-by"),
    // stationsByName: mapBy("sortedStations", "name"),
    // stations: computed("stationsByName", function () {
    //   return this.stationsByName;
    // }),
    // stationNotSelected: empty("currentStation"),
    // actions: {
    //   selectStation(station) {
    //     Cookies.set(stationCookieName, station);
    //     this.transitionToRoute("shop");
    //   },
    //   handleExitProductionLine() {
    //     Cookies.remove(productionLineCookieName);
    //     this.transitionToRoute("shop");
    //   },
    // },
  });

  _exports.default = _default;
});