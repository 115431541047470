define("product-management/utils/stations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.underscoredProductionStations = _exports.underscoredStations = _exports.productionStations = _exports.stations = void 0;
  var stations = [{
    key: 'Rough Cut',
    value: 'rough-cut'
  }, {
    key: 'Final Cut',
    value: 'final-cut'
  }, {
    key: 'Zipper',
    value: 'zipper'
  }, {
    key: "Splicer",
    value: "splicer"
  }, {
    key: "Roll Tube",
    value: "roll-tube"
  }, {
    key: "Final Assembly",
    value: "final-assembly"
  }, {
    key: "Bottom Bar",
    value: "bottom-bar"
  }, {
    key: "Side Track",
    value: "side-track"
  }, {
    key: "Deliveries",
    value: "deliveries"
  }, {
    key: "Parts",
    value: "parts"
  }, {
    key: "Interior",
    value: "interior"
  }, {
    key: "Awning Fabric",
    value: "awning_fabric"
  }, {
    key: "Awning Hardware",
    value: "awning_hardware"
  }];
  _exports.stations = stations;
  var productionStations = stations.filter(function (station) {
    return station.value != "deliveries";
  });
  _exports.productionStations = productionStations;

  var underscoredStations = _makeUnderscoredStations(stations);

  _exports.underscoredStations = underscoredStations;

  var underscoredProductionStations = _makeUnderscoredStations(productionStations);

  _exports.underscoredProductionStations = underscoredProductionStations;

  function _makeUnderscoredStations(stationList) {
    return stationList.map(function (station) {
      return {
        key: station.key,
        value: station.value.replace("-", "_")
      };
    });
  }
});