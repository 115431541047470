define("product-management/pods/components/fulfillment/delivery-zones/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hfy09nx0",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[4,[38,1],[\"submit\",[30,[36,0],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[12],[2,\"Zone Name\"],[13],[2,\"\\n\\t\\t\"],[11,\"input\"],[24,0,\"form-control\"],[16,2,[34,2]],[24,4,\"text\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]],\"name\"],null]],null],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\n\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[12],[2,\"Description\"],[13],[2,\"\\n\\t\\t\"],[11,\"input\"],[24,0,\"form-control\"],[16,2,[34,3]],[24,4,\"text\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]],\"description\"],null]],null],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[12],[2,\"Color\"],[13],[2,\"\\n\\t\\t\"],[11,\"input\"],[24,0,\"form-control\"],[16,2,[34,4]],[24,4,\"color\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]],\"color\"],null]],null],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"my-2\"],[12],[2,\"\\n\\t\\t\"],[8,\"ui/async-button\",[[24,0,\"btn btn-primary btn-block\"]],[[\"@isRunning\",\"@defaultText\",\"@pendingText\",\"@afterComplete\"],[[34,5,[\"isRunning\"]],\"Save\",\"Saving....\",\"Saved...\"]],null],[2,\"\\n\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"name\",\"description\",\"color\",\"save\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/delivery-zones/form/template.hbs"
    }
  });

  _exports.default = _default;
});