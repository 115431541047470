define("product-management/pods/admin/settings/code-snippets/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tWl0XZ6s",
    "block": "{\"symbols\":[],\"statements\":[[8,\"stardust/code-snippets/form\",[],[[\"@codeSnippet\",\"@indexRoute\"],[[34,0],\"admin.settings.code-snippets\"]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/code-snippets/show/template.hbs"
    }
  });

  _exports.default = _default;
});