define("product-management/pods/components/shared/signature-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "htM6EBAW",
    "block": "{\"symbols\":[\"@onClearSignature\"],\"statements\":[[10,\"div\"],[14,0,\"signature-area\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[35,0]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"printed-name-area\"],[12],[2,\"\\n  \"],[10,\"strong\"],[12],[2,\"Signed by:\"],[13],[2,\" \"],[1,[34,2]],[2,\" on \"],[1,[30,[36,4],[[35,3],\"MM/DD/YYYY\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"clear-signature\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,5],[[32,0],[32,1]],null],[12],[2,\"clear\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"signature\",\"html-safe\",\"printed-name\",\"signed-at\",\"moment-format\",\"action\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shared/signature-display/template.hbs"
    }
  });

  _exports.default = _default;
});