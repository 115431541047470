define("product-management/pods/components/pm/comments/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XL/GJ4Y0",
    "block": "{\"symbols\":[\"comment\",\"@comments\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[30,[36,0],[\"createdAt\",[32,2]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"pm/comments/item\",[],[[\"@comment\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/comments/list/template.hbs"
    }
  });

  _exports.default = _default;
});