define("product-management/pods/components/tags/filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tL+CsDkp",
    "block": "{\"symbols\":[\"option\",\"@tags\"],\"statements\":[[2,\"\\t\"],[8,\"power-select-multiple\",[],[[\"@options\",\"@selected\",\"@onChange\",\"@searchEnabled\",\"@searchField\"],[[32,2],[32,0,[\"selected\"]],[30,[36,0],[[32,0,[\"handleChange\"]]],null],true,\"name\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/tags/filter/template.hbs"
    }
  });

  _exports.default = _default;
});