define("product-management/pods/components/products/product-weight-categories/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xzwCOpv/",
    "block": "{\"symbols\":[\"codeEditor\"],\"statements\":[[11,\"form\"],[4,[38,1],[\"submit\",[30,[36,0],[[32,0,[\"handleSubmit\"]]],null]],null],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Name\"],[13],[2,\"\\n        \"],[11,\"input\"],[16,2,[34,2]],[24,0,\"form-control\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]],\"name\"],null]],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Summary\"],[13],[2,\"\\n        \"],[8,\"pm/ui/code-editor\",[],[[\"@editorValue\"],[[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"editor\"]],[],[[\"@value\",\"@lines\",\"@mode\"],[[34,3],30,\"ace/mode/ruby\"]],null],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-right mt-4\"],[12],[2,\"\\n        \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[14,4,\"submit\"],[12],[2,\"\\n            Save\\n        \"],[13],[2,\"\\n        or\\n        \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.product-weights.categories\"]],[[\"default\"],[{\"statements\":[[2,\"cancel\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"name\",\"summary\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/products/product-weight-categories/form/template.hbs"
    }
  });

  _exports.default = _default;
});