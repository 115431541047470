define("product-management/pods/admin/dealers/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fWGar4NB",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[8,\"app-container\",[],[[\"@title\"],[\"New Dealer\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[35,7],[35,6]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"propertyName\",\"changeset\",\"label-text\"],[\"name\",[32,1],\"Name\"]]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"\\n        QB Customer Account\\n      \"],[13],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"selection\"],[[35,1],\"content.id\",\"content.name\",[32,1,[\"qbCustomer\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn-add\"],[4,[38,3],[[32,0],\"submit\",[32,1]],null],[12],[2,\"\\n      Create Dealer\\n    \"],[13],[2,\"\\n    or\\n    \"],[6,[37,5],null,[[\"route\"],[[35,4]]],[[\"default\"],[{\"statements\":[[2,\"cancel\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"validated-input\",\"qbCustomers\",\"ember-selectize\",\"action\",\"cancel-route\",\"link-to\",\"DealerValidation\",\"dealer\",\"changeset\",\"with\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/dealers/new/template.hbs"
    }
  });

  _exports.default = _default;
});