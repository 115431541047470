define("product-management/pods/components/shop/line-items/final-cut-station/step-1/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a5pUSzGp",
    "block": "{\"symbols\":[\"@nextStep\"],\"statements\":[[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"instructionsSet\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"instruction\"],[12],[2,\"\\n      \"],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[[30,[36,2],[[35,1],\"isChecked\"],null]]]],[4,[38,3],[[32,0],\"verifyScreen\"],null],[12],[1,[30,[36,4],[\"check-circle fa-2x\"],null]],[13],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Verify fabric type\"],[13],[2,\"\\n      \"],[10,\"h4\"],[12],[2,\"\\n        Mesh/Fabric:\\n        \"],[1,[35,0,[\"mesh_name\"]]],[2,\"\\n        -\\n        \"],[6,[37,2],[[35,0,[\"mesh_brand\"]]],null,[[\"default\"],[{\"statements\":[[1,[35,0,[\"mesh_brand\"]]],[2,\" - \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[35,0,[\"mesh_color\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12 text-right\"],[12],[2,\"\\n      \"],[11,\"button\"],[16,\"disabled\",[34,5]],[16,0,[31,[\"btn btn-lg btn-primary completeButton\\n          \",[30,[36,7],[[35,6],\"disabled\"],null]]]],[4,[38,3],[[32,0],[32,1]],null],[12],[2,\"Next Step \"],[1,[30,[36,4],[\"fa-angle-double-right\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"details\",\"isScreenVerified\",\"if\",\"action\",\"fa-icon\",\"cannotMoveForward\",\"canMoveForward\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/line-items/final-cut-station/step-1/template.hbs"
    }
  });

  _exports.default = _default;
});