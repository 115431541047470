define("product-management/helpers/g-map/compute", ["exports", "ember-google-maps/helpers/g-map/compute"], function (_exports, _compute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _compute.default;
    }
  });
  Object.defineProperty(_exports, "gMapCompute", {
    enumerable: true,
    get: function get() {
      return _compute.gMapCompute;
    }
  });
});