define("product-management/pods/admin/accounting/sales-tax/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gaQ00rNs",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n    \"],[8,\"accounting/sales-tax/certificates/admin-upload\",[],[[\"@onSubmitted\"],[[30,[36,0],[[32,0,[\"handleSubmitted\"]]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[8,[32,0,[\"displayComponent\"]],[],[[\"@records\"],[[34,1]]],null]],\"hasEval\":false,\"upvars\":[\"fn\",\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/sales-tax/index/template.hbs"
    }
  });

  _exports.default = _default;
});