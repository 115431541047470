define("product-management/pods/components/tags/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GBXtYcjM",
    "block": "{\"symbols\":[\"option\",\"@onEditingComplete\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[8,\"power-select-multiple\",[],[[\"@options\",\"@selected\",\"@onChange\",\"@searchEnabled\",\"@searchField\",\"@onKeydown\"],[[32,0,[\"tags\"]],[32,0,[\"selected\"]],[30,[36,1],[[35,0]],null],true,\"name\",[32,0,[\"handleCreateOnEnter\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1,[\"name\"]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mt-2\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,0,\"text-sm cursor-pointer\"],[4,[38,2],[\"click\",[32,2]],null],[12],[2,\"\\n      done editing\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"handleSelection\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/tags/edit/template.hbs"
    }
  });

  _exports.default = _default;
});