define("product-management/pods/admin/settings/users/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JpSwzm1O",
    "block": "{\"symbols\":[\"PageHeader\"],\"statements\":[[8,\"settings/page-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\tCreate a new user\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.users\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\tBack to Users List\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[8,\"forms/user\",[],[[\"@newRecord\",\"@user\"],[true,[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/users/new/template.hbs"
    }
  });

  _exports.default = _default;
});