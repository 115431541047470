define("product-management/pods/components/invoices/bill-to-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sr6YoA+7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"px-4 py-2 bg-grey-light rounded-t\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-xl font-semibold\"],[12],[2,\"\\n      Billing\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"p-4 border border-grey-light rounded-b\"],[12],[2,\"\\n    \"],[10,\"address\"],[12],[2,\"\\n      \"],[1,[35,0,[\"billingAddress1\"]]],[2,\"\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,1],[[35,0,[\"billingAddress2\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[35,0,[\"billingAddress2\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,0,[\"billingAddress3\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[35,0,[\"billingAddress3\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[1,[35,0,[\"billingCity\"]]],[2,\", \"],[1,[35,0,[\"billingState\"]]],[2,\" \"],[1,[35,0,[\"billingZip\"]]],[2,\"\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,2],[\"envelope-o\"],null]],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[\"mailto:\",[34,0,[\"billingEmail\"]]]]],[12],[2,\"\\n      \"],[1,[35,0,[\"billingEmail\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"dealer\",\"if\",\"fa-icon\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/invoices/bill-to-card/template.hbs"
    }
  });

  _exports.default = _default;
});