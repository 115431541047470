define("product-management/pods/admin/settings/matrices/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O8nFjoJd",
    "block": "{\"symbols\":[\"PageHeader\"],\"statements\":[[8,\"settings/page-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Matrix Details\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"painless-manufacturing/matrices/table\",[],[[\"@matrix\",\"@indexRoute\"],[[34,0],\"admin.settings.matrices\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/matrices/show/template.hbs"
    }
  });

  _exports.default = _default;
});