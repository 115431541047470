define("product-management/mixins/shared/top-level-route", ["exports", "shamgar-auto-upgrade/mixins/auto-upgrade-route", "ember-ui-helpers/mixins/notifyable", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _autoUpgradeRoute, _notifyable, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import CableRegistrationRoute from "product-management/mixins/shared/cable-registration-route";
  var _default = Ember.Mixin.create(_authenticatedRouteMixin.default, _autoUpgradeRoute.default, // CableRegistrationRoute,
  _notifyable.default, {
    actions: {
      error: function error(_error, transition) {
        if (_error.errors === undefined) {
          this.error("Contact ParableSoft if the problem persists. This is the error that occurred: ".concat(_error));
        } else {
          if (_error.errors[0] && ["401", "403"].includes(_error.errors[0].status)) {
            this.transitionTo('login');
          } else {
            this.error("Contact ParableSoft if the problem persists.");
          }
        }
      }
    }
  });

  _exports.default = _default;
});