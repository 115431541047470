define("product-management/pods/admin-mobile/inventory/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pngxsRAQ",
    "block": "{\"symbols\":[\"purchase-order\"],\"statements\":[[10,\"h3\"],[12],[2,\"Inventory\"],[13],[2,\"\\n\"],[10,\"strong\"],[12],[2,\"Select a purchase order to receive\"],[13],[2,\"\\n\\n\"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"class\"],[\"list-group-item\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[6,[37,0],null,[[\"route\",\"model\"],[\"admin_mobile.inventory.receiving\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"number\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"active-link\",\"purchase-orders\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin-mobile/inventory/index/template.hbs"
    }
  });

  _exports.default = _default;
});