define("product-management/pods/shop/deliveries/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EfPCllM1",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[8,\"shop/station-and-queue-picker\",[],[[\"@currentStation\"],[[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"well change-station-horizontal text-center\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"text-2xl\"]],[[\"@route\"],[\"shop.deliveries\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Change Delivery Route\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"currentStation\"]}",
    "meta": {
      "moduleName": "product-management/pods/shop/deliveries/show/template.hbs"
    }
  });

  _exports.default = _default;
});