define("product-management/components/ordering/wizards/conversion-kit/bottom-bar", ["exports", "marygrove-components/components/ordering/wizards/conversion-kit/bottom-bar"], function (_exports, _bottomBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _bottomBar.default;
    }
  });
});