define("product-management/pods/components/stardust/code-snippets/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XS3o2Oaf",
    "block": "{\"symbols\":[\"record\",\"@showRoute\",\"@records\"],\"statements\":[[10,\"table\"],[14,0,\"table table-condensed table-bordered table-striped\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Name\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[[32,2],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1,[\"name\"]]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/code-snippets/list/template.hbs"
    }
  });

  _exports.default = _default;
});