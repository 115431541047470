define("product-management/pods/admin-mobile/inventory/receiving/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    incompleteItems: Ember.computed.filterBy("received-units", "isComplete", false),
    isReceivingComplete: Ember.computed.empty("incompleteItems")
  });

  _exports.default = _default;
});