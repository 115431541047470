define("product-management/pods/components/pm/conversation/empty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qzmqlg9t",
    "block": "{\"symbols\":[\"&default\",\"@isVisible\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"p-2\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/conversation/empty/template.hbs"
    }
  });

  _exports.default = _default;
});