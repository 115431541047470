define("product-management/pods/components/dealers/accounting/prepayment-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1yKRTG0J",
    "block": "{\"symbols\":[\"canDo\",\"@dealer\"],\"statements\":[[10,\"div\"],[14,0,\"display-group\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n        Requires Prepayment\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"can-do\",[],[[\"@model\",\"@modelClass\",\"@action\",\"@policy\"],[[34,2],\"Dealer\",\"show\",\"Dealer::Accounting::Prepay\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"allowed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"tailwind-ui/toggle-switch\",[],[[\"@isOn\",\"@onToggle\"],[[32,2,[\"requiresPrepayment\"]],[30,[36,1],[[32,0,[\"handleSave\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[[32,2,[\"requiresPrepayment\"]],\"Yes\",\"No\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"dealer\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/dealers/accounting/prepayment-button/template.hbs"
    }
  });

  _exports.default = _default;
});