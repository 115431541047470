define("product-management/pods/components/shared/barcode-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    doFocus: function doFocus() {
      this.element.querySelector("input[type='text']").click();
    },
    actions: {
      onScan: function onScan(value) {
        var _this = this;

        return this.attrs["on-scan"](value).then(function () {
          _this.set("value", null);

          _this.doFocus();
        });
      }
    }
  });

  _exports.default = _default;
});