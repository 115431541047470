define("product-management/pods/admin/transportation/deliveries/show/pending-orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2MP/eW03",
    "block": "{\"symbols\":[\"order\"],\"statements\":[[10,\"table\"],[14,0,\"table table-striped table-condensed table-bordered\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"th\"],[12],[2,\"#\"],[13],[2,\"\\n            \"],[10,\"th\"],[12],[2,\"PO #\"],[13],[2,\"\\n            \"],[10,\"th\"],[12],[2,\"Order Status\"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[30,[36,2],[\"dealer.name,id\",[35,1,[\"orders\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"\\n                \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"orders.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[1,[32,1,[\"id\"]]],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"\\n                \"],[1,[32,1,[\"dealerReference\"]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"\\n                \"],[1,[32,1,[\"dealer\",\"name\"]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[[32,1,[\"status\"]]],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"humanize\",\"model\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/transportation/deliveries/show/pending-orders/template.hbs"
    }
  });

  _exports.default = _default;
});