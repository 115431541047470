define("product-management/pods/components/stations/key/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j2/ndBsC",
    "block": "{\"symbols\":[\"@station\"],\"statements\":[[6,[37,1],[[32,1,[\"key\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"station-key\"],[12],[2,\"\\n\\t\"],[1,[30,[36,0],[[32,1,[\"key\"]]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"uppercase\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stations/key/template.hbs"
    }
  });

  _exports.default = _default;
});