define("product-management/pods/components/sun-pro/accounting/delivery-billing-rules/table-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M1OAA9I4",
    "block": "{\"symbols\":[\"@onDelete\",\"@unitPrice\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[32,2,[\"product\",\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleToggleEdit\"]]],null]],null],[12],[2,\"\\n        \"],[1,[30,[36,4],[[32,2,[\"rate\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[11,\"input\"],[24,0,\"form-control\"],[16,2,[32,2,[\"rate\"]]],[4,[38,3],[[32,0,[\"handleEdit\"]]],null],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleRate\"]]],null]],null],[4,[38,2],[\"keydown\",[30,[36,1],[[32,0,[\"handleKeydown\"]]],null]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"td\"],[15,0,[31,[[30,[36,7],[[35,5],\"text-right\",\"text-center\"],null]]]],[12],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[4,[38,2],[\"click\",[32,1]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-times-circle text-red-dark\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[11,\"button\"],[16,\"disabled\",[34,0]],[24,0,\"btn btn-primary btn-sm\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n          Save\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"newRateInvalid\",\"fn\",\"on\",\"did-insert\",\"format-money\",\"isEditing\",\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/accounting/delivery-billing-rules/table-row/template.hbs"
    }
  });

  _exports.default = _default;
});