define("product-management/pods/components/pm/conversation/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/VOLQ3Bt",
    "block": "{\"symbols\":[\"@notes\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,3],null,[[\"notes\",\"note\",\"content\",\"empty\",\"newConversationButton\"],[[32,1],[30,[36,0],[\"pm/conversation/note\"],null],[30,[36,0],[\"pm/conversation/content\"],[[\"isVisible\"],[[30,[36,2],[[32,1,[\"length\"]],0],null]]]],[30,[36,0],[\"pm/conversation/empty\"],[[\"isVisible\"],[[30,[36,1],[[32,1,[\"length\"]],0],null]]]],[30,[36,0],[\"pm/conversation/new-button\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"eq\",\"gt\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/conversation/body/template.hbs"
    }
  });

  _exports.default = _default;
});