define("product-management/pods/components/shop/line-items/roll-tube-station/component", ["exports", "product-management/mixins/shop/hardware/station-component", "js-cookie"], function (_exports, _stationComponent, _jsCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_stationComponent.default, {
    stationName: Ember.computed(function () {
      return _jsCookie.default.get(this.stationCookieName).SHOP_STATION;
    }),
    oldFourInch: Ember.computed.equal("lineItem.details.side_track_type", "four_inch"),
    newFourInch: Ember.computed.equal("lineItem.details.side_track_type", "4 Inch Commercial"),
    hasFourInchTrackUnits: Ember.computed.or("oldFourInch", "newFourInch")
  });

  _exports.default = _default;
});