define("product-management/pods/components/pm/shipping/delivery/dropoff/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VfKlhBqX",
    "block": "{\"symbols\":[\"selectable\",\"@exceptionRecords\",\"@orderRecords\",\"&default\"],\"statements\":[[8,\"pm/ui/selectable-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[18,4,[[30,[36,1],null,[[\"selectable\",\"ordersTable\",\"exceptionsTable\",\"dialog\"],[[32,1],[30,[36,0],[\"pm/ui/table\"],[[\"records\"],[[32,3]]]],[30,[36,0],[\"pm/ui/table\"],[[\"records\"],[[32,2]]]],[30,[36,0],[\"pm/shipping/delivery/dropoff/dialog\"],[[\"selectedOrders\"],[[32,1,[\"selected\"]]]]]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/shipping/delivery/dropoff/template.hbs"
    }
  });

  _exports.default = _default;
});