define("product-management/pods/components/audit-trail-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var META_VALUE = "meta";

  var _default = Ember.Component.extend({
    tagName: "li",
    keySuffixDisplay: Ember.computed("key-suffix", function () {
      var keySuffix = this["key-suffix"];
      return keySuffix === META_VALUE ? "Additional Info" : keySuffix;
    }),
    displayValue: Ember.computed("audit-trail", "section", "key-suffix", function () {
      var fieldName = "".concat(this.section, "_").concat(this["key-suffix"]);
      return this["audit-trail"][fieldName];
    }),
    isVisible: Ember.computed("key-suffix", "displayValue", function () {
      return Ember.isPresent(this.displayValue);
    })
  });

  _exports.default = _default;
});