define("product-management/pods/components/pm/navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DsET9j7w",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,5,\"height: 95px;\"],[14,0,\"ml-auto pt-4 h-full flex flex-row justify-end items-center pr-6\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"item\",\"link-to\"],[[30,[36,0],[\"pm/navigation/item\"],null],[30,[36,0],[\"link-to\"],[[\"class\"],[\"\\n    text-grey-darker hover:text-grey-darkest hover:no-underline focus:no-underline focus:text-grey-darkest\"]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/navigation/template.hbs"
    }
  });

  _exports.default = _default;
});