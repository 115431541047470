define("product-management/pods/components/orders/printing/delivery-ticket/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hz3Y0RQ3",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],\"printDeliveryTicket\",[35,0]],null],[12],[2,\"Print Delivery Ticket\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"order\",\"action\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/orders/printing/delivery-ticket/template.hbs"
    }
  });

  _exports.default = _default;
});