define("product-management/pods/tailwind-ui/toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XXLWWihf",
    "block": "{\"symbols\":[\"@onToggle\",\"@isOn\"],\"statements\":[[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n\\t\"],[10,\"span\"],[14,\"role\",\"checkbox\"],[14,\"tabindex\",\"0\"],[14,\"aria-checked\",\"false\"],[15,0,[31,[[30,[36,1],[[32,2],\"bg-indigo\",\"bg-grey-light\"],null],\" relative inline-block flex-no-shrink h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none\"]]],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[15,0,[31,[[30,[36,1],[[32,2],\"ml-6\"],null],\" inline-block h-4 w-4 rounded-full bg-white shadow transform transition ease-in-out duration-200\"]]],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/tailwind-ui/toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});