define("product-management/pods/components/pm/shipping/pickup/summary-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hviT4DGJ",
    "block": "{\"symbols\":[\"UiModal\",\"item\",\"@detailRoute\"],\"statements\":[[6,[37,10],[[30,[36,9],[\"pm/ui/modal\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,8],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"table\"],[14,0,\"table table-striped table-condensed\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Dealer\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"# of Orders\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[6,[37,3],null,[[\"route\",\"model\"],[[32,3],[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,2,[\"dealerName\"]]]],\"parameters\":[]}]]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[32,2,[\"readyCount\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"blank-slate\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n     There are no items that are ready to be picked up\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,8],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,[32,1],[],[[\"@onClose\"],[[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null],false],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n Foo\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"isShowingModal\",\"mut\",\"action\",\"link-to\",\"sorted\",\"-track-array\",\"each\",\"hasItems\",\"if\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/shipping/pickup/summary-list/template.hbs"
    }
  });

  _exports.default = _default;
});