define("product-management/pods/dealer-admin/pending/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8YYS3cm6",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[\"Pending Orders\"],null]],[2,\"\\n\"],[1,[30,[36,2],null,[[\"title\"],[\"Pending Orders\"]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pending-orders-sub-nav\"],[12],[2,\"\\n        \"],[8,\"link-to\",[],[[\"@query\"],[[30,[36,3],null,[[\"subStatus\"],[\"confirmation\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n                Pending Confirmation\\n\"],[6,[37,4],[[35,0,[\"ordersPendingConfirmation\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"pending-stat\"],[12],[2,\"\\n                    \"],[1,[35,0,[\"ordersPendingConfirmation\",\"length\"]]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,\"link-to\",[],[[\"@query\"],[[30,[36,3],null,[[\"subStatus\"],[\"payment\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n\\n                Pending Prepayment\\n\"],[6,[37,4],[[35,0,[\"ordersPendingPayment\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[10,\"span\"],[14,0,\"pending-stat\"],[12],[2,\"\\n                    \"],[1,[35,0,[\"ordersPendingPayment\",\"length\"]]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"page-title\",\"page-header\",\"hash\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/dealer-admin/pending/template.hbs"
    }
  });

  _exports.default = _default;
});