define("product-management/pods/dealer-admin/order/edit-line-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e3zrszlV",
    "block": "{\"symbols\":[\"modal\",\"cs\"],\"statements\":[[6,[37,7],null,[[\"keyboard\",\"backdropClose\",\"size\",\"onHide\"],[false,false,\"lg\",[30,[36,0],[[32,0],\"closeModal\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[35,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"model\",\"choices\",\"mode\",\"onSave\",\"onClose\"],[[32,2],[35,2],[35,1],[30,[36,0],[[32,0],\"save\"],null],[30,[36,0],[[32,0],\"closeModal\"],null]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"mode\",\"choices\",\"edit-line-item-container\",\"lineItem\",\"changeset\",\"with\",\"bs-modal\"]}",
    "meta": {
      "moduleName": "product-management/pods/dealer-admin/order/edit-line-item/template.hbs"
    }
  });

  _exports.default = _default;
});