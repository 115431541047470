define("product-management/pods/components/password-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      submit: function submit(changeset) {
        return this.attrs.onSubmit(changeset);
      }
    }
  });

  _exports.default = _default;
});