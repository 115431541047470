define("product-management/pods/orders/show/line-items/warranty-claim/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y02uRMTa",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ordering/return-of-goods/warranty-claim\",[],[[\"@lineItem\"],[[34,0]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-right my-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"orders.show\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Return to Order\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/line-items/warranty-claim/template.hbs"
    }
  });

  _exports.default = _default;
});