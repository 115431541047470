define("product-management/pods/invoice-item/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    tax: (0, _model.attr)(),
    isTaxable: Ember.computed("tax", function () {
      var tax = this.tax;
      if (Ember.isEmpty(tax)) return false;
      return tax.toLowerCase() === "tax";
    }),
    invoiceLineItems: (0, _model.hasMany)(),
    products: (0, _model.hasMany)()
  });

  _exports.default = _default;
});