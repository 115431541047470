define("product-management/pods/components/shop/line-items/final-cut-station/interior-unit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      signedWork: function signedWork(data) {
        this.attrs.signedWork(data[1]);
      }
    }
  });

  _exports.default = _default;
});