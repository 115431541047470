define("product-management/pods/current-dealer/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    currentDealer: function currentDealer() {
      var store = this.store;
      var dealer = store.createRecord("dealer");
      return dealer.current().then(function (data) {
        store.pushPayload(data);
        var id = data["data"]["id"];
        return store.peekRecord("dealer", id);
      });
    }
  });

  _exports.default = _default;
});