define("product-management/helpers/floor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.floor = floor;
  _exports.default = void 0;

  function floor(value, opts) {
    return Math.floor(value);
  }

  var _default = Ember.Helper.helper(floor);

  _exports.default = _default;
});