define("product-management/pods/components/tailwind-ui/lists/narrow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YAqoIP9B",
    "block": "{\"symbols\":[\"record\",\"&default\",\"@records\"],\"statements\":[[10,\"ul\"],[14,0,\"divide-y divide-gray-200\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t  \"],[18,2,[[30,[36,1],null,[[\"listItem\",\"record\"],[[30,[36,0],[\"tailwind-ui/lists/narrow/item\"],null],[32,1]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/tailwind-ui/lists/narrow/template.hbs"
    }
  });

  _exports.default = _default;
});