define("product-management/pods/components/fulfillment/delivery-zones/route-setup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GtlYFTcP",
    "block": "{\"symbols\":[\"@deliveryZone\"],\"statements\":[[8,\"fulfillment/delivery-routes/form\",[],[[\"@deliveryZone\",\"@canEdit\"],[[32,1],true]],null],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/fulfillment/delivery-zones/route-setup/template.hbs"
    }
  });

  _exports.default = _default;
});