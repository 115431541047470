define("product-management/pods/components/painless-manufacturing/production/other-units/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EogfyH3x",
    "block": "{\"symbols\":[\"lineItem\",\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[17,3],[12],[2,\"\\n  \"],[10,\"h5\"],[14,0,\"text-center pt-0 mt-0\"],[12],[2,\"\\n    Other Units On This Order\\n  \"],[13],[2,\"\\n  \"],[10,\"table\"],[14,0,\"table table-condensed table-bordered table-striped\"],[12],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"position\",[35,0]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"lineItemsWithStation\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/other-units/template.hbs"
    }
  });

  _exports.default = _default;
});