define("product-management/pods/components/pricing/price-per-square-foot/add-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TXHQzt74",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"h3\"],[12],[2,\"Add an option\"],[13],[2,\"\\n\\t\"],[1,[30,[36,5],null,[[\"availableScreens\",\"product\",\"option\",\"onSave\"],[[35,4],[35,3],[35,2],[30,[36,1],[[32,0],\"save\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"spinner\"],[[\"spin\"],[true]]]],[2,\" Adding\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"action\",\"newOption\",\"product\",\"availableScreens\",\"pricing/price-per-square-foot-form\",\"isAdding\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pricing/price-per-square-foot/add-option/template.hbs"
    }
  });

  _exports.default = _default;
});