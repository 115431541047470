define("product-management/pods/components/orders/date-filters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y2+ysmaQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"startDateValue\",\"endDateValue\",\"label\"],[[35,1],[35,0],\"Placed between:\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"startDateValue\",\"endDateValue\",\"label\"],[[35,4],[35,3],\"Completed between:\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"startDateValue\",\"endDateValue\",\"label\"],[[35,6],[35,5],\"Shipped between:\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"startDateValue\",\"endDateValue\",\"label\"],[[35,8],[35,7],\"Invoiced between:\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"orderDateEnd\",\"orderDateStart\",\"orders/date-filter\",\"completedDateEnd\",\"completedDateStart\",\"shippedDateEnd\",\"shippedDateStart\",\"invoicedDateEnd\",\"invoicedDateStart\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/orders/date-filters/template.hbs"
    }
  });

  _exports.default = _default;
});