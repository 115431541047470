define("product-management/pods/admin/accounting/inventory/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vVOwoR7u",
    "block": "{\"symbols\":[\"item-count\"],\"statements\":[[10,\"h3\"],[12],[2,\"Current Inventory\"],[13],[2,\"\\n\"],[10,\"table\"],[14,0,\"table table-condensed table-striped\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Product\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"SKU\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Physical Stock Count\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[32,1,[\"sku\"]]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[32,1,[\"count\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tfoot\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[14,0,\"text-right\"],[14,\"colspan\",\"2\"],[12],[10,\"strong\"],[12],[2,\"Total\"],[13],[13],[2,\"\\n      \"],[10,\"td\"],[12],[10,\"strong\"],[12],[1,[34,3]],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inventory-item-counts\",\"-track-array\",\"each\",\"total-units\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/accounting/inventory/index/template.hbs"
    }
  });

  _exports.default = _default;
});