define("product-management/pods/components/pm/conversation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iSfioQwC",
    "block": "{\"symbols\":[\"@notes\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"mb-4 border-2 rounded\"],[12],[2,\"\\n\\n  \"],[18,2,[[30,[36,1],null,[[\"body\",\"header\"],[[30,[36,0],[\"pm/conversation/body\"],[[\"notes\"],[[32,1]]]],[30,[36,0],[\"pm/conversation/header\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/conversation/template.hbs"
    }
  });

  _exports.default = _default;
});