define("product-management/pods/components/audit-trail-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    "link-text": "View Audit Trail",
    actions: {
      "toggle-audit-trail": function toggleAuditTrail() {
        this.toggleProperty("is-showing-audit-trail");
      }
    }
  });

  _exports.default = _default;
});