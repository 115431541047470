define("product-management/pods/components/sun-pro/shop/pick-station/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cNu0fz0k",
    "block": "{\"symbols\":[\"station\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-6 col-md-offset-3\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"\\n      Pick Your Station\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"well\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ember-icon-radio-buttons\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[30,[36,6],[\"position\",[35,5]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[15,0,[31,[\"ember-icon-radio-button \",[30,[36,2],[[30,[36,1],[[32,1],[35,0]],null],\"checked\"],null]]]],[12],[2,\"\\n            \"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"handleSelectStation\"]],[32,1]],null]],null],[12],[2,\"\\n              \"],[10,\"i\"],[14,0,\"ember-icon-radio-button-icon fa fa-check-circle\"],[12],[13],[2,\"\\n              \"],[10,\"span\"],[14,0,\"ember-icon-radio-button-label\"],[12],[2,\"\\n                \"],[1,[32,1,[\"name\"]]],[2,\"\\n              \"],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[11,\"button\"],[16,\"disabled\",[34,9]],[24,0,\"btn btn-block btn-lg btn-primary\"],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"handleChooseStation\"]]],null]],null],[12],[2,\"\\n        Select\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"mt-4 text-center\"],[12],[2,\"\\n        or\\n        \"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"handleExitProductionLine\"]]],null]],null],[12],[2,\"\\n          Exit Production Line\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"currentStation\",\"eq\",\"if\",\"fn\",\"on\",\"stations\",\"sort-by\",\"-track-array\",\"each\",\"stationNotSelected\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/sun-pro/shop/pick-station/template.hbs"
    }
  });

  _exports.default = _default;
});