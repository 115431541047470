define("product-management/pods/dealer-admin/order/complete-screen/index/route", ["exports", "marygrove-components/mixins/complete-screen-wizard-route"], function (_exports, _completeScreenWizardRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_completeScreenWizardRoute.default, {
    resetController: function resetController(controller) {
      controller.setProperties({
        selectedScreen: null
      });
    }
  });

  _exports.default = _default;
});