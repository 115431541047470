define("product-management/pods/components/painless-manufacturing/production/view-station/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b9a3i48u",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Loading...\\n\"]],\"parameters\":[]},{\"statements\":[[11,\"a\"],[24,0,\"cursor-pointer font-semibold\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleViewStation\"]]],null]],null],[12],[2,\"\\n  View Station\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"gotoStation\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/view-station/template.hbs"
    }
  });

  _exports.default = _default;
});