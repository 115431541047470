define("product-management/pods/my-account/adapter", ["exports", "product-management/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    urlForFindAll: function urlForFindAll() {
      return this.host + "/api/v1/my-account";
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      return this.host + "/api/v1/my-account";
    }
  });

  _exports.default = _default;
});