define("product-management/pods/orders/show/index/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yASS7LQz",
    "block": "{\"symbols\":[\"lineItem\"],\"statements\":[[6,[37,4],[[35,3,[\"isAdmin\"]]],null,[[\"default\"],[{\"statements\":[[8,\"action-cable-subscriber\",[],[[\"@name\",\"@params\",\"@channel\",\"@received\"],[\"order\",[30,[36,2],null,[[\"id\"],[[35,1,[\"id\"]]]]],\"OrderChannel\",[30,[36,0],[[32,0,[\"handleWebSocket\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[30,[36,5],[\"position\",[35,1,[\"lineItems\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[8,\"painless-manufacturing/line-item/card\",[],[[\"@auditTrailRoute\",\"@lineItem\",\"@orderId\",\"@order\",\"@hasInvoice\",\"@onWarningReview\"],[\"orders.show.index.audit-trail\",[32,1],[32,0,[\"model\",\"id\"]],[32,0,[\"model\"]],[32,0,[\"model\",\"invoice\"]],[30,[36,0],[[32,0,[\"onWarningReview\"]],[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[1,[30,[36,9],[[30,[36,8],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"fn\",\"model\",\"hash\",\"session\",\"if\",\"sort-by\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/index/index/template.hbs"
    }
  });

  _exports.default = _default;
});