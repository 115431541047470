define("product-management/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lN0yNsny",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-4 col-md-offset-4\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"alert alert-warning margin-bottom-20 text-center\"],[12],[2,\"\\n            \"],[10,\"strong\"],[12],[1,[34,1]],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[1,[30,[36,6],null,[[\"identification\",\"password\",\"onAuthenticate\"],[[35,5],[35,4],[30,[36,3],[[32,0],\"authenticate\"],null]]]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[1,[30,[36,0],[\"spinner\"],[[\"spin\"],[true]]]],[2,\" Logging In\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"errorMessage\",\"if\",\"action\",\"password\",\"identification\",\"login-form\",\"isLoggingIn\",\"unless\"]}",
    "meta": {
      "moduleName": "product-management/pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});