define("product-management/pods/components/shop/line-items/final-cut-station/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kty8+Pqi",
    "block": "{\"symbols\":[\"@onComplete\",\"@signedWork\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"shop/line-items/important-instructions\",[],[[\"@lineItem\"],[[34,2]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"],[6,[37,10],[[35,12]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,11],null,[[\"signedWork\",\"lineItem\",\"details\",\"order\",\"complete\"],[[32,2],[35,2],[35,3],[35,1],[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,8],null,[[\"lineItem\"],[[35,2]]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\"],[6,[37,10],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,7],null,[[\"nextStep\",\"details\",\"lineItem\",\"order\"],[[30,[36,4],[[32,0],\"nextStep\"],null],[35,3],[35,2],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,6],[[35,5]],[[\"nextStep\",\"prevStep\",\"details\",\"lineItem\",\"order\",\"signedWork\",\"hasSignature\",\"complete\"],[[30,[36,4],[[32,0],\"nextStep\"],null],[30,[36,4],[[32,0],\"prevStep\"],null],[35,3],[35,2],[35,1],[32,2],[35,0],[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hasSignature\",\"order\",\"lineItem\",\"details\",\"action\",\"componentName\",\"component\",\"shop/line-items/final-cut-station/step-1\",\"custom/shop/four-five-warning\",\"isInitializing\",\"if\",\"shop/line-items/final-cut-station/interior-unit\",\"isInteriorUnit\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/line-items/final-cut-station/template.hbs"
    }
  });

  _exports.default = _default;
});