define("product-management/pods/dealer-discount/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    dealer: (0, _model.belongsTo)(),
    productType: (0, _model.belongsTo)(),
    // percentage: DS.attr(),
    percentage: (0, _model.attr)("string")
  });

  _exports.default = _default;
});