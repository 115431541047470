define("product-management/pods/admin/settings/products/show/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XNUNtDhA",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[30,[36,3],[[35,2,[\"isSystemManager\"]],[35,0,[\"product\",\"priceMultiplier\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui/red-message\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-2xl\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-exclamation-circle mr-2\"],[12],[13],[2,\"\\n      The Price Multiplier:\\n      \"],[10,\"span\"],[14,0,\"underline\"],[12],[2,\"\\n        \"],[1,[35,0,[\"product\",\"priceMultiplier\",\"name\"]]],[2,\"\\n      \"],[13],[2,\"\\n      is in effect for this product. The value is\\n      \"],[1,[30,[36,1],[[35,0,[\"product\",\"priceMultiplier\",\"amount\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"flex flex-row\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"text-3xl font-bold\"],[12],[2,\"\\n      Product Details\\n    \"],[13],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"text-sm\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"\"],[12],[2,\"\\n        Updated on\\n        \"],[1,[30,[36,5],[[35,0,[\"product\",\"updatedAt\"]],\"lll\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"painless-manufacturing/product/details-card\",[],[[\"@product\"],[[34,0,[\"product\"]]]],null],[2,\"\\n\"],[8,\"painless-manufacturing/product/configuration\",[],[[\"@product\"],[[34,0,[\"product\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\",\"pct\",\"session\",\"and\",\"if\",\"moment-format\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/products/show/index/template.hbs"
    }
  });

  _exports.default = _default;
});