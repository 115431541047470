define("product-management/pods/admin/bi/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qfr70/xG",
    "block": "{\"symbols\":[\"Container\"],\"statements\":[[8,\"app-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header-title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      Reports and KPIs\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.reports\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Reports\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.kpis\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      KPIs\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.goals\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Goals\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.critical-inventory\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Critical Inventory\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/admin/bi/template.hbs"
    }
  });

  _exports.default = _default;
});