define("product-management/pods/admin/orders/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var filters = {
    quoted: "Quotes",
    ordered: "New Orders",
    prepayment: "Prepayment",
    in_process: "In Production",
    on_hold: "On Hold",
    complete: "Complete",
    on_truck: "On Truck",
    shipped: "Shipped",
    invoiced: "Invoiced"
  };
  var hideNumberStatuses = ["pending", "en_route", "submitted", "received", "closed", "quoted"];

  var _default = Ember.Controller.extend({
    filters: filters,
    queryParams: ["page", "status", "dealer", "poNumber", "mode"],
    status: "ordered",
    mode: "",
    page: 1,
    dealer: "",
    poNumber: "",
    sortBy: ["dealer.name"],
    filteredOrders: Ember.computed.filterBy("model.orders", "isNew", false),
    orders: Ember.computed.sort("filteredOrders", "sortBy"),
    pageCount: Ember.computed.alias("model.orders.meta.page-count"),
    isViewingQuotesList: Ember.computed.equal("status", "quoted"),
    showOrderNumber: Ember.computed("status", function () {
      return !hideNumberStatuses.includes(this.status);
    }),
    actions: {
      changePage: function changePage(page) {
        this.set("page", page);
      },
      changeStatus: function changeStatus(newStatus) {
        this.setProperties({
          page: 1,
          status: newStatus,
          mode: ""
        });
      }
    },
    orderDetailRoute: Ember.computed("isViewingQuotesList", function () {
      return this.isViewingQuotesList ? "orders.show" : "admin.order";
    })
  });

  _exports.default = _default;
});