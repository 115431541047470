define("product-management/instance-initializers/page-title-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'page-title-setup-browser',
    before: 'head-browser',
    initialize: function initialize() {
      if (typeof FastBoot === 'undefined') {
        // Remove <title> tags from the initial index.html page, so they can be correctly replaced
        // and managed by ember-cli-head.
        var titles = document.getElementsByTagName('title');

        for (var i = 0; i < titles.length; i++) {
          var title = titles[i];
          title.parentNode.removeChild(title);
        }
      }
    }
  };
  _exports.default = _default;
});