define("product-management/pods/components/stardust/report/filters/picklist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mv7kAkji",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[8,\"ember-selectize\",[],[[\"@content\",\"@optionValuePath\",\"@optionLabelPath\",\"@value\",\"@class\",\"@select-value\"],[[32,1,[\"listData\"]],\"content.id\",\"content.label\",[34,0],\"w-full\",[30,[36,1],[[32,0,[\"changeValue\"]]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"filterValue\",\"fn\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/report/filters/picklist/template.hbs"
    }
  });

  _exports.default = _default;
});