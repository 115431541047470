define("product-management/pods/components/shop/current-production-line/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mHqWj70B",
    "block": "{\"symbols\":[\"@currentProductionLine\"],\"statements\":[[10,\"div\"],[14,0,\"clearfix well change-station-horizontal\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pull-left\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Current Production Line is \"],[10,\"span\"],[14,0,\"current-station\"],[12],[1,[32,1,[\"name\"]]],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n    \"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-danger\",\"shop.pick_work_queue\"]],[[\"default\"],[{\"statements\":[[2,\"Change Line\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/current-production-line/template.hbs"
    }
  });

  _exports.default = _default;
});