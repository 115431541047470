define("product-management/pods/admin/transportation/vehicles/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jf+LNFl2",
    "block": "{\"symbols\":[\"vehicle\"],\"statements\":[[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-1/3 p-4 vehicle-sub-nav\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[30,[36,1],[\"name\",[35,0,[\"vehicles\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"my-1 p-2 border-radius border border-grey-light\"],[12],[2,\"\\n        \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.transportation.vehicles.show.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"my-1 p-2 border-radius border border-grey-light bg-blue-light text-center new\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"text-white\"]],[[\"@route\"],[\"admin.transportation.vehicles.show.new\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Add \"],[1,[30,[36,4],[[35,0,[\"name\"]]],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-2/3 p-4\"],[12],[2,\"\\n    \"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"sort-by\",\"-track-array\",\"each\",\"humanize\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/transportation/vehicles/show/template.hbs"
    }
  });

  _exports.default = _default;
});