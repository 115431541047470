define("product-management/pods/order-summary/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: (0, _model.attr)(),
    dealerReference: (0, _model.attr)(),
    dealerName: (0, _model.attr)(),
    dealerId: (0, _model.attr)(),
    accountManagerId: (0, _model.attr)(),
    accountManager: (0, _model.attr)(),
    shippingMethod: (0, _model.attr)(),
    ecd: (0, _model.attr)(),
    deliveryZone: (0, _model.attr)(),
    deliveryOn: (0, _model.attr)(),
    deliveryId: (0, _model.attr)(),
    carrier: (0, _model.attr)(),
    trackingInfo: (0, _model.attr)(),
    completeAt: (0, _model.attr)(),
    createdAt: (0, _model.attr)(),
    orderedAt: (0, _model.attr)(),
    invoicedAt: (0, _model.attr)(),
    onTruckAt: (0, _model.attr)(),
    readyForPickupAt: (0, _model.attr)(),
    shippedAt: (0, _model.attr)(),
    inProcessAt: (0, _model.attr)(),
    units: (0, _model.attr)(),
    invoiceId: (0, _model.attr)(),
    invoiceNumber: (0, _model.attr)(),
    auditTrail: (0, _model.attr)("raw")
  }); // [:id,:status,:dealer_reference,:dealer_name,:dealer_id,:shipping_method,:carrier,:tracking_info,:complete_at,:ordered_at,:invoiced_at,:on_truck_at,:ready_for_pickup_at,:shipped_at,:invoice_id,:invoice_number,:audit_trail,:work_queue_name,:work_queue_id]


  _exports.default = _default;
});