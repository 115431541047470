define("product-management/pods/components/stardust/report/compared-footers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ycYkbudZ",
    "block": "{\"symbols\":[\"@showChangePercentage\",\"@showChange\",\"@comparing\",\"@cellClass\",\"@totals\",\"&default\"],\"statements\":[[18,6,[[30,[36,1],null,[[\"footer\"],[[30,[36,0],[\"stardust/report/compared-footers/item\"],[[\"totals\",\"cellClass\",\"comparing\",\"showChange\",\"showChangePercentage\"],[[32,5],[32,4],[32,3],[32,2],[32,1]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/report/compared-footers/template.hbs"
    }
  });

  _exports.default = _default;
});