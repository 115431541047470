define("product-management/pods/components/shop/packaging/unit-details/interior-unit-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/AWaCcKP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"dl\"],[12],[2,\"\\n  \"],[10,\"dt\"],[12],[2,\"Mesh\"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[1,[35,0,[\"mesh_name\"]]],[2,\" \"],[1,[35,1,[\"details\",\"mesh_color\"]]],[13],[2,\"\\n  \"],[10,\"dt\"],[12],[2,\"Fascia\"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[1,[35,0,[\"fascia_color\"]]],[13],[2,\"\\n  \"],[10,\"dt\"],[12],[2,\"Remotes\"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Qty: \"],[1,[35,0,[\"remote_quantity\"]]],[10,\"br\"],[12],[13],[2,\"\\n    Channels: \"],[1,[35,0,[\"control_remote_channels\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"     No Remote\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"details\",\"lineItem\",\"needsRemote\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shop/packaging/unit-details/interior-unit-display/template.hbs"
    }
  });

  _exports.default = _default;
});