define("product-management/pods/components/shared/select-all-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vOOggaOv",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"type\",\"checked\",\"change\"],[\"checkbox\",[35,1],[30,[36,3],[[32,0],\"toggleItem\",[35,2],[35,1],[35,0]],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"collection\",\"isChecked\",\"itemId\",\"action\",\"input\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/shared/select-all-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});