define("product-management/pods/components/navigation/nav-logo/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var routeMap = {
    admin: "admin.orders",
    shop: "shop",
    dealer: "dealer_admin.orders"
  };

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    routeName: Ember.computed("session.role", function () {
      return routeMap[this.get("session.role")];
    })
  });

  _exports.default = _default;
});