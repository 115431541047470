define("product-management/pods/components/ui/filter-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P10tY/1+",
    "block": "{\"symbols\":[\"@on-filter\",\"&default\",\"@on-clear-filter\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,6,\"#\"],[4,[38,2],[[32,0],\"clearAndClose\"],null],[12],[2,\"x\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"filter-option\"],[12],[2,\"\\n        \"],[18,2,[[30,[36,2],[[32,0],[32,1]],null]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,2],[[32,0],[32,1]],null],[12],[2,\"Apply Filter\"],[13],[2,\"\\n      or \"],[11,\"a\"],[24,6,\"#\"],[4,[38,2],[[32,0],[32,3]],null],[12],[2,\"clear filters\"],[13],[2,\"\\n\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,2],[[32,0],[30,[36,1],[[35,0]],null],true],null],[12],[2,\"Filter List\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isFilterOptionBoxOpen\",\"mut\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/filter-bar/template.hbs"
    }
  });

  _exports.default = _default;
});