define("product-management/pods/components/painless-manufacturing/production/release-from-on-hold/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZlwMMKO3",
    "block": "{\"symbols\":[\"ShopCode\",\"reason\"],\"statements\":[[11,\"a\"],[24,0,\"cursor-pointer font-semibold\"],[4,[38,7],[\"click\",[30,[36,6],[[32,0,[\"toggleRelease\"]]],null]],null],[12],[2,\"\\n  Release from On Hold\\n\"],[13],[2,\"\\n\"],[6,[37,8],[[32,0,[\"isShowingRelease\"]]],null,[[\"default\"],[{\"statements\":[[8,\"shop/shop-code\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"font-semibold\"],[12],[2,\"\\n      Hold Reason:\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"my-2\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n      by \"],[1,[34,1]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"font-semibold\"],[12],[2,\"Reason\"],[13],[2,\"\\n    \"],[8,\"power-select-from-option-list\",[],[[\"@renderInPlace\",\"@name\",\"@onChange\",\"@selected\"],[true,\"Release from Hold Reasons\",[34,2],[32,0,[\"reason\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"my-4\"],[12],[2,\"\\n    \"],[8,[32,1,[\"entry\"]],[],[[\"@inputType\"],[\"text\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,[36,5],[[30,[36,4],[[32,1,[\"isValid\"]]],null],[30,[36,4],[[35,3]],null]],null]],[24,0,\"btn btn-block btn-sm btn-danger\"],[4,[38,7],[\"click\",[30,[36,6],[[32,0,[\"handleRelease\"]],[32,1,[\"value\"]]],null]],null],[12],[2,\"\\n      Confirm\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"holdReason\",\"codeHoldReason\",\"handleChangeReason\",\"reasonPresent\",\"not\",\"or\",\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/painless-manufacturing/production/release-from-on-hold/template.hbs"
    }
  });

  _exports.default = _default;
});