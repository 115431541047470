define("product-management/pods/components/ui/red-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GwIfw6PG",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"flex flex-row py-2 text-center bg-red text-white font-semibold items-center justify-center mb-2\"],[17,1],[12],[2,\"\\n\\t\"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/red-message/template.hbs"
    }
  });

  _exports.default = _default;
});