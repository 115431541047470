define("product-management/pods/option-set/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    products: (0, _model.hasMany)(),
    name: (0, _model.attr)(),
    options: (0, _model.attr)("raw") // validation: DS.attr("raw"),

  });

  _exports.default = _default;
});