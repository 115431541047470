define("product-management/pods/admin/settings/stations/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ayIitlsa",
    "block": "{\"symbols\":[\"PageHeader\"],\"statements\":[[8,\"settings/page-header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Station Details\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,\"link-to\",[],[[\"@route\"],[\"admin.settings.stations.index\"]],[[\"default\"],[{\"statements\":[[2,\"Back to Stations\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"mb-4\"],[12],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"font-semibold\"],[12],[2,\"Name:\"],[13],[2,\" \"],[1,[35,0,[\"name\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"w-full\"],[12],[2,\"\\n\\t\"],[8,\"stations/form\",[],[[\"@station\"],[[34,0]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/stations/show/template.hbs"
    }
  });

  _exports.default = _default;
});