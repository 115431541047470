define("product-management/pods/search/adapter", ["exports", "product-management/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    urlForQuery: function urlForQuery(params, modelName) {
      var host = this.host,
          namespace = this.namespace;
      return "".concat(host, "/").concat(namespace, "/search");
    }
  });

  _exports.default = _default;
});