define("product-management/pods/admin/production-lines/show/schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E50V6TYl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex mb-8\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"handleChangeDates\"]],-1],null]],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-arrow-circle-left\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"handleChangeDates\"]],1],null]],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-arrow-circle-right\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  Changing Dates\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"painless-manufacturing/production/schedule\",[],[[\"@productionLineId\",\"@startAt\",\"@endAt\",\"@ticks\",\"@onMove\"],[[34,0,[\"id\"]],[34,0,[\"startAtWeekOne\"]],[34,0,[\"endAtWeekOne\"]],[34,1],[30,[36,2],[[32,0,[\"handleMove\"]]],null]]],null],[2,\"\\n  \"],[8,\"painless-manufacturing/production/schedule\",[],[[\"@productionLineId\",\"@startAt\",\"@endAt\",\"@ticks\",\"@onMove\"],[[34,0,[\"id\"]],[34,0,[\"startAtWeekTwo\"]],[34,0,[\"endAtWeekTwo\"]],[34,1],[30,[36,2],[[32,0,[\"handleMove\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[30,[36,7],[[30,[36,6],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"ticks\",\"fn\",\"on\",\"isChangingDates\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/production-lines/show/schedule/template.hbs"
    }
  });

  _exports.default = _default;
});