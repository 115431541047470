define("product-management/pods/components/wip/queue-tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WwHTZuRk",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"tagName\",\"route\",\"model\"],[\"li\",\"admin.wip.queues.detail\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"a\"],[12],[2,\"\\n    \"],[11,\"span\"],[24,0,\"work-queue-status-toggle\"],[4,[38,1],[[32,0],\"toggleStatus\",[35,0]],[[\"bubbles\"],[false]]],[12],[2,\"\\n      \"],[1,[30,[36,3],[[35,2]],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"work-queue-name\"],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n    (\"],[1,[34,4]],[2,\")\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"workQueue\",\"action\",\"icon\",\"fa-icon\",\"status\",\"link-to\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/wip/queue-tab/template.hbs"
    }
  });

  _exports.default = _default;
});