define("product-management/pods/users/confirmation/controller", ["exports", "product-management/validations/password", "ember-ui-helpers/mixins/notifyable"], function (_exports, _password, _notifyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PasswordSet = Ember.Object.extend({
    password: "",
    passwordConfirmation: ""
  });

  var _default = Ember.Controller.extend(_notifyable.default, {
    PasswordValidations: _password.default,
    passwordSet: Ember.computed(function () {
      return PasswordSet.create();
    }),
    actions: {
      createPassword: function createPassword(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            changeset.save().then(function () {
              _this.info("Your password has been set. You can now login");

              _this.transitionToRoute("login");
            }, function () {
              _this.error("Your password could not be set. Please try again. If the problem continues, please contact support.");
            });
          } else {
            _this.error("You have entered an invalid password.");
          }
        });
      }
    }
  });

  _exports.default = _default;
});