define("product-management/pods/dealer-admin/order/complete-screen/route", ["exports", "product-management/mixins/orders/product-type-route"], function (_exports, _productTypeRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_productTypeRoute.default, {
    unitType: "Complete Unit"
  });

  _exports.default = _default;
});