define("product-management/pods/components/ui/progress-ring/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uOq0RdXV",
    "block": "{\"symbols\":[\"@value\",\"@percentComplete\",\"@completedRingColor\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"set-size charts-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pie-wrapper style-2\"],[12],[2,\"\\n      \"],[11,\"span\"],[16,0,[31,[\"label \",[30,[36,3],[[35,2],\"label-value\"],null],\" \",[30,[36,1],[[35,0],\"cursor-pointer-important\"],null]]]],[4,[38,5],[\"click\",[30,[36,4],[[32,0,[\"toggleView\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,1],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[32,2]],[10,\"span\"],[14,0,\"smaller\"],[12],[2,\"%\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"pie \",[30,[36,1],[[35,6],\"rect-clip\",\"non-rect-clip\"],null]]]],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"left-side half-circle\"],[15,5,[31,[\"transform: rotate(\",[34,8],\"deg);border-color:\\n            \",[30,[36,3],[[30,[36,7],[[32,2],100],null],[32,3]],null]]]],[12],[13],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"right-side half-circle \",[34,9]]]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"shadow\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hasValue\",\"if\",\"showPercentage\",\"unless\",\"fn\",\"on\",\"overFifty\",\"eq\",\"leftRotation\",\"rightDisplay\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/ui/progress-ring/template.hbs"
    }
  });

  _exports.default = _default;
});