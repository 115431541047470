define("product-management/pods/components/ordering/wizards/screen-only/pick-manufacturer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    manufacturers: [{
      value: "Marygrove Zipper",
      displayValue: "Marygrove Zipper (Top and Bottom 8 Molded, Left and Right 7 Coiled)",
      top: "8 Molded",
      bottom: "8 Molded",
      left: "7 Coiled",
      right: "7 Coiled",
      trackSystem: "standard",
      zipperType: "Zipper"
    }, {
      value: "Marygrove Keder",
      displayValue: "Marygrove Keder (Top and Bottom 8 Molded, Left and Right #4 Keder)",
      top: "8 Molded",
      bottom: "8 Molded",
      left: "Keder",
      right: "Keder",
      trackSystem: "Standard",
      zipperType: "Keder"
    }, {
      value: "Marygrove 4 Inch Commercial",
      displayValue: "Marygrove 4 Inch Commercial (Top and Bottom 8 Molded, Left and Right #4 Keder)",
      top: "8 Molded",
      bottom: "8 Molded",
      left: "Keder",
      right: "Keder",
      zipperType: "Keder",
      trackSystem: "Commercial"
    }, {
      value: "Custom",
      displayValue: "Custom (Select your own zipper configuration)"
    }],
    selectedZipperSettings: Ember.computed("line-item.details.manufacturer", function () {
      var _this = this;

      return this.manufacturers.find(function (item) {
        return item.value === _this.get("line-item.details.manufacturer");
      });
    }),
    applyZipperSettings: function applyZipperSettings(lineItem, custom) {
      var manufacturerSettings;

      if (custom) {
        manufacturerSettings = {
          top: null,
          bottom: null,
          left: null,
          right: null,
          zipperType: null,
          trackSystem: null
        };
      } else {
        manufacturerSettings = this.selectedZipperSettings;
      }

      lineItem.setProperties({
        "details.zipper_top": manufacturerSettings.top,
        "details.zipper_bottom": manufacturerSettings.bottom,
        "details.zipper_left": manufacturerSettings.left,
        "details.zipper_right": manufacturerSettings.right,
        "details.zipper_type": manufacturerSettings.zipperType,
        "details.track_system": manufacturerSettings.trackSystem
      });
    },
    actions: {
      pickManufacturer: function pickManufacturer(lineItem, value) {
        this.applyZipperSettings(lineItem, value === "Custom");
      }
    }
  });

  _exports.default = _default;
});