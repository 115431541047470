define("product-management/pods/components/shop/misc-items/component", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["well"],
    units: (0, _emberGroupBy.default)('misc-items', 'dealerReference'),
    isVisible: Ember.computed.notEmpty("misc-items")
  });

  _exports.default = _default;
});