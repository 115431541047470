define("product-management/pods/components/stardust/report/filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Fk45JMv",
    "block": "{\"symbols\":[\"@filter\",\"&default\"],\"statements\":[[18,2,[[30,[36,2],null,[[\"filter\"],[[30,[36,1],[[35,0]],[[\"filter\"],[[32,1]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"filterName\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/report/filter/template.hbs"
    }
  });

  _exports.default = _default;
});