define("product-management/pods/components/stardust/code-snippets/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VN5de7so",
    "block": "{\"symbols\":[\"codeEditor\",\"@indexRoute\"],\"statements\":[[8,\"pm/ui/code-editor\",[],[[\"@editorValue\"],[[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Name\"],[13],[2,\"\\n    \"],[10,\"input\"],[15,2,[34,1]],[15,\"oninput\",[30,[36,2],[[32,0],\"handleInput\",\"name\"],null]],[14,0,\"form-control\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Group\"],[13],[2,\"\\n    \"],[10,\"input\"],[15,2,[34,3]],[15,\"oninput\",[30,[36,2],[[32,0],\"handleInput\",\"group\"],null]],[14,0,\"form-control\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Configuration\"],[13],[2,\"\\n    \"],[8,[32,1,[\"editor\"]],[],[[\"@value\",\"@lines\",\"@mode\"],[[34,0],40,\"ace/mode/ruby\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"button\"],[16,\"disabled\",[30,[36,5],[[35,4]],null]],[24,0,\"btn btn-primary btn-sm\"],[4,[38,7],[\"click\",[30,[36,6],[[32,0,[\"handleSubmit\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,9],[[35,8,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      Saving...\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Save\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,9],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    or \"],[8,\"link-to\",[],[[\"@route\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"cancel\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"configuration\",\"name\",\"action\",\"group\",\"isValid\",\"not\",\"fn\",\"on\",\"save\",\"if\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/stardust/code-snippets/form/template.hbs"
    }
  });

  _exports.default = _default;
});