define("product-management/pods/orders/show/index/index/note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fvt9PNgp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"static\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"fixed pin-t pin-r bg-white sm:w-full md:w-1/2 lg:w-1/3 z-top h-screen\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"p-4 flex justify-between items-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"text-xl font-semibold block mb-2\"],[12],[2,\"\\n          Conversation Details\\n        \"],[13],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[8,\"link-to\",[],[[\"@route\"],[\"orders.show\"]],[[\"default\"],[{\"statements\":[[2,\"Back to Order\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\\n      \"],[13],[2,\"\\n\\n      \"],[8,\"messenger/flag-button\",[],[[\"@model\"],[[34,0]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"pm/notes/item\",[],[[\"@dealerId\",\"@noteId\",\"@showingComments\"],[[34,0,[\"order\",\"dealer\",\"id\"]],[34,0,[\"noteId\"]],true]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "product-management/pods/orders/show/index/index/note/template.hbs"
    }
  });

  _exports.default = _default;
});