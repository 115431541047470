define("product-management/pods/admin/production-lines/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8WTiejeQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container mt-2 mb-8\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-left\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"relative\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"text-grey-dark sublink mr-2 whitespace-no-wrap font-medium text-sm leading-5 text-indigo focus:outline-none focus:text-indigo-darker focus:border-indigo-dark hover:no-underline focus:no-underline\"]],[[\"@route\"],[\"admin.production-lines.show.index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Production Line\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"text-grey-dark sublink mr-2 whitespace-no-wrap font-medium text-sm leading-5 text-indigo focus:outline-none focus:text-indigo-darker focus:border-indigo-dark hover:no-underline focus:no-underline\"]],[[\"@route\"],[\"admin.production-lines.show.schedule\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Schedule\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"text-grey-dark sublink whitespace-no-wrap font-medium text-sm leading-5 text-indigo focus:outline-none focus:text-indigo-darker focus:border-indigo-dark hover:no-underline focus:no-underline\"]],[[\"@route\"],[\"admin.production-lines.show.configuration\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Configuration\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"mt-4\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "product-management/pods/admin/production-lines/show/template.hbs"
    }
  });

  _exports.default = _default;
});