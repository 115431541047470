define("product-management/pods/invoice-line-item/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    invoice: (0, _model.belongsTo)(),
    invoiceItem: (0, _model.belongsTo)(),
    rate: (0, _model.attr)(),
    description: (0, _model.attr)(),
    quantity: (0, _model.attr)(),
    notes: (0, _model.attr)(),
    hasNotes: Ember.computed.notEmpty("notes"),
    total: Ember.computed("quantity", "rate", function () {
      var quantity = this.quantity;
      var rate = this.rate;
      if (Ember.isEmpty(rate) || Ember.isEmpty(quantity) || rate === undefined || quantity === undefined) return 0;
      return Number(quantity) * Number(rate);
    })
  });

  _exports.default = _default;
});