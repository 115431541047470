define("product-management/pods/delivery-queue/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    dealerReference: (0, _model.attr)(),
    quantity: (0, _model.attr)(),
    orderId: (0, _model.attr)(),
    dealerId: (0, _model.attr)(),
    dealerName: (0, _model.attr)(),
    position: (0, _model.attr)(),
    status: (0, _model.attr)(),
    productName: (0, _model.attr)(),
    onlyPicked: (0, _model.attr)(),
    sideTrackStatus: (0, _model.attr)(),
    bottomBarStatus: (0, _model.attr)(),
    finalAssemblyStatus: (0, _model.attr)(),
    awningStatus: (0, _model.attr)(),
    miscStatus: (0, _model.attr)(),
    assemblyLoaded: (0, _model.attr)(),
    awningLoaded: (0, _model.attr)(),
    sideTrackLoaded: (0, _model.attr)(),
    bottomBarLoaded: (0, _model.attr)(),
    screenLoaded: (0, _model.attr)(),
    partLoaded: (0, _model.attr)(),
    workQueueId: (0, _model.attr)(),
    notes: (0, _model.attr)(),
    details: (0, _model.attr)("raw"),
    rollTubeLoaded: (0, _model.attr)(),
    productType: (0, _model.attr)(),
    sequence: (0, _model.attr)(),
    deliveryStatus: (0, _model.attr)(),
    workflowDetails: (0, _model.attr)(),
    deliveryWorkflow: (0, _model.attr)(),
    loadedStatuses: (0, _model.attr)("array"),
    camelizedLoadedStatuses: Ember.computed.map("loadedStatuses", function (item) {
      return Ember.String.camelize(item);
    }),
    isNotFullyLoaded: Ember.computed.not("isFullyLoaded"),
    isFullyLoaded: Ember.computed("assemblyLoaded", "sideTrackLoaded", "bottomBarLoaded", "screenLoaded", "rollTubeLoaded", "partLoaded", "awningLoaded", function () {
      var assemblyLoaded = this.assemblyLoaded;
      var sideTrackLoaded = this.sideTrackLoaded;
      var bottomBarLoaded = this.bottomBarLoaded;
      var screenLoaded = this.screenLoaded;
      var rollTubeLoaded = this.rollTubeLoaded;
      var partLoaded = this.partLoaded;
      var awningLoaded = this.awningLoaded;
      var loaded = Ember.A();
      if (assemblyLoaded != null) loaded.addObject(assemblyLoaded);
      if (rollTubeLoaded != null) loaded.addObject(rollTubeLoaded);
      if (sideTrackLoaded != null) loaded.addObject(sideTrackLoaded);
      if (bottomBarLoaded != null) loaded.addObject(bottomBarLoaded);
      if (screenLoaded != null) loaded.addObject(screenLoaded);
      if (partLoaded != null) loaded.addObject(partLoaded);
      if (awningLoaded != null) loaded.addObject(awningLoaded);
      return loaded.length === 0 ? false : loaded.every(function (item) {
        return item === true;
      });
    })
  });

  _exports.default = _default;
});