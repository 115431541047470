define("product-management/pods/components/pm/ui/mentionable-textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uzIeKjAO",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[17,1],[24,0,\"form-control mentionable-textarea\"],[4,[38,0],[[32,0,[\"setupTribute\"]],[32,0]],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/pm/ui/mentionable-textarea/template.hbs"
    }
  });

  _exports.default = _default;
});