define("product-management/pods/components/google/address/auto-complete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y0qg9MNE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,4],null,[[\"searchBox\",\"clearAddress\",\"address\"],[[30,[36,2],[\"google/address/auto-complete/search-box\"],[[\"onPlaceChanged\",\"focusSearch\"],[[30,[36,1],[[32,0,[\"fillInAddress\"]]],null],[35,3]]]],[30,[36,2],[\"google/address/auto-complete/clear\"],[[\"onClear\"],[[30,[36,1],[[32,0,[\"clearAddress\"]]],null]]]],[35,0]]]]]]],\"hasEval\":false,\"upvars\":[\"address\",\"fn\",\"component\",\"focusSearch\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/google/address/auto-complete/template.hbs"
    }
  });

  _exports.default = _default;
});