define("product-management/pods/components/can-do/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Md6AZrJn",
    "block": "{\"symbols\":[\"@updateWith\",\"&default\"],\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"span\"],[24,0,\"hide\"],[4,[38,0],[[32,0,[\"handleUpdate\"]],[32,0],[32,1]],null],[12],[2,\"\\n        \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,2,[[30,[36,2],null,[[\"allowed\"],[[32,0,[\"allowed\"]]]]]]]],\"hasEval\":false,\"upvars\":[\"did-update\",\"if\",\"hash\"]}",
    "meta": {
      "moduleName": "product-management/pods/components/can-do/template.hbs"
    }
  });

  _exports.default = _default;
});