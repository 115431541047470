define("product-management/pods/admin/settings/product-weights/categories/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ibs5wlOW",
    "block": "{\"symbols\":[],\"statements\":[[8,\"products/product-weight-categories/form\",[],[[\"@model\"],[null]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "product-management/pods/admin/settings/product-weights/categories/new/template.hbs"
    }
  });

  _exports.default = _default;
});